import { ContactPerson } from "./ContactPerson";

export interface Supplier {
  Id: string;
  CompanyName: string;
  VatNumber: string;
  ContactPerson: ContactPerson;
  Email: string;
  About?: string;
  AvatarUrl?: string;
}

export type UserSupplierDTO = {
  id: number;
  appUserId?: number;
  supplierId?: number;
  isDefault?: boolean;
  supplier?: SupplierDTO;
};

export type SupplierDTO = {
  id: string;
  name?: string;
  emailAddress?: string;
  country?: string;
  city?: string;
  streetName?: string;
  streetNumber?: string;
  postalCode?: string;
  phoneNumber?: string;
  faxNumber?: string;
  divisionName?: string;
  avatar?: string;
  supplierBackgroundImg?: string;
  vesselMaintainerResponsibleId?: string;
  vesselMaintainerApproverId?: string;
  supplierImages?: SupplierImageDTO[];
  alias?: string;
  imageOfSignature?: string;
};

export type SupplierDetailsDTO = {
  id: string;
  name?: string;
  emailAddress?: string;
  address?: string;
  postalCode?: string;
  phoneNumber?: string;
  faxNumber?: string;
  divisionName?: string;
  avatar?: string;
  supplierBackgroundImg?: string;
  vesselMaintainerResponsibleId?: string;
  vesselMaintainerApproverId?: string;
  supplierImages?: SupplierImageDTO[];
  alias?: string;
  imageOfSignature?: string;
  function?: string;
  nameOfEmployee?: string;
  username?: string;
};

export type SupplierImageDTO = {
  id?: number;
  name?: string;
  supplierId?: number;
};
export interface Suppliers {
  Suppliers: Supplier[];
  TotalItems: number;
  Page: number;
}

export type SupplierInventoryDTO = {
  id?: number;
  productName?: string;
  productCode?: string;
  containsHazMat?: boolean;
  poItemIdReferenceId?: number;
  supplierId?: number;
  supplier?: SupplierDTO;
  productInformation?: string;
  itemUnit?: string;
  supplierInventoryMaterial?: SupplierInventoryMaterialDTO[];
};

export type SupplierInventoryMaterialDTO = {
  id?: number;
  name: string;
  category: string;
  comment?: string;
  thresholdValue: string;
  presentAboveThresholdValue: boolean;
  mass: number;
  unit: string;
  supplierInventoryId?: number;
  comment1?: string;
  comment2?: string;
};

export enum ImportSiWizardSteps {
  SELECT_EXCEL_FILE,
  SI_VALIDATION,
  FINISHED,
}
