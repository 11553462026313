// material
import { LoadingButton } from "@mui/lab";
import { Box, Container, Typography, DialogActions } from "@mui/material";
// custom
import { DialogAnimate } from "../animate";
import Scrollbar from "../Scrollbar";

// ----------------------------------------------------------------------
type VesselMapWarningModalProps = {
  isOpenPreview: boolean;
  onClosePreview: VoidFunction;
};

export default function VesselMapWarningModal({
  isOpenPreview,
  onClosePreview,
}: VesselMapWarningModalProps) {
  const handleSubmit = () => {
    onClosePreview();
  };

  return (
    <DialogAnimate open={isOpenPreview} onClose={onClosePreview}>
      <Scrollbar>
        <Container>
          <Box sx={{ mt: 3, mb: 5 }}>
            <Typography variant="h6" sx={{ mb: 5 }}>
              {"Please select a Position Marker to Remove"}
            </Typography>
          </Box>
        </Container>
      </Scrollbar>

      <DialogActions sx={{ py: 2, px: 3 }}>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={() => handleSubmit()}
        >
          Ok
        </LoadingButton>
      </DialogActions>
    </DialogAnimate>
  );
}
