import { useEffect } from "react";
// material
import { Container, Box, LinearProgress, Typography } from "@mui/material";
import { CREATE_SUPPLIER_INVENTORIES_MUTATION } from "../../../../graphQL/Mutations";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMutation } from "@apollo/client";
import { SupplierInventoryDTO } from "../../../../@types/Supplier";
import CheckCircleRounded from "@mui/icons-material/CheckCircleRounded";
import { GET_SUPPLIER_INVENTORY_QUERY } from "../../../../graphQL/Queries";

// --------------------------------custom component styles-----------------------------------

// --------------------------------component prop types---------------------------------------
type ImportSiWizardResultsProps = {
  supplierInventoriesArray: SupplierInventoryDTO[];
};

// -----------------------------------------------------------------------------------------------------------------
export default function ImportSiWizardResults({
  supplierInventoriesArray,
}: ImportSiWizardResultsProps) {
  // Native Props

  // --------------------------------- mutations -------------------------------------------------
  const [
    CreateSupplierInventoriesMutation,
    CreateSupplierInventoriesMutationProps,
  ] = useMutation(CREATE_SUPPLIER_INVENTORIES_MUTATION, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    refetchQueries: [GET_SUPPLIER_INVENTORY_QUERY],
    fetchPolicy: "network-only",
  });
  const useCreateSupplierInventoriesMutation = (
    supplierInventoriesArray: SupplierInventoryDTO[]
  ) => {
    CreateSupplierInventoriesMutation({
      variables: {
        createSupplierInventoryInputArray: supplierInventoriesArray,
      },
    });
  };

  // --------------------------------- hooks -------------------------------------------------
  useEffect(() => {
    if (supplierInventoriesArray.length > 0) {
      useCreateSupplierInventoriesMutation(supplierInventoriesArray);
    }
  }, [supplierInventoriesArray]);

  // --------------------------------- handlers -----------------------------------------------

  // ------------------------------------------------------------------------------------------
  return (
    <Container maxWidth={"xl"}>
      <Box sx={{ height: 400 }}>
        <Container
          maxWidth={"xl"}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            mt: 2,
          }}
        >
          {CreateSupplierInventoriesMutationProps.loading ? (
            <LinearProgress
              color="primary"
              sx={{
                minHeight: "5px",
                mt: 0.1,
                minWidth: "300%",
              }}
            />
          ) : CreateSupplierInventoriesMutationProps?.data
            ?.createSupplierInventories == true ? (
            <Box sx={{ mt: 10 }}>
              <CheckCircleRounded sx={{ color: "#5BE584", fontSize: 152 }} />
              <Typography variant="subtitle1">Successfully Imported</Typography>
              <Typography variant="subtitle1">We are analyzing your Excel, and the table with</Typography>
              <Typography variant="subtitle1">your products is going to be updated soon.</Typography>
            </Box>
          ) : (
            <Box sx={{ mt: 10 }}>
              <CheckCircleRounded sx={{ color: "error", fontSize: 152 }} />
              <Typography variant="subtitle1">Something went Wrong</Typography>
            </Box>
          )}
        </Container>
      </Box>
    </Container>
  );
}
