import { HashRouter } from "react-router-dom";
import NotistackProvider from "./components/NotistackProvider";
import RtlLayout from "./components/RtlLayout";
import ThemeLocalization from "./components/ThemeLocalization";
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import Routes from "./routes";
import ThemeConfig from "./theme";
// Apollo setup
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useKeycloak } from "@react-keycloak/web";
import { createUploadLink } from "apollo-upload-client";

import IdleTimer from "react-idle-timer";

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_SERVER_URI + "/graphql",
});

export default function App() {
  const { initialized, keycloak } = useKeycloak();


  const LogOut = () => {
    console.log("Logout");

    if(initialized){
        window.sessionStorage.removeItem('SelectedShipOwnerStorage');
        window.sessionStorage.removeItem('SelectedVesselStorage');
        window.sessionStorage.removeItem('jwt')
        keycloak.logout();
    }
  }

  const onIdle = () => {
    LogOut();

  };



  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err.extensions.code) {
          case "UNAUTHENTICATED":
            keycloak.logout();
            break;
        }
      }
    }
  });

  const authLink = setContext((_, { headers }) => {

    const token = window.sessionStorage.getItem("jwt");
    if (initialized && keycloak != undefined && keycloak !== null) {
      keycloak.onTokenExpired = () => keycloak.updateToken(7200);
    }
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token? `Bearer ${token}` : "",
      },
    };
  });

  const links = [errorLink, authLink, httpLink];
  const link = ApolloLink.from(links);
  // create Client
  const client = new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false,
    }),
    link: link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "network-only",
      },
    },
  });

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <ApolloProvider client={client}>
                <HashRouter>

                  <Routes />

                  <div>




                  <IdleTimer   timeout={900000} onIdle={onIdle}></IdleTimer>

                </div>
                </HashRouter>
              </ApolloProvider>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
