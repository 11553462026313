import * as L from "leaflet";
import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import "leaflet-extra-markers/dist/js/leaflet.extra-markers.js";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useMapEvents } from "react-leaflet";
import { ApprovalStatusEnum, IhmItemPositionDTO } from "../../../@types/IHM";

export const InstallIhmMapComponent = forwardRef(
  (
    props: {
      description: string;
      addMarker: (
        lat: number,
        lng: number,
        applicationOfPaint: string,
        nameOfPaint: string,
        nameOfEquipmentAndMachinery: string,
        nameOfStructuralElement: string,
        partsWhereUsed: string
      ) => void;
      //   selectedMarker: (lat: number, lng: number) => void;
      positions: IhmItemPositionDTO[] | undefined;
      enableEdit: boolean;
      placeMarkerToMap: boolean;
      applicationOfPaint: string;
      nameOfPaint: string;
      nameOfEquipmentAndMachinery: string;
      nameOfStructuralElement: string;
      partsWhereUsed: string;
    },
    ref
  ) => {
    const {
      addMarker,
      positions,
      enableEdit,
      description,
      placeMarkerToMap,
      applicationOfPaint,
      nameOfPaint,
      nameOfEquipmentAndMachinery,
      nameOfStructuralElement,
      partsWhereUsed,
    } = props;

    const [mapSelectedMarker, setMapSelectedMarker] = useState(null);
    const [clickedMarker,setClickedMarker] = useState<any[]>([]);
    const approved = (L as any).ExtraMarkers.icon({
      icon: "fa-checked",
      markerColor: "yellow",
      prefix: "fa fa-4x",
      iconColor: "white",
    });

    const notApproved = (L as any).ExtraMarkers.icon({
      icon: "fa-eye",
      markerColor: "red",
      prefix: "fa fa-4x",
      iconColor: "white",
    });

    const selected = (L as any).ExtraMarkers.icon({
      icon: "fa-eye",
      markerColor: "blue",
      prefix: "fa fa-4x",
      iconColor: "white",
    });

    const isDelected = (L as any).ExtraMarkers.icon({
      icon: "fa-eye",
      markerColor: "black",
      prefix: "fa fa-4x",
      iconColor: "white",
    });

    const isReplaced = (L as any).ExtraMarkers.icon({
      icon: "fa-eye",
      markerColor: "green",
      prefix: "fa fa-4x",
      iconColor: "white",
    });

    const map = useMapEvents({
      click: (e: L.LeafletMouseEvent) => {
        if (enableEdit && placeMarkerToMap) {
          rebuildMarkers(-1);
          const { lat, lng } = e.latlng;
          const marker = L.marker([lat, lng], {
            riseOnHover: true,
            icon: notApproved,
            opacity: 0.7,
          })
            .bindTooltip(description)
            .setOpacity(0.6)
            .addEventListener("click", pinCliked)
            .addTo(map);
          clickedMarker.push(marker);
          setClickedMarker(clickedMarker);
          addMarker(
            lat,
            lng,
            applicationOfPaint,
            nameOfPaint,
            nameOfEquipmentAndMachinery,
            nameOfStructuralElement,
            partsWhereUsed
          );
        }
      },
    });

    const pinCliked = (event: L.LeafletMouseEvent) => {
      const lat = event.latlng.lat;
      const lng = event.latlng.lng;

      if (
        event.target.options.title != undefined &&
        !isNaN(event.target.options.title) &&
        !isNaN(parseFloat(event.target.options.title))
      ) {
        rebuildMarkers(+event.target.options.title);
      }
      setClickedMarker(event.target);
    };

    useEffect(() => {
      rebuildMarkers(-1);
    }, [positions]);

    const rebuildMarkers = (selectedId: number) => {
      setClickedMarker([]);
      map.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          map.removeLayer(layer);
        }
      });

      positions?.forEach((position: any) => {
        let description = "";
        if (position.productName && position.productName.length > 0) {
          description = position.productName;
        }

        if (
          description.length > 0 &&
          position.productCode &&
          position.productCode.length > 0
        ) {
          description += `, ${position.productCode}`;
        } else {
          description = position.productCode;
        }

        if (position.x && position.y) {
          if (position.id === selectedId) {
            const marker = L.marker([position.y, position.x], {
              icon: selected,
            })
              .bindTooltip(description)
              .addEventListener("click", pinCliked)
              .addTo(map);
              clickedMarker.push(marker);
              setClickedMarker(clickedMarker);
            marker.options.title = position.id;
            marker.options.alt = position.approvalStatus;
          } else {
            if (position.replacedByIhmPosition) {
              let description = "";
              if (position.replacedByIhmPosition) {
                if (position.replacedByIhmPosition.ihmItem) {
                  if (position.replacedByIhmPosition.ihmItem.productName) {
                    description =
                      position.replacedByIhmPosition.ihmItem.productName;
                  }
                  if (position.replacedByIhmPosition.ihmItem.productName) {
                    description +=
                      " " + position.replacedByIhmPosition.ihmItem.productCode;
                  }
                }
              }
              const marker = L.marker([position.y, position.x], {
                icon: isReplaced,
              })
                .bindTooltip(`Replaced by :` + description)
                .addEventListener("click", pinCliked)
                .addTo(map);
                clickedMarker.push(marker);
                setClickedMarker(clickedMarker);
              marker.options.title = position.id;
              marker.options.alt = "true";
            } else if (position.isDeleted) {
              const marker = L.marker([position.y, position.x], {
                icon: isDelected,
              })
                .bindTooltip(`Installation is Removed :` + description)
                .addEventListener("click", pinCliked)
                .addTo(map);
                clickedMarker.push(marker);
                setClickedMarker(clickedMarker);
              marker.options.title = position.id;
              marker.options.alt = "true";
            } else if (
              position.approvalStatus === ApprovalStatusEnum.APPROVED
            ) {
              const marker = L.marker([position.y, position.x], {
                icon: approved,
              })
                .bindTooltip(description)
                .addEventListener("click", pinCliked)
                .addTo(map);
                clickedMarker.push(marker);
                setClickedMarker(clickedMarker);
              marker.options.title = position.id;
              marker.options.alt = position.approvalStatus;
            } else {
              const marker = L.marker([position.y, position.x], {
                icon: notApproved,
              })
                .bindTooltip(description)
                .addEventListener("click", pinCliked)
                .addTo(map);
                clickedMarker.push(marker);
                setClickedMarker(clickedMarker);
              marker.options.title = position.id;
              marker.options.alt = position.approvalStatus;
            }
          }
        }
      });
    };
    useImperativeHandle(ref, () => ({
      removeMarker(lat: number, lng: number) {
        if (mapSelectedMarker != null) {
          map.removeLayer(mapSelectedMarker);
          setMapSelectedMarker(null);
        }
      },
      resetMarkers() {
        rebuildMarkers(-1);
      },
      makeMarkersBlue(){
        if(clickedMarker){
          for(const item of clickedMarker){
            item.setIcon(selected);
          }
        }
      },
    }));

    return null;
  }
);
