export type IHMDTO = {
  id?: number;
  vesselId?: string | undefined;
  replacedByIhmItemId?: number;
  unit?: string;
  referenceNumber?: string;
  equipmentManufacturer?: string;
  equipmentDescription?: string;
  usedParts?: string;
  productCode?: string;
  quantity?: number;
  location?: string;
  containsPCHM?: boolean;
  inventoryPart?: number | undefined;
  inventorySubPart?: number | undefined;
  dateAdded?: string | Date | null;
  dateApproved?: string | Date | null;
  approvedBy?: string;
  deleted?: boolean | undefined;
  status?: ihmItemStatus;
  replacedByIhmItem?: IHMDTO;
  productName?: string;
  hasImg?: boolean;
  ihmPosition?: IhmItemPositionDTO[];
  vesselPurchaseOrderItemIhm?: VesselPurchaseOrderItemIhmDTO | undefined;
  ihmItemMaterials?: IhmItemMaterialDTO[];
  pendingItemStatus?: string;
};

export enum ihmItemStatus {
  UNKNOWN,
  STORED,
  INSTALLED,
  REMOVED,
  REPLACED,
}

export enum ihmItemInventoryPart {
  UNKNOWN,
  PART_1,
  PART_2,
  PART_3,
}

export enum ihmItemInventorySubPart {
  UNKNOWN,
  SUB_CATEGORY_1,
  SUB_CATEGORY_2,
  SUB_CATEGORY_3,
  SUB_CATEGORY_4,
}
export type VesselPurchaseOrderItemIhmDTO = {
  id?: number;
  vesselId?: string | undefined;
  purchaseOrderItemId?: number | undefined;
  ihmItemId?: number;
};

export type IhmItemMaterialDTO = {
  id?: number;
  name?: string;
  category?: string;
  mass?: number;
  unit?: string;
  thresholdValue?: string;
  presentAboveThresholdValue?: boolean;
  comments?: string;
  ihmItemId?: number;
};

export type IhmItemAttachmentDTO = {
  id?: number;
  name?: string;
  path?: string;
  mimeType?: number;
  includeToExport?: boolean;
  ihmItem?: IHMDTO;
};

export enum IhmInventoryPart {
  UNKNOWN,
  PART_1,
  PART_2,
  PART_3,
}

export enum IhmInventorySubPart {
  UNKNOWN,
  SUB_CATEGORY_1,
  SUB_CATEGORY_2,
  SUB_CATEGORY_3,
  SUB_CATEGORY_4,
}

export enum ApprovalStatusEnum {
  NOT_APPROVED,
  APPROVED,
  REFUSED,
}

export type IhmItemPositionDTO = {
  id?: number;
  approvalStatus?: ApprovalStatusEnum;
  description?: string;
  vesselDrawingViewPath?: string;
  applicationOfPaint?: string;
  nameOfPaint?: string;
  nameOfEquipmentAndMachinery?: string;
  nameOfStructuralElement?: string;
  partsWhereUsed?: string;
  xBounds?: number;
  yBounds?: number;
  x?: number;
  y?: number;
  ihmItemId?: number;
  ihmItem?: IHMDTO;
  inventoryPart?: IhmInventoryPart;
  inventorySubPart?: IhmInventorySubPart;
  quantity?: number;
  location?: string;
  productCode?: string;
  productName?: string;
  registrationDate?: string;
  isDeleted?: boolean;
  replacedByIhmPosition?: ReplacedByIhmPosition;
  imageOfPositionOfTheItem?: string;
};

export type ReplacedByIhmPosition = {
  id?: number;
  ihmItem?: IHMDTO;
};

export type VesselIhmHistoryDTO = {
  id?: number;
  text?: string;
  ihmItemId?: number;
  vesselIhmHistoryStatusId?: number;
  appUserId?: number;
  createdAt?: string;
};

export type VesselIhmItemResponseDTO = {
  ihmId?: number;
  productCode?: string;
  productName?: string;
  vesselName?: string;
  vesselImo?: string;
};

export type VesselIhmVersioningDTO = {
  MdSdocInfoLastModified: string;
  tableInfoLastModified: string;
  vesselInfoLastModified: string;
  vesselId: number;
  numberOfVersioning: number;
  comment: string;
  updatedAt: string;
  id: number;
}
