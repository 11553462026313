import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IhmItemPositionDTO } from "../../@types/IHM";
import Scrollbar from "../Scrollbar";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  fieldWithHelperText: {
    "&.MuiTextField-root": {
      "&>.MuiInput-root": {
        "&>.MuiInput-input": {
          fontSize: "14px",
        },
      },
      "&>.MuiFormHelperText-root": {
        color: "#39464f",
        fontSize: "14px",
      },
    },
  },
  selectionField: {
    "&>.MuiSelect-select": {
      fontSize: "14px",
    },
  },
}));

export enum MapperActionCodesEnum {
  IS_REMOVE,
  IS_INSTALL,
  IS_APPROVE,
  IS_REPLACE,
}

type IhmMapperFormProps = {
  applicationOfPaint: string;
  setApplicationOfPaint: Dispatch<SetStateAction<string>>;
  nameOfPaint: string;
  setNameOfPaint: Dispatch<SetStateAction<string>>;
  nameOfEquipmentMachinery: string;
  setNameOfEquipmentMachinery: Dispatch<SetStateAction<string>>;
  nameOfStructuralElement: string;
  setNameOfStructuralElement: Dispatch<SetStateAction<string>>;
  location: string;
  setLocation: Dispatch<SetStateAction<string>>;
  partsUsed: string;
  setPartsUsed: Dispatch<SetStateAction<string>>;
  quantity: number;
  setQuantity: Dispatch<SetStateAction<number>>;
  subPart: string;
  setSubPart: Dispatch<SetStateAction<string>>;
  selectedNewIhmPosition: IhmItemPositionDTO | null;
  setSelectedNewIhmPosition: Dispatch<
    SetStateAction<IhmItemPositionDTO | null>
  >;
  selectedExistingIhmPosition: IhmItemPositionDTO | null;
  setSelectedExistingNewIhmPosition: Dispatch<
    SetStateAction<IhmItemPositionDTO | null>
  >;
  mapperAction: MapperActionCodesEnum;
  isFromInstall?: boolean;
  isFromRemove?: boolean;
  isFromReplace?: boolean;
};

const IhmMapperForm = (props: IhmMapperFormProps) => {
  const {
    applicationOfPaint,
    setApplicationOfPaint,
    nameOfPaint,
    setNameOfPaint,
    nameOfEquipmentMachinery,
    setNameOfEquipmentMachinery,
    nameOfStructuralElement,
    setNameOfStructuralElement,
    location,
    setLocation,
    partsUsed,
    setPartsUsed,
    quantity,
    setQuantity,
    subPart,
    setSubPart,
    selectedNewIhmPosition,
    setSelectedNewIhmPosition,
    selectedExistingIhmPosition,
    setSelectedExistingNewIhmPosition,
    mapperAction,
    isFromInstall,
    isFromRemove,
    isFromReplace,
  } = props;

  const classesNew = useStyles();
  const availableSubParts = ["SubPart 1", "SubPart 2", "SubPart 3"];

  const [isSubPartDisabled, setIsSubPartDisabled] = useState(false);
  const [isApplicationOfPaintDisabled, setIsApplicationOfPaintDisabled] =
    useState(false);
  const [isNameOfPaintDisabled, setIsNameOfPaintDisabled] = useState(false);
  const [isLocationDisabled, setIsLocationDisabled] = useState(false);
  const [isQuantityDisabled, setIsQuantityDisabled] = useState(false);
  const [isNameOfEquipmentDisabled, setIsNameOfEquipmentDisabled] =
    useState(false);
  const [isUsedPartsDisabled, setIsUsedPartsDisabled] = useState(false);
  const [
    isNameOfStructuralElementDisabled,
    setIsNameOfStructuralElementDisabled,
  ] = useState(false);
  const [totalStored, setTotalStored] = useState<number>();

  // ------------------------------------------------------------------ Component Lifecycle ----------------------------------------
  useEffect(() => {
    switch (mapperAction) {
      case MapperActionCodesEnum.IS_APPROVE:
        setIsSubPartDisabled(true);
        break;
      case MapperActionCodesEnum.IS_REMOVE:
        setIsSubPartDisabled(true);
        setIsApplicationOfPaintDisabled(true);
        setIsNameOfPaintDisabled(true);
        setIsLocationDisabled(true);
        setIsQuantityDisabled(true);
        setIsNameOfEquipmentDisabled(true);
        setIsUsedPartsDisabled(true);
        setIsNameOfStructuralElementDisabled(true);
        break;
      case MapperActionCodesEnum.IS_REPLACE:
        setIsSubPartDisabled(true);
        setIsApplicationOfPaintDisabled(true);
        setIsNameOfPaintDisabled(true);
        setIsLocationDisabled(true);
        setIsQuantityDisabled(true);
        setIsNameOfEquipmentDisabled(true);
        setIsUsedPartsDisabled(true);
        setIsNameOfStructuralElementDisabled(true);
        break;

      default:
        break;
    }
  }, [mapperAction]);

  useEffect(() => {
    setTotalStored(quantity);
  }, []);

  // ------------------------------------------------------------------ Handlers ---------------------------------------------------
  const handleSubPartInput = (event: SelectChangeEvent) => {
    const selectedSP = event.target.value as string;
    const foundSP = availableSubParts.filter((sp) => sp == selectedSP)[0];
    setSubPart(foundSP);
  };

  const handleQuantityInput = (e: any) => {
    const value = e.target.value.replace(/,/g, "");
    let _quantity = "0";
    if (value.includes(".")) {
      const decimals = value.split(".");
      if (decimals.length == 2) {
        if (decimals[1].length == 0) {
          _quantity = value;
        } else {
          _quantity = parseFloat(value).toFixed(decimals[1].length);
        }
      } else {
        _quantity = parseFloat(value).toFixed(0);
      }
    } else {
      _quantity = parseFloat(value).toFixed(0);
    }
    const finalQuantity = parseFloat(_quantity);
    setQuantity(finalQuantity);
    if (
      selectedNewIhmPosition != undefined &&
      selectedNewIhmPosition !== null
    ) {
      const updatedSelectedNewIhmPosition = selectedNewIhmPosition;
      updatedSelectedNewIhmPosition.quantity = finalQuantity;
      setSelectedNewIhmPosition(updatedSelectedNewIhmPosition);
    }
    if (
      selectedExistingIhmPosition != undefined &&
      selectedExistingIhmPosition !== null
    ) {
      const updatedSelectedExistingIhmPosition = selectedExistingIhmPosition;
      updatedSelectedExistingIhmPosition.quantity = finalQuantity;
      setSelectedExistingNewIhmPosition(updatedSelectedExistingIhmPosition);
    }
  };

  return (
    <Scrollbar>
      <Stack sx={{ mb: 3 }}>
        <Typography sx={{ fontSize: "14px", mb: 1 }}>
          3. Select IHM sub-part, depending on product category that is being
          installed*.
        </Typography>
        <FormControl variant="standard" fullWidth>
          <Select
            variant="standard"
            labelId={`select-label-id-}`}
            id={`select-subpart-id}`}
            className={classesNew.selectionField}
            label="Select SubPart"
            value={subPart}
            onChange={handleSubPartInput}
            disabled={isSubPartDisabled}
          >
            {availableSubParts.map((subPart: string, _i: number) => (
              <MenuItem key={`subpart_${_i}`} value={subPart}>
                {subPart == "SubPart 1"
                  ? "I-1 Paints"
                  : subPart == "SubPart 2"
                  ? "I-2 Equipment and machinery"
                  : subPart == "SubPart 3" && "I-3 Structure"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      <Stack>
        {isFromInstall && (
          <Typography sx={{ fontSize: "14px", mb: 2 }}>
            4. Complete following required* fields. Make sure to input the Name
            of Equipment or Application of Paint, and exact location (e.g. Deck
            A/Cargo Control Room) that correspond to the specific installation.
            Parts where used or Name of Paint are pre-filled with the Product
            Name of the selected stored item, and Installed Quantity with the
            total remaining stored quantity; you may edit them to reflect the
            actual installation quantity or porduct description.
          </Typography>
        )}
        {isFromRemove ||
          (isFromReplace && (
            <Typography sx={{ fontSize: "14px", mb: 2 }}>
              4. Details of installed prodcut
            </Typography>
          ))}
      </Stack>
      {subPart == "SubPart 1" && (
        <>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                columnGap: "30px",
                m: "8px 0",
              }}
            >
              <TextField
                variant="standard"
                fullWidth
                helperText="Application of Paint *"
                className={classesNew.fieldWithHelperText}
                value={applicationOfPaint}
                disabled={isApplicationOfPaintDisabled}
                onChange={(e: any) => {
                  const data = e.target.value as string;
                  setApplicationOfPaint(data);
                  if (
                    selectedNewIhmPosition != undefined &&
                    selectedNewIhmPosition !== null
                  ) {
                    const updatedSelectedNewIhmPosition =
                      selectedNewIhmPosition;
                    updatedSelectedNewIhmPosition.applicationOfPaint = data;
                    setSelectedNewIhmPosition(updatedSelectedNewIhmPosition);
                  }
                  if (
                    selectedExistingIhmPosition != undefined &&
                    selectedExistingIhmPosition !== null
                  ) {
                    const updatedSelectedExistingIhmPosition =
                      selectedExistingIhmPosition;
                    updatedSelectedExistingIhmPosition.applicationOfPaint =
                      data;
                    setSelectedExistingNewIhmPosition(
                      updatedSelectedExistingIhmPosition
                    );
                  }
                }}
              />

              <TextField
                variant="standard"
                fullWidth
                helperText="Name of Paint *"
                className={classesNew.fieldWithHelperText}
                sx={{ ml: 1 }}
                value={nameOfPaint}
                disabled={isNameOfPaintDisabled}
                onChange={(e: any) => {
                  const data = e.target.value as string;
                  setNameOfPaint(data);
                  if (
                    selectedNewIhmPosition != undefined &&
                    selectedNewIhmPosition !== null
                  ) {
                    const updatedSelectedNewIhmPosition =
                      selectedNewIhmPosition;
                    updatedSelectedNewIhmPosition.nameOfPaint = data;
                    setSelectedNewIhmPosition(updatedSelectedNewIhmPosition);
                  }
                  if (
                    selectedExistingIhmPosition != undefined &&
                    selectedExistingIhmPosition !== null
                  ) {
                    const updatedSelectedExistingIhmPosition =
                      selectedExistingIhmPosition;
                    updatedSelectedExistingIhmPosition.nameOfPaint = data;
                    setSelectedExistingNewIhmPosition(
                      updatedSelectedExistingIhmPosition
                    );
                  }
                }}
              />

              <TextField
                variant="standard"
                helperText="Location *"
                className={classesNew.fieldWithHelperText}
                fullWidth
                sx={{ ml: 1 }}
                value={location}
                disabled={isLocationDisabled}
                onChange={(e: any) => {
                  const data = e.target.value as string;
                  setLocation(data);
                  if (
                    selectedNewIhmPosition != undefined &&
                    selectedNewIhmPosition !== null
                  ) {
                    const updatedSelectedNewIhmPosition =
                      selectedNewIhmPosition;
                    updatedSelectedNewIhmPosition.location = data;
                    setSelectedNewIhmPosition(updatedSelectedNewIhmPosition);
                  }
                  if (
                    selectedExistingIhmPosition != undefined &&
                    selectedExistingIhmPosition !== null
                  ) {
                    const updatedSelectedExistingIhmPosition =
                      selectedExistingIhmPosition;
                    updatedSelectedExistingIhmPosition.location = data;
                    setSelectedExistingNewIhmPosition(
                      updatedSelectedExistingIhmPosition
                    );
                  }
                }}
              />
              <TextField
                variant="standard"
                fullWidth
                helperText={
                  isFromReplace
                    ? `Quantity to replace *\n(total stored: ${totalStored} Units)`
                    : `Quantity to Install *\n(total stored: ${totalStored} Units)`
                }
                className={classesNew.fieldWithHelperText}
                sx={{ ml: 1 }}
                value={quantity}
                disabled={isQuantityDisabled}
                onChange={handleQuantityInput}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Box>
        </>
      )}

      {subPart == "SubPart 2" && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", flexDirection: "row", m: 1 }}>
            <TextField
              variant="standard"
              fullWidth
              helperText="Name of equipment and machinery *"
              className={classesNew.fieldWithHelperText}
              sx={{ mt: 1 }}
              value={nameOfEquipmentMachinery}
              disabled={isNameOfEquipmentDisabled}
              onChange={(e: any) => {
                const data = e.target.value as string;
                setNameOfEquipmentMachinery(data);
                if (
                  selectedNewIhmPosition != undefined &&
                  selectedNewIhmPosition !== null
                ) {
                  const updatedSelectedNewIhmPosition = selectedNewIhmPosition;
                  updatedSelectedNewIhmPosition.nameOfEquipmentAndMachinery =
                    data;
                  setSelectedNewIhmPosition(updatedSelectedNewIhmPosition);
                }
                if (
                  selectedExistingIhmPosition != undefined &&
                  selectedExistingIhmPosition !== null
                ) {
                  const updatedSelectedExistingIhmPosition =
                    selectedExistingIhmPosition;
                  updatedSelectedExistingIhmPosition.nameOfEquipmentAndMachinery =
                    data;
                  setSelectedExistingNewIhmPosition(
                    updatedSelectedExistingIhmPosition
                  );
                }
              }}
            />

            <TextField
              variant="standard"
              fullWidth
              helperText="location *"
              className={classesNew.fieldWithHelperText}
              sx={{ mt: 1, ml: 1 }}
              value={location}
              disabled={isLocationDisabled}
              onChange={(e: any) => {
                const data = e.target.value as string;
                setLocation(data);
                if (
                  selectedNewIhmPosition != undefined &&
                  selectedNewIhmPosition !== null
                ) {
                  const updatedSelectedNewIhmPosition = selectedNewIhmPosition;
                  updatedSelectedNewIhmPosition.location = data;
                  setSelectedNewIhmPosition(updatedSelectedNewIhmPosition);
                }
                if (
                  selectedExistingIhmPosition != undefined &&
                  selectedExistingIhmPosition !== null
                ) {
                  const updatedSelectedExistingIhmPosition =
                    selectedExistingIhmPosition;
                  updatedSelectedExistingIhmPosition.location = data;
                  setSelectedExistingNewIhmPosition(
                    updatedSelectedExistingIhmPosition
                  );
                }
              }}
            />

            <TextField
              variant="standard"
              fullWidth
              helperText="Parts where used *"
              className={classesNew.fieldWithHelperText}
              sx={{ mt: 1, ml: 1 }}
              value={partsUsed}
              disabled={isUsedPartsDisabled}
              onChange={(e: any) => {
                const data = e.target.value as string;
                setPartsUsed(data);
                if (
                  selectedNewIhmPosition != undefined &&
                  selectedNewIhmPosition !== null
                ) {
                  const updatedSelectedNewIhmPosition = selectedNewIhmPosition;
                  updatedSelectedNewIhmPosition.partsWhereUsed = data;
                  setSelectedNewIhmPosition(updatedSelectedNewIhmPosition);
                }
                if (
                  selectedExistingIhmPosition != undefined &&
                  selectedExistingIhmPosition !== null
                ) {
                  const updatedSelectedExistingIhmPosition =
                    selectedExistingIhmPosition;
                  updatedSelectedExistingIhmPosition.partsWhereUsed = data;
                  setSelectedExistingNewIhmPosition(
                    updatedSelectedExistingIhmPosition
                  );
                }
              }}
            />
            <TextField
              variant="standard"
              fullWidth
              helperText={`Quantity to Replace *\n(total stored: ${totalStored} Units)`}
              className={classesNew.fieldWithHelperText}
              sx={{ mt: 1, ml: 1 }}
              value={quantity}
              disabled={isQuantityDisabled}
              onChange={handleQuantityInput}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", m: 1 }}></Box>
        </Box>
      )}

      {subPart == "SubPart 3" && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", flexDirection: "row", m: 1 }}>
            <TextField
              variant="standard"
              fullWidth
              helperText="Name of structural element *"
              className={classesNew.fieldWithHelperText}
              sx={{ mt: 1 }}
              value={nameOfStructuralElement}
              disabled={isNameOfStructuralElementDisabled}
              onChange={(e: any) => {
                const data = e.target.value as string;
                setNameOfStructuralElement(data);
                if (
                  selectedNewIhmPosition != undefined &&
                  selectedNewIhmPosition !== null
                ) {
                  const updatedSelectedNewIhmPosition = selectedNewIhmPosition;
                  updatedSelectedNewIhmPosition.nameOfStructuralElement = data;
                  setSelectedNewIhmPosition(updatedSelectedNewIhmPosition);
                }
                if (
                  selectedExistingIhmPosition != undefined &&
                  selectedExistingIhmPosition !== null
                ) {
                  const updatedSelectedExistingIhmPosition =
                    selectedExistingIhmPosition;
                  updatedSelectedExistingIhmPosition.nameOfStructuralElement =
                    data;
                  setSelectedExistingNewIhmPosition(
                    updatedSelectedExistingIhmPosition
                  );
                }
              }}
            />

            <TextField
              variant="standard"
              fullWidth
              helperText="Location *"
              className={classesNew.fieldWithHelperText}
              sx={{ mt: 1, ml: 1 }}
              value={location}
              disabled={isLocationDisabled}
              onChange={(e: any) => {
                const data = e.target.value as string;
                setLocation(data);
                if (
                  selectedNewIhmPosition != undefined &&
                  selectedNewIhmPosition !== null
                ) {
                  const updatedSelectedNewIhmPosition = selectedNewIhmPosition;
                  updatedSelectedNewIhmPosition.location = data;
                  setSelectedNewIhmPosition(updatedSelectedNewIhmPosition);
                }
                if (
                  selectedExistingIhmPosition != undefined &&
                  selectedExistingIhmPosition !== null
                ) {
                  const updatedSelectedExistingIhmPosition =
                    selectedExistingIhmPosition;
                  updatedSelectedExistingIhmPosition.location = data;
                  setSelectedExistingNewIhmPosition(
                    updatedSelectedExistingIhmPosition
                  );
                }
              }}
            />

            <TextField
              variant="standard"
              fullWidth
              helperText="Parts where used *"
              className={classesNew.fieldWithHelperText}
              sx={{ mt: 1, ml: 1 }}
              value={partsUsed}
              disabled={isUsedPartsDisabled}
              onChange={(e: any) => {
                const data = e.target.value as string;
                setPartsUsed(data);
                if (
                  selectedNewIhmPosition != undefined &&
                  selectedNewIhmPosition !== null
                ) {
                  const updatedSelectedNewIhmPosition = selectedNewIhmPosition;
                  updatedSelectedNewIhmPosition.partsWhereUsed = data;
                  setSelectedNewIhmPosition(updatedSelectedNewIhmPosition);
                }
                if (
                  selectedExistingIhmPosition != undefined &&
                  selectedExistingIhmPosition !== null
                ) {
                  const updatedSelectedExistingIhmPosition =
                    selectedExistingIhmPosition;
                  updatedSelectedExistingIhmPosition.partsWhereUsed = data;
                  setSelectedExistingNewIhmPosition(
                    updatedSelectedExistingIhmPosition
                  );
                }
              }}
            />
            <TextField
              variant="standard"
              fullWidth
              helperText={`Quantity to Install *\n(total stored: ${totalStored} Units)`}
              className={classesNew.fieldWithHelperText}
              sx={{ mt: 1, ml: 1 }}
              value={quantity}
              disabled={isQuantityDisabled}
              onChange={handleQuantityInput}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", m: 1 }}></Box>
        </Box>
      )}
    </Scrollbar>
  );
};

export default IhmMapperForm;
