import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import L from "leaflet";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { ImageOverlay, MapContainer } from "react-leaflet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  IHMDTO,
  IhmInventorySubPart,
  IhmItemPositionDTO,
} from "../../../@types/IHM";
import { VesselDrawingDTO } from "../../../@types/Vessel";
import { REMOVE_IHM_INSTALLATION } from "../../../graphQL/Mutations";
import { GET_IHM_INSTALLED_ITEMS } from "../../../graphQL/Queries";
import Scrollbar from "../../Scrollbar";
import IhmMapperForm, { MapperActionCodesEnum } from "../IhmMapperForm";
import { RemoveIhmInstallationMapComponent } from "./RemoveIhmInstallationMapComponent";

const serverUri = process.env.REACT_APP_SERVER_URI;

// ------------------------------------------------------------------ Define Queries ---------------------------------------------------------------------
const useFetchDrawingsQuery = async (
  drawingFullPath: string,
  token: string | null
) => {
  return axios.get(`${serverUri}/api/v1/drawing/${drawingFullPath}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob",
  });
};

interface RemoveIhmInstallationVesselMapProps {
  selectedVesselDrawing: VesselDrawingDTO | undefined;
  ihmItem: IHMDTO;
  ihmItemPosition: IhmItemPositionDTO;
  remainingQuantity: number;
  modalClose: VoidFunction;
}

const RemoveIhmInstallationVesselMap = ({
  selectedVesselDrawing,
  ihmItem,
  ihmItemPosition,
  remainingQuantity,
  modalClose,
}: RemoveIhmInstallationVesselMapProps) => {
  interface ImageDimensions {
    width: number;
    height: number;
  }
  const { enqueueSnackbar } = useSnackbar();
  const [imageUrl, setImageUrl] = useState<string>();
  const { initialized, keycloak } = useKeycloak();
  const [userRole, setUserRole] = useState<undefined | string>(undefined);

  // form fields
  const [applicationOfPaint, setApplicationOfPaint] = useState<string>("");
  const [nameOfPaint, setNameOfPaint] = useState<string>("");
  const [nameOfEquipmentMachinery, setNameOfEquipmentMachinery] =
    useState<string>("");
  const [nameOfStructuralElement, setNameOfStructuralElement] =
    useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [partsUsed, setPartsUsed] = useState<string>("");
  const [quantity, setQuantity] = useState<number>(0);
  const [subPart, setSubPart] = useState<string>("SubPart 1");
  const [dimensionsOfImage, setDimensionsOfImage] = useState<ImageDimensions>();
  // marker attributes
  const [selectedNewIhmPosition, setSelectedNewIhmPosition] =
    useState<IhmItemPositionDTO | null>(null);
  const [selectedExistingIhmPosition, setSelectedExistingNewIhmPosition] =
    useState<IhmItemPositionDTO | null>(null);

  const mapComponentRef = useRef<any>();

  const token = window.sessionStorage.getItem("jwt");

  // ------------------------------------------------------------------ Define Mutations ---------------------------------------------------------------------
  // Remove
  const [RemoveInstallationMutation, RemoveInstallationMutationProps] =
    useMutation(REMOVE_IHM_INSTALLATION, {
      fetchPolicy: "network-only",
    });
  const useRemoveInstallationMutation = (ihmPositionId: number) => {
    RemoveInstallationMutation({
      variables: {
        id: ihmPositionId,
      },
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
      refetchQueries: [GET_IHM_INSTALLED_ITEMS],
      onCompleted(dt) {
        modalClose();
        if (dt.removeInstallation === true && userRole != undefined) {
          if (userRole === "Maintainer") {
            enqueueSnackbar(`Installation has been Removed.`, {
              variant: "success",
            });
          } else {
            enqueueSnackbar(
              `Installation has been Successfully marked as Removed and is waiting for Maintainer Approval.`,
              {
                variant: "success",
              }
            );
          }
        }
      },
    });
  };
  // ------------------------------------------------------------------ Hooks --------------------------------------------------------------
  useEffect(() => {
    if (selectedVesselDrawing != undefined) {
      const parsedPath = selectedVesselDrawing.path?.split("/").join("-");
      if (parsedPath) {
        useQueryExec(parsedPath, token);
      }
    }
  }, [selectedVesselDrawing]);

  useEffect(() => {
    if (ihmItemPosition) {
      ihmItemPosition.applicationOfPaint &&
        setApplicationOfPaint(ihmItemPosition.applicationOfPaint);
      ihmItemPosition.nameOfEquipmentAndMachinery &&
        setNameOfEquipmentMachinery(
          ihmItemPosition.nameOfEquipmentAndMachinery
        );

      ihmItemPosition.nameOfPaint &&
        setNameOfPaint(ihmItemPosition.nameOfPaint);

      ihmItemPosition.partsWhereUsed &&
        setPartsUsed(ihmItemPosition.partsWhereUsed);

      ihmItemPosition.nameOfStructuralElement &&
        setNameOfStructuralElement(ihmItemPosition.nameOfStructuralElement);

      ihmItemPosition.location && setLocation(ihmItemPosition.location);

      ihmItemPosition.quantity && setQuantity(ihmItemPosition.quantity);
      switch (ihmItemPosition.inventorySubPart) {
        case IhmInventorySubPart.SUB_CATEGORY_1:
          setSubPart("SubPart 1");
          break;
        case IhmInventorySubPart.SUB_CATEGORY_2:
          setSubPart("SubPart 2");
          break;
        case IhmInventorySubPart.SUB_CATEGORY_3:
          setSubPart("SubPart 3");
          break;

        default:
          break;
      }
    }
  }, [ihmItemPosition]);

  useEffect(() => {
    if (initialized) {
      if (keycloak.realmAccess) {
        if (keycloak.realmAccess.roles) {
          if (keycloak.realmAccess.roles.find((x) => x === "ShipOwner")) {
            setUserRole("ShipOwner");
          } else if (keycloak.realmAccess.roles.find((x) => x === "Supplier")) {
            setUserRole("Supplier");
          } else if (
            keycloak.realmAccess.roles.find((x) => x === "Maintainer")
          ) {
            setUserRole("Maintainer");
          }
        }
      } else if (window.sessionStorage.getItem("userRole")) {
        setUserRole(window.sessionStorage.getItem("userRole")?.toString());
      }
    }
  }, [keycloak, initialized]);

  useEffect(  ()=>{
    if(imageUrl){
      const fetchData = async () => {
        const dimensions =  await getImageDimensions(imageUrl);
        
        setDimensionsOfImage({width: dimensions.width, height: dimensions.height});
        return dimensions;
      }
      fetchData();
      
    }
  
  
  },[imageUrl]);

  // ------------------------------------------------------------------ Handlers ---------------------------------------------------------------------
  // Helper function
  async function useQueryExec(drawingFullPath: string, token: string | null) {
    const response = await useFetchDrawingsQuery(drawingFullPath, token);
    if (response && response.data) {
      const imgFromBlob = URL.createObjectURL(response.data);

      if (imgFromBlob) {
        setImageUrl(imgFromBlob);
      }
    }
  }

  function getImageDimensions(file: string): Promise<ImageDimensions> {
    return new Promise (function (resolved, rejected) {
      const i = new Image();
      i.onload = function(){
        resolved({width: i.width, height: i.height})
      };
      i.src = file
    })
  }

  const useHandleRemoveInstallation = () => {
    ihmItemPosition.id && useRemoveInstallationMutation(ihmItemPosition.id);
  };

  const mapRefForZooming = useRef<any>(null);

  const handleZoomIn = () => {
    const map = mapRefForZooming.current;
    if (map) {
      map.setZoom(map.getZoom() + 1);
    }
  }
  
  const handleZoomOut = () => {
    const map = mapRefForZooming.current;
    if (map) {
      map.setZoom(map.getZoom() - 1);
    }
  }


  return (
    <>
    <LoadingButton
    variant="contained"
    onClick={handleZoomIn}
  >
  +
  </LoadingButton>
  <LoadingButton
    sx={{ml:"5px"}}
    variant="contained"
    onClick={handleZoomOut}
  >
  -
  </LoadingButton>
    <Scrollbar>
      {selectedVesselDrawing != undefined ? (
        <MapContainer
          className="map"
          zoom={6}
          scrollWheelZoom={false}
          crs={L.CRS.Simple}
          bounds={[
            [0, 0],
            [3000, 3000],
          ]}
          minZoom={-6}
          maxZoom={20}
          ref={mapRefForZooming}
          attributionControl={false}
          zoomControl={false}
          style={{ height: "300px", width: "550px", backgroundColor: "white" }}
        >
        {imageUrl && dimensionsOfImage &&
        (

            <ImageOverlay
              url={imageUrl}
              bounds={[
                [0, 0],
                [dimensionsOfImage.height*10, dimensionsOfImage.width*10],
              ]}
            />
        ) }

          <RemoveIhmInstallationMapComponent
            placeMarkerToMap={false}
            positions={
              ihmItemPosition !== null && ihmItemPosition != undefined
                ? [ihmItemPosition]
                : []
            }
            enableEdit={true}
            ref={mapComponentRef}
          />
        </MapContainer>
      ) : (
        <Box
          sx={{
            height: "80px",
          }}
        >
          <Typography variant="h6">
            Placement preview is not available.
          </Typography>
        </Box>
      )}

      <IhmMapperForm
        applicationOfPaint={applicationOfPaint}
        setApplicationOfPaint={setApplicationOfPaint}
        nameOfPaint={nameOfPaint}
        setNameOfPaint={setNameOfPaint}
        nameOfEquipmentMachinery={nameOfEquipmentMachinery}
        setNameOfEquipmentMachinery={setNameOfEquipmentMachinery}
        nameOfStructuralElement={nameOfStructuralElement}
        setNameOfStructuralElement={setNameOfStructuralElement}
        location={location}
        setLocation={setLocation}
        partsUsed={partsUsed}
        setPartsUsed={setPartsUsed}
        quantity={quantity}
        setQuantity={setQuantity}
        subPart={subPart}
        setSubPart={setSubPart}
        selectedNewIhmPosition={selectedNewIhmPosition}
        setSelectedNewIhmPosition={setSelectedNewIhmPosition}
        selectedExistingIhmPosition={selectedExistingIhmPosition}
        setSelectedExistingNewIhmPosition={setSelectedExistingNewIhmPosition}
        mapperAction={MapperActionCodesEnum.IS_REMOVE}
        isFromRemove={true}
      />

      <Stack
        sx={{ flexDirection: "row", justifyContent: "flex-end", mt: 2, mb: 3 }}
      >
        <LoadingButton
          variant="contained"
          id="removeSelectedItem"
          onClick={useHandleRemoveInstallation}
          component="label"
          loading={RemoveInstallationMutationProps.loading}
        >
          Remove
        </LoadingButton>
      </Stack>
    </Scrollbar>
    </>
  );
};

export default RemoveIhmInstallationVesselMap;
