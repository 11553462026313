// material
import { Card, Grid, LinearProgress } from "@mui/material";
// components
import StatsCard from "../../components/custom/Cards/StatsCard";
import NumberCard from "../../components/custom/Cards/NumberCard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FIND_ALL_POS_BY_ROLE_QUERY,
  FIND_ALL_REQUESTS_BY_ROLE,
  FIND_SUPPLIER_COMPLIANCE_QUERY,
  FIND_ALL_POI_REQUESTS_BY_ROLE,
  GET_PO_IN_SCOPE_BY_SO_AND_VSL_QUERY,
} from "../../graphQL/Queries";
import { useQuery } from "@apollo/client";
import AppexGraph from "../custom/AppexGraph";
import { useEffect, useState } from "react";
//styles
import { makeStyles } from "@mui/styles";
import { OrderStatus } from "../../@types/PurchaseOrder";
import { ShipOwnerVesselDto } from "../../@types/ShipOwnerVessel";
import { useKeycloak } from "@react-keycloak/web";

const useStyles = makeStyles(() => ({
  loadingDiv: {
    display: "flex",
    flex: "1",
    minWidth: "280px",
  },
}));

// Define Queries
const findAllPOsByRole = () =>
  useQuery(FIND_ALL_POS_BY_ROLE_QUERY, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });
const findAllPoByRole = (states: number[]) =>
  useQuery(FIND_ALL_POI_REQUESTS_BY_ROLE, {
    variables: {
      states: states,
    },
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });
const supplierComplianceQuery = () =>
  useQuery(FIND_SUPPLIER_COMPLIANCE_QUERY, {
    variables: {
      shipOwnerId: 0,
      vesselId: 0
    },
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });


  const useGetPOInScopeByShipOwnerAndVesselQuery = (
    shipOWnerVessel: ShipOwnerVesselDto | undefined,
    totalMonths: string,
    dateFromInput: string,
    userRole: string,
  ) =>
    useQuery(GET_PO_IN_SCOPE_BY_SO_AND_VSL_QUERY, {
      variables: {
        dateFromInput: dateFromInput,
        totalMonths: totalMonths === "all" ? -1 : parseInt(totalMonths),
        shipOwnerId:
          shipOWnerVessel && shipOWnerVessel.shipOwnerDto && shipOWnerVessel.shipOwnerDto.id && parseInt(shipOWnerVessel.shipOwnerDto.id) ? parseInt(shipOWnerVessel.shipOwnerDto.id) : 0,
        vesselId: shipOWnerVessel && shipOWnerVessel.vesselDto && shipOWnerVessel.vesselDto?.id ? parseInt(shipOWnerVessel.vesselDto?.id) : 0,
        userRole: userRole,
      },
      skip: shipOWnerVessel == undefined || shipOWnerVessel.shipOwnerDto == undefined || shipOWnerVessel.vesselDto == undefined || userRole == undefined,
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
      fetchPolicy: "network-only",
    });

// --------------------------------------------------------------------------------------------------
export default function SupplierHomepage() {
  const classesNew = useStyles();
  const [chartData, setChartData] = useState({
    supplierCompliance: 0,
  });
  const { initialized, keycloak } = useKeycloak();
  const [userRole, setUserRole] = useState<undefined | string>(undefined);
  const today = new Date();
  const [selectedShipOwnerIdAndVesselId, setSelectedShipOwnerIdAndVesselId] =useState({
    shipOwnerDto: { id: '0', name: 'All' },
    vesselDto: { id: '0', name: 'All' }
  });
  const stringDate =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  const findAllPOsByRoleResponse = findAllPoByRole([
    OrderStatus.PENDING_FOR_SUPPLIER_INFORMATION,
    OrderStatus.APPROVED,
    OrderStatus.INSTALLED,
    OrderStatus.DOCUMENT_SAVED,
    OrderStatus.DOCUMENT_PROCESS_IN_PROGRESS,
    OrderStatus.DOCUMENT_PROCESS_FAILED,
    OrderStatus.DOCUMENT_PROCESS_OK,
    OrderStatus.REJECTED
  ]);
  const findAllRequestsByRoleResponseOneTwo = findAllPoByRole([
    OrderStatus.PENDING_FOR_SUPPLIER_INFORMATION,
    OrderStatus.DOCUMENT_SAVED,
    OrderStatus.DOCUMENT_PROCESS_IN_PROGRESS,
    OrderStatus.DOCUMENT_PROCESS_FAILED,
  ]);
  const findAllRequestsByRoleResponseThree = findAllPoByRole([
    OrderStatus.DOCUMENT_PROCESS_OK,
  ]);
  const findAllRequestsByRoleResponseFour = findAllPoByRole([
    OrderStatus.APPROVED,
    OrderStatus.INSTALLED,
  ]);
  const supplierComplianceQueryResponse = supplierComplianceQuery();

  const useGetPOInScopeByShipOwnerAndVesselQueryResponse =
  useGetPOInScopeByShipOwnerAndVesselQuery(
    selectedShipOwnerIdAndVesselId,
    "all",
    stringDate,
    userRole ? userRole : ""
  );

  // -----------------------------------------Component Lifecycle-----------------------------------------------------
  useEffect(() => {
    if (supplierComplianceQueryResponse.data) {
      setChartData({
        supplierCompliance:
          supplierComplianceQueryResponse.data.supplierCompliance,
      });
    }
  }, [supplierComplianceQueryResponse.data]);

  useEffect(() => {
    if (initialized) {
      if (keycloak.realmAccess) {
        if (keycloak.realmAccess.roles) {
          if (keycloak.realmAccess.roles.find((x) => x === "ShipOwner")) {
            setUserRole("ShipOwner");
          } else if (keycloak.realmAccess.roles.find((x) => x === "Supplier")) {
            setUserRole("Supplier");
          } else if (
            keycloak.realmAccess.roles.find((x) => x === "Maintainer")
          ) {
            setUserRole("Maintainer");
          }
        }
      } else if (window.sessionStorage.getItem("userRole")) {
        setUserRole(window.sessionStorage.getItem("userRole")?.toString());
      }
    }
  }, [keycloak, initialized]);

  // ----------------------------------------------------------------------------------------------
  return (
    <Grid sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
      <Grid
        sx={{ display: "flex", flexDirection: "row", mt: 3, columnGap: "16px" }}
      >
        <Grid item xs={12} md={4} sx={{ display: "flex", flex: "1" }}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              flexBasis: "100%",
            }}
          >
            {supplierComplianceQueryResponse.loading ? (
              <>
                <LinearProgress color="primary" sx={{ pt: -1 }} />
                <div className={classesNew.loadingDiv}></div>
              </>
            ) : (
              <AppexGraph
                chartTitle="Your Compliance"
                headLabel="Compliance"
                chartLabels={["Compliance"]}
                chartData={[chartData.supplierCompliance]}
                isloading={supplierComplianceQueryResponse.loading}
              />
            )}
          </Card>
        </Grid>

        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "16px",
            flex: "2",
          }}
        >
          <StatsCard
            Title="Purchase Orders In Scope"
            Value={`${useGetPOInScopeByShipOwnerAndVesselQueryResponse?.data?.getPOInScopeByShipOwnerAndVessel}`}
            navigationUrl={"/dashboard/purchasedOrders/list"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              padding: "16px 24px",
              minHeight: "140px",
              cursor: "default",
            }}
            gridSx={{ flexBasis: "unset" }}
          />
          <NumberCard
            Title="Pending Documents"
            Value={`${findAllRequestsByRoleResponseOneTwo?.data?.findAllPoItemsByRole}`}
            isloading={findAllRequestsByRoleResponseOneTwo?.loading}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              p: 2,
              minHeight: "100px",
              cursor: "default",
            }}
            gridSx={{ flex: 1 }}
          />
          <NumberCard
            Title="In Review Documents"
            Value={`${findAllRequestsByRoleResponseThree?.data?.findAllPoItemsByRole}`}
            isloading={findAllRequestsByRoleResponseThree?.loading}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              p: 2,
              minHeight: "100px",
              cursor: "default",
            }}
            gridSx={{ flex: 1 }}
          />
          <NumberCard
            Title="Resolved Documents"
            Value={`${findAllRequestsByRoleResponseFour?.data?.findAllPoItemsByRole}`}
            isloading={findAllRequestsByRoleResponseFour?.loading}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              p: 2,
              minHeight: "100px",
              cursor: "default",
            }}
            gridSx={{ flex: 1 }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
