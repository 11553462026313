// material
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Container,
  Typography,
  DialogActions,
} from "@mui/material";
// custom
import { DialogAnimate } from "../animate";
import Scrollbar from "../Scrollbar";

// ----------------------------------------------------------------------

type ConfirmDeleteDialogProps = {
  isOpenPreview: boolean;
  onClosePreview: VoidFunction;
  onDelete: VoidFunction;
  dialogMsg: string;
  canDelete: boolean;
};

export default function ConfirmDeleteDialog({
  isOpenPreview,
  onClosePreview,
  onDelete,
  dialogMsg,
  canDelete,
}: ConfirmDeleteDialogProps) {
  const handleSubmit = () => {
    onDelete();
  };

  return (
    <DialogAnimate open={isOpenPreview} onClose={onClosePreview}>
      <Scrollbar>
        <Container>
          <Box sx={{ mt: 3, mb: 5 }}>
            <Typography variant="h4" sx={{ mb: 1, alignContent: "centre" }}>
              Warning!
            </Typography>
            <Typography variant="h6" sx={{ mb: 5 }}>
              {dialogMsg}
            </Typography>
          </Box>
        </Container>
      </Scrollbar>

      <DialogActions sx={{ py: 2, px: 3 }}>
        <Button onClick={onClosePreview}>Cancel</Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={() => handleSubmit()}
          disabled={!canDelete}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </DialogAnimate>
  );
}
