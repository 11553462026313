// material
import { Box, Container, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DialogAnimate } from "../../../animate";
import Scrollbar from "../../../Scrollbar";
import SupplierInventoryMaterialsTable from "./SupplierInventoryMaterialsTable";
// custom

// ----------------------------------------------------------------------

type SupplierInventoryMaterialsTableModalProps = {
  isOpenPreview: boolean;
  onClosePreview: VoidFunction;
  supplierInventoryId: number | undefined;
};

export default function SupplierInventoryMaterialsTableModal({
  isOpenPreview,
  onClosePreview,
  supplierInventoryId,
}: SupplierInventoryMaterialsTableModalProps) {
  return (
    <DialogAnimate
      open={isOpenPreview}
      onClose={onClosePreview}
      cardMinWidth={1300}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <LoadingButton onClick={onClosePreview}>X</LoadingButton>
      </Box>
      <Scrollbar>
        <Container>
          <Box
            sx={{
              mb: 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minWidth: "1000px",
            }}
          >
            <Typography variant="h3" sx={{ m: 2, mb: 4 }}>
              Supplier Inventory Materials Table
            </Typography>
            {supplierInventoryId && (
              <SupplierInventoryMaterialsTable
                supplierInventoryId={supplierInventoryId}
              />
            )}
          </Box>
        </Container>
      </Scrollbar>
    </DialogAnimate>
  );
}
