import * as L from "leaflet";
import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import "leaflet-extra-markers/dist/js/leaflet.extra-markers.js";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useMapEvents } from "react-leaflet";
import { IhmItemPositionDTO } from "../../../@types/IHM";

export const RemoveIhmInstallationMapComponent = forwardRef(
  (
    props: {
      positions: IhmItemPositionDTO[] | undefined;
      enableEdit: boolean;
      placeMarkerToMap: boolean;
    },
    ref
  ) => {
    const { positions, enableEdit, placeMarkerToMap } = props;

    const [mapSelectedMarker, setMapSelectedMarker] = useState(null);

    const approved = (L as any).ExtraMarkers.icon({
      icon: "fa-checked",
      markerColor: "yellow",
      prefix: "fa fa-4x",
      iconColor: "white",
    });

    const map = useMapEvents({
      click: (e: L.LeafletMouseEvent) => {
        if (enableEdit && placeMarkerToMap) {
          rebuildMarkers(-1);
          const { lat, lng } = e.latlng;
          L.marker([lat, lng], {
            riseOnHover: true,
            icon: approved,
            opacity: 0.7,
          })
            .setOpacity(0.6)
            .addEventListener("click", pinCliked)
            .addTo(map);
        }
      },
    });

    const pinCliked = (event: L.LeafletMouseEvent) => {
      const lat = event.latlng.lat;
      const lng = event.latlng.lng;

      if (
        event.target.options.title != undefined &&
        !isNaN(event.target.options.title) &&
        !isNaN(parseFloat(event.target.options.title))
      ) {
        rebuildMarkers(+event.target.options.title);
      }
    };

    useEffect(() => {
      rebuildMarkers(-1);
    }, [positions]);

    const rebuildMarkers = (selectedId: number) => {
      map.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          map.removeLayer(layer);
        }
      });

      positions?.forEach((position: any) => {
        let description = "";
        if (position.productName && position.productName.length > 0) {
          description = position.productName;
        }

        if (
          description.length > 0 &&
          position.productCode &&
          position.productCode.length > 0
        ) {
          description += `, ${position.productCode}`;
        } else {
          description = position.productCode;
        }

        if (position.x && position.y) {
          const marker = L.marker([position.y, position.x], {
            icon: approved,
          })
            .bindTooltip(description)
            .addTo(map);
          marker.options.title = position.id;
          marker.options.alt = position.approvalStatus;
        }
      });
    };
    useImperativeHandle(ref, () => ({
      removeMarker(lat: number, lng: number) {
        if (mapSelectedMarker != null) {
          map.removeLayer(mapSelectedMarker);
          setMapSelectedMarker(null);
        }
      },
      resetMarkers() {
        rebuildMarkers(-1);
      },
    }));

    return null;
  }
);
