import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import L from "leaflet";
import { toast } from "react-toastify";
import { IHMDTO } from "../../../@types/IHM";
import { VesselDrawingDTO } from "../../../@types/Vessel";
import {
  GET_IHM_POSITION_BY_ID,
  GET_IHM_REMAINING_QUANTITY_QUERY,
} from "../../../graphQL/Queries";
import InstallIhmVesselMap from "./InstallIhmVesselMap";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  Mapper: {
    padding: "0 45px 20px",
  },
  modalHeader: {
    marginBottom: "24px",
  },
  headerTitle: {
    "&.MuiTypography-root": {
      textAlign: "center",
      fontWeight: "600",
      marginBottom: "24px",
    },
  },
  headerDescription: {
    "&.MuiTypography-root": {
      fontSize: "14px",
    },
  },
  step1: {
    marginBottom: "24px",
  },
  step2: {
    marginBottom: "24px",
  },
  selectionField: {
    "&>.MuiSelect-select": {
      fontSize: "14px",
    },
  },
}));

// Define Queries
export const useGetPositionQuery = (_positionId: number | undefined) =>
  useQuery(GET_IHM_POSITION_BY_ID, {
    variables: {
      id: _positionId,
    },
    skip: !_positionId,
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

export const useGetIhmRemainingQuantityQuery = (ihmId: number | undefined) =>
  useQuery(GET_IHM_REMAINING_QUANTITY_QUERY, {
    variables: {
      ihmId: ihmId,
    },
    skip: !ihmId,
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

type InstallIhmMapperProps = {
  ihmItem: IHMDTO;
  ihmItemDrawingsAvailable: VesselDrawingDTO[];
  modalClose: VoidFunction;
};

const InstallIhmMapper = ({
  ihmItem,
  modalClose,
  ihmItemDrawingsAvailable,
}: InstallIhmMapperProps) => {
  const classesNew = useStyles();
  const [ihmItemDrawingSelected, setIhmItemDrawingSelected] =
    useState<VesselDrawingDTO>();

  L.Icon.Default.imagePath = "leaflet/";

  // Use Queries
  const useGetIhmRemainingQuantityQueryResponse =
    useGetIhmRemainingQuantityQuery(ihmItem?.id);

  // ------------------------------------------------------------------ Hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (ihmItemDrawingsAvailable?.length > 0 && ihmItemDrawingsAvailable[0]) {
      setIhmItemDrawingSelected(ihmItemDrawingsAvailable[0]);
    }
  }, [ihmItemDrawingsAvailable]);

  // ------------------------------------------------------------------ Handlers ---------------------------------------------------------------------
  const handleDrawingInput = (event: SelectChangeEvent) => {
    setIhmItemDrawingSelected(
      ihmItemDrawingsAvailable.filter(
        (vesselDrawing: VesselDrawingDTO) =>
          vesselDrawing.id === parseInt(event.target.value as string)
      )[0]
    );
  };

  return (
    <div className={classesNew.Mapper}>
      <Stack className={classesNew.modalHeader}>
        <Typography className={classesNew.headerTitle}>
          Installation Request
        </Typography>
        <Typography className={classesNew.headerDescription}>
          In order to proceed to an installation request of a stored item (IHM
          Part III) that has been identified to containHazardous Materials
          (HMs), proceed in completing the following form. You request will be
          submitted for approval by HazDash IHM Maintenance Experts.
        </Typography>
      </Stack>
      <Stack className={classesNew.step1}>
        <Typography sx={{ fontSize: "14px", mb: 1 }}>
          1. Select Vessel Drawing*.
        </Typography>
        <FormControl variant="standard" fullWidth>
          <Select
            variant="standard"
            labelId={`select-label-id-}`}
            id={`select-id-}`}
            className={classesNew.selectionField}
            label="Header Mapping"
            value={
              ihmItemDrawingSelected?.id ? `${ihmItemDrawingSelected.id}` : ""
            }
            onChange={handleDrawingInput}
          >
            {ihmItemDrawingsAvailable?.length > 0
              ? ihmItemDrawingsAvailable.map(
                  (ihmItemDrawing: VesselDrawingDTO, index: number) => {
                    return (
                      <MenuItem key={index} value={ihmItemDrawing.id}>
                        {`${ihmItemDrawing.path?.slice(
                          0,
                          ihmItemDrawing.path?.indexOf("/")
                        )} - ${ihmItemDrawing.name}`}
                      </MenuItem>
                    );
                  }
                )
              : null}
          </Select>
        </FormControl>
      </Stack>
      <Stack className={classesNew.step2}>
        <Typography sx={{ fontSize: "14px" }}>
          2. Click to place a marker on the installation position of item on
          Drawing*.
        </Typography>
      </Stack>
      {ihmItem && (
        <InstallIhmVesselMap
          ihmItem={ihmItem}
          selectedVesselDrawing={ihmItemDrawingSelected}
          remainingQuantity={
            useGetIhmRemainingQuantityQueryResponse?.data
              ?.getIhmRemainingQuantity
          }
          modalClose={modalClose}
        />
      )}
    </div>
  );
};

export default InstallIhmMapper;
