import { Box, Button, Dialog, DialogActions, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import * as Yup from "yup";
import { GridRowModel, GridValidRowModel } from "@mui/x-data-grid-pro";
import { Dispatch, MutableRefObject, Ref, RefObject, SetStateAction, useState } from "react";
import { SupplierInventoryDTO } from "../../../../@types/Supplier";
import { Form, FormikProvider, useFormik } from "formik";
import { toast } from "react-toastify";

type AddQuantityProps = {
  openModal: boolean;
  onClosePreview: VoidFunction;
  //setQuantityValue: Dispatch<SetStateAction<number | undefined>>;
  row: SupplierInventoryDTO;
  //quantityValue: number | undefined;
  dataRowsCurrent: MutableRefObject<GridRowModel[]>;
  setDataTableRowsOfBasket: Dispatch<SetStateAction<GridValidRowModel[]>>;
};

export default function AddQuantityModal({
  openModal,
  onClosePreview,
  //setQuantityValue,
  row,
  //quantityValue,
  dataRowsCurrent,
  setDataTableRowsOfBasket
}: AddQuantityProps) {

  const [
    selectedHazardousMaterialUnit,
    setSelectedHazardousMaterialUnit,
  ] = useState<string>();
const NewVesselSchema = Yup.object().shape({
    quantity: Yup.string().required("Quantity is required"),
    unit: Yup.string().required("Unit is required"),       
});

const formik = useFormik({
  enableReinitialize: true,
  initialValues: {
    quantity: "1",
    unit: "PCS",
    itemUnit: row.itemUnit,
  },
  validationSchema: NewVesselSchema,
  onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        
          

          const dataTableRow: GridRowModel = {};
          dataTableRow.id = row.id;
          dataTableRow.productCode = row.productCode;
          dataTableRow.productName = row.productName;
          dataTableRow.itemUnit = row.itemUnit;
          dataTableRow.containsHazMat =
            row.supplierInventoryMaterial &&
              row.supplierInventoryMaterial.length > 0
              ? true
              : false;

          dataTableRow.quantity = values?.quantity;
          dataTableRow.unit = values?.unit;
          //dataTableRow.selectedHazardousMaterialUnit = selectedHazardousMaterialUnit;
          dataTableRow.productInformation = row.productInformation;
          dataTableRow.supplierInventoryMaterial = row.supplierInventoryMaterial;
          const rowExists = dataRowsCurrent.current.find(x => x.id === dataTableRow.id);
          if (rowExists == undefined) {
            dataRowsCurrent.current.push(dataTableRow)

            setDataTableRowsOfBasket([...dataRowsCurrent.current]);
          }
          resetForm();
          onClosePreview();
            
  } catch (error) {
          console.error(error);
          setSubmitting(false);
          toast.configure();
          toast.error(`Error: ${error}`);
  }
      },
  });


const {
  errors,
  values,
  touched,
  handleSubmit,
  setFieldValue,
  isSubmitting,
  getFieldProps,
} = formik;


const handleVesselApproverInput = (event: SelectChangeEvent) => {
  
  //setSelectedVesselMaintainerApprover(
   // availableVesselMaintainers?.filter(
   //   (vMaintainerUser: AppUserDTO) =>
   //     vMaintainerUser.identityId === 
   // )[0]
  //);
 // if(event.target.value=='kg'){
    setSelectedHazardousMaterialUnit(event.target.value);
 // }else if(event.target.value=='m'){
    //setSelectedHazardousMaterialUnit('m');
//  }else if(event.target.value=='m2'){
   // setSelectedHazardousMaterialUnit('m2');
 // }
  
};

  return (
    <Dialog open={openModal} onClose={onClosePreview}>
      <DialogActions >
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box
            sx={{
                direction: 'column',
                flexDirection: 'column',
                display: "flex",
                alignItems: 'center',
              }
            }
          >
            <TextField
              label="Quantity*"
              variant="standard"
              error={Boolean(touched.quantity && errors.quantity)}
              helperText={touched.quantity && errors.quantity}

              {...getFieldProps("quantity")}
              value={values?.quantity}
            />
            <TextField
              label="Unit*"
              variant="standard"
              error={Boolean(touched.unit && errors.unit)}
              helperText={touched.unit && errors.unit}
              sx={{mt:2}}

              {...getFieldProps("unit")}
              value={values?.unit}
            />


          </Box>
          <Box sx={{mt:2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between" }}>

            <Button
              variant="contained" onClick={onClosePreview}>
              Cancel
            </Button>

            <Button variant="contained" type="submit">
              Ok
            </Button>
          </Box>

        </Form>
        </FormikProvider>

      </DialogActions>
    </Dialog>
  );
}