// material
import { Card, Container, Grid } from "@mui/material";
// components
import Page from "../../components/Page";
import AppWelcome from "../../components/@material-extend/AppWelcome";
import MyNotificationsCard from "../../components/custom/MyNotificationsCard";
import MaintainerCards from "./MaintainerCards";
import { useKeycloak } from "@react-keycloak/web";
import ShipownerHomepage from "../../components/homepage/ShipownerHomepage";
import SupplierHomepage from "../../components/homepage/SupplierHomepage";
import { useEffect, useState } from "react";
import IHMsTableContainer from "../../components/dashboard/ihm/IHMsTableContainer";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GET_APP_USER_IS_GUEST_QUERY } from "../../graphQL/Queries";

// Define Queries
const getAppUserIsGuestQuery = () =>
  useQuery(GET_APP_USER_IS_GUEST_QUERY, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

// --------------------------------------------------------------------------------------------------
type MaintainerLandingPageProps = {
  setNavigationHelper: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function MaintainerLandingPage({
  setNavigationHelper,
}: MaintainerLandingPageProps) {
  const { initialized, keycloak } = useKeycloak();
  const [userRole, setUserRole] = useState<undefined | string>(undefined);

  // Use Queries
  const useGetAppUserIsGuestQuery = getAppUserIsGuestQuery();

  // ---------------------------------- Component Lifecycle ---------------------------------------
  useEffect(() => {
    if (initialized) {
      if (keycloak.realmAccess) {
        if (keycloak.realmAccess.roles) {
          if (keycloak.realmAccess.roles.find((x) => x === "ShipOwner")) {
            setUserRole("ShipOwner");
          } else if (keycloak.realmAccess.roles.find((x) => x === "Supplier")) {
            setUserRole("Supplier");
          } else if (
            keycloak.realmAccess.roles.find((x) => x === "Maintainer")
          ) {
            setUserRole("Maintainer");
          }
        }
      } else if (window.sessionStorage.getItem("userRole")) {
        setUserRole(window.sessionStorage.getItem("userRole")?.toString());
      }
    }
  }, [keycloak, initialized]);

  useEffect(() => {
    setNavigationHelper(false);
  }, []);

  // ----------------------------------------------------------------------------------------------
  return (
    <Page title="Homepage">
      <Container maxWidth={"xl"}>
        <Grid sx={{ display: "flex", flexDirection: "column" }}>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              columnGap: "24px",
            }}
          >
            <AppWelcome />

            <MyNotificationsCard />
          </Grid>
          {userRole != undefined && userRole === "Maintainer" && (
            <>
              <MaintainerCards />
            </>
          )}
          {userRole != undefined && userRole == "ShipOwner" && (
            <ShipownerHomepage />
          )}
          {userRole != undefined && userRole == "Supplier" && (
            <SupplierHomepage />
          )}
          {userRole != undefined &&
            userRole != "Supplier" &&
            useGetAppUserIsGuestQuery?.data?.getAppUserIsGuest === false && (
              <Card sx={{ mt: 3 }}>
                <IHMsTableContainer />
              </Card>
            )}
        </Grid>
      </Container>
    </Page>
  );
}
