// material
import { DialogAnimate } from "../../../animate";
import ImportSiWizard from "./ImportSiWizard";
// custom

// ----------------------------------------------------------------------

type ImportSupplierInventoryWizardModalProps = {
  isOpenPreview: boolean;
  onClosePreview: VoidFunction;
};

export default function ImportSupplierInventoryWizardModal({
  isOpenPreview,
  onClosePreview,
}: ImportSupplierInventoryWizardModalProps) {
  return (
    <DialogAnimate
      open={isOpenPreview}
      onClose={onClosePreview}
      cardMinWidth={1000}
    >
      <ImportSiWizard onClosePreview={onClosePreview} />
    </DialogAnimate>
  );
}
