import { Icon } from "@iconify/react";
import arrowLeftFill from "@iconify/icons-eva/arrow-left-fill";
import arrowRightFill from "@iconify/icons-eva/arrow-right-fill";
import roundKeyboardArrowLeft from "@iconify/icons-ic/round-keyboard-arrow-left";
import roundKeyboardArrowRight from "@iconify/icons-ic/round-keyboard-arrow-right";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import { Typography, Box, BoxProps } from "@mui/material";
//
import { MIconButton } from "./";

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 20,
  height: 20,
};

const RootStyle = styled(Box)(({ theme }) => ({
  zIndex: 9,
  display: "flex",
  alignItems: "center",
  position: "absolute",
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.grey[800], 0.48),
}));

const ArrowStyle = styled(MIconButton)(({ theme }) => ({
  padding: 6,
  opacity: 0.48,
  color: theme.palette.common.white,
  "&:hover": { opacity: 1 },
}));

// ----------------------------------------------------------------------

interface MCarouselControlsArrowsIndexProps extends BoxProps {
  arrowLine?: boolean;
  index: number;
  total: number;
  onNext?: VoidFunction;
  onPrevious?: VoidFunction;
}

export default function MCarouselControlsArrowsIndex({
  arrowLine,
  index,
  total,
  onNext,
  onPrevious,
  ...other
}: MCarouselControlsArrowsIndexProps) {
  const theme = useTheme();
  const isRTL = theme.direction === "rtl";

  return (
    <RootStyle {...other}>
      <ArrowStyle size="small" onClick={onPrevious}>
        {arrowLine ? (
          <Icon
            icon={isRTL ? roundKeyboardArrowRight : roundKeyboardArrowLeft}
            {...ICON_SIZE}
          />
        ) : (
          <Icon icon={isRTL ? arrowRightFill : arrowLeftFill} {...ICON_SIZE} />
        )}
      </ArrowStyle>

      <Typography variant="subtitle2">
        {index + 1}/{total}
      </Typography>

      <ArrowStyle size="small" onClick={onNext}>
        {arrowLine ? (
          <Icon
            icon={isRTL ? roundKeyboardArrowLeft : roundKeyboardArrowRight}
            {...ICON_SIZE}
          />
        ) : (
          <Icon icon={isRTL ? arrowLeftFill : arrowRightFill} {...ICON_SIZE} />
        )}
      </ArrowStyle>
    </RootStyle>
  );
}
