import { Icon } from "@iconify/react";
import androidFilled from "@iconify/icons-ant-design/android-filled";
// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha("#007B55", 0)} 0%, ${alpha(
    "#007B55",
    0.24
  )} 100%)`,
}));

interface DashboardInfoCardProps {
  InfoUrl: string;
  Label: string;
  Value: string;
}

// ----------------------------------------------------------------------

export default function DashboardInfoCard(props: DashboardInfoCardProps) {
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={androidFilled} width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{props.Label}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {props.InfoUrl}
      </Typography>
    </RootStyle>
  );
}
