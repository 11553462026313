import React from "react";
// material
import { Card, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import StatsCard from "../../components/custom/Cards/StatsCard";
import NumberCard from "../../components/custom/Cards/NumberCard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GET_IHMS_BY_USER_ROLE_QUERY,
  GET_PO_REQUESTS_BY_USER_ROLE_QUERY,
  GET_PURCHASE_ORDERS_BY_USER_ROLE_QUERY,
  GET_VESSELS_BY_USER_ROLE_QUERY,
  GET_COUNT_DOCUMENTS_QUERY,
  GET_COUNT_SHIPOWNER_QUERY,
} from "../../graphQL/Queries";
import { useQuery } from "@apollo/client";
import { StateEnum } from "../../@types/State";

const useStyles = makeStyles(() => ({
  mainCard: {
    "&.MuiCard-root": {
      display: "flex",
      flexDirection: "row",
      padding: "24px",
      columnGap: "24px",
    },
  },
  bigInfosGrid: {
    "&.MuiGrid-root": {
      display: "flex",
      flexDirection: "column",
      rowGap: "24px",
    },
  },
}));

// Define Queries
const getPurchaseOrdersByUserRoleQuery = () =>
  useQuery(GET_PURCHASE_ORDERS_BY_USER_ROLE_QUERY, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });
const getVesselsByUserRoleQuery = () =>
  useQuery(GET_VESSELS_BY_USER_ROLE_QUERY, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });
const getIhmsByUserRoleQuery = () =>
  useQuery(GET_IHMS_BY_USER_ROLE_QUERY, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });
const getPoRequestsByUserRoleQuery = () =>
  useQuery(GET_PO_REQUESTS_BY_USER_ROLE_QUERY, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });
const getPendingDocumentsByUserRoleQuery = () =>
  useQuery(GET_PO_REQUESTS_BY_USER_ROLE_QUERY, {
    variables: {
      states: [StateEnum.INVITE_SUPPLIER, StateEnum.WAITING_FOR_SUPPLIER_SUBMISSION],
    },
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });
const getInReviewDocumentsByUserRoleQuery = () =>
  useQuery(GET_PO_REQUESTS_BY_USER_ROLE_QUERY, {
    variables: {
      states: [3],
    },
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });
const getResolvedPoByUserRoleQuery = () =>
  useQuery(GET_PO_REQUESTS_BY_USER_ROLE_QUERY, {
    variables: {
      states: [4],
    },
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });
const getCountDocumentsQuery = () =>
  useQuery(GET_COUNT_DOCUMENTS_QUERY, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });
const getCountShipOwnersQuery = () =>
  useQuery(GET_COUNT_SHIPOWNER_QUERY, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

function MaintainerCards() {
  const classesNew = useStyles();

  const getPurchaseOrdersByUserRoleQueryResponse =
    getPurchaseOrdersByUserRoleQuery();
  const getVesselsByUserRoleQueryResponse = getVesselsByUserRoleQuery();
  const getIhmsByUserRoleQueryResponse = getIhmsByUserRoleQuery();
  //   const getPoRequestsByUserRoleQueryResponse = getPoRequestsByUserRoleQuery();
  const getPendingDocumentsByUserRoleQueryResponse =
    getPendingDocumentsByUserRoleQuery();
  const getInReviewDocumentsByUserRoleQueryResponse =
    getInReviewDocumentsByUserRoleQuery();
  const getResolvedPoByUserRoleQueryResponse = getResolvedPoByUserRoleQuery();
  const getCountDocumentsQueryResponse = getCountDocumentsQuery();
  const getCountShipOwnersQueryResponse = getCountShipOwnersQuery();
  return (
    <>
      <Card className={classesNew.mainCard} sx={{ mt: 3 }}>
        <Stack sx={{ flexBasis: "33%" }}>
          <Grid className={classesNew.bigInfosGrid}>
            <StatsCard
              Title="Purchase Orders In Scope"
              Value={`${getPurchaseOrdersByUserRoleQueryResponse?.data?.findAllPOsByRole}`}
              navigationUrl={"/dashboard/purchasedOrders/list"}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                padding: "16px 24px",
                minHeight: "140px",
                cursor: "default",
                width: "450px",
              }}
            />
            <StatsCard
              Title="Total Vessels"
              Value={`${getVesselsByUserRoleQueryResponse?.data?.findAllVesselsByRole}`}
              navigationUrl={"/dashboard/vessels/dashboard"}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                padding: "16px 24px",
                minHeight: "140px",
                cursor: "default",
                width: "450px",
              }}
            />
            <StatsCard
              Title="Total IHMs"
              Value={`${getIhmsByUserRoleQueryResponse?.data?.findAllIhmsByRole}`}
              navigationUrl={"/dashboard/ihms/dashboard"}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                padding: "16px 24px",
                minHeight: "140px",
                cursor: "default",
                width: "450px",
              }}
            />
            <StatsCard
              Title="Total Ship Owners"
              Value={`${getCountShipOwnersQueryResponse?.data?.countShipOwners}`}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                padding: "16px 24px",
                minHeight: "140px",
                cursor: "default",
                width: "450px",
              }}
            />
          </Grid>
        </Stack>
        <Stack sx={{ flexBasis: "67%", rowGap: "24px" }}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <NumberCard
              Title="Pending Documents"
              Value={`${getPendingDocumentsByUserRoleQueryResponse?.data?.findAllRequestsByRole}`}
              isloading={getPendingDocumentsByUserRoleQueryResponse?.loading}
            />
            <NumberCard
              Title="In Review Documents"
              Value={`${getInReviewDocumentsByUserRoleQueryResponse?.data?.findAllRequestsByRole}`}
              isloading={getInReviewDocumentsByUserRoleQueryResponse?.loading}
            />
            <NumberCard
              Title="Resolved POs"
              Value={`${getResolvedPoByUserRoleQueryResponse?.data?.findAllRequestsByRole}`}
              isloading={getResolvedPoByUserRoleQueryResponse?.loading}
            />
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <NumberCard
              Title="Generated Green Documents"
              Value={`${getCountDocumentsQueryResponse?.data?.countDocuments.totalGeneratedGreenDocuments}`}
              isloading={getCountDocumentsQueryResponse?.loading}
            />
            <NumberCard
              Title="Generated HazMat Documents"
              Value={`${getCountDocumentsQueryResponse?.data?.countDocuments.totalGeneratedHazMatDocuments}`}
              isloading={getCountDocumentsQueryResponse?.loading}
            />
            <NumberCard
              Title="Uploaded Green Documents"
              Value={`${getCountDocumentsQueryResponse?.data?.countDocuments.totalUploadedGreenDocuments}`}
              isloading={getCountDocumentsQueryResponse?.loading}
            />
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <NumberCard
              Title="Uploaded HazMat Documents"
              Value={`${getCountDocumentsQueryResponse?.data?.countDocuments.totalUploadedHazMatDocuments}`}
              isloading={getCountDocumentsQueryResponse?.loading}
            />
            <NumberCard
              Title="Api Green Documents"
              Value={`${getCountDocumentsQueryResponse?.data?.countDocuments.totalApiGreenDocuments}`}
              isloading={getCountDocumentsQueryResponse?.loading}
            />
            <NumberCard
              Title="Api HazMat Documents"
              Value={`${getCountDocumentsQueryResponse?.data?.countDocuments.totalApiHazMatDocuments}`}
              isloading={getCountDocumentsQueryResponse?.loading}
            />
          </Grid>
        </Stack>
      </Card>
    </>
  );
}

export default MaintainerCards;
