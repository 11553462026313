// material
import {  Card, Grid, Typography } from "@mui/material";
import Page from "../../components/Page";
import epelogobig from "../../assets/epe-logo.png";

export default function TermsPage() {

  return (
    <Page title="TERMS OF USE">
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#006298",
        }}
      >
        <nav
          style={{
            backgroundColor: "whitesmoke",
            width: "100%",
            height: "95px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <img src={epelogobig} alt="epe logo" />
        </nav>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            padding: "0px 15px 20px",
            margin: "20px 0px",
            borderRadius: 3,
            maxWidth: "calc(100% - 30px)"
          }}
        >
          <Typography variant="h4" sx={{ m: 1, textAlign:"center" }}>
          TERMS OF USE
          </Typography>
          <Typography variant="h6" sx={{ m: 1, textAlign:"center" }}>
          LICENSE AGREEMENT FOR HAZDASH PLATFORM
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%" }}>
          ENVIRONMENTAL PROTECTION ENGINEERING SA (“EPE”) is willing to grant you the right to access or use its HAZDASH Platform, an intelligent cloud-based Platform, that allows the Inventory of Hazardous Materials (IHM) on board a ship to be kept fully up to date. This is a license agreement setting forth the terms and conditions upon which EPE offers to license the HAZDASH Platform to you.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          By using HAZDASH Platform you expressly and unreservedly accept that you are bound by all the terms and conditions set forth in this Agreement. Please note that the terms and conditions set forth herein shall apply to you whether you access or use the HAZDASH Platform directly or whether the HAZDASH Platform is made available to you by a third party.
          </Typography>
          <Typography variant="h6" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%" }}>
            I.	LICENSE AND USE LIMITATIONS
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          A. EPE hereby grants you, and you hereby accept a non-exclusive, non-transferable, non-sublicensable, worldwide, revocable license to access or use the HAZDASH Platform strictly for business purposes in accordance with this Agreement. The license granted hereunder shall commence when you indicate your acceptance of all of the terms and condition of this Agreement by clicking on the applicable buttons below and shall continue until terminated as provided on term IV.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          B. You acknowledge that all information – data, including without limitation pricing information, business information stored in the HAZDASH Platform are confidential. You agree to treat these in the same manner as you treat and protect your own proprietary information and trade secrets (but in any case using no less than a reasonable degree of care) and you will not disclose, share, duplicate any confidential information, including data or information with respect to HAZDASH Platform or pricing embodied therein, or any other documentation, instructions or other information relating thereto, to anyone except to those who need to know such information. You recognize that breach of the confidentiality obligations set out above will cause irreparable harm to EPE. EPE is entitled to immediate and temporary relief by way of injunction or restraining order against any future access to or use of HAZDASH Platform should you breach these obligations.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          C. You are not authorized to (i) sell, sublicense, transfer, assign, or distribute the HAZDASH Platform or its content; (ii) modify or make derivative works based upon the HAZDASH Platform or its content; (iii) “frame” or “mirror” the HAZDASH Platform or its content on any other server or Internet enabled device, or (iv) reverse engineer, decompile the HAZDASH Platform or its enabling software for any purpose.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          D. You are not authorized to use the HAZDASH Platform for the propagation, distribution, housing, processing, storing, or otherwise handling in any way lewd, obscene, or pornographic material, or any other material which we deem to be objectionable. The designation of any such materials is entirely at EPE’s sole discretion.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          E. EPE shall retain all intellectual property rights, which include but are not limited to any patents, copyrights, design rights, trademarks, trade secrets, know how, database rights, industrial property rights, and all other similar or corresponding rights (all of the foregoing rights, whether registered or unregistered), and all applications, amendments, and extensions for the same, whether under Greek law or EU law or foreign law, in the HAZDASH Platform and all data and information with respect to HAZDASH Platform, or technology embodied therein, and any other documentation instructions or other information relating thereto. You agree that EPE is the sole and exclusive owner of the HAZDASH Platform and has the right to license the access or use of the HAZDASH Platform under the terms of this Agreement. No title to or ownership of the HAZDASH Platform or intellectual property rights relating to the HAZDASH Platform is transferred to you.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          F. These use limitations shall survive the termination of this Agreement.
          </Typography>
          <Typography variant="h6" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%" }}>
            II.	SUBMITTED MATERIALS
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          A. You may be required to register, and/or provide personal information to the HAZDASH Platform. Use of any information you provide will be in accordance with EPE’s <a href="https://www.epe.gr/privacy-policy/" target="_blan">privacy policy</a>.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          B. You must ensure that the information you provide as part of the registration or uploading process is complete and accurate at all times. You may not use a false email address, impersonate any other individual or entity, or otherwise mislead EPE as to the origin of the submitted materials. In the event that you need to make any updates or changes to the information you provide when you register or upload any submitted material, please contact <a href = "mailto: support@hazdash.com ">support@hazdash.com </a>.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          C. When submitting information-data through the HAZDASH Platform you represent and warrant that:
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 1, textAlign:"left", width:"100%"  }}>
          1. The information-data are accurate, reliable, and valid;
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 1, textAlign:"left", width:"100%"  }}>
          2. The online generation of IHM docs (MDs & SDoCs) through HAZDASH Platform are originated by you whether you act as an individual or on behalf of a legal entity you represent that you that you are fully authorized to do so.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 1, textAlign:"left", width:"100%" }}>
          3. The online generated of IHM docs (MDs & SDoCs) through your registration on the Platform are equivalent to those signed and uploaded by you.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 1, textAlign:"left", width:"100%"  }}>
          4. You own all rights, title and interest in and to any submitted material and all intellectual property rights corresponding thereto;
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 1, textAlign:"left", width:"100%"  }}>
          5. You have the authority to disclose the submitted material and all intellectual property rights corresponding thereto on behalf of the owner(s) of the submitted material.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 1, textAlign:"left", width:"100%"  }}>
          6. You represent and warrant that the use of the submitted material will not cause damage to any individual or entity; that you have the written consent, release and/or permission of each and every identifiable individual person in your submitted material to use such person’s name or likeness and to grant EPE the rights stated herein;  that you will defend, indemnify and hold EPE harmless from all claims, demands, actions, and any liabilities, damages or expenses resulting therefrom, arising out of or relating to any breach of any of the foregoing representations or warranties or otherwise resulting from the submitted material. EPE takes no responsibility and assumes no liability for any submitted material. Without limiting the generality of the foregoing sentence, you agree that EPE shall not be responsible or liable for any damages you may sustain in connection with the creation or production of your submitted material.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 1, textAlign:"left", width:"100%"  }}>
          7. Should the submitted material become the subject of any claim, you shall promptly procure for EPE the right to continue using the submitted material or replace or modify it to make it non-infringing. If none of the above alternatives is reasonably available to you, then EPE may terminate the Agreement immediately.
          </Typography>
          <Typography variant="h6" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%" }}>
            III.	DISCLAIMERS
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          A. EPE makes reasonable efforts to include accurate and updated information in its HAZDASH Platform. Though, since HAZDASH Platform hosts information-data supplied by third parties, no warranties of any kind with respect to accuracy, completeness, reliability, security of such content are made be EPE.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          B. EPE does not make any kind of representations or warranties that the content of the HAZDASH Platform is error free, that defects will be corrected, that the server that makes it available is free of viruses or other harmful components, that the internet provider is free of interruptions. EPE makes reasonable efforts for the continuous operation and functionality of its HAZDASH Platform. Though EPE cannot guarantee them.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          C. You agree that your use of the HAZDASH Platform is at your own risk and is being provided to you on an “AS IS” and “AS AVAILABLE” basis. Accordingly, to the extent permitted by applicable law, EPE excludes all express or implied warranties, terms and conditions including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement. In addition, to the extent permitted by applicable law, EPE is not liable, and you agree to hold EPE harmless, for any damages or losses (including, but not limited to, loss of money, goodwill or reputation, profits, or other intangible losses or any special, indirect, or consequential damages) resulting directly or indirectly from:
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%", paddingLeft:"20px"  }}>
          <ul>
            <li>The access or use of the HAZDASH Platform;</li>
            <li>The inability to access or use of the HAZDASH Platform;</li>
            <li>Interruptions, delays;</li>
            <li>Viruses or other harmful codes;</li>
            <li>Infections to your computer equipment, programs, data;</li>
            <li>Inaccuracy, false and unreliable information-data.</li>
          </ul>
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          D. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output.
          </Typography>
          <Typography variant="h6" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%" }}>
          IV.	TERM
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          This Agreement is effective until terminated. You may terminate this Agreement at any time by providing written notice to EPE and ceasing to access or use the HAZDASH Platform. If either party fails to comply with any of the material terms and conditions of this Agreement, the non-defaulting party may terminate this Agreement and any and all license rights upon fifteen (15) days’ written notice to the defaulting party specifying any such breach, unless within the period of such notice, all breaches specified therein shall have been remedied. Upon termination, you shall immediately cease accessing the HAZDASH Platform.
          </Typography>
          <Typography variant="h6" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%" }}>
          V.	GOVERNING LAW
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%" }}>
          A. The Agreement shall be governed by Greek law.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%" }}>
          B. Any dispute that cannot be resolved amicably shall be submitted to the competent court(s) in Piraeus.
          </Typography>
          <Typography variant="h6" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%" }}>
          VI.	GENERAL TERMS
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          A. EPE reserves the right to modify these Terms and Conditions at any time. You should check these Terms and Conditions periodically for modifications. If any modification is unacceptable to you, your only recourse is to terminate this Agreement. Your continued access or use of the HAZDASH Platform following our posting of an amended Agreement or providing you notice of a modification will constitute binding acceptance.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          B. You are responsible for maintaining the confidentiality of your login protocols, and any additional information that EPE may provide regarding accessing the HAZDASH Platform. If you knowingly share your login protocols with another person who is not authorized to access or use the HAZDASH Platform, this Agreement is subject to termination for cause. You agree to immediately notify EPE of any unauthorized use of your login protocols or any other breach of security.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          C. Each of the Parties to this Agreement is a legal entity or person separate and independent of the other. Nothing contained in this Agreement is to be construed or deemed to create a principal and agent relationship between the parties and/or any form of partnership or joint venture. Neither party shall enter into or have authority to enter into any engagement or make any representation or warranty on behalf of or pledge the credit of or otherwise bind or oblige the other party.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          D. This Agreement supersedes all prior representations, arrangements and understandings between the parties relating to the subject matter hereof and except as expressly provided herein is intended by the parties to be a complete and conclusive statement of the terms and conditions of this Agreement.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          E. The waiver or failure of either party to exercise in any respect any right provided for in this Agreement shall not be deemed a waiver of any further right hereunder.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          F. Any attempt by you to sublicense, assign or transfer any of your rights, duties or obligations under this Agreement, in whole or in part, shall be void and of no effect.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
          G. If any provision of this Agreement is held to be invalid, void, ineffective, unenforceable and/or illegal, such will not affect the validity and/or enforceability of the remaining provisions of this Agreement. In such case, the parties shall enter into good faith negotiations to amend such a provision in conformity with the original intention of the parties.
          </Typography>
          <Typography variant="body2" sx={{ m: 0, mb: 2, textAlign:"left", width:"100%"  }}>
            Updated on 15/11/2022
          </Typography>
        </Card>
      </Grid>
    </Page>
  );
}