import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
// material
import { makeStyles } from "@mui/styles";
import { Container, Box, FormControl, FormHelperText } from "@mui/material";

// --------------------------------custom component styles-----------------------------------
const useStyles = makeStyles(() => ({
  root: {
    "& label": {
      maxWidth: "100%",
      height: "400px",
      border: "dashed 2px #00ab55",
      flexDirection: "column",
      "& svg": {
        marginTop: "15%",
        "& path": {
          fill: "#00ab55",
        },
      },
    },
  },
}));

// --------------------------------component prop types---------------------------------------
type ImportSiWizardSelectFileProps = {
  setCanProceed: React.Dispatch<React.SetStateAction<boolean>>;
  file: File | undefined;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
};

// -----------------------------------------------------------------------------------------------------------------
export default function ImportSiWizardSelectFile({
  setCanProceed,
  setFile,
  file,
}: ImportSiWizardSelectFileProps) {
  const newClass = useStyles();

  useEffect(() => {
    if (file != undefined) {
      setCanProceed(true);
    } else {
      setCanProceed(false);
    }
  }, [file]);

  // --------------------------------- handlers -----------------------------------------------
  const handleFileInput = (inputFile: File) => {
    setFile(inputFile);
  };
  // ------------------------------------------------------------------------------------------
  return (
    <Container maxWidth={"xl"}>
      <Box sx={{ width: "100%", marginTop: 12 }}>
        <FormControl variant="standard" fullWidth>
          <Box sx={{ width: "100%", marginTop: 2 }} className={newClass.root}>
            <FileUploader
              handleChange={handleFileInput}
              name="file"
              types={["xlsx"]}
              maxSize={3}
              onTypeError={(err: any) => console.log(err)}
            />
            <FormHelperText id="file-description">
              {file ? `File name: ${file.name}` : "no files uploaded yet"}
            </FormHelperText>
          </Box>
        </FormControl>
      </Box>
    </Container>
  );
}
