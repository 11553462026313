import { useEffect, useState } from "react";
// material
import { useMutation } from "@apollo/client";
import { Box, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SupplierInventoryDTO } from "../../../../@types/Supplier";
import { IMPORT_SUPPLIER_INVENTORY_MAP_EXCEL_HEADERS_MUTATION } from "../../../../graphQL/Mutations";
import { DataGridPro, GridColDef, GridRowModel, useGridApiRef } from "@mui/x-data-grid-pro";
import React from "react";

// -------------------------------- Styles ---------------------------------------
const useStyles = makeStyles(() => ({
  customStyle: {
    "& .MuiDataGrid-toolbarContainer": {
      background: "#ffffff",
    },
  },
  panel: {
    "& .MuiNativeSelect-select": {
      backgroundColor: "red",
      color: "red",
    },
  },
}));

// --------------------------------component prop types---------------------------------------
type ImportSiWizardMapHeadersProps = {
  file: File | undefined;
  supplierInventoriesArray: SupplierInventoryDTO[] | undefined;
  setSupplierInventoriesArray: React.Dispatch<
    React.SetStateAction<SupplierInventoryDTO[] | undefined>
  >;
};

// ------------------------------------------------------------------------------------------------------------------------
export default function ImportSiWizardMapHeaders(
  props: ImportSiWizardMapHeadersProps
) {
  // native props
  const classesNew = useStyles();
  const [dataTableRows, setDataTableRows] = useState<GridRowModel[]>([]);

  // passed from parent
  const { file, supplierInventoriesArray, setSupplierInventoriesArray } = props;
  const apiRef = useGridApiRef();
  const [tableHeadOrdered, setTableHeadOrdered] = useState<GridColDef[]>([]);
  const [orderedColumns, setOrderedColumns] = useState<GridColDef[]>([]);
  // Import Supplier Inventory
  const [
    ImportSupplierInventoryMapExcelHeadersMutation,
    ImportSupplierInventoryMapExcelHeadersMutationProps,
  ] = useMutation(IMPORT_SUPPLIER_INVENTORY_MAP_EXCEL_HEADERS_MUTATION, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });
  const useImportSupplierInventoryMapExcelHeadersMutation = (
    fileToUpload: File
  ) => {
    ImportSupplierInventoryMapExcelHeadersMutation({
      variables: {
        file: fileToUpload,
      },
    });
  };

  // --------------------------------- hooks -------------------------------------------------
  useEffect(() => {
    if (file != undefined) {
      useImportSupplierInventoryMapExcelHeadersMutation(file);
    }
  }, [file]);

  useEffect(() => {
    if (ImportSupplierInventoryMapExcelHeadersMutationProps.data) {
      setSupplierInventoriesArray(
        ImportSupplierInventoryMapExcelHeadersMutationProps.data
          .importSupplierInventoryMapExcelHeaders
      );
    }
  }, [ImportSupplierInventoryMapExcelHeadersMutationProps.data]);

  useEffect(() => {
    const tableRowsArray: GridRowModel[] = [];
    supplierInventoriesArray?.forEach(
      (si: SupplierInventoryDTO, index: number) => {
        const dataTableRow: GridRowModel = { id: index };

        dataTableRow.productName = si.productName;
        dataTableRow.productCode = si.productCode;
        dataTableRow.itemUnit = si.itemUnit;
        dataTableRow.productInformation = si.productInformation;
        dataTableRow.containsHazMat = si.containsHazMat;
        dataTableRow.poItemIdReferenceId = si.poItemIdReferenceId;
        if (si.supplierInventoryMaterial) {
          dataTableRow.mat1_name = si.supplierInventoryMaterial[0]
            ? si.supplierInventoryMaterial[0].name
            : "";
          dataTableRow.mat1_category = si.supplierInventoryMaterial[0]
            ? si.supplierInventoryMaterial[0].category
            : "";
          dataTableRow.mat1_thresholdValue = si.supplierInventoryMaterial[0]
            ? si.supplierInventoryMaterial[0].thresholdValue
            : "";
          dataTableRow.mat1_presentAboveThresholdValue = si
            .supplierInventoryMaterial[0]
            ? si.supplierInventoryMaterial[0].presentAboveThresholdValue
            : "";
          dataTableRow.mat1_mass = si.supplierInventoryMaterial[0]
            ? si.supplierInventoryMaterial[0].mass
            : 0;
          dataTableRow.mat1_unit = si.supplierInventoryMaterial[0]
            ? si.supplierInventoryMaterial[0].unit
            : "";
          dataTableRow.comment1 = si.supplierInventoryMaterial[0]
            ? si.supplierInventoryMaterial[0].comment
            : "";
          dataTableRow.mat2_name = si.supplierInventoryMaterial[1]
            ? si.supplierInventoryMaterial[1].name
            : "";
          dataTableRow.mat2_category = si.supplierInventoryMaterial[1]
            ? si.supplierInventoryMaterial[1].category
            : "";
          dataTableRow.mat2_thresholdValue = si.supplierInventoryMaterial[1]
            ? si.supplierInventoryMaterial[1].thresholdValue
            : "";
          dataTableRow.mat2_presentAboveThresholdValue = si
            .supplierInventoryMaterial[1]
            ? si.supplierInventoryMaterial[1].presentAboveThresholdValue
            : "";
          dataTableRow.mat2_mass = si.supplierInventoryMaterial[1]
            ? si.supplierInventoryMaterial[1].mass
            : 0;
          dataTableRow.mat2_unit = si.supplierInventoryMaterial[1]
            ? si.supplierInventoryMaterial[1].unit
            : "";
          dataTableRow.comment2 = si.supplierInventoryMaterial[1]
            ? si.supplierInventoryMaterial[1].comment
            : "";
        }

        tableRowsArray.push(dataTableRow);
      }
    );

    setDataTableRows(tableRowsArray);
  }, [supplierInventoriesArray]);

  // --------------------------------- handlers -----------------------------------------------



  React.useEffect(() => {
    try {
      const TABLE_HEAD: GridColDef[] = [
        {
          field: "productName",
          headerName: "Product Name",
          width: 180,
          type: "string",
          editable: true,
        },
        {
          field: "productCode",
          headerName: "Product Code",
          width: 180,
          type: "string",
          editable: true,
        },
        {
          field: "itemUnit",
          headerName: "Item Unit",
          width: 180,
          type: "string",
          editable: true,
        },
        {
          field: "containsHazMat",
          headerName: "Contains Hazardous Materials",
          width: 180,
          type: "boolean",
          editable: true,
        },
       // {
       //   field: "poItemIdReferenceId",
       //   headerName: "PO item Reference Id",
       //   width: 180,
       //   type: "number",
       //   editable: true,
       // },
        {
          field: "mat1_name",
          headerName: "Material 1 Name",
          width: 180,
          type: "string",
          editable: true,
        },
        {
          field: "mat1_category",
          headerName: "Material 1 CAtegory",
          width: 180,
          type: "string",
          editable: true,
        },
        {
          field: "mat1_thresholdValue",
          headerName: "Material 1 Threshold Value",
          width: 180,
          type: "string",
          editable: true,
        },
        {
          field: "mat1_presentAboveThresholdValue",
          headerName: "Material 1 Above Threshold",
          width: 180,
          type: "boolean",
          editable: true,
        },
        {
          field: "mat1_mass",
          headerName: "Material 1 Mass",
          width: 180,
          type: "number",
          editable: true,
        },
        {
          field: "mat1_unit",
          headerName: "Material 1 Unit",
          width: 180,
          type: "string",
          editable: true,
        },
        {
          field: "mat2_name",
          headerName: "Material 2 Name",
          width: 180,
          type: "string",
          editable: true,
        },
        {
          field: "mat2_category",
          headerName: "Material 2 CAtegory",
          width: 180,
          type: "string",
          editable: true,
        },
        {
          field: "mat2_thresholdValue",
          headerName: "Material 2 Threshold Value",
          width: 180,
          type: "string",
          editable: true,
        },
        {
          field: "mat2_presentAboveThresholdValue",
          headerName: "Material 2 Above Threshold",
          width: 180,
          type: "boolean",
          editable: true,
        },
        {
          field: "mat2_mass",
          headerName: "Material 2 Mass",
          width: 180,
          type: "number",
          editable: true,
        },
        {
          field: "mat2_unit",
          headerName: "Material 2 Unit",
          width: 180,
          type: "string",
          editable: true,
        },
        {
          field: "productInformation",
          headerName: "Product Information",
          width: 180,
          type: "string",
          editable: true,
        },
        {
          field: "comment1",
          headerName: "Comment 1",
          width: 180,
          type: "string",
          editable: true,
        },
        {
          field: "comment2",
          headerName: "Comment 2",
          width: 180,
          type: "string",
          editable: true,
        },
      ];

      const savedData = localStorage.getItem("gridImportSiWizardMapHeadersTable");
  
      if(savedData){
        
      const savedDataJsonFormated = JSON.parse(savedData != undefined ? savedData : "{}");
      setOrderedColumns([]);
     
      
      if(TABLE_HEAD!= undefined){
        
      for (const field of savedDataJsonFormated.columns.orderedFields){
      
        if(field != "__check__"){
          
          const savedColumn  = TABLE_HEAD.find((obj) => {
            return obj.field === field;
          });
          if(savedColumn != undefined){
            savedColumn.width = savedDataJsonFormated.columns.dimensions[field]["width"];
          }
          
          orderedColumns.push(savedColumn != undefined ? savedColumn : JSON.parse('{}'));
          }
          
        }
      
        setTableHeadOrdered(orderedColumns);
      
      
        }
      }else{
        setTableHeadOrdered(TABLE_HEAD);
      }
   
    } catch (error) {
      console.log(error)
    }
  }, [apiRef]);



  const exportState = React.useCallback(() => {
    const state = apiRef.current.exportState();
    if (window.localStorage) {
      localStorage.setItem("gridImportSiWizardMapHeadersTable", JSON.stringify(state));
    }
  }, [apiRef]);

  // ------------------------------------------------------------------------------------------
  return (
    <Container maxWidth={"xl"}>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography variant="h3" sx={{ mt: 2 }}>
          Inventories to Import
        </Typography>

        <Box
          sx={{
            height: 550,
            width: 1,
            "& .super-app-theme--2": {
              bgcolor: () => "rgba(91, 229, 132, 0.15)",
            },
            "& .super-app-theme--1": {
              bgcolor: () => "rgba(255, 244, 165, 0.5)",
            },
            marginTop: 4,
          }}
        >
          <DataGridPro
            columns={tableHeadOrdered}
            rows={dataTableRows}
            apiRef={apiRef}
            disableRowSelectionOnClick
            className={classesNew.customStyle}
            onColumnVisibilityModelChange={exportState}
            onColumnOrderChange={exportState}
            onColumnWidthChange={exportState}
            loading={
              ImportSupplierInventoryMapExcelHeadersMutationProps.loading
            }
          />
        </Box>
      </Box>
    </Container>
  );
}
