import { ApprovalStatusEnum, IhmItemPositionDTO } from "../../@types/IHM";
import * as L from "leaflet";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useMapEvents } from "react-leaflet";
import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import "leaflet-extra-markers/dist/js/leaflet.extra-markers.js";

export const MapComponent = forwardRef(
  (
    props: {
      description: string;
      addMarker: (
        lat: number,
        lng: number,
        applicationOfPaint: string,
        nameOfPaint: string,
        nameOfEquipmentAndMachinery: string,
        nameOfStructuralElement: string,
        partsWhereUsed: string
      ) => void | undefined;
      selectedMarker: (lat: number, lng: number) => void | undefined;
      positions: IhmItemPositionDTO[] | undefined;
      enableEdit: boolean;
      placeMarkerToMap: boolean;
      applicationOfPaint: string;
      nameOfPaint: string;
      nameOfEquipmentAndMachinery: string;
      nameOfStructuralElement: string;
      partsWhereUsed: string;
      selectedPositionId: number | undefined;
      setSelectedPositionId: any;
      setMarkerNewPosition: (lat: number, lng: number, id: number) => void | undefined;
      captureScreenshot?: () => Promise<Blob | null>;
      readUploadedFile?: (inputFile: any) => Promise<File>;
      changePositionOfInstallation: boolean
    },
    ref
  ) => {
    const {
      addMarker,
      selectedMarker,
      positions,
      enableEdit,
      description,
      placeMarkerToMap,
      applicationOfPaint,
      nameOfPaint,
      nameOfEquipmentAndMachinery,
      nameOfStructuralElement,
      partsWhereUsed,
      selectedPositionId,
      setSelectedPositionId,
      setMarkerNewPosition,
      changePositionOfInstallation,
    } = props;

    const [canDragPoi, setCanDragPoi] = useState<boolean>(false);
    const [clickedMarker,setClickedMarker] = useState<any[]>([]);

    useEffect(()=>{
      if(enableEdit && changePositionOfInstallation){
        setCanDragPoi(true);

      }else{
        setCanDragPoi(false);
      }

    },[enableEdit,changePositionOfInstallation])

    useEffect(()=>{
        rebuildMarkers(-1, true);
    },[canDragPoi])



    const [mapSelectedMarker, setMapSelectedMarker] = useState(null);

    const approved = (L as any).ExtraMarkers.icon({
      icon: "fa-checked",
      markerColor: "yellow",
      prefix: "fa fa-4x",
      iconColor: "white",
    });

    const notApproved = (L as any).ExtraMarkers.icon({
      icon: "fa-eye",
      markerColor: "red",
      prefix: "fa fa-4x",
      iconColor: "white",
    });

    const selected = (L as any).ExtraMarkers.icon({
      icon: "fa-eye",
      markerColor: "blue",
      prefix: "fa fa-4x",
      iconColor: "white",
    });

    const isDelected = (L as any).ExtraMarkers.icon({
      icon: "fa-eye",
      markerColor: "black",
      prefix: "fa fa-4x",
      iconColor: "white",
    });

    const isReplaced = (L as any).ExtraMarkers.icon({
      icon: "fa-eye",
      markerColor: "green",
      prefix: "fa fa-4x",
      iconColor: "white",
    });

    const map = useMapEvents({
      click: (e: L.LeafletMouseEvent) => {

        console.log(`${e}`);
        if(!changePositionOfInstallation){
          if (enableEdit && placeMarkerToMap) {
            rebuildMarkers(-1, false);
            const { lat, lng } = e.latlng;
            const marker = L.marker([lat, lng], {
              riseOnHover: true,
              icon: notApproved,
              opacity: 1,
            })
              .bindTooltip(description)
              .setOpacity(1)
              .addEventListener("click", pinCliked)
              .addTo(map);
            clickedMarker.push(marker);
            setClickedMarker(clickedMarker);
            addMarker(
              lat,
              lng,
              applicationOfPaint,
              nameOfPaint,
              nameOfEquipmentAndMachinery,
              nameOfStructuralElement,
              partsWhereUsed
            );
          }
        }

      },
    });

    const pinCliked = (event: L.LeafletMouseEvent) => {
      const lat = event.latlng.lat;
      const lng = event.latlng.lng;

      if (
        event.target.options.title != undefined &&
        !isNaN(event.target.options.title) &&
        !isNaN(parseFloat(event.target.options.title))
      ) {
        rebuildMarkers(+event.target.options.title, false);
      }
      setClickedMarker([event.target]);
      selectedMarker(lat, lng);
    };

    useEffect(() => {
      rebuildMarkers(-1, false);
    }, [positions]);

    const rebuildMarkers = (selectedId: number, clearAllMarkers: boolean) => {
      setClickedMarker([]);
      map.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          map.removeLayer(layer);
        }
      });

      positions?.forEach((position: any) => {
        let description = "";
        if (position.productName && position.productName.length > 0) {
          description = position.productName;
        }

        if (
          description.length > 0 &&
          position.productCode &&
          position.productCode.length > 0
        ) {
          description += `, ${position.productCode}`;
        } else {
          description = position.productCode;
        }

        if (position.x && position.y) {
          if (
            clearAllMarkers === false &&
            (position.id === selectedId ||
              (position.id === selectedPositionId &&
                (selectedId == undefined ||
                  selectedId === null ||
                  selectedId === -1)))
          ) {

            const marker = L.marker([position.y, position.x], {
              icon: selected,
              attribution: position.id,
              draggable: canDragPoi
            })
              .bindTooltip(description)
              .addEventListener("click", pinCliked)
              .addEventListener("dragend",(event)=>{
                const data = event.target;
                if(data){
                  setMarkerNewPosition(marker.getLatLng().lat,marker.getLatLng().lng,
                  +data.getAttribution());
                }
              })
              .addTo(map);
              clickedMarker.push(marker);
              setClickedMarker(clickedMarker);
            marker.options.title = position.id;
            marker.options.alt = position.approvalStatus;
          } else {
            if (position.replacedByIhmPosition) {
              let description = "";
              if (position.replacedByIhmPosition) {
                if (position.replacedByIhmPosition.ihmItem) {
                  if (position.replacedByIhmPosition.ihmItem.productName) {
                    description =
                      position.replacedByIhmPosition.ihmItem.productName;
                  }
                  if (position.replacedByIhmPosition.ihmItem.productName) {
                    description +=
                      " " + position.replacedByIhmPosition.ihmItem.productCode;
                  }
                }
              }
              const marker = L.marker([position.y, position.x], {
                icon: isReplaced,
                attribution: position.id,
                draggable: canDragPoi
              })
                .bindTooltip(`Replaced by :` + description)
                .addEventListener("click", pinCliked)
                .addEventListener("dragend",(event)=>{
                  const data = event.target;
                  if(data){
                    setMarkerNewPosition(marker.getLatLng().lat,marker.getLatLng().lng,
                    +data.getAttribution());
                  }
                })
                .addTo(map);
                clickedMarker.push(marker);
                setClickedMarker(clickedMarker);
              marker.options.title = position.id;
              marker.options.alt = "true";
            } else if (position.isDeleted) {
              const marker = L.marker([position.y, position.x], {
                icon: isDelected,
                attribution: position.id,
                draggable: canDragPoi
              })
                .bindTooltip(`Installation is Removed :` + description)
                .addEventListener("click", pinCliked)
                .addEventListener("dragend",(event)=>{
                  const data = event.target;
                  if(data){
                    setMarkerNewPosition(marker.getLatLng().lat,marker.getLatLng().lng,
                    +data.getAttribution());
                  }
                })
                .addTo(map);
              clickedMarker.push(marker);
              setClickedMarker(clickedMarker);
              marker.options.title = position.id;
              marker.options.alt = "true";
            } else if (
              position.approvalStatus === ApprovalStatusEnum.APPROVED
            ) {
              const marker = L.marker([position.y, position.x], {
                icon: approved,
                attribution: position.id,
                draggable: canDragPoi
              })
                .bindTooltip(description)
                .addEventListener("click", pinCliked)
                .addEventListener("dragend",(event)=>{
                  const data = event.target;
                  if(data){
                    setMarkerNewPosition(marker.getLatLng().lat,marker.getLatLng().lng,
                    +data.getAttribution());
                  }
                })
                .addTo(map);
              clickedMarker.push(marker);
              setClickedMarker(clickedMarker);
              marker.options.title = position.id;
              marker.options.alt = position.approvalStatus;
            } else {
              const marker = L.marker([position.y, position.x], {
                icon: notApproved,
                attribution: position.id,
                draggable: canDragPoi
              })
                .bindTooltip(description)
                .addEventListener("click", pinCliked)
                .addEventListener("dragend",(event)=>{
                  const data = event.target;
                  if(data){
                    setMarkerNewPosition(marker.getLatLng().lat,marker.getLatLng().lng,
                    +data.getAttribution());
                  }
                })
                .addTo(map);
                clickedMarker.push(marker);
                setClickedMarker(clickedMarker);
              marker.options.title = position.id;
              marker.options.alt = position.approvalStatus;
            }
          }
        }
      });
    };

    useImperativeHandle(ref, () => ({
      removeMarker(lat: number, lng: number) {
        if (mapSelectedMarker != null) {
          map.removeLayer(mapSelectedMarker);
          setMapSelectedMarker(null);
        }
      },
      resetMarkers() {
        setSelectedPositionId(undefined);
        rebuildMarkers(-1, false);
      },
      clearPreselected() {
        setSelectedPositionId(undefined);
      },
      resetAllMarkers() {
        setSelectedPositionId(undefined);
        rebuildMarkers(-1, true);
      },
      makeMarkersBlue(){
        if(clickedMarker){
          for(const item of clickedMarker){
            item.setIcon(selected);
          }
        }
      },
    }));

    return null;
  }
);
