import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// material
import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
  FormHelperText,
  Container,
} from "@mui/material";
// @types
import { ShipOwnerUserDTO } from "../../../@types/ShipOwnerUser";
import { VesselDTO } from "../../../@types/Vessel";
// components
import { UploadAvatar } from "../..";
import ShipOwnerUserForm from "../../form/ShipOwnerUserForm";
// utils
import { fData } from "../../../utils/formatNumber";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_SHIP_OWNER_USER_MUTATION,
  UPDATE_SHIP_OWNER_USER_MUTATION,
} from "../../../graphQL/Mutations";
import {
  GET_APP_USER_BY_ID_QUERY,
  
  GET_VESSELS_BY_APP_USER_AND_SHIPOWNER,
} from "../../../graphQL/Queries";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import Page from "../../Page";
import HeaderBreadcrumbs from "../../HeaderBreadcrumbs";
import { Icon } from "@iconify/react";
import arrowCircleLeftOutline from "@iconify/icons-eva/arrow-circle-left-outline";
//styles
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  navigationIcon: {
    cursor: "pointer",
    color: "#0079C1",
    transition: "0.5s ease",
    "&:hover": {
      color: "#39464f",
      transition: "0.5s ease",
    },
  },
}));

// Chain Query to get app user and his vessels
const getAppUserQuery = (selectedShipOwnerUserId: string | undefined) =>
  useQuery(GET_APP_USER_BY_ID_QUERY, {
    skip: !selectedShipOwnerUserId,
    variables: {
      id:
        selectedShipOwnerUserId != undefined &&
        parseInt(selectedShipOwnerUserId),
    },
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
  });

const getVesselsByAppUserQuery = (appUserId: number | undefined | null, shipOwnerId: string) =>
  useQuery(GET_VESSELS_BY_APP_USER_AND_SHIPOWNER, {
    skip: !appUserId || appUserId == undefined || shipOwnerId == '-1',
    variables: {
      appUserId: appUserId,
      shipOwnerId: parseInt(shipOwnerId)
    },
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

// -----------------------------------------------------------------------------
type ShipOwnerUserNewFormProps = {
  setNavigationHelper: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ShipOwnerUserNewForm({
  setNavigationHelper,
}: ShipOwnerUserNewFormProps) {
  // native props
  const { shipOwnerId, selectedShipOwnerUserId } = useParams();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const navigate = useNavigate();
  const classesNew = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [shipOwnerUserVessels, setShipOwnerUserVessels] =
    useState<VesselDTO[]>();
  const [selectedShipOwnerUser, setSelectedShipOwnerUser] =
    useState<ShipOwnerUserDTO>();
  const [isAllVesselsSelected, setIsAllVesselsSelected] =
    useState<boolean>(false);
  // Use Queries
  const getAppUserQueryResponse = getAppUserQuery(selectedShipOwnerUserId);
  const getVesselsByAppUserQueryResponse = getVesselsByAppUserQuery(
    selectedShipOwnerUser?.id && typeof selectedShipOwnerUser?.id == "number"
      ? selectedShipOwnerUser?.id
      : null, shipOwnerId ? shipOwnerId : '-1'
  );

  // Component Lifecycle
  useEffect(() => {
    if (isAllVesselsSelected) {
      setShipOwnerUserVessels([]);
    }
  }, [isAllVesselsSelected]);

  useEffect(() => {
    if (selectedShipOwnerUserId && selectedShipOwnerUserId != undefined) {
      setIsEdit(true);
    }
  }, [selectedShipOwnerUserId]);

  useEffect(() => {
    if (getAppUserQueryResponse && getAppUserQueryResponse.data) {
      setSelectedShipOwnerUser(getAppUserQueryResponse?.data?.getAppUser);
      setIsAllVesselsSelected(
        getAppUserQueryResponse?.data?.getAppUser?.userShipOwner[0]
          ?.includeAllVessels
      );
    }
  }, [getAppUserQueryResponse]);

  useEffect(() => {
    if (
      getVesselsByAppUserQueryResponse &&
      getVesselsByAppUserQueryResponse.data
    ) {
      setShipOwnerUserVessels(
        getVesselsByAppUserQueryResponse?.data?.getVesselsByAppUserAndShipOwner
      );
    }
  }, [getVesselsByAppUserQueryResponse]);

  useEffect(() => {
    if (isEdit) {
      setNavigationHelper(true);
    } else {
      setNavigationHelper(false);
    }
  }, []);

  // Define Form Schema
  const NewShipOwnerUserSchema = Yup.object().shape({
    Name: Yup.string().required("Name is required"),
    LastName: Yup.string().required("LastName is required"),
    Position: Yup.string().required("Position is required"),
    City: Yup.string(),
    PhoneNumber: Yup.string(),
    Email: Yup.string()
      .email("Please enter valid email")
      .required("Email is required"),
    About: Yup.string(),
    AvatarUrl: Yup.object().nullable(),
  });

  // Initialize Form Schema values
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Name: selectedShipOwnerUser?.name || "",
      LastName: selectedShipOwnerUser?.lastname || "",
      Position: selectedShipOwnerUser?.position || "",
      City: selectedShipOwnerUser?.city || "",
      PhoneNumber: selectedShipOwnerUser?.phoneNumber || "",
      Email: selectedShipOwnerUser?.email || "",
      About: selectedShipOwnerUser?.about || "",
      AvatarUrl:
        selectedShipOwnerUser?.avatar &&
        selectedShipOwnerUser?.avatar != undefined
          ? JSON.parse(selectedShipOwnerUser?.avatar)
          : "",
    },
    validationSchema: NewShipOwnerUserSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      try {
        const vesselIdsArray: number[] = [];
        if (isAllVesselsSelected) {
          vesselIdsArray.push(-1);
        } else if (shipOwnerUserVessels) {
          shipOwnerUserVessels.forEach((souVessel: VesselDTO) => {
            if (souVessel.id) {
              vesselIdsArray.push(parseInt(souVessel.id));
            }
          });
        }

        if (isEdit && selectedShipOwnerUserId && shipOwnerId) {
          // CASE UPDATE
          const newShipOwnerUserObject: ShipOwnerUserDTO = {
            id: parseInt(selectedShipOwnerUserId),
            name: values.Name,
            lastname: values.LastName,
            position: values.Position,
            phoneNumber: values.PhoneNumber,
            email: values.Email,
            city: values.City,
            about: values.About,
            avatar: JSON.stringify(values.AvatarUrl.base64encoded),
            userRole: 2,
          };

          // Use Mutation
          useUpdateShipOwnerUserMutation(
            newShipOwnerUserObject,
            vesselIdsArray,
            parseInt(shipOwnerId),
            parseInt(selectedShipOwnerUserId)
          );
        } else if (shipOwnerId) {
          // CASE CREATE
          const newShipOwnerUserObject: ShipOwnerUserDTO = {
            name: values.Name,
            lastname: values.LastName,
            position: values.Position,
            phoneNumber: values.PhoneNumber,
            email: values.Email,
            city: values.City,
            about: values.About,
            avatar: JSON.stringify(values.AvatarUrl.base64encoded),
            userRole: 2,
          };

          // Use Mutation
          useCreateShipOwnerUserMutation(
            newShipOwnerUserObject,
            vesselIdsArray,
            parseInt(shipOwnerId)
          );
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });
  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;

  // --------------------------------- handlers ---------------------------------------------------
  const handleDrop = useCallback(
    (acceptedFiles: any) => {
      const file = acceptedFiles[0];

      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          setFieldValue("AvatarUrl", {
            ...file,
            preview: URL.createObjectURL(file),
            base64encoded: reader.result,
          });
        };
        reader.onerror = function (err) {
          toast.configure();
          toast.error(`Error: ${err}`);
        };
      }
    },
    [setFieldValue]
  );

  // --------------------------------- Mutations ---------------------------------------------------
  // UPDATE
  const [UpdateShipOwnerUserMutation, UpdateShipOwnerUserMutationProps] =
    useMutation(UPDATE_SHIP_OWNER_USER_MUTATION, {
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err}`);
      },
      fetchPolicy: "network-only",
    });
  const useUpdateShipOwnerUserMutation = (
    newShipOwnerUserObject: ShipOwnerUserDTO,
    vesselIdsArray: number[],
    soId: number,
    appUserId: number
  ) => {
    UpdateShipOwnerUserMutation({
      variables: {
        appUserId: appUserId,
        updateAppUserInput: newShipOwnerUserObject,
        shipOwnerId: soId,
        vesselIdsArray: vesselIdsArray,
      },
      onCompleted(dt) {
        if (dt.updateShipOwnerUser) {
          formik.resetForm();
          formik.setSubmitting(false);
          enqueueSnackbar(
            `Shipowner User ${values.Name} ${values.LastName} updated successfully!`,
            { variant: "success" }
          );
          navigate(`/dashboard/shipowners/all`, { replace: true });
        }
      },
    });
  };
  // CREATE
  const [CreateShipOwnerUserMutation, CreateShipOwnerUserMutationProps] =
    useMutation(CREATE_SHIP_OWNER_USER_MUTATION, {
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err}`);
      },
      fetchPolicy: "network-only",
    });
  const useCreateShipOwnerUserMutation = (
    newShipOwnerUserObject: ShipOwnerUserDTO,
    vesselIdsArray: number[],
    soId: number
  ) => {
    CreateShipOwnerUserMutation({
      variables: {
        createAppUserInput: newShipOwnerUserObject,
        shipOwnerId: soId,
        vesselIdsArray: vesselIdsArray,
      },
      onCompleted(dt) {
        if (dt.createShipOwnerUser) {
          formik.resetForm();
          formik.setSubmitting(false);
          enqueueSnackbar(
            `Shipowner User ${values.Name} ${values.LastName} created successfully!`,
            { variant: "success" }
          );
          navigate(`/dashboard/shipowners/all`, { replace: true });
        }
      },
    });
  };

  // ----------------------------------------------------------------------------------------------
  return (
    <Page title={`${isEdit ? "Edit Ship Owner" : "Create Ship Owner"}`}>
      <Container maxWidth={"xl"}>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <HeaderBreadcrumbs
            heading={`${isEdit ? "Edit Ship Owner" : "Create Ship Owner"}`}
            links={[
              { name: "Dashboard" },
              { name: "Ship Owners" },
              { name: "Manage Ship Owner Users" },
            ]}
          />
          <Icon
            width={45}
            height={45}
            icon={arrowCircleLeftOutline}
            className={classesNew.navigationIcon}
            onClick={() => navigate("/dashboard/shipowners/all")}
          />
        </Stack>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Card sx={{ py: 10, px: 3 }}>
                  <Box sx={{ mb: 5 }}>
                    <UploadAvatar
                      accept="image/*"
                      file={values.AvatarUrl}
                      maxSize={75000}
                      onDrop={handleDrop}
                      error={Boolean(touched.AvatarUrl && errors.AvatarUrl)}
                      caption={
                        <Typography
                          variant="caption"
                          sx={{
                            mt: 2,
                            mx: "auto",
                            display: "block",
                            textAlign: "center",
                            color: "text.primary",
                          }}
                        >
                          Allowed *.jpeg, *.jpg, *.png, *.gif
                          <br /> max size of {fData(75000)}
                        </Typography>
                      }
                    />
                    <FormHelperText error sx={{ px: 2, textAlign: "center" }}>
                      {touched.AvatarUrl && errors.AvatarUrl}
                    </FormHelperText>
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={12} md={8}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <ShipOwnerUserForm
                      getFieldProps={getFieldProps}
                      touched={touched}
                      errors={errors}
                      values={values}
                      isSubmitting={isSubmitting}
                      selectedVessels={shipOwnerUserVessels}
                      setSelectedVessels={setShipOwnerUserVessels}
                      buttonMsg={
                        selectedShipOwnerUserId
                          ? "Update Ship Owner User"
                          : "Create Ship Owner User"
                      }
                      isAllVesselsSelected={isAllVesselsSelected}
                      setIsAllVesselsSelected={setIsAllVesselsSelected}
                      submitButtonIsloading={
                        UpdateShipOwnerUserMutationProps.loading ||
                        CreateShipOwnerUserMutationProps.loading
                      }
                      isEdit={isEdit}
                    />
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
