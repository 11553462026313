import { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
// material
import { styled } from "@mui/material/styles";
import { Box, Divider } from "@mui/material";
// utils
import { MCarouselControlsArrowsIndex } from "./";

// ------------------------------Component Styles----------------------------------------
const THUMB_SIZE = 64;

const RootStyle = styled(Box)(({ theme }) => {
  const isRTL = theme.direction === "rtl";

  return {
    root: {
      "& .slick-slide": {
        float: isRTL ? "right" : "left",
      },
    },
  };
});

const LargeImgStyle = styled("img")(({ theme }) => ({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
}));

const ThumbImgStyle = styled("img")(({ theme }) => ({
  opacity: 0.48,
  width: THUMB_SIZE,
  cursor: "pointer",
  height: THUMB_SIZE,
  margin: theme.spacing(0, 1),
  borderRadius: theme.shape.borderRadiusSm,
  "&:hover": {
    opacity: 0.72,
    transition: theme.transitions.create("opacity"),
  },
}));

type CarouselItemProps = {
  title: string;
  description: string;
  image: string;
};
function LargeItem({ item }: { item: CarouselItemProps }) {
  const { image, title } = item;

  return (
    <Box
      sx={{
        position: "relative",
        paddingTop: {
          xs: "100%",
          md: "50%",
        },
      }}
    >
      <LargeImgStyle alt={title} src={image} />
    </Box>
  );
}
function ThumbnailItem({ item }: { item: CarouselItemProps }) {
  const { image, title } = item;

  return <ThumbImgStyle alt={title} src={image} />;
}

// Component Props
export type MCarouselProps = {
  imageArrayProp: any[];
};

export default function MCarousel({ imageArrayProp }: MCarouselProps) {
  // Native props
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nav1, setNav1] = useState<Slider | undefined>(undefined);
  const [nav2, setNav2] = useState<Slider | undefined>(undefined);
  const slider1 = useRef<Slider | null>(null);
  const slider2 = useRef<Slider | null>(null);

  const [images, setImages] = useState<any[]>([
    {
      id: "PlaceHolder",
      title: "",
      image: "",
      description: "PlaceHolder",
    },
  ]);
  // -----------------------------------Hooks--------------------------------------------------------------
  useEffect(() => {
    if (imageArrayProp.length > 0) {
      setImages(imageArrayProp);
    }
  }, [imageArrayProp]);

  useEffect(() => {
    if (slider1.current) {
      setNav1(slider1.current);
    }
    if (slider2.current) {
      setNav2(slider2.current);
    }
  }, []);
  // -----------------------------------Settings--------------------------------------------------------------
  const settingsBigSlider = {
    lazyload: true,
    dots: false,
    arrows: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (current: number, next: number) => setCurrentIndex(next),
  };
  const settingsSmallSlider = {
    dots: false,
    arrows: false,
    centerMode: true,
    isFinite: true,
    swipeToSlide: true,
    focusOnSelect: true,
    variableWidth: true,
    centerPadding: "0px",
    slidesToShow: images.length > 3 ? 3 : images.length,
    slidesToScroll: 3,
  };
  // -----------------------------------Handlers--------------------------------------------------------------
  const handlePrevious = () => {
    slider2.current?.slickPrev();
  };

  const handleNext = () => {
    slider2.current?.slickNext();
  };

  // ------------------------------------------------------------------------------------------------------
  return (
    <RootStyle>
      <Box
        sx={{
          zIndex: 0,
          borderRadius: 2,
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Slider {...settingsBigSlider} asNavFor={nav2} ref={slider1}>
          {images.map((item: any) => (
            <LargeItem key={item.id} item={item} />
          ))}
        </Slider>
        <MCarouselControlsArrowsIndex
          index={currentIndex}
          total={images.length}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />
      </Box>

      <Divider sx={{ mt: 2 }} />

      <Box
        sx={{
          mt: 3,
          mx: "auto",
          ...(images.length === 1 && { maxWidth: THUMB_SIZE * 1 + 16 }),
          ...(images.length === 2 && { maxWidth: THUMB_SIZE * 2 + 32 }),
          ...(images.length === 3 && { maxWidth: THUMB_SIZE * 3 + 48 }),
          ...(images.length === 4 && { maxWidth: THUMB_SIZE * 3 + 48 }),
          ...(images.length === 5 && { maxWidth: THUMB_SIZE * 6 }),
          "& .slick-current img": {
            opacity: 1,
            border: (theme) => `solid 3px ${theme.palette.text.primary}`,
          },
        }}
      >
        <Slider {...settingsSmallSlider} asNavFor={nav1} ref={slider2}>
          {images.map((item) => (
            <ThumbnailItem key={item.title} item={item} />
          ))}
        </Slider>
      </Box>
    </RootStyle>
  );
}
