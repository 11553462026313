export enum OperationValue {
    CONTAINS,
    EQUALS,
    STARTS_WITH,
    ENDS_WITH,
    IS_EMPTY,
    IS_NOT_EMPTY,
    UNKNOWN,
}

export type FilterTypeDTO = {
    columnField: string;
    operatorValue: OperationValue;
    value: string;
}
