import Keycloak from 'keycloak-js';
// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloakClient = Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL ?? '',
  realm: process.env.REACT_APP_KEYCLOAK_REALM ?? '',
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? '',
});

if(keycloakClient.idToken){
  keycloakClient.init(
    {
      onLoad: 'check-sso',
      checkLoginIframe: false,
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
    },
  );
}


export default keycloakClient;
