import VesselMap from "./VesselMap";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import L from "leaflet";
import { IHMDTO, IhmItemPositionDTO } from "../../@types/IHM";
import {
  GET_IHM_POSITION_BY_ID,
  GET_VESSEL_DRAWINGS_BY_IHM,
} from "../../graphQL/Queries";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { VesselDrawingDTO } from "../../@types/Vessel";
import { VesselDrawingPositionDTO } from "../../@types/VesselDrawingPosition";
import { useParams } from "react-router-dom";


// Define Queries
export const getVesselDrawingEntities = (ihmId: number | undefined) =>
  useQuery(GET_VESSEL_DRAWINGS_BY_IHM, {
    variables: {
      ihmId: ihmId,
    },
    skip: !ihmId,
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

export enum MapperActionsEnum {
  IS_VIEW,
  IS_SAVE,
  IS_REPLACE,
  IS_REMOVE,
  IS_DELETE,
  IS_PLACE_ON_MAP,
  IS_APPROVE,
  IS_ALL,
  IS_INSTALL_IHM_ITEM_TO_MAP,
}

type MapperProps = {
  ihmItem: IHMDTO;
  modalClose?: VoidFunction;
  setSelectedPositionId?: Dispatch<SetStateAction<number | undefined>>;
  allowedActions: MapperActionsEnum;
  positionId?: number;
  isFromModal: boolean;
  disableActions?: boolean;
};

const Mapper = ({
  ihmItem,
  modalClose,
  setSelectedPositionId,
  allowedActions,
  positionId,
  isFromModal,
  disableActions,
}: MapperProps) => {
  const { selectedPositionId } = useParams();
  const [ihmItemDrawingsAvailable, setIhmItemDrawingsAvailable] = useState<
    VesselDrawingDTO[]
  >([]);
  const [ihmItemDrawingSelected, setIhmItemDrawingSelected] =
    useState<VesselDrawingDTO>();
  const [positionsOnSelectedDrawing, setPositionsOnSelectedDrawing] = useState<
    VesselDrawingPositionDTO[]
  >([]);

  const [selectedVesselDrawingPositions, setSelectedVesselDrawingPositions] =
    useState<VesselDrawingPositionDTO>();
  const [vesselDrawingPositions, setVesselDrawingPositions] = useState<
    VesselDrawingPositionDTO[]
  >([]);
  const [clearPositionInformation, setClearPositionInformation] =
    useState(false);

  L.Icon.Default.imagePath = "leaflet/";

  // Use Queries
  const getVesselDrawingEntitiesQueryResult = getVesselDrawingEntities(
    ihmItem?.id
  );

  // ------------------------------------------------------------------ Hooks ---------------------------------------------------------------------
  // 1) Get vessel drawings
  useEffect(() => {
    setIhmItemDrawingsAvailable(
      getVesselDrawingEntitiesQueryResult.data?.findVesselDrawingByIhm
    );
  }, [getVesselDrawingEntitiesQueryResult.data]);

  // 3) Set 1st drawing-position Object as the selected one
  useEffect(() => {
    if (
      (allowedActions == MapperActionsEnum.IS_ALL ||
        allowedActions == MapperActionsEnum.IS_SAVE) &&
      ihmItemDrawingsAvailable &&
      ihmItemDrawingsAvailable.length > 0
    ) {
      if (
        selectedPositionId &&
        ihmItem.ihmPosition &&
        ihmItem.ihmPosition.length > 0
      ) {
        const foundPosition = ihmItem.ihmPosition.find(
          (pos: IhmItemPositionDTO) => pos.id === parseInt(selectedPositionId)
        );
        if (foundPosition) {
          const foundPositionDrawing = ihmItemDrawingsAvailable.find(
            (drawing: VesselDrawingDTO) =>
              drawing.path === foundPosition.vesselDrawingViewPath
          );
          if (foundPositionDrawing) {
            setIhmItemDrawingSelected(foundPositionDrawing);
          } else {
            setIhmItemDrawingSelected(ihmItemDrawingsAvailable[0]);
          }
        } else {
          setIhmItemDrawingSelected(ihmItemDrawingsAvailable[0]);
        }
      } else {
        setIhmItemDrawingSelected(ihmItemDrawingsAvailable[0]);
      }
    }
  }, [ihmItemDrawingsAvailable, ihmItem.ihmPosition, selectedPositionId]);

  // ------------------------------------------------------------------ Handlers ---------------------------------------------------------------------
  
  const manageNewPosition = (
    lat: number,
    lng: number,
    description: string,
    productCode: string,
    productName: string
  ) => {
    if (selectedVesselDrawingPositions?.vesselDrawing.path) {
      const createPosition: IhmItemPositionDTO = {
        description: description,
        vesselDrawingViewPath:
          selectedVesselDrawingPositions?.vesselDrawing.path,
        xBounds: 1000,
        yBounds: 2000,
        x: lng,
        y: lat,
        ihmItemId: ihmItem.id,
        productCode: productCode,
        productName: productName,
      };
      selectedVesselDrawingPositions?.positionsOnDrawing?.push(createPosition);
      setSelectedVesselDrawingPositions(selectedVesselDrawingPositions);
    }
  };

  const manageRemovePosition = (lat: number, lng: number) => {
    const positions: IhmItemPositionDTO[] = [];

    selectedVesselDrawingPositions?.positionsOnDrawing?.forEach(
      (item, index) => {
        if (!(item.y == lat && item.x == lng)) {
          positions.push(item);
        }
      }
    );

    if (selectedVesselDrawingPositions && positions) {
      const updatedVesselDrawingPositions: VesselDrawingPositionDTO = {
        vesselDrawing: selectedVesselDrawingPositions?.vesselDrawing,
        positionsOnDrawing: positions,
      };

      setSelectedVesselDrawingPositions(updatedVesselDrawingPositions);
    }
  };

  const handleDrawingInput = (event: SelectChangeEvent) => {
    setIhmItemDrawingSelected(
      ihmItemDrawingsAvailable.filter(
        (vesselDrawing: VesselDrawingDTO) =>
          vesselDrawing.id === parseInt(event.target.value as string)
      )[0]
    );
    setClearPositionInformation(true);
  };

  return (
    <div className="Mapper">
      <Box sx={{ mt: 1.2, ml: 2, display: "flex", flexDirection: "row" }}>
        <Typography>Total Quantity : {ihmItem.quantity}</Typography>
        <Typography sx={{ ml: 2 }}>Unit : {ihmItem.unit}</Typography>
      </Box>

      <hr></hr>
      <div style={{ padding: 8, paddingTop: 16 }}>
        <Typography sx={{ mt: 0.5 }}>1. Select Vessel Drawing</Typography>
        <FormControl variant="standard" fullWidth>
          <Select
            variant="standard"
            labelId={`select-label-id-}`}
            id={`select-id-}`}
            label="Header Mapping"
            disabled={disableActions}
            value={
              ihmItemDrawingSelected?.id ? `${ihmItemDrawingSelected.id}` : ""
            }
            onChange={handleDrawingInput}
          >
            {ihmItemDrawingsAvailable?.length > 0
              ? ihmItemDrawingsAvailable.map(
                  (
                    ihmItemDrawingAvailable: VesselDrawingDTO,
                    index: number
                  ) => {
                    return (
                      <MenuItem key={index} value={ihmItemDrawingAvailable.id}>
                        {`${ihmItemDrawingAvailable.path?.slice(
                          0,
                          ihmItemDrawingAvailable.path?.indexOf("/")
                        )} - ${ihmItemDrawingAvailable.name}`}
                      </MenuItem>
                    );
                  }
                )
              : null}
          </Select>
        </FormControl>
        <Box sx={{ display: "flex", flexDirection: "row", width: 600 }}>
          <Typography sx={{ mt: 0.6, display: "inline-block" }}>
            2. Place a marker to the Drawing{" "}
          </Typography>
          <Typography
            sx={{
              display: "inline-block",
              mt: 1.2,
              ml: 0.4,
              fontSize: 12,
              fontStyle: "italic",
            }}
          >
            {" "}
            &#40; Yellow installed, red not approved &#41;
          </Typography>
        </Box>
      </div>
      {ihmItemDrawingSelected && (
        <VesselMap
          vesselDrawingPosition={selectedVesselDrawingPositions}
          selectedDrawing={ihmItemDrawingSelected}
          addPosition={manageNewPosition}
          removePosition={manageRemovePosition}
          ihmItem={ihmItem}
          modalClose={modalClose}
          setSelectedPositionId={setSelectedPositionId}
          allowedAction={allowedActions}
          isFromModal={isFromModal}
          clearPositionInformation={clearPositionInformation}
          setClearPositionInformation={setClearPositionInformation}
          //updateInstallationPosition={updateInstallationPosition}
        />
      )}
    </div>
  );
};

export default Mapper;
