import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey('debc54b53e3099faad2a85569f0e34b7Tz05MjU5MyxFPTE3NTAxNzkwMzkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
import { useCallback, useEffect, useRef, useState } from "react";
// material
import { Box, Button, Card, Container, InputAdornment, OutlinedInput, Typography, styled } from "@mui/material";
// @types
// components
// utils
import { useMutation, useQuery } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "@mui/styles";

import { GET_APP_USER_BY_TOKEN_QUERY, GET_SUPPLIER_INVENTORY_QUERY } from "graphQL/Queries";
import { useSnackbar } from "notistack";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FilterTypeDTO, OperationValue } from "../../../../@types/DataGrid";
import { SupplierInventoryDTO } from "../../../../@types/Supplier";
import { REMOVE_SELECTED_SUPPLIERS_WITH_MATERIALS_MUTATION, REMOVE_SUPPLIERS_WITH_MATERIALS_MUTATION } from "../../../../graphQL/Mutations";
import WarningModal from "../../../modal/WarningModal";
import AddSupplierInventoryMaterialModal from "./AddSupplierInventoryMaterialModal";
import ImportSupplierInventoryWizardModal from "./ImportSupplierInventoryWizardModal";
import SupplierInventoryMaterialsTableModal from "./SupplierInventoryMaterialsTableModal";
import {
  DataGridPro, useGridApiRef,
  GridFilterModel,
  GridFilterPanel,
  GridRowModel,
  GridRowSelectionModel,
  GridSortModel,
  GridToolbar,
  GridColDef,
} from "@mui/x-data-grid-pro";
import React from "react";
import AddQuantityModal from './AddQuantityModal';
import DocumentInformationModal from './DocumentInformationModal';
import SetNewItemModal from './SetNewItemModal';
import { BasketOfItems, SupplierInventoryMaterials } from '../../../../@types/SupplierInventory';
import searchFill from '@iconify/icons-eva/search-fill';
import { Icon } from '@iconify/react';
import { TableListToolbar } from '../../../table';
import axios from "axios";

// -------------------------------- Styles ---------------------------------------
const useStyles = makeStyles(() => ({
  customStyle: {
    "& .MuiDataGrid-toolbarContainer": {
      background: "#ffffff",
    },
  },
  panel: {
    "& .MuiNativeSelect-select": {
      backgroundColor: "red",
      color: "red",
    },
  },
  searchbar: {
    "&.MuiToolbar-root": {
      padding: "0",
      height: "auto",

      flex: "1",
      "& .MuiOutlinedInput-root": {
        width: "98%",
      },
    },

  },
}));
// -------------------------------------queries------------------------------------------------------
const getSupplierInventoryQuery = (
  limit: number,
  page: number,
  filtersArray: FilterTypeDTO[],
  sortField: string | undefined,
  sortOrder: string | undefined,
  searchToken: string,
) =>
  useQuery(GET_SUPPLIER_INVENTORY_QUERY, {
    variables: {
      limit: limit,
      page: page,
      filtersArray: filtersArray,
      sortingOrder: {
        field: sortField,
        sort: sortOrder,
      },
      searchToken: searchToken,
    },
    onError(err) {
      toast.configure();
      toast.error(err.message);
    },
    fetchPolicy: "network-only",
  });



const getAppUserByTokenQuery = () =>
  useQuery(GET_APP_USER_BY_TOKEN_QUERY, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

// ---------------------------------------------------------------------------------------------------------------------------------------------------------
export default function SupplierInventoryTable() {
  // native props
  const classesNew = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [
    supplierInventoryQueryResponseData,
    setSupplierInventoryQueryResponseData,
  ] = useState<SupplierInventoryDTO[]>();
  const supplierInventoryQueryResponseDataRefreshed = useRef<SupplierInventoryDTO[]>([]);
  const [isAddNewItemModalOpen, setIsAddNewItemModalOpen] = useState(false);
  // Import si Props
  const [isImporSiWizardModalOpen, setIsImporSiWizardModalOpen] =
    useState(false);
  // Add new si Material Props
  const [
    supplierInventoryIdtoAddMaterial,
    setSupplierInventoryIdtoAddMaterial,
  ] = useState<number>();
  const [isAddMaterialModalOpen, setIsAddMaterialModalOpen] = useState(false);
  // Delete si props
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isMassiveDeleteModalOpen, setIsMassiveDeleteModalOpen] = useState(false);
  const [supplierInventoryIdtoDelete, setSupplierInventoryIdtoDelete] =
    useState<number>();
  const [deleteSelected, setDeleteSelected] = useState(false);
  const [massiveDeleteSelected, setMassiveDeleteSelected] = useState(false);
  // DataGridProps
  const [page, setPage] = useState(0);
  const [pageBasket, setPageBasket] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [rowsPerPageBasket, setRowsPerPageBasket] = useState<number>(20);
  const [dataTableColumns, setDataTableColumns] = useState<GridColDef[]>([]);
  const [dataTableColumnsOfBasket, setDataTableColumnsOfBasket] = useState<GridColDef[]>([]);
  const [dataTableRows, setDataTableRows] = useState<GridRowModel[]>([]);
  const [dataTableRowsOfBasket, setDataTableRowsOfBasket] = useState<GridRowModel[]>([]);
  // datagrid filtering
  const [filtersArray, setFiltersArray] = useState<FilterTypeDTO[]>([]);
  // dataGrid sorting
  const [sortField, setSortField] = useState<string>("productName");
  const [sortOrder, setSortOrder] = useState<string>("asc");
  // dataGrid selection
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [selectionModelBasket, setSelectionModelBasket] = useState<GridRowSelectionModel>([]);
  const prevSelectionModel = useRef<GridRowSelectionModel>(selectionModel);
  const [isMaterialsTableModalOpen, setIsMaterialsTableModalOpen] =
    useState(false);
  const [supplierInventoryId, setSupplierInventoryId] = useState<
    number | undefined
  >(undefined);
  const [orderedColumns, setOrderedColumns] = useState<GridColDef[]>([]);
  const [orderedColumnsBasket, setOrderedColumnsBasket] = useState<GridColDef[]>([]);
  const [columnsDefined, setColumnsDefined] = useState(false);
  const [columnsDefinedBasket, setColumnsDefinedBasket] = useState(false);
  const dataTableRef = useRef<GridRowModel[]>([]);
  const [totalRowsOfdataTable, setTotalRowsOfdataTable] = useState<number>();
  const [searchToken, setSearchToken] = useState("");
  const [isRemoveFromBasketModalOpen, setIsRemoveFromBasketModalOpen] = useState(false);
  const [removeSelectedFromBasket, setRemoveSelectedFromBasket] = useState(false);
  const rowsIdRef = useRef<any>();
  // useQuery
  const getSupplierInventoryQueryResponse = getSupplierInventoryQuery(
    rowsPerPage,
    page,
    filtersArray,
    sortField,
    sortOrder,
    searchToken
  );
  const [supplierId, setSupplierId] = useState<number>();

  const getAppUserByTokenQueryResponse = getAppUserByTokenQuery();

  useEffect(() => {
    if (getAppUserByTokenQueryResponse?.data) {
      setSupplierId(getAppUserByTokenQueryResponse?.data.getAppUserByToken.userSupplier.supplier.id);
    }
  }, [getAppUserByTokenQueryResponse?.data]);

  useEffect(() => {
    if (removeSelectedFromBasket === true && rowsIdRef.current != undefined) {
      const index = dataTableRef.current.findIndex(x => x.id == rowsIdRef.current);

      if (index > -1) {

        dataTableRef.current.splice(index, 1)
        setDataTableRowsOfBasket([...dataTableRef.current]);
      }
      setRemoveSelectedFromBasket(false);
    }
  }, [removeSelectedFromBasket, rowsIdRef.current]);

  // --------------------------------- Mutations -------------------------------------------------
  // Remove Supplier Inventory
  const [
    RemoveSupplierInventoryWithMaterialsMutation,
    RemoveSupplierInventoryWithMaterialsMutationResponse,
  ] = useMutation(REMOVE_SUPPLIERS_WITH_MATERIALS_MUTATION, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    onCompleted(dt) {
      setSupplierInventoryIdtoDelete(undefined);
      setDeleteSelected(false);
      if (dt.removeSupplierInventoryWithMaterials == 1) {
        enqueueSnackbar(`Supplier Inventory was succesfully Removed!`, {
          variant: "success",
        });
      }
    },
    fetchPolicy: "network-only",
    refetchQueries: "active",
  });
  const useRemoveSupplierInventoryWithMaterialsMutation = (
    supplierInventoryId: number
  ) => {
    RemoveSupplierInventoryWithMaterialsMutation({
      variables: {
        id: supplierInventoryId,
      },
    });
  };



  const [
    RemoveSelectedSupplierInventoryWithMaterialsMutation,
    RemoveSelectedSupplierInventoryWithMaterialsMutationResponse,
  ] = useMutation(REMOVE_SELECTED_SUPPLIERS_WITH_MATERIALS_MUTATION, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    onCompleted(dt) {

      if (dt.removeSelectedSupplierInventoriesWithMaterials == 1) {
        enqueueSnackbar(`Selected supplier inventories were succesfully Removed!`, {
          variant: "success",
        });
      }
    },
    fetchPolicy: "network-only",
    refetchQueries: "active",
  });
  const useRemoveSelectedSupplierInventoryWithMaterialsMutation = (
    selectedSupplierInventoryIds: number[]
  ) => {
    RemoveSelectedSupplierInventoryWithMaterialsMutation({
      variables: {
        ids: selectedSupplierInventoryIds,
      },
    });
  };





  // --------------------------------- hooks ------------------------------------------------------
  // get query respone
  useEffect(() => {
    if (
      getSupplierInventoryQueryResponse &&
      getSupplierInventoryQueryResponse.data
    ) {
      setSupplierInventoryQueryResponseData(
        getSupplierInventoryQueryResponse.data.getSupplierInventory.rows
      );
      setTotalRowsOfdataTable(getSupplierInventoryQueryResponse.data.getSupplierInventory.totalRows);
      setIsImporSiWizardModalOpen(false);
    }
  }, [getSupplierInventoryQueryResponse.data]);
  // parse response to dataGrid Objects



  const renderActionsForBasket = (params: any, dataRows: GridRowModel[]) => {

    return (
      <Box>
        <LoadingButton
          variant="contained"
          onClick={() => {
            setIsRemoveFromBasketModalOpen(true);
            rowsIdRef.current=params.row.id;


          }}

        >
          Remove
        </LoadingButton>
      </Box>
    );


  }

  useEffect(() => {
    if (supplierInventoryQueryResponseData) {
      supplierInventoryQueryResponseDataRefreshed.current = supplierInventoryQueryResponseData;
    }

  }, [supplierInventoryQueryResponseData]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  
  const [row, setRow] = useState<SupplierInventoryDTO>();
  const [dataOfRows, setDataOfRows] = useState<GridRowModel[]>([]);
  const [openDocumentInformationModal, setOpenDocumentInformationModal] = useState<boolean>(false);

  const renderActions = (params: any, dataRows: GridRowModel[], supplierInventoryData: SupplierInventoryDTO[]) => {
    const found = supplierInventoryData.filter((x) => {

      return x.id == params.row.id;
    })[0];

    if (found != undefined) {


      return (
        <Box>
          <LoadingButton
            variant="contained"
            onClick={() => {
              setSupplierInventoryIdtoDelete(found.id);
              setIsDeleteModalOpen(true);
            }}
            loading={
              RemoveSupplierInventoryWithMaterialsMutationResponse.loading
            }
            disabled={
              RemoveSupplierInventoryWithMaterialsMutationResponse.loading
            }
          >
            Delete
          </LoadingButton>

          <LoadingButton
            variant="contained"
            sx={{ ml: 2 }}
            onClick={() => {
              setSupplierInventoryIdtoAddMaterial(found.id);
              setIsAddMaterialModalOpen(true);
            }}
            loading={
              RemoveSupplierInventoryWithMaterialsMutationResponse.loading
            }
            disabled={
              RemoveSupplierInventoryWithMaterialsMutationResponse.loading
            }
          >
            New Material
          </LoadingButton>

          {found.supplierInventoryMaterial &&
            found.supplierInventoryMaterial.length > 0 && (
              <LoadingButton
                variant="contained"
                sx={{ ml: 2 }}
                onClick={() => {
                  setSupplierInventoryId(found.id);
                  setIsMaterialsTableModalOpen(true);
                }}
              >
                Materials
              </LoadingButton>
            )}


        </Box>


      );
    }

  }
  const renderActionsAdd = (params: any, dataRows: GridRowModel[], supplierInventoryData: SupplierInventoryDTO[]) => {
    const found = supplierInventoryData.filter((x) => {

      return x.id == params.row.id;
    })[0];

    if (found != undefined) {


      return (
        <Box>
         
          <LoadingButton
            variant="contained"
            sx={{ ml: 2 }}
            onClick={
              () => {
                setOpenModal(true);

                setRow(found);
                setDataOfRows(dataRows);
              }
            }
          >
            Add
          </LoadingButton>

        </Box>


      );
    }

  }
  const [retrievedColumnsOfBasket, setretrievedColumnsOfBasket] = useState<boolean>(false);
  const [retrievedColumns, setretrievedColumns] = useState<boolean>(false);

  useEffect(() => {
    if (supplierInventoryQueryResponseData) {
      // create columns
      if (supplierInventoryQueryResponseData[0]) {
        const dataTableColumnsArray: GridColDef[] = [];

        const columnNamesArray = Object.keys(
          supplierInventoryQueryResponseData[0]
        );

        columnNamesArray.forEach((columnName: string) => {
          if (
            columnName !== "poItemIdReferenceId" &&
            columnName !== "supplierId" &&
            columnName !== "supplier" &&
            columnName !== "supplierInventoryMaterial"
          ) {
            if (columnName == "id") {
              const dtTableColumn = {
                field: columnName,
                headerName: "id",
                width: 180,
                type: "string",
              };
              dataTableColumnsArray.push(dtTableColumn);
            } else if (columnName == "productName") {
              const dtTableColumn = {
                field: columnName,
                headerName: "Product Name",
                width: 180,
                type: "string",
              };
              dataTableColumnsArray.push(dtTableColumn);
            } else if (columnName == "productCode") {
              const dtTableColumn = {
                field: columnName,
                headerName: "Product Number",
                width: 180,
                type: "string",
              };
              dataTableColumnsArray.push(dtTableColumn);
            } else if (columnName == "containsHazMat") {
              const dtTableColumn = {
                field: columnName,
                headerName: "Contains Hazardous Materials",
                width: 180,
                type: "boolean"
              };
              dataTableColumnsArray.push(dtTableColumn);
            } else if (columnName == "itemUnit") {
              const dtTableColumn = {
                field: columnName,
                headerName: "Item Unit",
                width: 180,
                type: "string"
              };
              dataTableColumnsArray.push(dtTableColumn);
            }

          }
        }
        );

        const dtTableColumn = {
          field: "productInformation",
          headerName: "Product Information",
          width: 180,
          type: "string"
        };
        dataTableColumnsArray.push(dtTableColumn);

        if (!retrievedColumnsOfBasket) {
          setDataTableColumnsOfBasket([
            {
              field: "actions",
              headerName: "Actions",
              width: 300,
              filterable: false,
              sortable: false,
              renderCell: (params: any) => {
                return renderActionsForBasket(params, dataTableRef.current);
              }
            },
            ...dataTableColumnsArray,
          {
            field: "quantity",
            headerName: "Quantity",
            width: 180,
            filterable: false,
            sortable: false,
          }
            ,
          {
            field: "unit",
            headerName: "Unit",
            width: 180,
            filterable: false,
            sortable: false,
          },

          ]);
        }
        setretrievedColumnsOfBasket(true);



        if (!retrievedColumns) {
          setDataTableColumns([
            {
                field: "selectforprint",
                headerName: "Select for print",
                width: 200,
                filterable: false,
                sortable: false,
                renderCell: (params: any) => {
                  return renderActionsAdd(params, dataTableRef.current, supplierInventoryQueryResponseDataRefreshed.current);
                },
            },
            ...dataTableColumnsArray,
            {
              field: "actions",
              headerName: "Actions",
              width: 450,
              filterable: false,
              sortable: false,
              renderCell: (params: any) => {
                return renderActions(params, dataTableRef.current, supplierInventoryQueryResponseDataRefreshed.current);
              },
            },
          ]);
          setretrievedColumns(true);
        }

      }

      // create rows
      const dataTableRowsArray: GridRowModel[] = [];
      setDataTableRows([]);
      setRefreshStateOfColumns(undefined);
      supplierInventoryQueryResponseData.forEach(
        (supplierInventory: SupplierInventoryDTO) => {
          const dataTableRow: GridRowModel = {};

          dataTableRow.id = supplierInventory.id;
          dataTableRow.productCode = supplierInventory.productCode;
          dataTableRow.productName = supplierInventory.productName;
          dataTableRow.itemUnit = supplierInventory.itemUnit;
          dataTableRow.containsHazMat =
            supplierInventory.supplierInventoryMaterial &&
              supplierInventory.supplierInventoryMaterial.length > 0
              ? true
              : false;
          dataTableRow.productInformation = supplierInventory.productInformation;
          dataTableRowsArray.push(dataTableRow);
        }
      );

      setDataTableRows(dataTableRowsArray);
      setRefreshStateOfColumns(true);
      setColumnsDefined(true);
      setColumnsDefinedBasket(true);
    }
  }, [supplierInventoryQueryResponseData]);

  // handle delete
  useEffect(() => {
    if (supplierInventoryIdtoDelete && deleteSelected === true) {
      useRemoveSupplierInventoryWithMaterialsMutation(
        supplierInventoryIdtoDelete
      );
    }
  }, [supplierInventoryIdtoDelete, deleteSelected]);
  // handle add material
  useEffect(() => {
    if (supplierInventoryIdtoAddMaterial && deleteSelected) {
      useRemoveSupplierInventoryWithMaterialsMutation(
        supplierInventoryIdtoAddMaterial
      );
    }
  }, [supplierInventoryIdtoAddMaterial, deleteSelected]);

  useEffect(() => {
    
    if (selectionModel.length>0 && massiveDeleteSelected) {
      const ids: number[]=[];
      for(const id of selectionModel){
        ids.push(parseInt(id.toString()));
      }
      
      useRemoveSelectedSupplierInventoryWithMaterialsMutation(ids);

      setMassiveDeleteSelected(false);
    }
    
  }, [ massiveDeleteSelected]);

  // --------------------------------- handlers ---------------------------------------------------
  // sorting handler
  const handleSortModelChange = (newModel: GridSortModel) => {
    if (
      newModel !== null &&
      newModel != undefined &&
      newModel.length == 1 &&
      newModel[0].sort &&
      newModel[0].field
    ) {
      setSortField(newModel[0].field);
      setSortOrder(newModel[0].sort?.toString());
    }
  };
  // filtering handler
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    const properFilterArray: FilterTypeDTO[] = [];

    filterModel.items.forEach((filterObject) => {
      if (
        filterObject.field != undefined &&
        filterObject.operator != undefined &&
        filterObject.value != undefined
      ) {
        let opVal = OperationValue.UNKNOWN;
        switch (filterObject.operator) {
          case "contains":
            opVal = OperationValue.CONTAINS;
            break;
          case "equals":
            opVal = OperationValue.EQUALS;
            break;
          case "startsWith":
            opVal = OperationValue.STARTS_WITH;
            break;
          case "endsWith":
            opVal = OperationValue.ENDS_WITH;
            break;
          case "isEmpty":
            opVal = OperationValue.IS_EMPTY;
            break;
          case "isNotEmpty":
            opVal = OperationValue.IS_NOT_EMPTY;
            break;
          default:
            break;
        }
        const properFilter: FilterTypeDTO = {
          columnField: filterObject.field,
          operatorValue: opVal,
          value: filterObject.value,
        };
        properFilterArray.push(properFilter);
      }
    });
    if (properFilterArray.length > 0) {
      setFiltersArray(properFilterArray);
    }
  }, []);



  const apiRef = useGridApiRef();
  const apiRefBasket = useGridApiRef();
  const [refreshStateOfColumns, setRefreshStateOfColumns] = useState<boolean | undefined>(undefined);
  const [refreshStateOfColumnsBasket, setRefreshStateOfColumnsBasket] = useState<boolean | undefined>(undefined);


  const exportState = React.useCallback(() => {
    setRefreshStateOfColumns(undefined);

    if (apiRef) {
      if (apiRef.current) {
        const state = apiRef.current.exportState();
        if (state) {
          if (window.localStorage) {
            localStorage.setItem("gridStateOfPOsForSupplierInventory", JSON.stringify(state));
          }
          setRefreshStateOfColumns(true);
        }
      }
    }
  }, [apiRef]);

  const exportStateBasket = React.useCallback(() => {
    setRefreshStateOfColumnsBasket(undefined);

    if (apiRefBasket) {
      if (apiRefBasket.current) {
        const state = apiRefBasket.current.exportState();
        if (state) {
          if (window.localStorage) {
            localStorage.setItem("gridStateOfPOsForSupplierInventoryBasket", JSON.stringify(state));
          }
          setRefreshStateOfColumnsBasket(true);
        }
      }
    }
  }, [apiRefBasket]);


  const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 900,
    transition: theme.transitions.create(["box-shadow", "width"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    "&.Mui-focused": { width: 900, boxShadow: theme.customShadows.z8 },
    "& fieldset": {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[800]} !important`,
    },
  }));


  useEffect(() => {
    if ((!columnsDefined && dataTableRows.length > 0 && apiRef != undefined) || refreshStateOfColumns === true) {
      const savedData = localStorage.getItem("gridStateOfPOsForSupplierInventory");
      if (dataTableColumns != undefined) {
        if (savedData) {
          setOrderedColumns([]);
          const savedDataJsonFormated = JSON.parse(savedData != undefined ? savedData : "{}");
          if (savedDataJsonFormated) {
            for (const field of savedDataJsonFormated.columns.orderedFields) {
              if (field != "__check__") {
                const savedColumn = dataTableColumns.find((obj) => {
                  return obj.field === field;
                });
                if (savedColumn != undefined) {
                  savedColumn.width = savedDataJsonFormated.columns.dimensions[field]["width"];
                }
                orderedColumns.push(savedColumn != undefined ? savedColumn : JSON.parse('{}'));
              }
            }
          }
          if (orderedColumns && orderedColumns.length > 0) {
            setDataTableColumns(orderedColumns);
            setColumnsDefined(true);

          }
        }
      }
    }


  }, [apiRef, dataTableRows, refreshStateOfColumns]);

  useEffect(() => {
    if ((dataTableColumnsOfBasket.length > 0 && apiRefBasket != undefined) || refreshStateOfColumnsBasket === true) {

      const savedData = localStorage.getItem("gridStateOfPOsForSupplierInventoryBasket");
      if (dataTableColumnsOfBasket != undefined) {
        if (savedData) {
          setretrievedColumns(true);
          setOrderedColumnsBasket([]);
          const savedDataJsonFormated = JSON.parse(savedData != undefined ? savedData : "{}");
          if (savedDataJsonFormated) {
            for (const field of savedDataJsonFormated.columns.orderedFields) {
              if (field != "__check__") {
                const savedColumn = dataTableColumnsOfBasket.find((obj) => {
                  return obj.field === field;
                });
                if (savedColumn != undefined) {
                  savedColumn.width = savedDataJsonFormated.columns.dimensions[field]["width"];
                }
                orderedColumnsBasket.push(savedColumn != undefined ? savedColumn : JSON.parse('{}'));
              }
            }
          }
          if (orderedColumnsBasket && orderedColumnsBasket.length > 0) {
            setDataTableColumnsOfBasket(orderedColumnsBasket);
            setColumnsDefinedBasket(true);

          }
        }
      }
    }


  }, [apiRefBasket, dataTableRowsOfBasket, retrievedColumns, refreshStateOfColumnsBasket]);

  const [dataToPrint, setDataToPrint] = useState<BasketOfItems[]>([]);
  useEffect(() => {
    if (dataTableRowsOfBasket) {
      const dataToSave: BasketOfItems[] = [];
      for (const row of dataTableRowsOfBasket) {
        //const [itemOfBasket, setItemOfBasket]=useState<BasketOfItems>();

        const supplierInveMaterial: SupplierInventoryMaterials[] = [];
        for (const materialRow of row.supplierInventoryMaterial) {
          supplierInveMaterial.push(
            {
              id: materialRow.id,
              name: materialRow.name,
              comment: materialRow.comment ? materialRow.comment : "-",
              category: materialRow.category ? materialRow.category : "-",
              thresholdValue: materialRow.thresholdValue ? materialRow.thresholdValue : "-",
              presentAboveThresholdValue: materialRow.presentAboveThresholdValue,
              mass: parseFloat(materialRow.mass),
              unit: materialRow.unit,
              supplierInventoryId: materialRow.supplierInventoryId ? materialRow.supplierInventoryId : 0
            }
          );
        }
        dataToSave.push({
          id: parseInt(row.id),
          productInformation: row.productInformation ? row.productInformation : "-",
          productName: row.productName,
          productCode: row.productCode,
          containsHazMat: row.containsHazMat,
          selectedHazardousMaterialUnit: row.selectedHazardousMaterialUnit,
          quantity: row.quantity,
          unit: row.unit,
          itemUnit: row.itemUnit,
          supplierInventoryMaterial: supplierInveMaterial,
        });
      }
      setDataToPrint(dataToSave);
    }
  }, [dataTableRowsOfBasket]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [paginationModelBasket, setPaginationModelBasket] = useState({
    page: 0,
    pageSize: 20,
  });

  useEffect(() => {
    const { page, pageSize } = paginationModel;
    setPage(page);
    setRowsPerPage(pageSize);

  }, [paginationModel]);

  useEffect(() => {

    const { page, pageSize } = paginationModelBasket;
    setPageBasket(page);
    setRowsPerPageBasket(pageSize);

  }, [paginationModelBasket]);

  //const classesNew = useStyles();
  const token = window.sessionStorage.getItem("jwt");
  const handleFilterByName = (filterName: string) => {
    setSearchToken(filterName);
  };

  const serverUri = process.env.REACT_APP_SERVER_URI;
const downloadExcelFileQuery = async (
  token: string | null
) => {
  return axios.get(
    `${serverUri}/api/v1/supplierportal/excel`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    }
  );
};

  const handleDownloadExcel = () => {
    const response = downloadExcelFileQuery(token);
    response.then((dt: any) => {
      const url = window.URL.createObjectURL(new Blob([dt.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "suppliersportalheaders.xlsx"); //or any other extension 
      document.body.appendChild(link);
      link.click();
      enqueueSnackbar(`Download Successfull`, { variant: "success" });
    });
  };
  // ----------------------------------------------------------------------------------------------
  return (
    <>
      <Box>
        <Box>
          <Card>
            <Container >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >

                <TableListToolbar
                  numSelected={0}
                  filterName={searchToken}
                  onFilterName={handleFilterByName}
                  className={classesNew.searchbar}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    sx={{ mb: 2, mt: 2, mr: 2 }}
                    onClick={() => setIsAddNewItemModalOpen(true)}
                  >
                    Add new item
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    sx={{ mb: 2, mt: 2, mr: 2 }}
                    onClick={() => setIsImporSiWizardModalOpen(true)}
                  >
                    Import New
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    sx={{ mb: 2, mt: 2, mr: 2 }}
                    onClick={() => setIsMassiveDeleteModalOpen(true)}
                    disabled={selectionModel.length == 0}
                  >
                    Delete Selected
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    sx={{ mb: 2, mt: 2, mr: 2 }}
                    onClick={handleDownloadExcel}
                    
                  >
                    Download Excel
                  </LoadingButton>
                </Box>
              </Box>

              <Box
                sx={{
                  //mr: 10,
                  //height: 660,
                  //width: 1150,
                  width:1,
                  "& .super-app-theme--2": {
                    bgcolor: () => "rgba(91, 229, 132, 0.15)",
                  },
                  "& .super-app-theme--1": {
                    bgcolor: () => "rgba(255, 244, 165, 0.5)",
                  },
                }}
              >
                <Typography variant="h3" >My inventory</Typography>
                {columnsDefined && (<DataGridPro
                  apiRef={apiRef}
                  columns={dataTableColumns}
                  rows={dataTableRows}
                  disableRowSelectionOnClick
                  checkboxSelection
                  components={{ Toolbar: GridToolbar, FilterPanel: GridFilterPanel }}
                  className={classesNew.customStyle}
                  // ---- selection props
                  onRowSelectionModelChange={(newSelectionModel: any) => setSelectionModel(newSelectionModel)}
                  rowSelectionModel={selectionModel}
                  // ---- filtering props
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  // ---- sorting props
                  sortingMode="server"
                  onSortModelChange={handleSortModelChange}
                  // ---- pagination props
                  pagination
                  paginationMode="server"
                  rowCount={totalRowsOfdataTable}//dataTableRows.length

                  pageSizeOptions={[20, 50, 100]}
                  paginationModel={{ pageSize: rowsPerPage, page: page }}

                  onColumnVisibilityModelChange={exportState}
                  onColumnOrderChange={exportState}
                  onColumnWidthChange={exportState}
                  onPaginationModelChange={setPaginationModel}


                  loading={getSupplierInventoryQueryResponse.loading} />)}
              </Box>



              <WarningModal
                isOpenPreview={isDeleteModalOpen}
                onClosePreview={() => setIsDeleteModalOpen(false)}
                warningMsg={"Are you sure you want to delete this Inventory?"}
                setUserResponseTrue={() => {
                  setDeleteSelected(true);
                  setIsDeleteModalOpen(false);
                }} />

              <WarningModal
                isOpenPreview={isMassiveDeleteModalOpen}
                onClosePreview={() => setIsMassiveDeleteModalOpen(false)}
                warningMsg={"Are you sure you want to delete these items?"}
                setUserResponseTrue={() => {
                  
                  setMassiveDeleteSelected(true);
                  setIsMassiveDeleteModalOpen(false);
                }} />

              <AddSupplierInventoryMaterialModal
                isOpenPreview={isAddMaterialModalOpen}
                onClosePreview={() => setIsAddMaterialModalOpen(false)}
                supplierInventoryId={supplierInventoryIdtoAddMaterial} />

              <ImportSupplierInventoryWizardModal
                isOpenPreview={isImporSiWizardModalOpen}
                onClosePreview={() => setIsImporSiWizardModalOpen(false)} />
              <SupplierInventoryMaterialsTableModal
                isOpenPreview={isMaterialsTableModalOpen}
                onClosePreview={() => setIsMaterialsTableModalOpen(false)}
                supplierInventoryId={supplierInventoryId} />


              <AddQuantityModal
                onClosePreview={() => setOpenModal(false)}
                openModal={openModal}
                //setQuantityValue={setQuantity}
                row={row ? row : {}}
                //quantityValue={quantity}
                dataRowsCurrent={dataTableRef}
                setDataTableRowsOfBasket={setDataTableRowsOfBasket}
              />

              <DocumentInformationModal
                openModal={openDocumentInformationModal}
                onClosePreview={() => setOpenDocumentInformationModal(false)}
                supplierId={supplierId ? supplierId : 0}
                basketOfItems={dataToPrint}
                setDataToPrint={setDataToPrint}
                setDataTableRowsOfBasket={setDataTableRowsOfBasket}
                dataTableRefCurren={dataTableRef}
              />

              <SetNewItemModal
                isAddNewItemModalOpen={isAddNewItemModalOpen}
                onClosePreview={() => setIsAddNewItemModalOpen(false)}
                supplierId={supplierId ? supplierId : 0}
              />






            </Container>
          </Card>
        </Box>
        <Box sx={{ mt: 10 }}>
          <Card>
            <Container>
              <Box
                sx={{
                  mt: 10,
                  height: 650,
                  width: 1,
                  "& .super-app-theme--2": {
                    bgcolor: () => "rgba(91, 229, 132, 0.15)",
                  },
                  "& .super-app-theme--1": {
                    bgcolor: () => "rgba(255, 244, 165, 0.5)",
                  },
                }}
              >
                <Typography variant="h3" >Selected items to print</Typography>
                {columnsDefinedBasket && (
                  <DataGridPro
                    apiRef={apiRefBasket}
                    columns={dataTableColumnsOfBasket}
                    rows={dataTableRowsOfBasket}
                    disableRowSelectionOnClick
                    // checkboxSelection
                    components={{ Toolbar: GridToolbar, FilterPanel: GridFilterPanel }}
                    className={classesNew.customStyle}
                    // ---- selection props
                    onRowSelectionModelChange={(newSelectionModel: any) =>
                      setSelectionModelBasket(newSelectionModel)
                    }
                    rowSelectionModel={selectionModelBasket}
                    // ---- filtering props
                    //filterMode="server"
                    //onFilterModelChange={onFilterChange}
                    // ---- sorting props
                    //sortingMode="server"
                    //onSortModelChange={handleSortModelChange}
                    // ---- pagination props
                    pagination={true}

                    rowCount={dataTableRowsOfBasket.length}
                    //pageSizeOptions={[20, 50, 100]}
                    //pageSize={rowsPerPage}
                    //onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
                    //onPageChange={(newPage) => {
                    //  setPage(newPage);
                    //}}
                    //paginationMode="server"
                    onPaginationModelChange={setPaginationModelBasket}


                    pageSizeOptions={[20, 50, 100]}
                    paginationModel={{ pageSize: rowsPerPageBasket, page: pageBasket }}

                    onColumnVisibilityModelChange={exportStateBasket}
                    onColumnOrderChange={exportStateBasket}
                    onColumnWidthChange={exportStateBasket}

                  />
                )}

              </Box>
              <Box
                sx={{ mt: 3, marginLeft: "auto", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Button
                  variant="contained"
                  disabled={dataTableRowsOfBasket.length==0}
                  sx={{ mt: 1, mb: 1, display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "auto" }}
                  onClick={
                    () => {
                      setOpenDocumentInformationModal(true);
                    }
                  }>
                  Print
                </Button>
              </Box>

            <WarningModal
              isOpenPreview={isRemoveFromBasketModalOpen}
              onClosePreview={() => setIsRemoveFromBasketModalOpen(false)}
              warningMsg={
                "Are you sure you want to remove this item?"
              }
              setUserResponseTrue={() => {
                setRemoveSelectedFromBasket(true);
                setIsRemoveFromBasketModalOpen(false);
              }}
            />

            </Container>
          </Card>
        </Box>
      </Box>
    </>
  );
}
