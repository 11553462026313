import { Icon } from "@iconify/react";
import { ApexOptions } from "apexcharts";

import ReactApexChart from "react-apexcharts";
import trendingUpFill from "@iconify/icons-eva/trending-up-fill";
import trendingDownFill from "@iconify/icons-eva/trending-down-fill";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import { Box, Card, Typography, Stack } from "@mui/material";
// utils
import { fNumber, fPercent } from "../../utils/formatNumber";

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  width: 24,
  height: 24,
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.success.main,
  backgroundColor: "white",
}));

// ----------------------------------------------------------------------

interface chartData {
  data: number[];
}
interface AnalyticsCardProps {
  Label: string;
  Value: number;
  ValueDetails?: number;
  GraphValues?: chartData[];
}
const DUMMY_CHART_DATA = [{ data: [20, 41, 63, 33, 28, 35, 50, 46, 11, 26] }];

export default function AnalyticsCard(props: AnalyticsCardProps) {
  const graphVals = props.GraphValues ? props.GraphValues : DUMMY_CHART_DATA;
  const theme = useTheme();

  const chartOptions: ApexOptions = {
    colors: [theme.palette.primary.main],
    chart: { sparkline: { enabled: true } },
    plotOptions: { bar: { columnWidth: "68%", borderRadius: 2 } },
    labels: ["1", "2", "3", "4", "5", "6", "7", "8"],
    tooltip: {
      x: { show: false },
      y: {
        formatter: (seriesName: number | string) => fNumber(seriesName),
        title: {
          formatter: (seriesName: number | string) => "",
        },
      },
      marker: { show: false },
    },
  };

  return (
    <Card sx={{ display: "flex", alignItems: "center", p: 3 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{props.Label}</Typography>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ mt: 2, mb: 1 }}
        >
          <IconWrapperStyle
            sx={{
              ...(props.ValueDetails &&
                props.ValueDetails < 0 && {
                  color: "error.main",
                  bgcolor: alpha(theme.palette.error.main, 0.16),
                }),
            }}
          >
            {props.ValueDetails && (
              <Icon
                width={16}
                height={16}
                icon={
                  props.ValueDetails >= 0 ? trendingUpFill : trendingDownFill
                }
              />
            )}
          </IconWrapperStyle>

          <Typography component="span" variant="subtitle2">
            {props.ValueDetails && props.ValueDetails > 0 && "+"}
            {props.ValueDetails && fPercent(props.ValueDetails)}
          </Typography>
        </Stack>

        <Typography variant="h3">{fNumber(props.Value)}</Typography>
      </Box>

      <ReactApexChart
        type="bar"
        series={graphVals}
        options={chartOptions}
        width={60}
        height={36}
      />
    </Card>
  );
}
