import { useEffect } from "react";
// material
import { Container, Card } from "@mui/material";
// components
import Page from "../../components/Page";
import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";
import SupplierInventoryTable from "../../components/dashboard/suppliers/supplierInventory/SupplierInventoryTable";

// --------------------------------------------------------------------------------------------------
type SupplierInventoryProps = {
  setNavigationHelper: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function SupplierInventory({
  setNavigationHelper,
}: SupplierInventoryProps) {
  useEffect(() => {
    setNavigationHelper(false);
  }, []);

  return (
    <Page title="Supplier Inventory">
      <Container maxWidth={"xl"}>
        <HeaderBreadcrumbs
          heading="Supplier Inventory"
          links={[
            { name: "Dashboard" },
            { name: "Users & Groups" },
            { name: "Suppliers" },
            { name: "Supplier Inventory" },
          ]}
        />

        <Card sx={{ display: "block", alignItems: "center", p: 3 }}>
          <SupplierInventoryTable />
        </Card>
      </Container>
    </Page>
  );
}
