import { AppUser } from '../@types/AppUser'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { AuthContext } from './authContext'
import { useKeycloak } from '@react-keycloak/web'

const authContext = createContext<AuthContext>(null!)

export function AuthProvider({ children } : { children : React.ReactNode}) {
    const [user, setUser] = useState<AppUser>({
        Id:"",
        FirstName : "",
        LastName : "",
        Email : "",
        IsAuthenticated : false,
        Role: ''
    });
    const { initialized, keycloak } = useKeycloak();
    useEffect(() => {
        const appUser : AppUser = {
            Id: '',
            FirstName : '',
            LastName : '',
            Email : '',
            AccessToken: keycloak?.token ?? '',
            IsAuthenticated : keycloak?.authenticated ?? false,
            Role: ''
        }
        setUser(appUser)
        if(keycloak.token){
            window.sessionStorage.setItem('jwt',keycloak?.token)
            if(keycloak?.realmAccess){
                if (keycloak?.realmAccess.roles.find((x) => x === "ShipOwner")) {
                    window.sessionStorage.setItem('userRole',"ShipOwner");
                  } else if (keycloak.realmAccess.roles.find((x) => x === "Supplier")) {
                    window.sessionStorage.setItem('userRole',"Supplier");
                  } else if (keycloak.realmAccess.roles.find((x) => x === "Maintainer")) {
                    window.sessionStorage.setItem('userRole',"Maintainer");
                }
            }
        }

    },[initialized, keycloak])

    const LogOut = async () => {
        console.log("Logout");
        window.sessionStorage.removeItem('SelectedShipOwnerStorage');
        window.sessionStorage.removeItem('SelectedVesselStorage');
        window.sessionStorage.removeItem('jwt')
        if(initialized){
            await keycloak.logout();
        }
    }

    const auth : AuthContext = {
        User: user,
        LogOut : LogOut
    }
    return <authContext.Provider value={auth}> { children } </authContext.Provider>
}

export const useAuth = () => useContext(authContext)
