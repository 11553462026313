// material
import { Box, Container, Typography } from "@mui/material";
import { DialogAnimate } from "../../../animate";
import AddNewSupplierInventoryMaterialForm from "./AddNewSupplierInventoryMaterialForm";

// ----------------------------------------------------------------------

type AddSupplierInventoryMaterialModalProps = {
  isOpenPreview: boolean;
  onClosePreview: VoidFunction;
  supplierInventoryId?: number;
};

export default function AddSupplierInventoryMaterialModal({
  isOpenPreview,
  onClosePreview,
  supplierInventoryId,
}: AddSupplierInventoryMaterialModalProps) {
  return (
    <DialogAnimate open={isOpenPreview} onClose={onClosePreview}>
      <Container>
        <Box
          sx={{
            mb: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" sx={{ m: 2, mb: 4 }}>
            Add New Material
          </Typography>
          {supplierInventoryId && (
            <AddNewSupplierInventoryMaterialForm
              supplierInventoryId={supplierInventoryId}
              onClosePreview={onClosePreview}
            />
          )}
        </Box>
      </Container>
    </DialogAnimate>
  );
}
