// material
import { styled } from "@mui/material/styles";
import { Typography, Card, CardContent, Box } from "@mui/material";
import SeoIllustration from "./illustration_seo";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GET_APP_USER_BY_TOKEN_QUERY } from "../../graphQL/Queries";
import { useQuery } from "@apollo/client";
import vesselImage from "../../assets/vesselImageDefault.jpg";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  // backgroundColor: theme.palette.primary.lighter,
  backgroundImage: `url(${vesselImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  color: "#fff",
  flex: "2",
  minHeight: "150px",
  [theme.breakpoints.up("md")]: {
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

// Define Queries
const getAppUserByTokenQuery = () =>
  useQuery(GET_APP_USER_BY_TOKEN_QUERY, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

export default function AppWelcome() {
  const getAppUserByTokenQueryResponse = getAppUserByTokenQuery();

  return (
    <RootStyle>
      <CardContent
        sx={{
          p: { md: 0 },
          pl: { md: 5 },
        }}
      >
        <Typography gutterBottom variant="h4">
          Welcome back,
          <br /> {getAppUserByTokenQueryResponse?.data?.getAppUserByToken?.name}
          !
        </Typography>

        <Box sx={{ m: 20, ml: 35 }}></Box>
      </CardContent>

      <SeoIllustration
        sx={{
          p: 3,
          width: 360,
          margin: { xs: "auto", md: "inherit" },
        }}
      />
    </RootStyle>
  );
}
