import { useState, useEffect, useCallback } from "react";
// material
import { Box } from "@mui/material";
// @types
import { SupplierInventoryMaterialDTO } from "../../../../@types/Supplier";
// utils
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMutation, useQuery } from "@apollo/client";
import { GET_SUPPLIER_INVENTORY_MATERIALS_BY_SUPPLIER_INVENTORY_QUERY } from "../../../../graphQL/Queries";
import {
  DataGrid,
  GridColumns,
  GridFilterModel,
  GridFilterPanel,
  GridRowModel,
  GridSelectionModel,
  GridSortModel,
  GridToolbar,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { REMOVE_SUPPLIERS_INVENTORY_MATERIAL_MUTATION } from "../../../../graphQL/Mutations";
import { useSnackbar } from "notistack";
import { FilterTypeDTO, OperationValue } from "../../../../@types/DataGrid";

// -------------------------------- Styles ---------------------------------------
const useStyles = makeStyles(() => ({
  customStyle: {
    "& .MuiDataGrid-toolbarContainer": {
      background: "#ffffff",
    },
  },
  panel: {
    "& .MuiNativeSelect-select": {
      backgroundColor: "red",
      color: "red",
    },
  },
}));

// -------------------------------------queries------------------------------------------------------
const getSupplierInventoryMaterialsQuery = (
  suppplierInventoryId: number,
  limit: number,
  page: number,
  filtersArray: FilterTypeDTO[],
  sortField: string | undefined,
  sortOrder: string | undefined
) =>
  useQuery(GET_SUPPLIER_INVENTORY_MATERIALS_BY_SUPPLIER_INVENTORY_QUERY, {
    variables: {
      supplierInventoryId: suppplierInventoryId,
      limit: limit,
      page: page,
      filtersArray: filtersArray,
      sortingOrder: {
        field: sortField,
        sort: sortOrder,
      },
    },
    // skip: !shipOwnerId,
    onError(err) {
      toast.configure();
      toast.error(err.message);
    },
    fetchPolicy: "network-only",
  });

type SupplierInventoryMaterialsTableProps = {
  supplierInventoryId: number;
};

// ---------------------------------------------------------------------------------------------------------------------------------------------------------
export default function SupplierInventoryMaterialsTable({
  supplierInventoryId,
}: SupplierInventoryMaterialsTableProps) {
  // native props
  const classesNew = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [supplierInventoryMaterials, setSupplierInventoryMaterials] = useState<
    SupplierInventoryMaterialDTO[]
  >([]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [
    supplierInventoryMaterialIdtoDelete,
    setSupplierInventoryMaterialIdtoDelete,
  ] = useState<number>();

  // DataGridProps
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [dataTableColumns, setDataTableColumns] = useState<GridColumns>([]);
  const [dataTableRows, setDataTableRows] = useState<GridRowModel[]>([]);
  // datagrid filtering
  const [filtersArray, setFiltersArray] = useState<FilterTypeDTO[]>([]);
  // dataGrid sorting
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("asc");
  // dataGrid selection
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  // const prevSelectionModel = useRef<GridSelectionModel>(selectionModel);

  // use Query
  const getSupplierInventoryMaterialsQueryResponse =
    getSupplierInventoryMaterialsQuery(
      supplierInventoryId,
      rowsPerPage,
      page,
      filtersArray,
      sortField,
      sortOrder
    );

  // --------------------------------- Mutations -------------------------------------------------
  const [
    RemoveSupplierInventoryMaterialMutation,
    RemoveSupplierInventoryMaterialMutationResponse,
  ] = useMutation(REMOVE_SUPPLIERS_INVENTORY_MATERIAL_MUTATION, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
    refetchQueries: "active",
  });
  const useRemoveSupplierInventoryMaterialMutation = (
    supplierInventoryMaterialId: number
  ) => {
    RemoveSupplierInventoryMaterialMutation({
      variables: {
        id: supplierInventoryMaterialId,
      },
    });
  };

  // --------------------------------- hooks ------------------------------------------------------

  useEffect(() => {
    if (getSupplierInventoryMaterialsQueryResponse.data) {
      setSupplierInventoryMaterials(
        getSupplierInventoryMaterialsQueryResponse.data
          .getSupplierInventoryMaterialsBySupplierInventory
      );
    }
  }, [getSupplierInventoryMaterialsQueryResponse.data]);

  // parse response to dataGrid Objects
  useEffect(() => {
    if (supplierInventoryMaterials) {
      // create columns
      if (supplierInventoryMaterials[0]) {
        const dataTableColumnsArray: GridColumns = [];

        const columnNamesArray = Object.keys(supplierInventoryMaterials[0]);

        columnNamesArray.forEach((columnName: string) => {
          const dtTableColumn = {
            field: columnName,
            headerName: columnName,
            width: 180,
            type:
              columnName == "presentAboveThresholdValue"
                ? "boolean"
                : columnName == "mass"
                  ? "number"
                  : "string",
            filterable: columnName != "id",
          };
          dataTableColumnsArray.push(dtTableColumn);
        });
        setDataTableColumns([
          ...dataTableColumnsArray,
          {
            field: "actions",
            headerName: "actions",
            width: 200,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
              const found = supplierInventoryMaterials?.filter((x) => {
                return x.id == params.row.id;
              })[0];

              return (
                <Box>
                  <LoadingButton
                    variant="contained"
                    onClick={() => {
                      setSupplierInventoryMaterialIdtoDelete(found.id);
                      setIsDeleteModalOpen(true);
                    }}
                    loading={
                      RemoveSupplierInventoryMaterialMutationResponse.loading
                    }
                    disabled={
                      RemoveSupplierInventoryMaterialMutationResponse.loading
                    }
                  >
                    Delete
                  </LoadingButton>
                </Box>
              );
            },
          },
        ]);
      }

      // create rows
      const dataTableRowsArray: GridRowModel[] = [];

      supplierInventoryMaterials.forEach(
        (supplierInventoryMateral: SupplierInventoryMaterialDTO) => {
          const dataTableRow: GridRowModel = {};

          dataTableRow.id = supplierInventoryMateral.id;
          dataTableRow.name = supplierInventoryMateral.name;
          dataTableRow.category = supplierInventoryMateral.category;
          dataTableRow.mass = supplierInventoryMateral.mass;
          dataTableRow.unit = supplierInventoryMateral.unit;
          dataTableRow.comment = supplierInventoryMateral.comment;
          dataTableRow.thresholdValue = supplierInventoryMateral.thresholdValue;
          dataTableRow.presentAboveThresholdValue =
            supplierInventoryMateral.presentAboveThresholdValue;

          dataTableRowsArray.push(dataTableRow);
        }
      );

      setDataTableRows(dataTableRowsArray);
    }
  }, [supplierInventoryMaterials]);

  // handle delete
  useEffect(() => {
    if (supplierInventoryMaterialIdtoDelete) {
      useRemoveSupplierInventoryMaterialMutation(
        supplierInventoryMaterialIdtoDelete
      );
    }
  }, [supplierInventoryMaterialIdtoDelete]);

  useEffect(() => {
    if (RemoveSupplierInventoryMaterialMutationResponse.data) {
      enqueueSnackbar(`Supplier Inventory Material was succesfully Removed!`, {
        variant: "success",
      });
    }
  }, [RemoveSupplierInventoryMaterialMutationResponse.data]);

  // --------------------------------- handlers ---------------------------------------------------
  // sorting handler
  const handleSortModelChange = (newModel: GridSortModel) => {
    if (
      newModel !== null &&
      newModel != undefined &&
      newModel.length == 1 &&
      newModel[0].sort &&
      newModel[0].field
    ) {
      setSortField(newModel[0].field);
      setSortOrder(newModel[0].sort?.toString());
    }
  };
  // filtering handler
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    const properFilterArray: FilterTypeDTO[] = [];

    filterModel.items.forEach((filterObject) => {
      if (
        filterObject.columnField != undefined &&
        filterObject.operatorValue != undefined &&
        filterObject.value != undefined
      ) {
        let opVal = OperationValue.UNKNOWN;
        switch (filterObject.operatorValue) {
          case "contains":
            opVal = OperationValue.CONTAINS;
            break;
          case "equals":
            opVal = OperationValue.EQUALS;
            break;
          case "startsWith":
            opVal = OperationValue.STARTS_WITH;
            break;
          case "endsWith":
            opVal = OperationValue.ENDS_WITH;
            break;
          case "isEmpty":
            opVal = OperationValue.IS_EMPTY;
            break;
          case "isNotEmpty":
            opVal = OperationValue.IS_NOT_EMPTY;
            break;
          default:
            break;
        }
        const properFilter: FilterTypeDTO = {
          columnField: filterObject.columnField,
          operatorValue: opVal,
          value: filterObject.value,
        };
        properFilterArray.push(properFilter);
      }
    });
    if (properFilterArray.length > 0) {
      setFiltersArray(properFilterArray);
    }
  }, []);

  // ----------------------------------------------------------------------------------------------
  return (
    <Box
      sx={{
        height: 550,
        width: 1,
        "& .super-app-theme--2": {
          bgcolor: () => "rgba(91, 229, 132, 0.15)",
        },
        "& .super-app-theme--1": {
          bgcolor: () => "rgba(255, 244, 165, 0.5)",
        },
      }}
    >
      <DataGrid
        columns={dataTableColumns}
        rows={dataTableRows}
        disableSelectionOnClick
        // checkboxSelection
        components={{ Toolbar: GridToolbar, FilterPanel: GridFilterPanel }}
        className={classesNew.customStyle}
        // --------------------selection props
        onSelectionModelChange={(newSelectionModel) =>
          setSelectionModel(newSelectionModel)
        }
        selectionModel={selectionModel}
        // --------------------filtering props
        filterMode="server"
        onFilterModelChange={onFilterChange}
        // --------------------sorting props
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        // --------------------pagination props
        pagination
        paginationMode="server"
        rowCount={dataTableRows.length}
        rowsPerPageOptions={[20, 50, 100]}
        pageSize={rowsPerPage}
        onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
        onPageChange={(newPage) => {
          setPage(newPage);
        }}
        loading={getSupplierInventoryMaterialsQueryResponse.loading}
      />
    </Box>
  );
}
