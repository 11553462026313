// material
import { CardHeader, LinearProgress } from "@mui/material";
// hooks
import { styled, useTheme } from "@mui/material/styles";
import ReactApexChart from "react-apexcharts";
import { merge } from "lodash";
import VesselHazardousMaterialsChart from "../../components/charts/VesselHazardousMaterialsChart";
import { useEffect, useState } from "react";

// Define Chart
const CHART_HEIGHT = 360;
const LEGEND_HEIGHT = 70;
const ChartWrapperStyle = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  flex: "1",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  "& .apexcharts-canvas": {
    minHeight: "360px",
  },
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important" as "relative",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------
type AppexGraphProps = {
  chartTitle: string;
  headLabel: string;
  chartLabels: string[];
  chartData: number[];
  isloading?: boolean;
};

export default function AppexGraph({
  chartTitle,
  headLabel,
  chartLabels,
  chartData,
  isloading,
}: AppexGraphProps) {
  const theme = useTheme();
  const [chartDataObj, setChartDataObj] = useState<number[]>([]);
  const [chartLabelObj, setChartLabelObj] = useState<string[]>([]);

  useEffect(() => {
    const newCD: number[] = [];


    for (const dtItem of chartData) {
      newCD.push(dtItem);
    }
    setChartDataObj(newCD);
  }, [chartData]);

  useEffect(() => {
    const labels: string[] = [];
    for (const label of chartLabels) {
      labels.push(label);
    }
    setChartLabelObj(labels);
  }, [chartLabels]);

  // chart options
  const chartOptions = merge(VesselHazardousMaterialsChart(), {
    labels: chartLabelObj,
    legend: { floating: true, horizontalAlign: "center" },
    fill: {
      type: "gradient",
      gradient: {
        colorStops: [
          [
            {
              offset: 0,
              color: theme.palette.primary.light,
            },
            {
              offset: 100,
              color: theme.palette.primary.main,
            },
          ],
          [
            {
              offset: 0,
              color: theme.palette.warning.light,
            },
            {
              offset: 100,
              color: theme.palette.warning.main,
            },
          ],
        ],
      },
    },
    plotOptions: {
      radialBar: {
        hollow: { size: "68%" },
        dataLabels: {
          total: {
            label: headLabel,
          },
          value: { offsetY: 16 },
        },
      },
    },
  });

  return (
    <>
      {isloading && <LinearProgress color="primary" sx={{ pt: -1 }} />}
      <CardHeader title={chartTitle} />
      <ChartWrapperStyle dir="ltr">
        {chartLabelObj.length>0 && chartDataObj.length>0 && (
            <ReactApexChart
            type="radialBar"
            series={chartDataObj}
            options={chartOptions}
            height={310}
          />
        )
        }

      </ChartWrapperStyle>
    </>
  );
}
