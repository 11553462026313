import { makeStyles } from "@mui/styles";
import { Icon } from "@iconify/react";
import { Container, Stack, Box, FormControl, InputLabel, Select, LinearProgress, MenuItem, SelectChangeEvent, Button, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import Page from "../../Page";
import HeaderBreadcrumbs from "../../HeaderBreadcrumbs";
import arrowCircleLeftOutline from "@iconify/icons-eva/arrow-circle-left-outline";
import { useNavigate } from "react-router";
import {
  DataGrid,
  GridApi,
  GridCellValue,
  GridFilterPanel,
  GridRowId,
  GridSelectionModel,
  GridToolbar,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_PURCHASE_ORDER_REQUESTS_DANAOS, GET_PURCHASE_ORDER_REQUESTS_DANAOS_BY_SHIPOWNER_ID_PROCESSED, GET_SHIPOWNERS_QUERY } from "../../../graphQL/Queries";
import { toast } from "react-toastify";
import { ShipOwnerDTO } from "../../../@types/ShipOwner";
import axios from "axios";
import moment from "moment";

export enum RadioGroupSelectionValuesEnum {
  NOTPROCESSED,
  PROCESSED,
}


const serverUri = process.env.REACT_APP_SERVER_URI;
const downloadExcelFileQuery = async (
  vendors: number,
  shipowner: number,
  token: string | null
) => {
  return axios.get(
    `${serverUri}/api/v1/danaos/shipowner/${shipowner}/vendors/${vendors}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    }
  );
};


const downloadAllExcelFileQuery = async (

  shipowner: number,
  token: string | null
) => {
  return axios.get(
    `${serverUri}/api/v1/danaos/shipowner/${shipowner}/vendor/all`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    }
  );
};

const downloadExcelFileProcessedQuery = async (
  batchDownloadId: string,

  token: string | null
) => {
  return axios.get(
    `${serverUri}/api/v1/danaos/batchDownloadId/${batchDownloadId}`,//shipowner/${shipowner}/
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    }
  );
};
const useStyles = makeStyles(() => ({
  navigationIcon: {
    cursor: "pointer",
    color: "#0079C1",
    transition: "0.5s ease",
    "&:hover": {
      color: "#39464f",
      transition: "0.5s ease",
    },
  },
  customStyle: {
    "& .MuiDataGrid-toolbarContainer": {
      background: "#ffffff",
    },
  },
}));
const SHIP_OWNER_VALUE_KEY = "SelectedShipOwnerStorage";
const DanaosRequests = () => {

  interface GridRowsPropProcessed {
    batchDownloadId?: any;
    shipOwnerName?: any;
    batchDownloadDate?: any;
  }

  interface GridRowsPropNotProcessed {
    id?: number;
    vesselCode?: any;
    department?: any;
    documentCode?: any;
    documentNo?: any;
    itemSerialNo?: any;
    parcial?: any;
    exportSequence?: any;
    recordStatus?: any;
    syncDate?: any;
    entryType?: any;
    imoNumber?: any;
    vesselName?: any;
    poNumber?: any;
    poApprovedDate?: any;
    vendorName?: any;
    vendorEmail?: any;
    vendorPhone?: any;
    materialCategory?: any;
    equipmentName?: any;
    modelName?: any;
    makerName?: any;
    equipmentSerialNo?: any;
    subEquipmentName?: any;
    partNo?: any;
    itemShortDescription?: any;
    itemLongDescription?: any;
    qty?: any;
    uom?: any;
    vendorHeaderRemarks?: any;
    mdRequired?: any;
    sdReceived?: any;
    expectedDeliveryDate?: any;
    vendorCountry?: any;
    deliveryPort?: any;
    apiUniqueId?: any;
    ledgerCard?: any;
    orderReference?: any;
    dateExported?: any;
    poDate?: any;
    processedDate?: any;
  }

  const classesNew = useStyles();
  const navigate = useNavigate();

  const [radioGroupValue, setRadioGroupValue] =
    useState<RadioGroupSelectionValuesEnum>(
      RadioGroupSelectionValuesEnum.NOTPROCESSED
    );
  const [vendors, setVendors] = useState<number>(0);

  const [availableShipOwners, setAvailableShipOwners] = useState<ShipOwnerDTO[]>([]);
  const [selectedShipOwner, setSelectedShipOwner] = useState<ShipOwnerDTO>();

  const [pageNotProcessed, setpageNotProcessed] = useState(0);
  const [rowsPerPageNotProcessed, setRowsPerPageNotProcessed] = useState(20);
  const [totalPageNotProcessedRows, setTotalPageNotProcessedRows] = useState<number>(0);
  const [rowsArrayNotProcessed, setRowsArrayNotProcessed] = useState<GridRowsPropNotProcessed[]>([]);

  const [pageProcessed, setpageProcessed] = useState(0);
  const [rowsPerPageProcessed, setRowsPerPageProcessed] = useState(20);
  const [totalPageProcessedRows, setTotalPageProcessedRows] = useState<number>(0);
  const [rowsArrayProcessed, setRowsArrayProcessed] = useState<GridRowsPropProcessed[]>([]);

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const token = window.sessionStorage.getItem("jwt");


  const TABLE_COLUMNS_PROCESSED = [
    {
      field: "batchDownloadId",
      headerName: "Batch Download Id",
      width: 380,
      type: "string",
      filterable: false,
    },
    {
      field: "shipOwnerName",
      headerName: "Shipowner",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "batchDownloadDate",
      headerName: "Download Date",
      width: 180,
      type: "string",
      filterable: false,
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      renderCell: (params: any) => {
        const onClick = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking

          const api: GridApi = params.api;
          const thisRow: Record<string, GridCellValue> = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== '__check__' && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
            );


          if (thisRow != undefined) {
            const response = downloadExcelFileProcessedQuery(thisRow.batchDownloadId ? thisRow.batchDownloadId as string : "", token);
            response.then((dt: any) => {
              const url = window.URL.createObjectURL(new Blob([dt.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' }));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "orders.xlsx"); //or any other extension 
              document.body.appendChild(link);
              link.click();
              enqueueSnackbar(`Download Successfull`, { variant: "success" });

            });
          }

        };

        return <Button onClick={onClick}>Download</Button>;
      },
    }
  ];

  const TABLE_COLUMNS = [
    {
      field: "id"
    },
    {
      field: "vesselCode",
      headerName: "Vessel",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "department",
      headerName: "Department",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "documentCode",
      headerName: "Document Code",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "documentNo",
      headerName: "Document No",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "itemSerialNo",
      headerName: "Item Serial No",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "parcial",
      headerName: "Parcial",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "exportSequence",
      headerName: "Export Sequence",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "recordStatus",
      headerName: "Record Status",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "syncDate",
      headerName: "Sync Date",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "entryType",
      headerName: "Entry Type",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "imoNumber",
      headerName: "Imo Number",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "vesselName",
      headerName: "Vessel Name",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "poNumber",
      headerName: "Po Number",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "poApprovedDate",
      headerName: "Po Approved Date",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "vendorName",
      headerName: "Vendor Name",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "vendorEmail",
      headerName: "Vendor Email",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "vendorPhone",
      headerName: "Vendor Phone",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "materialCategory",
      headerName: "Material Category",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "equipmentName",
      headerName: "Equipment Name",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "modelName",
      headerName: "Model Name",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "makerName",
      headerName: "Maker Name",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "equipmentSerialNo",
      headerName: "Equipment Serial No",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "subEquipmentName",
      headerName: "Sub Equipment Name",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "partNo",
      headerName: "Part No",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "itemShortDescription",
      headerName: "Item Short Description",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "itemLongDescription",
      headerName: "Item Long Description",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "qty",
      headerName: "qty",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "uom",
      headerName: "uom",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "vendorHeaderRemarks",
      headerName: "Vendor Header Remarks",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "mdRequired",
      headerName: "Md Required",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "sdReceived",
      headerName: "Sd Received",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "expectedDeliveryDate",
      headerName: "Expected Delivery Date",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "vendorCountry",
      headerName: "Vendor Country",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "deliveryPort",
      headerName: "Delivery Port",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "apiUniqueId",
      headerName: "Api Unique Id",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "ledgerCard",
      headerName: "Ledger Card",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "orderReference",
      headerName: "Order Reference",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "dateExported",
      headerName: "Date Exported",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "poDate",
      headerName: "Po Date",
      width: 230,
      type: "string",
      filterable: false,
    },
    {
      field: "processedDate",
      headerName: "Processed Date",
      width: 230,
      type: "string",
      filterable: false,
    },
  ]


  const useNotProcessedDanaosRows = () =>
    useQuery(GET_PURCHASE_ORDER_REQUESTS_DANAOS,
      {
        variables: {
          limit: rowsPerPageNotProcessed,
          page: pageNotProcessed,
          isProcessed: false,
          shipOwnerId: selectedShipOwner && selectedShipOwner.id ? parseInt(selectedShipOwner.id) : -1
        },
        skip: radioGroupValue === RadioGroupSelectionValuesEnum.PROCESSED,
        onError(err) {
          toast.configure();
          toast.error(err.message);
        },
        fetchPolicy: "network-only",
      }
    );

  const useProcessedDanaosRows = () =>
    useQuery(GET_PURCHASE_ORDER_REQUESTS_DANAOS_BY_SHIPOWNER_ID_PROCESSED,
      {
        variables: {
          limit: rowsPerPageProcessed,
          page: pageProcessed,
          isProcessed: true,
          shipOwnerId: selectedShipOwner && selectedShipOwner.id ? parseInt(selectedShipOwner.id) : -1
        },
        skip: radioGroupValue === RadioGroupSelectionValuesEnum.NOTPROCESSED,
        onError(err) {
          toast.configure();
          toast.error(err.message);
        },
        fetchPolicy: "network-only",
      })



  const useFetchShipOwnersQuery = () =>
    useQuery(GET_SHIPOWNERS_QUERY, {
      variables: {
        limit: 0,
        page: 1,
        searchQuery: "",
      },
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
      fetchPolicy: "network-only",
    });


  const [refreshTable, setRefreshTable] = useState(0);

  ///-------------------------------- Use Queries-----------------------------------------///
  const getUseNotProcessedDanaosRowsRespose = useNotProcessedDanaosRows();
  const getShipOwnersQuery = useFetchShipOwnersQuery();
  const getUseProcessedDanaosRowsRespose = useProcessedDanaosRows();

  ///--------------------------------- Hooks -------------------------------------------///
  useEffect(() => {
    if (
      getUseNotProcessedDanaosRowsRespose &&
      getUseNotProcessedDanaosRowsRespose.data
    ) {
      const rowsArrayfill: GridRowsPropNotProcessed[] = [];
      if (getUseNotProcessedDanaosRowsRespose.data.getDanaosRowsbyShipOwnerId.rows) {
        for (const row of getUseNotProcessedDanaosRowsRespose.data.getDanaosRowsbyShipOwnerId.rows) {
          rowsArrayfill.push({
            id: row.id,
            vesselCode: row.vesselCode,
            department: row.department,
            documentCode: row.documentCode,
            documentNo: row.documentNo,
            itemSerialNo: row.itemSerialNo,
            parcial: row.parcial,
            exportSequence: row.exportSequence,
            recordStatus: row.recordStatus,
            syncDate: moment(row.syncDate).format('DD/MM/YYYY'),
            entryType: row.entryType,
            imoNumber: row.imoNumber,
            vesselName: row.vesselName,
            poNumber: row.poNumber,
            poApprovedDate: moment(row.poApprovedDate).format('DD/MM/YYYY'),
            vendorName: row.vendorName,
            vendorEmail: row.vendorEmail,
            vendorPhone: row.vendorPhone,
            materialCategory: row.materialCategory,
            equipmentName: row.equipmentName,
            modelName: row.modelName,
            makerName: row.makerName,
            equipmentSerialNo: row.equipmentSerialNo,
            subEquipmentName: row.subEquipmentName,
            partNo: row.partNo,
            itemShortDescription: row.itemShortDescription,
            itemLongDescription: row.itemLongDescription,
            qty: row.qty,
            uom: row.uom,
            vendorHeaderRemarks: row.vendorHeaderRemarks,
            mdRequired: row.mdRequired,
            sdReceived: row.sdReceived,
            expectedDeliveryDate: moment(row.expectedDeliveryDate).format('DD/MM/YYYY'),
            vendorCountry: row.vendorCountry,
            deliveryPort: row.deliveryPort,
            apiUniqueId: row.apiUniqueId,
            ledgerCard: row.ledgerCard,
            orderReference: row.orderReference,
            dateExported: moment(row.dateExported).format('DD/MM/YYYY'),
            poDate: moment(row.poDate).format('DD/MM/YYYY'),
            processedDate: moment(row.processedDate).format('DD/MM/YYYY')
          });
        }
        setRowsArrayNotProcessed(rowsArrayfill);
        setTotalPageNotProcessedRows(getUseNotProcessedDanaosRowsRespose.data.getDanaosRowsbyShipOwnerId.totalRows);
      }
    }
  }, [getUseNotProcessedDanaosRowsRespose.data]);

  useEffect(() => {
    if (getShipOwnersQuery.data) {

      const shipOwnersArray: ShipOwnerDTO[] = [];
      interface ShipOwnerDTO {
        id?: any;
        name?: any;
      }
      getShipOwnersQuery.data?.fetchShipOwners.ShipOwners.forEach((row: any) => {
        shipOwnersArray.push(row);
      });
      shipOwnersArray.push({ id: 0, name: 'All' });
      setAvailableShipOwners(
        shipOwnersArray
      );
      setSelectedShipOwner(shipOwnersArray[0]);
    }
  }, [getShipOwnersQuery.data]);

  useEffect(() => {
    if (
      availableShipOwners != undefined &&
      availableShipOwners !== null &&
      availableShipOwners.length > 0
    ) {
      if (availableShipOwners.length == 1) {
        setSelectedShipOwner(availableShipOwners[0]);
      } else {
        const shipOwnerFromStorage =
          sessionStorage.getItem(SHIP_OWNER_VALUE_KEY);
        if (shipOwnerFromStorage) {
          const shipOwnerId = JSON.parse(shipOwnerFromStorage);
          if (shipOwnerId) {
            const findSelectedShipOwner = availableShipOwners.find(
              (x) => x.id === shipOwnerId
            );
            if (findSelectedShipOwner) {
              setSelectedShipOwner(findSelectedShipOwner);
            }
          }
        }
      }
    }
  }, [availableShipOwners]);

  useEffect(() => {
    if (getUseProcessedDanaosRowsRespose && getUseProcessedDanaosRowsRespose.data) {
      const rowsArrayfill: GridRowsPropProcessed[] = [];
      if (getUseProcessedDanaosRowsRespose.data.getDanaosRowsbyShipOwnerIdProcessed.rows) {
        for (const row of getUseProcessedDanaosRowsRespose.data.getDanaosRowsbyShipOwnerIdProcessed.rows) {
          rowsArrayfill.push({
            batchDownloadId: row.batchDownloadId,
            shipOwnerName: row.shipOwnerName,
            batchDownloadDate: moment(row.batchDownloadDate).format('DD/MM/YYYY')
          }
          );
        }
        setTotalPageProcessedRows(getUseProcessedDanaosRowsRespose.data.getDanaosRowsbyShipOwnerIdProcessed.totalRows)
        setRowsArrayProcessed(rowsArrayfill);
      }
    }

  }, [getUseProcessedDanaosRowsRespose.data])

  const handleDownload = async () => {
    if (selectedShipOwner != undefined) {
      const response = downloadExcelFileQuery(vendors, parseInt(selectedShipOwner.id != undefined ? selectedShipOwner.id : "0"), token);
      response.then((dt: any) => {
        const url = window.URL.createObjectURL(new Blob([dt.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "orders.xlsx"); //or any other extension 
        document.body.appendChild(link);
        link.click();
        enqueueSnackbar(`Download Successfull`, { variant: "success" });
      });
      getUseNotProcessedDanaosRowsRespose.refetch();
      setRefreshTable(refreshTable + 1);
    }
  }

  const handleDownloadAll = async () => {
    if (selectedShipOwner != undefined) {
      const response = downloadAllExcelFileQuery(parseInt(selectedShipOwner.id != undefined ? selectedShipOwner.id : "0"), token);
      response.then((dt: any) => {
        const url = window.URL.createObjectURL(new Blob([dt.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ordersAll.xlsx"); //or any other extension 
        document.body.appendChild(link);
        link.click();
        enqueueSnackbar(`Download Successfull`, { variant: "success" });
      });
      getUseNotProcessedDanaosRowsRespose.refetch();
      setRefreshTable(refreshTable + 1);
    }
  }

  const handleChangeProcessedOrNot = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setRadioGroupValue(parseInt(val));
  }

  const handleShipOwnerInput = (event: SelectChangeEvent) => {
    setSelectedShipOwner(
      availableShipOwners.filter(
        (soObject: ShipOwnerDTO) =>
          soObject.id === (event.target.value as string)
      )[0]
    );
  };


  return (
    <Page title="Purchase Order From Danaos">

      <Container maxWidth={"xl"} sx={{ p: 0 }}>
        <Container maxWidth={"xl"} sx={{ p: 0 }}>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <HeaderBreadcrumbs
              heading="Manage Orders"
              links={
                [
                  { name: "Dashboard" },
                  { name: "Purchased Orders" },
                  { name: "Danaos" },
                ]
              }
            />
            <Icon
              width={45}
              height={45}
              icon={arrowCircleLeftOutline}
              className={classesNew.navigationIcon}
              onClick={() => navigate("/dashboard/purchasedOrders/list")}
            />
          </Stack>
          <div>
            <Box sx={{ width: "100%", marginTop: 2, marginBottom: 2 }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ color: "text.primary" }}
                >
                  Select Ship Owner
                </InputLabel>
                <Select
                  variant="standard"
                  labelId="select-ship-owner-label"
                  id="select-ship-owner"
                  label="Ship Owners"

                  value={
                    selectedShipOwner != undefined ? selectedShipOwner.id : ""
                  }
                  onChange={handleShipOwnerInput}
                >
                  {getShipOwnersQuery.loading ? (
                    <LinearProgress
                      color="primary"
                      sx={{
                        minHeight: "5px",
                        mt: 0.1,
                      }}
                    />
                  ) : (
                    availableShipOwners?.map(
                      (shipOwnerObject: ShipOwnerDTO, index: number) => (
                        <MenuItem key={index} value={shipOwnerObject.id}>
                          {shipOwnerObject.name}
                        </MenuItem>
                      )
                    )
                  )}
                </Select>

              </FormControl>





              <Box
                sx={{
                  height: 550,
                  width: 1,
                  "& .super-app-theme--2": {
                    bgcolor: () => "rgba(91, 229, 132, 0.15)",
                  },
                  "& .super-app-theme--1": {
                    bgcolor: () => "rgba(255, 244, 165, 0.5)",
                  },
                }}
              >

                <Stack direction={"row"} justifyContent="flex-end">

                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={radioGroupValue}
                    onChange={handleChangeProcessedOrNot}
                    sx={{ mr: 1, mt: 7, pb: 3 }}
                  >
                    <FormControlLabel
                      value={RadioGroupSelectionValuesEnum.NOTPROCESSED}
                      control={<Radio />}
                      label="Not Processed"
                    />
                    <FormControlLabel
                      value={RadioGroupSelectionValuesEnum.PROCESSED}
                      control={<Radio />}
                      label="Processed"
                    />

                  </RadioGroup>




                  <Button
                    variant="contained"

                    disabled={selectedShipOwner?.name === "All" || radioGroupValue == 1 ? true : false}
                    onClick={handleDownloadAll}
                    sx={{ mr: 1, mt: 5 }}
                  >
                    Download All
                  </Button>
                </Stack>
                {
                  radioGroupValue == 0 ?
                    <DataGrid
                      columns={TABLE_COLUMNS}
                      rows={rowsArrayNotProcessed != undefined ? rowsArrayNotProcessed : []}
                      disableSelectionOnClick

                      components={{
                        Toolbar: GridToolbar,
                        FilterPanel: GridFilterPanel,
                      }}
                      className={classesNew.customStyle}
                      // // selection props
                      onSelectionModelChange={(newSelectionModel: GridRowId[]) => {
                        setSelectionModel(newSelectionModel);
                        if (newSelectionModel.length === 1) {
                          const selectedRow = +newSelectionModel[0];

                        }
                      }}
                      getRowId={(row: any) => row.id}
                      // filtering props
                      filterMode="server"
                      // sorting props
                      sortingMode="server"
                      // pagination props
                      pagination
                      paginationMode="server"
                      rowCount={
                        totalPageNotProcessedRows
                      }
                      page={pageNotProcessed}
                      rowsPerPageOptions={[20, 50, 100]}
                      pageSize={rowsPerPageNotProcessed}
                      onPageSizeChange={(pageSize) => setRowsPerPageNotProcessed(pageSize)}

                      onPageChange={(pg) => setpageNotProcessed(pg)}
                      loading={getUseNotProcessedDanaosRowsRespose.loading}
                    /> : <DataGrid
                      columns={TABLE_COLUMNS_PROCESSED}
                      rows={rowsArrayProcessed != undefined ? rowsArrayProcessed : []}
                      disableSelectionOnClick

                      components={{

                        FilterPanel: GridFilterPanel,
                      }}
                      className={classesNew.customStyle}
                      // // selection props
                      onSelectionModelChange={(newSelectionModel: GridRowId[]) => {
                        setSelectionModel(newSelectionModel);
                        if (newSelectionModel.length === 1) {
                          const selectedRow = +newSelectionModel[0];

                        }
                      }}
                      getRowId={(row: any) => row.batchDownloadId}
                      // filtering props
                      filterMode="server"
                      // sorting props
                      sortingMode="server"
                      // pagination props
                      pagination
                      paginationMode="server"
                      rowCount={
                        totalPageProcessedRows
                      }
                      page={pageProcessed}
                      rowsPerPageOptions={[20, 50, 100]}
                      pageSize={rowsPerPageProcessed}
                      onPageSizeChange={(pageSize) => setRowsPerPageProcessed(pageSize)}

                      onPageChange={(pg) => setpageProcessed(pg)}
                      loading={getUseProcessedDanaosRowsRespose.loading}
                    />
                }

              </Box>

            </Box>
          </div>
        </Container>
      </Container>
    </Page>
  );
};

export default DanaosRequests;

function enqueueSnackbar(arg0: string, arg1: { variant: string; }) {
  throw new Error("Function not implemented.");
}
