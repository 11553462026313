import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, BoxProps } from "@mui/material";
import { makeStyles } from "@mui/styles";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  height: "100%",
  overflow: "hidden",
}));

const useStyles = makeStyles(() => ({
  mySimpleBar: {
    maxHeight: "100%",
    "& .simplebar-scrollbar": {
      "&:before": {
        backgroundColor: alpha("#39464f", 0.48),
      },
      "&.simplebar-visible:before": {
        opacity: 1,
      },
    },
    "& .simplebar-track.simplebar-vertical": {
      width: 10,
    },
    "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar": {
      height: 6,
    },
    "& .simplebar-mask": {
      zIndex: "inherit",
    },
  },
}));

// ----------------------------------------------------------------------

export default function Scrollbar({ children, sx, ...other }: BoxProps) {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const classesNew = useStyles();

  if (isMobile) {
    return (
      <Box sx={{ overflowX: "auto", ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <RootStyle>
      <SimpleBar className={classesNew.mySimpleBar}>{children}</SimpleBar>
    </RootStyle>
  );
}
