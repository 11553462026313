import { Box, Button, Card, Dialog, Divider, Grid, Stack, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from "react";
import Scrollbar from "../../../Scrollbar";
import {  GET_SUPPLIER_DETAILS_BY_ID_QUERY } from "../../../../graphQL/Queries";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { GridRowModel, GridValidRowModel } from "@mui/x-data-grid-pro";
import { Form, FormikProvider, useFormik } from "formik";
import {  SupplierDetailsDTO } from "../../../../@types/Supplier";
import * as Yup from "yup";
import moment from "moment";
import { MD_DOC_MUTATION } from "../../../../graphQL/Mutations";

import { LoadingButton } from "@mui/lab";
import { BasketOfItems } from "../../../../@types/SupplierInventory";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh'
    },

}));

const getSupplierInfoQuery = (
    supplierId: number
) =>
    useQuery(GET_SUPPLIER_DETAILS_BY_ID_QUERY, {
        variables: {
            id: supplierId
        },
        skip: !supplierId,
        onError(err) {
            toast.configure();
            toast.error(err.message);
        },
        fetchPolicy: "network-only",
    });







type DocumentInformationProps = {
    openModal: boolean;
    onClosePreview: VoidFunction;
    supplierId: number;
    basketOfItems: BasketOfItems[];
    setDataToPrint: Dispatch<SetStateAction<BasketOfItems[]>>;
    setDataTableRowsOfBasket: React.Dispatch<React.SetStateAction<GridValidRowModel[]>>;
    dataTableRefCurren: MutableRefObject<GridRowModel[]>;
};



export default function DocumentInformationModal(
    {
        openModal,
        onClosePreview,
        supplierId,
        basketOfItems,
        setDataToPrint,
        setDataTableRowsOfBasket,
        dataTableRefCurren
    }: DocumentInformationProps
) {
    
    const classes = useStyles();
    const [dateofDeliveryValue, setDateofDeliveryValue] = useState(new Date());
    const [dateofSignatureValue, setDateofSignatureValue] = useState(new Date());
    //useQuery
    const getSupplierInfoQueryResponse = getSupplierInfoQuery(supplierId);
    const [dateofDelivery, setDateofDelivery] = useState("");
    const [dateofSignature, setDateofSignature] = useState("");


    const [supplier, setSupplier] = useState<SupplierDetailsDTO>();

    const [MDMutation, MDMutationProps] = useMutation(
        MD_DOC_MUTATION,
        {
            fetchPolicy: "network-only",
        }
    );


    const NewVesselSchema = Yup.object().shape({
        companyName: Yup.string(),
        divisionName: Yup.string(),
        address: Yup.string(),
        telephoneNo: Yup.string(),
        faxNumber: Yup.string(),
        emailAddress: Yup.string().email(),
        nameOfEmployee: Yup.string(),

    });


const [loading, setLoading] = useState<boolean>(false);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            
            remark1: "",
            remark2: "",
            remark3: "",
            companyName: supplier?.name || "",
            divisionName: supplier?.divisionName || "",
            address: supplier?.address || "",
            telephoneNo: supplier?.phoneNumber || "",
            faxNumber: supplier?.faxNumber || "",
            emailAddress: supplier?.emailAddress || "",
            signedForAndOnBehalfOf: supplier?.name || "",
            signatureDate: "",
            nameOfEmployee: supplier?.nameOfEmployee ||"",
            function: supplier?.function || "",
            contactPerson: supplier?.username || "",
            
            signature: supplier?.imageOfSignature || "",
        },
        validationSchema: NewVesselSchema,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                setLoading(true);
                // CASE UPDATE
                const sendMdObject = {

                    date: dateofDelivery,
                    
                    remark1: values.remark1,
                    remark2: values.remark2,
                    remark3: values.remark3,
                    companyName: values.companyName,
                    divisionName: values.divisionName,
                    address: values.address,
                    telephoneNo: values.telephoneNo,
                    faxNumber: values.faxNumber,
                    emailAddress: values.emailAddress,
                    contactPerson: values.contactPerson,
                    
                    signedForAndOnBehalfOf: values.companyName,
                    signatureDate: dateofDelivery,
                    name: values.nameOfEmployee,
                    function: values.function,
                    
                    signature: values.signature,
                    basketOfItems: basketOfItems
                };

                

                const useUpdateVesselMutation = async () => {
                     
                    
                    const resultPdf = await MDMutation({
                        variables: {
                            sendMdInput: sendMdObject,
                            supplierId: supplierId
                        },
                        onCompleted() {
                            //resetForm();
                            setDataToPrint([]);
                            setDataTableRowsOfBasket([]);
                            setSubmitting(false);
                            setLoading(false);
                            dataTableRefCurren.current=[];
                            onClosePreview();
                            // enqueueSnackbar(`Vessel ${values.name} updated successfully!`,
                            //    { variant: "success" }
                            //  );
                            //navigate("/dashboard/vessels/dashboard");
                        },
                        onError(err) {
                            toast.configure();
                            toast.error(`Error: ${err.message}`);
                        },
                    });
                    
                    const bin = atob(resultPdf.data.mdToPrint.file);
                    

                    // Insert a link that allows the user to download the PDF file
                    const link = document.createElement('a');
                    link.innerHTML = 'Download PDF file';
                    link.download = resultPdf.data.mdToPrint.name+'.pdf';
                    link.href = 'data:application/octet-stream;base64,' + resultPdf.data.mdToPrint.file;
                    //document.body.appendChild(link);
                    link.click();
                };
                useUpdateVesselMutation();





            } catch (error) {
                console.error(error);
                setSubmitting(false);
                toast.configure();
                toast.error(`Error: ${error}`);
            }
        },
    });

    const {
        errors,
        values,
        touched,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        getFieldProps,
    } = formik;


    useEffect(() => {
        if (getSupplierInfoQueryResponse.data) {
            setSupplier(getSupplierInfoQueryResponse.data.getSupplierForSupplierPortal);

        }
    }, [getSupplierInfoQueryResponse.data]);

    useEffect(() => {
        if (dateofDeliveryValue) {
            const theDate = new Date(dateofDeliveryValue);
            const formattedDate = moment(theDate).format("YYYY-MM-DD");
            setDateofDelivery(formattedDate);
        }
    }, [dateofDeliveryValue]);

    useEffect(() => {
        if (dateofSignatureValue) {
            const theDate = new Date(dateofSignatureValue);
            const formattedDate = moment(theDate).format("YYYY-MM-DD");
            setDateofSignature(formattedDate);
        }
    }, [dateofSignatureValue]);



    return (
        <Dialog sx={{ overflow: 'scroll', }} open={openModal} onClose={onClosePreview}>
            <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            pr: 10,
                            pl: 10,
                            direction: 'column',
                            flexDirection: 'column',
                            display: "flex",
                            alignItems: 'center',
                            mt: 1,
                            width: 1,
                            "& .super-app-theme--2": {
                                bgcolor: () => "rgba(91, 229, 132, 0.15)",
                            },
                            "& .super-app-theme--1": {
                                bgcolor: () => "rgba(255, 244, 165, 0.5)",
                            },
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Date"
                                value={dateofDeliveryValue}
                                onChange={(dateVal: any) => {
                                    if (dateVal) {
                                        setDateofDeliveryValue(new Date(dateVal));
                                    }
                                }}
                                inputFormat="dd/MM/yyyy"
                                renderInput={(params: any) => (
                                    <TextField
                                        sx={{ mt: 1, width: "500px" }}
                                        fullWidth
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: "dd/mm/aaaa",
                                        }}
                                    />
                                )}

                            />
                        </LocalizationProvider>

                       
                        <TextField
                            sx={{ mt: 1, width: "500px" }}
                            label="Remark 1"
                            variant="standard"
                            {...getFieldProps("remark1")}
                        />
                        <TextField
                            sx={{ mt: 1, width: "500px" }}
                            label="Remark 2"
                            variant="standard"
                            {...getFieldProps("remark2")}
                        />
                        <TextField
                            sx={{ mt: 1, width: "500px" }}
                            label="Remark 3"
                            variant="standard"
                            {...getFieldProps("remark3")}
                        />

                    </Box>


                    <Divider sx={{ mt: 2 }}></Divider>


                    <Box
                        sx={{
                            pr: 10,
                            pl: 10,
                            direction: 'column',
                            flexDirection: 'column',
                            display: "flex",
                            alignItems: 'center',
                            mt: 1,
                            width: 1,
                            "& .super-app-theme--2": {
                                bgcolor: () => "rgba(91, 229, 132, 0.15)",
                            },
                            "& .super-app-theme--1": {
                                bgcolor: () => "rgba(255, 244, 165, 0.5)",
                            },
                        }}
                    >
                        <TextField
                            sx={{ mt: 1, width: "500px" }}
                            label="Company Name"
                            variant="standard"
                            {...getFieldProps("companyName")}
                            value={values?.companyName}

                        />
                        <TextField
                            sx={{ mt: 1, width: "500px" }}
                            label="Division Name"
                            {...getFieldProps("divisionName")}
                            value={values?.divisionName}
                            variant="standard"
                        />
                        <TextField
                            sx={{ mt: 1, width: "500px" }}
                            label="Address"
                            variant="standard"
                            {...getFieldProps("address")}
                            value={values?.address}
                        />
                        <TextField
                            sx={{ mt: 1, width: "500px" }}
                            label="Contact Person"
                            variant="standard"
                            {...getFieldProps("contactPerson")}
                        />
                        <TextField
                            sx={{ mt: 1, width: "500px" }}
                            label="Telephone No"
                            variant="standard"
                            {...getFieldProps("telephoneNo")}
                            value={values?.telephoneNo}
                        />
                        <TextField
                            sx={{ mt: 1, width: "500px" }}
                            label="Fax Number"
                            variant="standard"
                            {...getFieldProps("faxNumber")}
                            value={values?.faxNumber}
                        />
                        <TextField
                            sx={{ mt: 1, width: "500px" }}
                            label="Email Address"
                            variant="standard"
                            {...getFieldProps("emailAddress")}
                            value={values?.emailAddress}
                        />
                        
                    </Box>

                    <Divider sx={{ mt: 2 }}></Divider>

                    <Box
                        sx={{
                            pr: 10,
                            pl: 10,
                            direction: 'column',
                            flexDirection: 'column',
                            display: "flex",
                            alignItems: 'center',
                            mt: 1,
                            mb: 2,
                            width: 1,
                            "& .super-app-theme--2": {
                                bgcolor: () => "rgba(91, 229, 132, 0.15)",
                            },
                            "& .super-app-theme--1": {
                                bgcolor: () => "rgba(255, 244, 165, 0.5)",
                            },
                        }}
                    >
                        <TextField
                            sx={{ mt: 1, width: "500px" }}
                            label="Signed for and on behalf of"
                            variant="standard"
                            {...getFieldProps("signedForAndOnBehalfOf")}
                            value={values?.companyName}
                        />


                        <TextField
                            sx={{ mt: 1, width: "500px" }}
                            label="Name"
                            variant="standard"
                            {...getFieldProps("nameOfEmployee")}
                            value={values?.nameOfEmployee}
                        />
                        <TextField
                            sx={{ mt: 1, width: "500px" }}
                            label="Function"
                            variant="standard"
                            {...getFieldProps("function")}
                            value={values?.function}
                        />
                    </Box>


                    <Stack
                        direction="row"

                        sx={{ alignItems: "center", justifyContent: "space-between" }}
                    >
                        <Button sx={{ mb: 1, ml: 1 }} variant="contained" onClick={onClosePreview}>
                            Cancel
                        </Button>
                        <LoadingButton 
                            sx={{ mb: 1, mr: 1 }} 
                            variant="contained"
                            type="submit"
                            loading={loading}
                        >
                            Print
                        </LoadingButton> 
                    </Stack>
                </Form>
            </FormikProvider>
        </Dialog>
    );
}


