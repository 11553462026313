import { Link as RouterLink } from "react-router-dom";
// material
import { useQuery } from "@apollo/client";
import {
  Box,
  Card,
  CardHeader,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MyNotificationDTO } from "../../@types/Notification";
import {
  COUNT_MY_NOTIFICATIONS_QUERY,
  GET_USER_NOTIFICATIONS_QUERY,
} from "../../graphQL/Queries";
import Scrollbar from "../Scrollbar";
import { TableListHead } from "../table";

type MyNotificationProps = {
  notification: MyNotificationDTO;
};
// ----------------------------------------------------------------------
function MyNotification({ notification }: MyNotificationProps) {
  const { what, text } = notification;

  return (
    <Stack direction="row" spacing={2}>
      <Box sx={{ flexGrow: 1, minWidth: 200 }}>
        <Link
          component={RouterLink}
          to="#"
          sx={{ color: "text.primary", typography: "subtitle2" }}
          underline="hover"
        >
          {what}
        </Link>

        <Typography variant="body2" sx={{ color: "text.primary" }}>
          {text}
        </Typography>
      </Box>
    </Stack>
  );
}

// ----------------------------------------------------------------------
// Define Queries
const getNotificationsQuery = (page: number, limit: number) =>
  useQuery(GET_USER_NOTIFICATIONS_QUERY, {
    variables: {
      page: page,
      limit: limit,
      markAsRead: false,
      readOnlyNotSaw: false,
    },
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

const countMyNotificationsQuery = () =>
  useQuery(COUNT_MY_NOTIFICATIONS_QUERY, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

export default function MyNotificationsCard() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [notifications, setNotifications] = useState<MyNotificationDTO[]>([]);

  const getNotificationsQueryResults = getNotificationsQuery(page, rowsPerPage);
  const useCountMyNotificationsQueryResponse = countMyNotificationsQuery();

  useEffect(() => {
    if (
      getNotificationsQueryResults.data &&
      getNotificationsQueryResults.data.myNotifications
    ) {
      setNotifications(getNotificationsQueryResults.data.myNotifications);
    }
  }, [getNotificationsQueryResults.data]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  return (
    <Card
      sx={{
        height: "350px",
        width: "30%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardHeader title="You Are Interested in" />
      <Scrollbar>
        <TableContainer>
          <Table>
            <TableListHead headLabel={[]} />
            <TableBody>
              {notifications.map((notification: MyNotificationDTO) => (
                <TableRow key={`tr-${notification.id}`}>
                  <TableCell key={`tc-${notification.id}`}>
                    <MyNotification
                      key={notification.id}
                      notification={notification}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        sx={{ height: "30%" }}
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={useCountMyNotificationsQueryResponse?.data?.id}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, pg) => setPage(pg)}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
