import { useMutation, useQuery } from "@apollo/client";
import { Box, Button, Card,  } from "@mui/material";
import { DataGrid, GridColumns, GridFilterModel, GridFilterPanel, GridRowId, GridRowModel, GridSelectionModel, GridSortModel, GridToolbar } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { GET_IHM_STORED_ITEMS_WITH_TOTAL_NUMBER_OF_ROWS } from "../../../graphQL/Queries";
import { IHMDTO } from "../../../@types/IHM";
import { makeStyles } from "@mui/styles";
import { FilterTypeDTO, OperationValue } from "../../../@types/DataGrid";
import { REMOVE_IHM_ITEMS_AND_CASCADE_MUTATION } from "../../../graphQL/Mutations";
import ConfirmDeleteDialog from "../../../components/modal/ConfirmDeleteDialog";



const useStyles = makeStyles(() => ({
  customStyle: {
    "& .MuiDataGrid-toolbarContainer": {
      background: "#ffffff",
    },
  },
  panel: {
    "& .MuiNativeSelect-select": {
      backgroundColor: "red",
      color: "red",
    },
  },
  glowAnimation: {
    animation: "$glowing 1800ms infinite",
  },
  "@keyframes glowing": {
    "0%": {
      backgroundColor: "#0079C1",
      boxShadow: "0 0 5px #0079C1",
    },
    "50%": {
      backgroundColor: "#39464f",
      boxShadow: "0 0 20px #39464f",
    },
    "100%": {
      backgroundColor: "#0079C1",
      boxShadow: "0 0 5px #0079C1",
    },
  },
  uploadbtn: {
    "&.MuiLoadingButton-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
      minWidth: "unset",
      padding: "0",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
}));


const useGetIhmsItemsQuery = (
  shipOwnerId: number,
  vesselId: number,
  limit: number,
  page: number,
  filtersArray: FilterTypeDTO[],
  sortField: string | undefined,
  sortOrder: string | undefined,
) => useQuery(GET_IHM_STORED_ITEMS_WITH_TOTAL_NUMBER_OF_ROWS, {
  variables: {
    searchToken: '',
    shipOwnerId: shipOwnerId,
    vesselId: vesselId,
    limit: limit,
    page: page,
    filtersArray: filtersArray,
    sortingOrder: {
      field: sortField,
      sort: sortOrder,
    },
  },
  skip: shipOwnerId == undefined || shipOwnerId == 0 || vesselId == 0 || vesselId == undefined,
  onError(err) {
    toast.configure();
    toast.error(err.message);
  },
  fetchPolicy: "network-only",
}
);


type ManageIHMsItemsProps = {
  shipOwnerId?: number;
  selectedVesselId?: number;
  isPublic?: boolean;
};



const ManageIHMsItems = ({
  shipOwnerId,
  selectedVesselId,
  isPublic,
}: ManageIHMsItemsProps) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  // datagrid filtering
  const [filtersArray, setFiltersArray] = useState<FilterTypeDTO[]>([]);
  // dataGrid sorting
  const [sortField, setSortField] = useState<string>("referenceNumber");
  const [sortOrder, setSortOrder] = useState<string>("asc");

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const ihmItems = useGetIhmsItemsQuery(shipOwnerId ? shipOwnerId : 0, selectedVesselId ? selectedVesselId : 0,
    rowsPerPage,
    page,
    filtersArray,
    sortField,
    sortOrder,);
  const [totalRows, setTotalRows] = useState<number>();
  const [vesselIhmItemResponseRows, setVesselIhmItemResponseRows] = useState<IHMDTO[]>();

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const [selectedDataTableRow, setSelectedDataTableRow] = useState<
    GridRowModel | undefined
  >(undefined);



  const [RemoveIhmItemMutation, RemoveIhmItemMutationProps] = useMutation(
    REMOVE_IHM_ITEMS_AND_CASCADE_MUTATION,
    {
      fetchPolicy: "network-only",
    }
  );

  const removeIhmItemMutation = (ihmIds: number[]) => {
    RemoveIhmItemMutation({
      variables: {
        ihmItemIds: ihmIds ? ihmIds : 0,
      },
      refetchQueries: [GET_IHM_STORED_ITEMS_WITH_TOTAL_NUMBER_OF_ROWS],
      onCompleted() {
        toast.configure();
        toast.success(`Succesfully Deleted`);
      },
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err}`);
      },
    });
  };







  const classesNew = useStyles();
  const TABLE_COLUMNS: GridColumns = [
    {
      field: "ihmId",
      headerName: "Id",
      width: 180,
      type: "string",
      filterable: false,
      hide: true,
    },
    {
      field: "vesselImo",
      headerName: "Vessel IMO",
      width: 180,
      type: "string",
      filterable: true,
      sortable: true,
    },
    {
      field: "equipmentManufacturer",
      headerName: "Equipment Manufacturer",
      width: 230,
      filterable: true,
      sortable: true,

    },
    {
      field: "equipmentDescription",
      headerName: "Equipment Description",
      width: 230,
      filterable: true,
      sortable: true,

    },
    {
      field: "productName",
      headerName: "Product Name",
      width: 180,
      type: "string",
      filterable: true,
      sortable: true,
    },
    {
      field: "referenceNumber",
      headerName: "Reference Number",
      width: 180,
      type: "string",
      filterable: true,
      sortable: true,
    },
    {
      field: "pendingItemStatus",
      headerName: "In Progress",
      width: 180,
      type: "string",
      filterable: false,
      sortable: false,
    }
  ];

  useEffect(() => {
    if (ihmItems) {
      if (ihmItems.data != undefined) {
        if (ihmItems.data?.findIhmItemsAndTotalNumberOfRows != undefined) {
          setVesselIhmItemResponseRows(ihmItems.data?.findIhmItemsAndTotalNumberOfRows.vesselIhmItemResponseRows);
          setTotalRows(ihmItems.data?.findIhmItemsAndTotalNumberOfRows.totalRows);

        }
      }


    }

  }, [ihmItems.data]);





  // filtering handler
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    const properFilterArray: FilterTypeDTO[] = [];

    filterModel.items.forEach((filterObject) => {
      let opVal = OperationValue.UNKNOWN;
      if (
        filterObject.columnField != undefined &&
        filterObject.operatorValue != undefined &&
        filterObject.value != undefined
      ) {

        switch (filterObject.operatorValue) {
          case "contains":
            opVal = OperationValue.CONTAINS;
            break;
          case "equals":
            opVal = OperationValue.EQUALS;
            break;
          case "startsWith":
            opVal = OperationValue.STARTS_WITH;
            break;
          case "endsWith":
            opVal = OperationValue.ENDS_WITH;
            break;
          case "isEmpty":
            opVal = OperationValue.IS_EMPTY;
            break;
          case "isNotEmpty":
            opVal = OperationValue.IS_NOT_EMPTY;
            break;
          default:
            break;
        }
        const properFilter: FilterTypeDTO = {
          columnField: filterObject.columnField,
          operatorValue: opVal,
          value: filterObject.value,
        };
        properFilterArray.push(properFilter);
      } else if (filterObject.operatorValue == "isEmpty" || filterObject.operatorValue == "isNotEmpty") {
        switch (filterObject.operatorValue) {
          case "isEmpty":
            opVal = OperationValue.IS_EMPTY;
            break;
          case "isNotEmpty":
            opVal = OperationValue.IS_NOT_EMPTY;
            break;
          default:
            break;
        }
        const properFilter: FilterTypeDTO = {
          columnField: filterObject.columnField,
          operatorValue: opVal,
          value: opVal == 4 || opVal == 5 ? '' : filterObject.value,
        };
        properFilterArray.push(properFilter);
      }
    });
    if (properFilterArray.length > 0) {
      setFiltersArray(properFilterArray);
    }
  }, []);


  // sorting handler
  const handleSortModelChange = (newModel: GridSortModel) => {
    if (
      newModel !== null &&
      newModel != undefined &&
      newModel.length == 1 &&
      newModel[0].sort &&
      newModel[0].field
    ) {
      setSortField(newModel[0].field);
      setSortOrder(newModel[0].sort?.toString());
    }
  };

  const handleDelete = () => {

    const convertIhmIds: Array<number> = [];
    for (const selectedItem of selectionModel) {
      const id = parseInt((JSON.stringify(selectedItem)));
      convertIhmIds.push(id);
    }

    removeIhmItemMutation(convertIhmIds);
    setOpenDeleteDialog(false);
  };

  const handleClosePreview = () => {

    setOpenDeleteDialog(false);
  };

  const onDelete = () => {
    setOpenDeleteDialog(true);
  };

  return (

    <div>
      <Button disabled={selectionModel.length == 0} onClick={onDelete} variant="contained">Delete Selected</Button>
      <ConfirmDeleteDialog
        isOpenPreview={openDeleteDialog}
        onClosePreview={handleClosePreview}
        onDelete={handleDelete}
        canDelete={true}
        dialogMsg={selectionModel.length == 1 ? "You are going to delete " + selectionModel.length + " item. Are you sure?" : "You are going to delete " + selectionModel.length + " items. Are you sure?"}
      />
      <Card sx={{ minHeight: "530px" }}>



        <Box
          sx={{
            height: 550,
            width: 1,
            "& .super-app-theme--2": {
              bgcolor: () => "rgba(91, 229, 132, 0.15)",
            },
            "& .super-app-theme--1": {
              bgcolor: () => "rgba(255, 244, 165, 0.5)",
            },
          }}
        >

          <DataGrid
            columns={TABLE_COLUMNS}
            rows={vesselIhmItemResponseRows != undefined ? vesselIhmItemResponseRows : []}
            disableSelectionOnClick
            checkboxSelection
            components={{
              Toolbar: GridToolbar,
              FilterPanel: GridFilterPanel,
            }}
            className={classesNew.customStyle}
            // selection props
            onSelectionModelChange={(newSelectionModel: GridRowId[]) => {
              setSelectionModel(newSelectionModel);
              if (newSelectionModel.length === 1) {
                const selectedRow = +newSelectionModel[0];
                setSelectedDataTableRow(vesselIhmItemResponseRows != undefined ? vesselIhmItemResponseRows[selectedRow] : []);


              } else {
                setSelectedDataTableRow(undefined);
              }
            }}
            selectionModel={selectionModel}
            // filtering props
            filterMode="server"
            onFilterModelChange={onFilterChange}
            // sorting props
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            // pagination props
            pagination
            paginationMode="server"
            rowCount={totalRows}
            rowsPerPageOptions={[5, 10, 15]}
            pageSize={rowsPerPage}
            onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
            getRowId={(row: any) => row.ihmId}


            onPageChange={(newPage) => {

              setPage(newPage);
            }}

            loading={ihmItems.loading}
          />
        </Box>


      </Card>

    </div>





  );
};

export default ManageIHMsItems;