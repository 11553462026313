import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import trendingUpFill from "@iconify/icons-eva/trending-up-fill";
import trendingDownFill from "@iconify/icons-eva/trending-down-fill";
import arrowCircleRightOutline from "@iconify/icons-eva/arrow-circle-right-outline";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Card,
  Typography,
  Stack,
  LinearProgress,
  Grid,
  Box,
} from "@mui/material";
// utils
import { fNumber, fPercent } from "../../../utils/formatNumber";
import { useNavigate } from "react-router-dom";
//styles
import { makeStyles } from "@mui/styles";

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  width: 24,
  height: 24,
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16),
}));

const useStyles = makeStyles(() => ({
  cardSelected: {
    "&.MuiCard-root": {
      border: "1px solid #39464f",
      backgroundColor: "rgba(57, 70, 79, 0.08)",
    },
  },
  navigationIcon: {
    cursor: "pointer",
    color: "#0079C1",
    transition: "0.5s ease",
    "&:hover": {
      color: "#39464f",
      transition: "0.5s ease",
    },
  },
}));

// ----------------------------------------------------------------------

type StatsCardProps = {
  Title: string;
  Value: string;
  Percent?: number;
  totalMonths?: number;
  isloading?: boolean;
  isFilter?: boolean;
  filterCallback?: (isSelected: boolean) => void;
  navigationUrl?: string;
  sx?: any;
  gridSx?: any;
  isPercent?: boolean;
  fitToContent?: boolean
};

export default function StatsCard({
  Title,
  Value,
  Percent,
  totalMonths,
  isloading,
  isFilter,
  filterCallback,
  navigationUrl,
  sx,
  gridSx,
  isPercent,
  fitToContent,
}: StatsCardProps) {
  const theme = useTheme();
  const classesNew = useStyles();
  const [isSelected, setIsSelected] = useState<boolean | undefined>(false);
  const navigate = useNavigate();
  const [fontSizeValue, setFontSizeValue] = useState<string | undefined>('');

  const cardStyle = sx
    ? sx
    : {
        display: "flex",
        alignItems: "flex-start",
        padding: "16px 24px",
        minHeight: "140px",
        cursor: isFilter ? "pointer" : "default",
      };

  if (
    navigationUrl != undefined &&
    navigationUrl !== null &&
    navigationUrl.length > 0
  ) {
    isFilter = false;
  }

  const selectedHandler = () => {
    if (isFilter) {
      setIsSelected(!isSelected);
      if (filterCallback) {
        filterCallback(!isSelected);
      }
    }
  };

  const navigationHandler = (navigationUrl: string) => {
    navigate(navigationUrl);
  };

  useEffect ( () => {
    if(fitToContent != undefined && fitToContent=== true){
      const lengthOfValue = fNumber(Value).toString().length;
      if (lengthOfValue<=3){
        setFontSizeValue('34px');
      }else if (lengthOfValue>3 && lengthOfValue<=5){
        setFontSizeValue('24px');
      }else if (lengthOfValue>5 && lengthOfValue<=7){
        setFontSizeValue('22px');
      }
    }
  },[Value]);

  return (
    <Grid item xs={12} md={gridSx ? 4 : 6} sx={gridSx ? gridSx : {}}>
      <Card
        sx={cardStyle}
        onClick={() => selectedHandler()}
        className={isSelected ? classesNew.cardSelected : ""}
      >
        <Box sx={{ flexGrow: 1 }}>
          {isloading && <LinearProgress color="primary" sx={{ pt: -1 }} />}
          <Typography variant="subtitle2" sx={{ fontSize: "18px" }}>
            {Title}
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            {
              (fitToContent != undefined && fitToContent=== true) ? <Typography variant="subtitle2"  sx={{ fontSize: fontSizeValue }}>{fNumber(Value)}</Typography> : <Typography variant="h2"  sx={{ fontSize: fontSizeValue }}>{fNumber(Value)}</Typography>
            }
            
            <Typography variant="h2" >{isPercent && "%"}</Typography>
            {Percent && Percent > 0 ? (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ mt: 1, mb: 1 }}
              >
                {totalMonths != -1 ? (
                  <>
                    <IconWrapperStyle
                      sx={{
                        ...(Percent < 0 && {
                          color: "error.main",
                          bgcolor: alpha(theme.palette.error.main, 0.16),
                        }),
                      }}
                    >
                      <Icon
                        width={16}
                        height={16}
                        icon={Percent >= 0 ? trendingUpFill : trendingDownFill}
                      />
                    </IconWrapperStyle>
                    <Typography component="span" variant="subtitle2">
                      {Percent > 0 && "+"}
                      {fPercent(Percent)}
                    </Typography>
                  </>
                ) : (
                  <div style={{ width: 24, height: 24 }}></div>
                )}
              </Stack>
            ) : null}
            {navigationUrl != undefined &&
              navigationUrl !== null &&
              navigationUrl.length > 0 && (
                <Icon
                  width={45}
                  height={45}
                  icon={arrowCircleRightOutline}
                  className={classesNew.navigationIcon}
                  onClick={() => navigationHandler(navigationUrl)}
                />
              )}
          </Stack>
        </Box>
      </Card>
    </Grid>
  );
}
