import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Box, Checkbox, Dialog, DialogActions, Stack, TextField, Typography } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CREATE_SUPPLIER_INVENTORY } from "../../../../graphQL/Mutations";
import { useState } from "react";
import { GET_SUPPLIER_INVENTORY_QUERY } from "../../../../graphQL/Queries";


type SetNewItemModalProps={
    isAddNewItemModalOpen: boolean;
    onClosePreview: VoidFunction;
    supplierId: number;
};

export default function  SetNewItemModal({
        isAddNewItemModalOpen,
        onClosePreview,
        supplierId,
    }: SetNewItemModalProps){

    const [loading, setLoading] = useState<boolean>(false);
    const [SupplierInventoryMutation, SupplierInventoryMutationProps] = useMutation(
        CREATE_SUPPLIER_INVENTORY,
        {
            refetchQueries: [GET_SUPPLIER_INVENTORY_QUERY],
            fetchPolicy: "network-only",
        }
    );
    const NewVesselSchema = Yup.object().shape({
        productName: Yup.string().required("Product name is required"),
        productCode: Yup.string().required("Product number is required"),
        itemUnit: Yup.string().required("Product number is required"),
        productInformation: Yup.string(),
        containsHazMat: Yup.boolean(),        
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            productName: "",
            productCode: "",
            itemUnit: "",
            productInformation: "",
            containsHazMat: false,
            supplierId: supplierId,
        },
        validationSchema: NewVesselSchema,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                setLoading(true);
                // CASE UPDATE
                const sendNewItemObject = {
                        productName: values?.productName || "",
                        productCode: values?.productCode || "",
                        itemUnit: values?.itemUnit || "",
                        productInformation: values?.productInformation || "",
                        containsHazMat: values?.containsHazMat || false,
                        supplierId: supplierId,
                        supplierInventoryMaterial: [],
                        poItemIdReferenceId: null
                    };

    
                    
    
            const useUpdateVesselMutation = async () => {
                         
                        
            const resultPdf = await SupplierInventoryMutation({
                variables: {
                    sendNewItemInput: sendNewItemObject,
                },
                onCompleted() {
                    resetForm();
                    setSubmitting(false);
                    setLoading(false);
                    toast.configure();
                    toast.success("The item was added successfully.");
                    onClosePreview();
                },
                    onError(err) {
                        toast.configure();
                        toast.error(`Error: ${err.message}`);
                    },
                });
            };
                useUpdateVesselMutation();    
        } catch (error) {
                console.error(error);
                setSubmitting(false);
                toast.configure();
                toast.error(`Error: ${error}`);
        }
            },
        });


    const handleChangeContainsHazMat = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setFieldValue("containsHazMat", event.target.checked);
    }

        
    const {
        errors,
        values,
        touched,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        getFieldProps,
    } = formik;


    return(
        <Dialog open={isAddNewItemModalOpen} onClose={onClosePreview}>
        <DialogActions sx={{ py: 2, px: 3 }}>
            <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Box>
                    <Box
                        sx={{
                            //pr: 20,
                            //pl: 20,
                            direction: 'column',
                            flexDirection: 'column',
                            display: "flex",
                            alignItems: 'center',
                            mt: 1,
                            width: 350,
                            "& .super-app-theme--2": {
                                bgcolor: () => "rgba(91, 229, 132, 0.15)",
                            },
                            "& .super-app-theme--1": {
                                bgcolor: () => "rgba(255, 244, 165, 0.5)",
                            },
                        }}
                    >
                        <TextField
                            label="Product Name*"
                            variant="standard"
                            {...getFieldProps("productName")}
                            
                            error={Boolean(touched.productName && errors.productName)}
                            helperText={touched.productName && errors.productName}
                        />
                        <TextField
                            sx={{mt:1}}
                            label="Product Number*"
                            variant="standard"
                            {...getFieldProps("productCode")}
                            
                            error={Boolean(touched.productCode && errors.productCode)}
                            helperText={touched.productCode && errors.productCode}
                        />
                        <TextField
                            sx={{mt:1}}
                            label="Item Unit*"
                            variant="standard"
                            {...getFieldProps("itemUnit")}
                            
                            error={Boolean(touched.itemUnit && errors.itemUnit)}
                            helperText={touched.itemUnit && errors.itemUnit}
                        />
                        <Box sx={{                            
                            direction: 'row',
                            flexDirection: 'row',
                            display: "flex",
                            ml:2
                            }}> 
                            <Stack> 
                        <Checkbox
                            checked={values?.containsHazMat}
                            onChange={handleChangeContainsHazMat}
                        />
                        </Stack>
                        <Stack sx={{mt:1}}> <Typography>Contains Hazardous Materials</Typography></Stack>
                        </Box>          
                        <TextField
                            label="Product Information"
                            {...getFieldProps("productInformation")}
                            variant="standard"
                            
                        />



                        </Box>

                        <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
                    <LoadingButton variant="contained" onClick={onClosePreview}>
                        Cancel
                    </LoadingButton>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={loading}
                    >
                        Add
                    </LoadingButton>
                    </Box>
                    </Box>
             </Form>
            </FormikProvider>
        </DialogActions>
        </Dialog>
    );
}