import { motion, AnimatePresence } from "framer-motion";
// material
import { Dialog, Box, Paper, DialogProps } from "@mui/material";
//
import { varFadeInUp } from "./variants";

// ----------------------------------------------------------------------

interface DialogAnimateProps extends DialogProps {
  onClose?: VoidFunction;
  cardMinWidth?: number;
  cardMinHeight?: number;
}

export default function DialogAnimate({
  open = false,
  onClose,
  children,
  cardMinWidth,
  cardMinHeight,
  ...other
}: DialogAnimateProps) {
  if (!cardMinWidth) {
    const cardMinWidth = 500;
  }
  return (
    <AnimatePresence>
      {open && (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={open}
          onClose={onClose}
          PaperComponent={(props) => (
            <Box
              component={motion.div}
              {...varFadeInUp}
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                onClick={onClose}
                sx={{ width: "100%", height: "100%", position: "fixed" }}
              />
              <Paper {...props} sx={{ minWidth: `${cardMinWidth}px`, minHeight: `${cardMinHeight}px` }}>
                {props.children}
              </Paper>
            </Box>
          )}
          {...other}
        >
          {children}
        </Dialog>
      )}
    </AnimatePresence>
  );
}
