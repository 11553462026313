// material
import { Card, Grid, Stack, Typography } from "@mui/material";
// components
import { useLazyQuery, useQuery } from "@apollo/client";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumberCard from "../../components/custom/Cards/NumberCard";
import StatsCard from "../../components/custom/Cards/StatsCard";
import {
  FIND_ALL_IHMS_BY_ROLE,
  FIND_ALL_POI_REQUESTS_BY_ROLE,
  FIND_ALL_POS_BY_ROLE_QUERY,
  FIND_ALL_PO_SHIP_OWNER,
  FIND_ALL_VESSELS_BY_ROLE,
  FIND_SHIPOWNER_COMPLIANCE_QUERY,
  GET_APP_USER_BY_TOKEN_QUERY,
  GET_FLEET_COMPLIANCE_FOR_VESSEL_QUERY,
  GET_PO_IN_SCOPE_BY_SO_AND_VSL_QUERY,
  GET_PO_IN_SCOPE_PERCENTAGE_BY_SO_AND_VSL_QUERY,
  GET_REPORT_STATUS_IHM_ITEMS,
  GET_SUPPLIER_COMPLIANCE_FOR_SHIPOWNER_QUERY,
  GET_TOTAL_SUPPLIERS_FOR_SHIPOWNER_QUERY,
} from "../../graphQL/Queries";
import AppexGraph from "../custom/AppexGraph";
//styles
import { makeStyles } from "@mui/styles";
import { DatePeriodReport } from "../../@types/DatePeriodReport";
import { OrderStatus } from "../../@types/PurchaseOrder";
import IHMsTableContainer from "../dashboard/ihm/IHMsTableContainer";
import { ShipOwnerVesselDto } from "../../@types/ShipOwnerVessel";
import { useKeycloak } from "@react-keycloak/web";

const useStyles = makeStyles(() => ({
  stackInside: {
    "& >.MuiGrid-root": {
      maxWidth: "100%",
      "& >.MuiCard-root": {
        backgroundColor: "transparent",
        boxShadow: "unset",
        padding: "0",
        textAlign: "center",
        "& >.MuiBox-root": {
          "& >div": {
            justifyContent: "center",
          },
        },
      },
    },
  },
  stackInsideDouble: {
    "& >.MuiGrid-root": {
      flex: "1",
      "& >.MuiCard-root": {
        minHeight: "100px",
        padding: "8px",
      },
    },
  },
  loadingDiv: {
    display: "flex",
    flex: "1",
    minWidth: "280px",
  },
}));

// Define Queries
const findAllPOsByRole = () =>
  useQuery(FIND_ALL_POS_BY_ROLE_QUERY, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });
const findAllPoItemsByRole = (states: OrderStatus[]) =>
  useQuery(FIND_ALL_POI_REQUESTS_BY_ROLE, {
    variables: {
      states: states,
    },
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

  const findAllPoShipOwner = (states: OrderStatus[]) =>
  useQuery(FIND_ALL_PO_SHIP_OWNER, {
    variables: {
      states: states,
    },
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });
const findAllVesselsByRole = () =>
  useQuery(FIND_ALL_VESSELS_BY_ROLE, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });
const findAllIhmsByRole = () =>
  useQuery(FIND_ALL_IHMS_BY_ROLE, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });
const shipOwnerComplianceQuery = () =>
  useQuery(FIND_SHIPOWNER_COMPLIANCE_QUERY, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });
const useGetPOInScopePercentageByShipOwnerAndVesselQuery = (
  shipOwnerId: string | undefined,
  vesselId: string | undefined,
  totalMonths: string,
  dateFromInput: string
) =>
  useQuery(GET_PO_IN_SCOPE_PERCENTAGE_BY_SO_AND_VSL_QUERY, {
    variables: {
      dateFromInput: dateFromInput,
      totalMonths: totalMonths === "all" ? -1 : parseInt(totalMonths),
      shipOwnerId:
        shipOwnerId && parseInt(shipOwnerId) ? parseInt(shipOwnerId) : 0,
      vesselId: vesselId ? parseInt(vesselId) : 0,
    },
    skip: !(shipOwnerId || (shipOwnerId && vesselId)),
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

const useGetAppUserByTokenQuery = () =>
  useQuery(GET_APP_USER_BY_TOKEN_QUERY, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

const useGetTotalSuppliersForShipOwnerQuery = () =>
  useQuery(GET_TOTAL_SUPPLIERS_FOR_SHIPOWNER_QUERY, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

const useGetSupplierComplianceForShipOwnerQuery = () =>
  useQuery(GET_SUPPLIER_COMPLIANCE_FOR_SHIPOWNER_QUERY, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });


  const useGetPOInScopeByShipOwnerAndVesselQuery = (
    shipOWnerVessel: ShipOwnerVesselDto | undefined,
    totalMonths: string,
    dateFromInput: string,
    userRole: string,
  ) =>
    useQuery(GET_PO_IN_SCOPE_BY_SO_AND_VSL_QUERY, {
      variables: {
        dateFromInput: dateFromInput,
        totalMonths: totalMonths === "all" ? -1 : parseInt(totalMonths),
        shipOwnerId:
          shipOWnerVessel && shipOWnerVessel.shipOwnerDto && shipOWnerVessel.shipOwnerDto.id && parseInt(shipOWnerVessel.shipOwnerDto.id) ? parseInt(shipOWnerVessel.shipOwnerDto.id) : 0,
        vesselId: shipOWnerVessel && shipOWnerVessel.vesselDto && shipOWnerVessel.vesselDto?.id ? parseInt(shipOWnerVessel.vesselDto?.id) : 0,
        userRole: userRole,
      },
      skip: shipOWnerVessel == undefined || shipOWnerVessel.shipOwnerDto == undefined || shipOWnerVessel.vesselDto == undefined,
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
      fetchPolicy: "network-only",
    });

const getReportStatusIhmItems = (
  shipOwnerId: string | undefined,
  vesselId: number | undefined,
  totalMonths: string,
  reportPeriod: DatePeriodReport,
  dateFromInput: string
) =>
  useQuery(GET_REPORT_STATUS_IHM_ITEMS, {
    variables: {
      dateFromInput: dateFromInput,
      totalMonths: totalMonths === "all" ? -1 : parseInt(totalMonths),
      reportPeriod: reportPeriod,
      shipOwnerId:
        shipOwnerId && parseInt(shipOwnerId) ? parseInt(shipOwnerId) : 0,
      vesselId: vesselId,
    },
    skip: !shipOwnerId,
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

const useGetFleetCompliance = (
  shipOwnerId: number | undefined,
  vesselId: number | undefined,
  totalMonths: string,
  dateFromInput: string
) =>
  useQuery(GET_FLEET_COMPLIANCE_FOR_VESSEL_QUERY, {
    variables: {
      dateFromInput: dateFromInput,
      totalMonths: totalMonths === "all" ? -1 : parseInt(totalMonths),
      shipOwnerId: shipOwnerId,
      vesselId: vesselId,
    },
    skip: !(shipOwnerId || (shipOwnerId && vesselId)),
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

// --------------------------------------------------------------------------------------------------
export default function ShipownerHomepage() {
  const [chartData, setChartData] = useState({
    shipOwnerCompliance: 0,
    vesselCompliance: 0,
  });
  const [storedItems, setStoredItems] = useState(0);
  const [installedItems, setInstalledItems] = useState(0);
  const [statusCardIsLoading, setStatusCardIsLoading] = useState<boolean>(true);
  const [totalHazardousMaterials, setTotalHazardousMaterials] =
    useState<number>(0);
  const classesNew = useStyles();

  const today = new Date();
  const stringDate =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  const findAllPOsByRoleResponse = findAllPoShipOwner([
    OrderStatus.CREATED,
    OrderStatus.PENDING_FOR_SUPPLIER_INFORMATION,
    OrderStatus.APPROVED,
    OrderStatus.REJECTED,
    OrderStatus.INSTALLED,
    OrderStatus.UNKNOWN,
    OrderStatus.DOCUMENT_SAVED,
    OrderStatus.DOCUMENT_PROCESS_IN_PROGRESS,
    OrderStatus.DOCUMENT_PROCESS_FAILED,
    OrderStatus.DOCUMENT_PROCESS_OK,
  ]);
  const findPendingRequestsByRoleResponseOneTwo = findAllPoItemsByRole([
    OrderStatus.CREATED,
    OrderStatus.PENDING_FOR_SUPPLIER_INFORMATION,
    OrderStatus.DOCUMENT_SAVED,
  ]);
  const findAllRequestsByRoleResponseInReview = findAllPoItemsByRole([
    OrderStatus.DOCUMENT_PROCESS_OK,
    OrderStatus.DOCUMENT_PROCESS_IN_PROGRESS,
    OrderStatus.DOCUMENT_PROCESS_FAILED,
  ]);
  const findAllRequestsByRoleResponseResolved = findAllPoItemsByRole([
    OrderStatus.APPROVED,
    OrderStatus.INSTALLED,
  ]);
  const findAllVesselsByRoleResponse = findAllVesselsByRole();
  const findAllIhmsByRoleResponse = findAllIhmsByRole();
  const shipOwnerComplianceQueryResponse = shipOwnerComplianceQuery();

  const getAppUserByTokenQueryResponse = useGetAppUserByTokenQuery();

  const getTotalSuppliersForShipOwnerQueryResponse =
    useGetTotalSuppliersForShipOwnerQuery();

  const getSupplierComplianceForShipOwnerQueryResponse =
    useGetSupplierComplianceForShipOwnerQuery();

  
    const [getPOInScopePercentageByShipOwnerAndVesselLazyQuery, { loading: pOInScopePercentageByShipOwnerAndVesselLoading, data: pOInScopePercentageByShipOwnerAndVesselData }] = useLazyQuery(
      GET_PO_IN_SCOPE_PERCENTAGE_BY_SO_AND_VSL_QUERY,
      {
        variables: {
          dateFromInput: stringDate,
          totalMonths: -1,
          shipOwnerId:
          getAppUserByTokenQueryResponse?.data?.getAppUserByToken?.userShipOwner[0]
          ?.shipOwnerId && parseInt(      getAppUserByTokenQueryResponse?.data?.getAppUserByToken?.userShipOwner[0]
            ?.shipOwnerId) ? parseInt(      getAppUserByTokenQueryResponse?.data?.getAppUserByToken?.userShipOwner[0]
              ?.shipOwnerId) : 0,
          vesselId: 0,
        }
      }
    );





  

  const [getReportStatusIhmItemsLazyQuery, { loading: reportStatusIhmItemsLoading, data: reportStatusIhmItemsData }] = useLazyQuery(
    GET_REPORT_STATUS_IHM_ITEMS,
    {
      variables: {
        dateFromInput: stringDate,
        totalMonths: -1,
        reportPeriod: DatePeriodReport.UNKNOWN,
        shipOwnerId:
        getAppUserByTokenQueryResponse?.data?.getAppUserByToken?.userShipOwner[0]
        ?.shipOwnerId && parseInt(getAppUserByTokenQueryResponse?.data?.getAppUserByToken?.userShipOwner[0]
          ?.shipOwnerId) ? parseInt(getAppUserByTokenQueryResponse?.data?.getAppUserByToken?.userShipOwner[0]
            ?.shipOwnerId) : 0,
        vesselId: 0,
      }
    }
  );

 

  const [getFleetComplianceLazyQuery, { loading: fleetComplianceLoading, data: fleetComplianceData }] = useLazyQuery(
    GET_FLEET_COMPLIANCE_FOR_VESSEL_QUERY,
    {
      variables: {
        dateFromInput: stringDate,
        totalMonths:  -1,
        shipOwnerId: getAppUserByTokenQueryResponse?.data?.getAppUserByToken?.userShipOwner[0]
        ?.shipOwnerId,
        vesselId: 0,
      }
    }
  );




  const [userRole, setUserRole] = useState<undefined | string>(undefined);
  const { initialized, keycloak } = useKeycloak();
  // Hooks
  useEffect(()=>{
    if(getAppUserByTokenQueryResponse?.data && stringDate){
      getReportStatusIhmItemsLazyQuery();
      getFleetComplianceLazyQuery();
    }
  },[getAppUserByTokenQueryResponse.data, stringDate]);

useEffect(() => {
if (initialized) {
  if (keycloak.realmAccess) {
    if (keycloak.realmAccess.roles) {
      if (keycloak.realmAccess.roles.find((x) => x === "ShipOwner")) {
        setUserRole("ShipOwner");
      } else if (keycloak.realmAccess.roles.find((x) => x === "Supplier")) {
        setUserRole("Supplier");
      } else if (
        keycloak.realmAccess.roles.find((x) => x === "Maintainer")
      ) {
        setUserRole("Maintainer");
      }
    }
  } else if (window.sessionStorage.getItem("userRole")) {
    setUserRole(window.sessionStorage.getItem("userRole")?.toString());
  }
}
}, [keycloak, initialized]);


  const useGetPOInScopeByShipOwnerAndVesselQueryResponse =
  useGetPOInScopeByShipOwnerAndVesselQuery(
    {shipOwnerDto: {id: "0"},
     vesselDto: {id: "0"}},
    "all",
    stringDate,
    userRole ? userRole : ""
  );

  // -----------------------------------------Component Lifecycle-----------------------------------------------------
  useEffect(() => {
    if (
      shipOwnerComplianceQueryResponse.data &&
      shipOwnerComplianceQueryResponse.data.vesselCompliance
    ) {
      setChartData({
        shipOwnerCompliance:
          shipOwnerComplianceQueryResponse.data.shipOwnerCompliance,
        vesselCompliance:
          shipOwnerComplianceQueryResponse.data.vesselCompliance,
      });
    }
  }, [shipOwnerComplianceQueryResponse.data]);

  useEffect(() => {
    setStatusCardIsLoading(reportStatusIhmItemsLoading);
    if (reportStatusIhmItemsData) {
      setStoredItems(
        reportStatusIhmItemsData.getReportStatusIhmItems
          .storedStatusTotal
      );
      setInstalledItems(
        reportStatusIhmItemsData.getReportStatusIhmItems
          .installedStatusTotal
      );
      setTotalHazardousMaterials(
        reportStatusIhmItemsData.getReportStatusIhmItems
          .storedStatusTotal +
          reportStatusIhmItemsData.getReportStatusIhmItems
            .installedStatusTotal
      );
    }
  }, [reportStatusIhmItemsData, reportStatusIhmItemsLoading]);

  // ----------------------------------------------------------------------------------------------
  return (
    <Grid sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
      <Grid
        sx={{ display: "flex", flexDirection: "row", mb: 3, columnGap: "16px" }}
      >
        <Grid sx={{ display: "flex", flex: "1" }}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              flexBasis: "100%",
            }}
          >
            {fleetComplianceData?.getFleetCompliance !=
              undefined && (
              <AppexGraph
                chartTitle="Fleet"
                headLabel="Compliance"
                chartLabels={["Compliance"]}
                chartData={[
                  fleetComplianceData?.getFleetCompliance,
                ]}
                isloading={fleetComplianceLoading}
              />
            )}
          </Card>
        </Grid>

        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "16px",
            flex: "2",
          }}
        >
          <StatsCard
            Title="Vessels"
            Value={`${findAllVesselsByRoleResponse?.data?.findAllVesselsByRole}`}
            navigationUrl={"/dashboard/vessels/dashboard"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              padding: "16px 24px",
              minHeight: "140px",
              cursor: "default",
              width: "100%",
            }}
            gridSx={{ flexBasis: "unset" }}
          />
          <StatsCard
            Title="Suppliers"
            Value={`${getTotalSuppliersForShipOwnerQueryResponse?.data?.getTotalSuppliersForShipOwner}`}
            navigationUrl={"/dashboard/supplierCompliance"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              padding: "16px 24px",
              minHeight: "140px",
              cursor: "default",
              width: "100%",
            }}
            gridSx={{ flexBasis: "unset" }}
            isloading={getTotalSuppliersForShipOwnerQueryResponse.loading}
          />
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: "1",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Stack className={classesNew.stackInside}>
              <StatsCard
                Title="Hazardous Materials"
                Value={`${totalHazardousMaterials}`}
                isloading={statusCardIsLoading}
              />
            </Stack>
            <Stack
              sx={{ flexDirection: "row", columnGap: 2 }}
              className={classesNew.stackInsideDouble}
            >
              <StatsCard
                Title="Installed"
                Value={`${installedItems}`}
                isloading={statusCardIsLoading}
              />
              <StatsCard
                Title="Stored"
                Value={`${storedItems}`}
                isloading={statusCardIsLoading}
              />
            </Stack>
          </Card>
        </Grid>
        <Grid sx={{ display: "flex", flex: "1" }}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              flexBasis: "100%",
            }}
          >
            {getSupplierComplianceForShipOwnerQueryResponse?.data
              ?.getSupplierComplianceForShipOwner != undefined && (
              <AppexGraph
                chartTitle="Suppliers"
                headLabel="Compliance"
                chartLabels={["Response Rate"]}
                chartData={[
                  getSupplierComplianceForShipOwnerQueryResponse?.data
                    ?.getSupplierComplianceForShipOwner,
                ]}
                isloading={
                  getSupplierComplianceForShipOwnerQueryResponse.loading
                }
              />
            )}
          </Card>
        </Grid>
      </Grid>
      <Card>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            m: 2,
            justifyContent: "space-around",
            columnGap: "16px",
            alignItems: "strech",
          }}
        >
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 2,
              flexBasis: "40%",
            }}
          >
            <Typography sx={{ fontWeight: 700, mb: 2 }}>
              Purchase Orders
            </Typography>
            <Stack direction="row" sx={{ columnGap: 2 }}>
              <StatsCard
                Title="Purchase Orders In Scope"
                Value={`${useGetPOInScopeByShipOwnerAndVesselQueryResponse?.data?.getPOInScopeByShipOwnerAndVessel}`}
                isloading={useGetPOInScopeByShipOwnerAndVesselQueryResponse?.loading}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  padding: "16px 24px",
                  minHeight: "140px",
                  cursor: "default",
                }}
                gridSx={{ flex: 1 }}
              />
            </Stack>
          </Card>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 2,
              flexBasis: "60%",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontWeight: 700, mb: 2 }}>
              PO Documents
            </Typography>
            <Stack direction="row" sx={{ columnGap: 2 }}>
              <NumberCard
                Title="Pending"
                Value={`${findPendingRequestsByRoleResponseOneTwo?.data?.findAllPoItemsByRole}`}
                isloading={findPendingRequestsByRoleResponseOneTwo?.loading}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  p: 2,
                  minHeight: "100px",
                  cursor: "default",
                }}
                gridSx={{ flex: 1 }}
              />
              <NumberCard
                Title="In Review"
                Value={`${findAllRequestsByRoleResponseInReview?.data?.findAllPoItemsByRole}`}
                isloading={findAllRequestsByRoleResponseInReview?.loading}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  p: 2,
                  minHeight: "100px",
                  cursor: "default",
                }}
                gridSx={{ flex: 1 }}
              />
              <NumberCard
                Title="Resolved"
                Value={`${findAllRequestsByRoleResponseResolved?.data?.findAllPoItemsByRole}`}
                isloading={findAllRequestsByRoleResponseResolved?.loading}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  p: 2,
                  minHeight: "100px",
                  cursor: "default",
                }}
                gridSx={{ flex: 1 }}
              />
            </Stack>
          </Card>
        </Box>
      </Card>
      <Card sx={{ mt: 3 }}>
        <IHMsTableContainer />
      </Card>
    </Grid>
  );
}
