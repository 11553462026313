// material
import { TableRow, TableCell, TableHead } from "@mui/material";

// ----------------------------------------------------------------------

type UserListHeadProps = {
  headLabel: any[];
};

export default function TableListHead({ headLabel }: UserListHeadProps) {
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? "right" : "left"}
            sx={{ color: "white", backgroundColor: "text.primary" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
