import archiveFill from "@iconify/icons-eva/archive-fill";
import editFill from "@iconify/icons-eva/edit-fill";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { useRef, useState } from "react";
// material
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { GET_FILTER_SUPPLIERS } from "../../graphQL/Queries";
import { SupplierDTO } from "../../@types/Supplier";
import { LoadingButton } from "@mui/lab";
import { MOVE_SUPPLIER_USER } from "../../graphQL/Mutations";
import { useNavigate } from "react-router";

// ----------------------Component Prop Types------------------------------------------------
type TableMoreMenuProps = {
  onDelete?: VoidFunction;
  userId: string;
  setOpenForm?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedUserId?: React.Dispatch<React.SetStateAction<string>>;
  handleEditAction?: (maintainerId: string) => void;
  handleRestoreAction?: (maintainerId: string) => void;
  setDefaultUser?: () => void;
  fetchDeleted?: boolean | undefined;
  userRole: string | undefined;
  enableTransfer: boolean;
  supplierId?: number | undefined;
  removeEnabled?: boolean;
  handleRemoveAction?: (maintainerId: string) => void;
};

// ------------------------------------------------------------------------------------------
export default function TableMoreMenu({
  onDelete,
  userId,
  handleEditAction,
  handleRestoreAction,
  setOpenForm,
  setSelectedUserId,
  setDefaultUser,
  fetchDeleted,
  userRole,
  enableTransfer,
  supplierId,
  removeEnabled,
  handleRemoveAction,
}: TableMoreMenuProps) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [supplierNameFilter, setSupplierNameFilter] = useState<string>();
  const [transferOrderDialog, setTransferOrderDialog] = useState(false);
  const [filteredSuppliers, setFilteredSuppliers] = useState<SupplierDTO[]>([]);
  const [selectedSupplierToTransfer, setSelectedSupplierToTransfer] = useState<SupplierDTO>();
  const navigate = useNavigate();

  const { loading: loadingSuppliers, refetch: refetchFilteredSuppliers } = useQuery(GET_FILTER_SUPPLIERS, {
    variables: {
      name: supplierNameFilter
    },
    skip: transferOrderDialog === false || supplierNameFilter == undefined || supplierNameFilter.length === 0,
    fetchPolicy: "network-only",
    onCompleted(dt) {
      console.log(dt);
      if (dt.filterSuppliers && dt.filterSuppliers.length > 0) {
        setFilteredSuppliers(dt.filterSuppliers)
      }
    }
  })

  const [MoveSupplierUserMutation, MoveSupplierUserMutationProps] =
    useMutation(MOVE_SUPPLIER_USER, {
      fetchPolicy: "network-only",
    });

  const moveSupplierUserMutation = (
    supplierIdFrom: number,
    supplierIdTo: number,
    selectedUserId: number,
  ) => {
    MoveSupplierUserMutation({
      variables: {
        supplierFromId: supplierIdFrom,
        supplierToId: supplierIdTo,
        selectedUserId: selectedUserId,
      },
      onCompleted(dt) {
        navigate(`/dashboard/suppliers`);
      },
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err}`);
      },
    })
  }

  // --------------------------------- handlers ---------------------------------------------------
  const handleEditSupplierUser = () => {
    if (setSelectedUserId != undefined && setOpenForm != undefined) {
      setSelectedUserId(userId);
      setOpenForm(true);
    }
  };

  const handleMoveOfSupplierUser = () => {
    if (selectedSupplierToTransfer != undefined && supplierId != undefined) {
      moveSupplierUserMutation(supplierId, parseInt(selectedSupplierToTransfer.id), parseInt(userId))
    }
  }

  const handleCloseTransferDialog = () => {
    setTransferOrderDialog(false);
    setSelectedSupplierToTransfer(undefined);
  }


  // ------------------------------------------------------------------------------------------
  return (
    <>
      <Dialog open={transferOrderDialog}>
        <DialogTitle>Transfer User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You will transfer the user to
          </DialogContentText>
          <Autocomplete
            disablePortal
            id="supplier-to-transfer"
            options={filteredSuppliers}
            filterOptions={(x) => x}
            autoComplete
            includeInputInList
            filterSelectedOptions
            noOptionsText="Supplier is not found"
            isOptionEqualToValue={(option: SupplierDTO, value: SupplierDTO) => {
              if (option.name === value.name) {
                setSelectedSupplierToTransfer(value);
                return true;
              }
              return false;
            }}
            getOptionLabel={(option: SupplierDTO) => option.name ? option.name : 'Select Supplier'}
            loading={loadingSuppliers}
            onInputChange={(event, newInputValue) => {
              if (newInputValue.length > 2) {
                setSupplierNameFilter(newInputValue)
              }
            }}

            renderInput={(params) => <TextField {...params} label="Supplier" fullWidth />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTransferDialog}>Cancel</Button>
          <LoadingButton loading={MoveSupplierUserMutationProps.loading}
            disabled={selectedSupplierToTransfer == undefined ? true : false} onClick={handleMoveOfSupplierUser}>Transfer</LoadingButton>
        </DialogActions>
      </Dialog>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>
      {fetchDeleted && (
        <Menu
          open={isOpen}
          anchorEl={ref.current}
          onClose={() => setIsOpen(false)}
          PaperProps={{
            sx: { width: 200, maxWidth: "100%" },
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem
            sx={{ color: "text.primary" }}
            onClick={() => {
              if (handleRestoreAction != undefined) {
                handleRestoreAction(userId);
              }
            }}
          >
            <ListItemIcon>
              <Icon icon={archiveFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Restore"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        </Menu>
      )}
      {!fetchDeleted && (
        <Menu
          open={isOpen}
          anchorEl={ref.current}
          onClose={() => setIsOpen(false)}
          PaperProps={{
            sx: { width: 200, maxWidth: "100%" },
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem onClick={onDelete} sx={{ color: "text.primary" }}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>

          <MenuItem
            sx={{ color: "text.primary" }}
            onClick={() => {
              if (handleEditAction != undefined) {
                handleEditAction(userId);
              } else {
                handleEditSupplierUser();
              }
            }}
          >
            <ListItemIcon>
              <Icon icon={editFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>

          {removeEnabled ? (<MenuItem
            sx={{ color: "text.primary" }}
            onClick={() => {
              if (handleRemoveAction != undefined) {

                handleRemoveAction(userId);

              }
            }}
          >
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Remove"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>) : (null)}

          {setDefaultUser != undefined && (
            <MenuItem
              onClick={() => setDefaultUser()}
              sx={{ color: "text.primary" }}
            >
              <ListItemIcon>
                <Icon icon="bx:user-check" width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary="Set Default User"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          )}

          {
            userRole != undefined && userRole === "Maintainer" && enableTransfer && (
              <MenuItem
                onClick={() => { setTransferOrderDialog(true) }}
                sx={{ color: "text.primary" }}
              >
                <ListItemIcon>
                  <Icon icon="bx:transfer" width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary="Transfer User"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>
            )
          }
        </Menu>
      )}
    </>
  );
}
function enqueueSnackbar(arg0: string, arg1: { variant: string; }) {
  throw new Error("Function not implemented.");
}

