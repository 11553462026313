import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import L from "leaflet";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { ImageOverlay, MapContainer } from "react-leaflet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ApprovalStatusEnum,
  IHMDTO,
  IhmInventorySubPart,
  IhmItemPositionDTO,
} from "../../../@types/IHM";
import { VesselDrawingDTO } from "../../../@types/Vessel";
import {
  APPROVE_INSTALLATION_MUTATION,
  APPROVE_INSTALLATION_REPLACE_MUTATION,
  REJECT_INSTALLATION_MUTATION,
  REJECT_REMOVAL_MUTATION,
  REJECT_REPLACEMENT_MUTATION,
} from "../../../graphQL/Mutations";
import { GET_IHM_PENDING_FOR_APPROVAL_ITEMS } from "../../../graphQL/Queries";
import Scrollbar from "../../Scrollbar";
import IhmMapperForm, { MapperActionCodesEnum } from "../IhmMapperForm";
import { ApproveIhmInstallationMapComponent } from "./ApproveIhmInstallationMapComponent";

const serverUri = process.env.REACT_APP_SERVER_URI;

// ------------------------------------------------------------------ Define Queries ---------------------------------------------------------------------
const useFetchDrawingsQuery = async (
  drawingFullPath: string,
  token: string | null
) => {
  return axios.get(`${serverUri}/api/v1/drawing/${drawingFullPath}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob",
  });
};

interface ApproveIhmInstallationVesselMapProps {
  selectedVesselDrawing: VesselDrawingDTO | undefined;
  ihmItem: IHMDTO;
  ihmItemPosition: IhmItemPositionDTO;
  ihmItemToReplaceExisting: IHMDTO | undefined;
  ihmPositionToReplaceExisting: IhmItemPositionDTO | undefined;
  remainingQuantity: number;
  modalClose: VoidFunction;
}

const ApproveIhmInstallationVesselMap = ({
  selectedVesselDrawing,
  ihmItem,
  ihmItemPosition,
  ihmItemToReplaceExisting,
  ihmPositionToReplaceExisting,
  remainingQuantity,
  modalClose,
}: ApproveIhmInstallationVesselMapProps) => {
  interface ImageDimensions {
    width: number;
    height: number;
  }
  const { enqueueSnackbar } = useSnackbar();
  const [imageUrl, setImageUrl] = useState<string>();
  const [dimensionsOfImage, setDimensionsOfImage] = useState<ImageDimensions>();
  // form fields
  const [applicationOfPaint, setApplicationOfPaint] = useState<string>("");
  const [nameOfPaint, setNameOfPaint] = useState<string>("");
  const [nameOfEquipmentMachinery, setNameOfEquipmentMachinery] =
    useState<string>("");
  const [nameOfStructuralElement, setNameOfStructuralElement] =
    useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [partsUsed, setPartsUsed] = useState<string>("");
  const [quantity, setQuantity] = useState<number>(0);
  const [subPart, setSubPart] = useState<string>("SubPart 1");

  // marker attributes
  const [selectedNewIhmPosition, setSelectedNewIhmPosition] =
    useState<IhmItemPositionDTO | null>(null);
  const [selectedExistingIhmPosition, setSelectedExistingNewIhmPosition] =
    useState<IhmItemPositionDTO | null>(null);

  const mapComponentRef = useRef<any>();

  const token = window.sessionStorage.getItem("jwt");

  // ------------------------------------------------------------------ Define Mutations ---------------------------------------------------------------------
  // Approve
  const [ApproveInstallationMutation, ApproveInstallationMutationProps] =
    useMutation(APPROVE_INSTALLATION_MUTATION, {
      fetchPolicy: "network-only",
    });
  const useApproveInstallationMutation = (
    updatePosition: IhmItemPositionDTO
  ) => {
    ApproveInstallationMutation({
      variables: {
        updateIhmPositionInput: updatePosition,
      },
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
      refetchQueries: [GET_IHM_PENDING_FOR_APPROVAL_ITEMS],
      onCompleted(dt) {
        modalClose();
        if (dt.approveInstallation === true) {
          enqueueSnackbar(`Installation has been Approved`, {
            variant: "success",
          });
        }
      },
    });
  };
  // Approve Replacement
  const [
    ApproveInstallationReplacementMutation,
    ApproveInstallationReplacementMutationProps,
  ] = useMutation(APPROVE_INSTALLATION_REPLACE_MUTATION, {
    fetchPolicy: "network-only",
  });
  const useApproveInstallationReplacementMutation = (
    ihmPositionIdToReplace: number,
    ihmPositionIdToBeReplaced: number
  ) => {
    ApproveInstallationReplacementMutation({
      variables: {
        ihmPositionIdToBeReplaced: ihmPositionIdToBeReplaced,
        ihmPositionIdToReplace: ihmPositionIdToReplace,
      },
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
      refetchQueries: [GET_IHM_PENDING_FOR_APPROVAL_ITEMS],
      onCompleted(dt) {
        modalClose();
        if (dt.approveInstallationReplace === true) {
          enqueueSnackbar(`Installation Replacement has been Approved`, {
            variant: "success",
          });
        }
      },
    });
  };

  // Reject
  const [RejectInstallationMutation, RejectInstallationMutationProps] =
    useMutation(REJECT_INSTALLATION_MUTATION, {
      fetchPolicy: "network-only",
    });
  const useRejectInstallationMutation = (ihmPositionIdToReject: number) => {
    RejectInstallationMutation({
      variables: {
        ihmPositionIdToReject: ihmPositionIdToReject,
      },
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
      refetchQueries: [GET_IHM_PENDING_FOR_APPROVAL_ITEMS],
      onCompleted(dt) {
        modalClose();
        if (dt.rejectInstallation === true) {
          enqueueSnackbar(`Installation has been Rejected`, {
            variant: "success",
          });
        }
      },
    });
  };

  //Reject replacement
  const [RejectReplacementMutation, RejectReplacementMutationProps] =
    useMutation(REJECT_REPLACEMENT_MUTATION, {
      fetchPolicy: "network-only",
    });
  const useRejectReplacementMutation = (replacedByIhmPositionId: number) => {
    RejectReplacementMutation({
      variables: {
        replacedByIhmPositionId: replacedByIhmPositionId,
      },
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
      refetchQueries: [GET_IHM_PENDING_FOR_APPROVAL_ITEMS],
      onCompleted(dt) {
        modalClose();
        if (dt.rejectReplacement === true) {
          enqueueSnackbar(`Installation Replacement has been Rejected`, {
            variant: "success",
          });
        }
      },
    });
  };

  //Reject Removal
  const [RejectPositionRemovalMutation, RejectPositionRemovalMutationProps] =
    useMutation(REJECT_REMOVAL_MUTATION, {
      fetchPolicy: "network-only",
    });
  const useRejectPositionRemovalMutation = (ihmPositionIdToReject: number) => {
    RejectPositionRemovalMutation({
      variables: {
        ihmPositionIdToReject: ihmPositionIdToReject,
      },
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
      refetchQueries: [GET_IHM_PENDING_FOR_APPROVAL_ITEMS],
      onCompleted(dt) {
        modalClose();
        if (dt.rejectPositionRemoval === true) {
          enqueueSnackbar(`Installation Removal has been Rejected`, {
            variant: "success",
          });
        }
      },
    });
  };

  // ------------------------------------------------------------------ Hooks --------------------------------------------------------------
  useEffect(() => {
    if (selectedVesselDrawing != undefined) {
      const parsedPath = selectedVesselDrawing.path?.split("/").join("-");
      if (parsedPath) {
        useQueryExec(parsedPath, token);
      }
    }
  }, [selectedVesselDrawing]);

  useEffect(() => {
    if (ihmItemPosition) {
      ihmItemPosition.applicationOfPaint &&
        setApplicationOfPaint(ihmItemPosition.applicationOfPaint);
      ihmItemPosition.nameOfEquipmentAndMachinery &&
        setNameOfEquipmentMachinery(
          ihmItemPosition.nameOfEquipmentAndMachinery
        );

      ihmItemPosition.nameOfPaint &&
        setNameOfPaint(ihmItemPosition.nameOfPaint);

      ihmItemPosition.partsWhereUsed &&
        setPartsUsed(ihmItemPosition.partsWhereUsed);

      ihmItemPosition.nameOfStructuralElement &&
        setNameOfStructuralElement(ihmItemPosition.nameOfStructuralElement);

      ihmItemPosition.location && setLocation(ihmItemPosition.location);

      ihmItemPosition.quantity && setQuantity(ihmItemPosition.quantity);
      switch (ihmItemPosition.inventorySubPart) {
        case IhmInventorySubPart.SUB_CATEGORY_1:
          setSubPart("SubPart 1");
          break;
        case IhmInventorySubPart.SUB_CATEGORY_2:
          setSubPart("SubPart 2");
          break;
        case IhmInventorySubPart.SUB_CATEGORY_3:
          setSubPart("SubPart 3");
          break;

        default:
          break;
      }
    }
  }, [ihmItemPosition]);

  useEffect(  ()=>{
    if(imageUrl){
      const fetchData = async () => {
        const dimensions =  await getImageDimensions(imageUrl);
        
        setDimensionsOfImage({width: dimensions.width, height: dimensions.height});
        return dimensions;
      }
      fetchData();
      
    }
  
  
  },[imageUrl]);


  // ------------------------------------------------------------------ Handlers ---------------------------------------------------------------------
  // Helper function
  async function useQueryExec(drawingFullPath: string, token: string | null) {
    const response = await useFetchDrawingsQuery(drawingFullPath, token);
    if (response && response.data) {
      const imgFromBlob = URL.createObjectURL(response.data);

      if (imgFromBlob) {
        setImageUrl(imgFromBlob);
      }
    }
  }

  function getImageDimensions(file: string): Promise<ImageDimensions> {
    return new Promise (function (resolved, rejected) {
      const i = new Image();
      i.onload = function(){
        resolved({width: i.width, height: i.height})
      };
      i.src = file
    })
  }

  const useHandleApproveInstallation = () => {
    if (
      ihmItemToReplaceExisting &&
      ihmPositionToReplaceExisting &&
      ihmPositionToReplaceExisting.id &&
      ihmItemPosition.id
    ) {
      useApproveInstallationReplacementMutation(
        ihmPositionToReplaceExisting.id,
        ihmItemPosition.id
      );
    } else {
      const updatedIhmPositionInput: IhmItemPositionDTO = ihmItemPosition;
      updatedIhmPositionInput.location = location;
      updatedIhmPositionInput.quantity = quantity;
      updatedIhmPositionInput.approvalStatus = ApprovalStatusEnum.APPROVED;

      switch (subPart) {
        case "SubPart 1":
          updatedIhmPositionInput.applicationOfPaint = applicationOfPaint;
          updatedIhmPositionInput.nameOfPaint = nameOfPaint;
          break;
        case "SubPart 2":
          updatedIhmPositionInput.nameOfEquipmentAndMachinery =
            nameOfEquipmentMachinery;
          updatedIhmPositionInput.partsWhereUsed = partsUsed;
          break;
        case "SubPart 3":
          updatedIhmPositionInput.nameOfStructuralElement =
            nameOfStructuralElement;
          updatedIhmPositionInput.partsWhereUsed = partsUsed;
          break;

        default:
          break;
      }
      useApproveInstallationMutation(updatedIhmPositionInput);
    }
  };

  const useHandleRejectInstallation = () => {
    if (ihmItemPosition) {
      if (
        ihmItemToReplaceExisting &&
        ihmPositionToReplaceExisting &&
        ihmPositionToReplaceExisting.id
      ) {
        useRejectReplacementMutation(ihmPositionToReplaceExisting.id);
      } else if (ihmItemPosition.isDeleted && ihmItemPosition.id) {
        useRejectPositionRemovalMutation(ihmItemPosition.id);
      } else if (ihmItemPosition.id) {
        useRejectInstallationMutation(ihmItemPosition.id);
      }
    }
  };

  const buttonLabel = () => {
    if (ihmItemPosition != undefined && ihmItemPosition !== null) {
      if (ihmItemPosition.isDeleted) {
        return "Approve Removal";
      } else if (ihmItemPosition.replacedByIhmPosition) {
        return "Approve Replacement";
      } else {
        return "Approve";
      }
    } else {
      return "Approve";
    }
  };

  const mapRefForZooming = useRef<any>(null);

  const handleZoomIn = () => {
    const map = mapRefForZooming.current;
    if (map) {
      map.setZoom(map.getZoom() + 1);
    }
  }
  
  const handleZoomOut = () => {
    const map = mapRefForZooming.current;
    if (map) {
      map.setZoom(map.getZoom() - 1);
    }
  }


  return (
    <>
    <LoadingButton
    variant="contained"
    onClick={handleZoomIn}
  >
  +
  </LoadingButton>
  <LoadingButton
    sx={{ml:"5px"}}
    variant="contained"
    onClick={handleZoomOut}
  >
  -
  </LoadingButton>
    <Scrollbar>
      {selectedVesselDrawing != undefined ? (
        <MapContainer
          className="map"
          zoom={6}
          scrollWheelZoom={false}
          crs={L.CRS.Simple}
          bounds={[
            [0, 0],
            [3000, 3000],
          ]}
          minZoom={-6}
          maxZoom={20}
          ref={mapRefForZooming}
          attributionControl={false}
          zoomControl={false}
          style={{ height: "300px", width: "550px", backgroundColor: "white" }}
        >
        {imageUrl && dimensionsOfImage &&
        (

            <ImageOverlay
              url={imageUrl}
              bounds={[
                [0, 0],
                [dimensionsOfImage.height*10, dimensionsOfImage.width*10],
              ]}
            />
        ) }

          <ApproveIhmInstallationMapComponent
            placeMarkerToMap={false}
            positions={
              ihmItemPosition !== null && ihmItemPosition != undefined
                ? [ihmItemPosition]
                : []
            }
            enableEdit={true}
            ref={mapComponentRef}
          />
        </MapContainer>
      ) : (
        <Box
          sx={{
            height: "300px",
            width: "550px",
            backgroundColor: "white",
            mt: 8,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6">
            Placement preview is not available.
          </Typography>
        </Box>
      )}

      <IhmMapperForm
        applicationOfPaint={applicationOfPaint}
        setApplicationOfPaint={setApplicationOfPaint}
        nameOfPaint={nameOfPaint}
        setNameOfPaint={setNameOfPaint}
        nameOfEquipmentMachinery={nameOfEquipmentMachinery}
        setNameOfEquipmentMachinery={setNameOfEquipmentMachinery}
        nameOfStructuralElement={nameOfStructuralElement}
        setNameOfStructuralElement={setNameOfStructuralElement}
        location={location}
        setLocation={setLocation}
        partsUsed={partsUsed}
        setPartsUsed={setPartsUsed}
        quantity={quantity}
        setQuantity={setQuantity}
        subPart={subPart}
        setSubPart={setSubPart}
        selectedNewIhmPosition={selectedNewIhmPosition}
        setSelectedNewIhmPosition={setSelectedNewIhmPosition}
        selectedExistingIhmPosition={selectedExistingIhmPosition}
        setSelectedExistingNewIhmPosition={setSelectedExistingNewIhmPosition}
        mapperAction={
          ihmItemPosition.isDeleted === true
            ? MapperActionCodesEnum.IS_REMOVE
            : MapperActionCodesEnum.IS_APPROVE
        }
      />

      {ihmItemToReplaceExisting && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", flexDirection: "row", ml: 1, mr: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: "3",
                mr: 1,
              }}
            >
              <Select
                variant="standard"
                labelId="select-ship-owner-label"
                id="select-ship-owner-ihm-items-to-replace"
                label="IHM Item"
                placeholder="Please select a product name to replace the existing installation"
                disabled
                value={0}
              >
                <MenuItem key={0} value={0}>
                  {`${ihmItemToReplaceExisting.productName} - ${ihmItemToReplaceExisting.productCode}`}
                </MenuItem>
              </Select>
              <FormHelperText>
                Please select a product name to replace the existing
                installation
              </FormHelperText>
            </Box>
            <TextField
              sx={{ flex: "1" }}
              variant="standard"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              helperText={"Quantity to Replace *"}
              value={ihmPositionToReplaceExisting?.quantity}
              disabled
              id="quantity-for-replace"
            />
          </Box>
        </Box>
      )}

      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 2,
          mb: 3,
        }}
      >
        <LoadingButton
          variant="contained"
          id="removeSelectedItem"
          onClick={useHandleRejectInstallation}
          component="label"
          loading={ApproveInstallationMutationProps.loading}
        >
          Reject
        </LoadingButton>
        <LoadingButton
          variant="contained"
          id="approveSelectedId"
          onClick={useHandleApproveInstallation}
          component="label"
          loading={
            ApproveInstallationMutationProps.loading ||
            ApproveInstallationReplacementMutationProps.loading ||
            RejectInstallationMutationProps.loading ||
            RejectReplacementMutationProps.loading ||
            RejectPositionRemovalMutationProps.loading
          }
        >
          {buttonLabel()}
        </LoadingButton>
      </Stack>
    </Scrollbar>
    </>
  );
};

export default ApproveIhmInstallationVesselMap;
