import { useMutation, useQuery } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import L from "leaflet";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { ImageOverlay, MapContainer } from "react-leaflet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  IHMDTO,
  IhmInventorySubPart,
  IhmItemPositionDTO,
} from "../../../@types/IHM";
import { IhmITemsForReplacementDTO } from "../../../@types/IhmITemsForReplacementDTO";
import { VesselDrawingDTO } from "../../../@types/Vessel";
import { REPLACE_IHM_POSITION } from "../../../graphQL/Mutations";
import {
  GET_IHM_INSTALLED_ITEMS,
  GET_IHM_ITEMS_FOR_REPLACEMENT,
} from "../../../graphQL/Queries";
import Scrollbar from "../../Scrollbar";
import IhmMapperForm, { MapperActionCodesEnum } from "../IhmMapperForm";
import { RemoveIhmInstallationMapComponent } from "./RemoveIhmInstallationMapComponent";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  fieldWithHelperText: {
    "&.MuiTextField-root": {
      "&>.MuiInput-root": {
        "&>.MuiInput-input": {
          fontSize: "14px",
          minHeight: "23px",
        },
      },
      "&>.MuiFormHelperText-root": {
        color: "#39464f",
        fontSize: "14px",
      },
    },
  },
  selectionField: {
    "&>.MuiSelect-nativeInput": {
      fontSize: "14px",
    },
  },
  helperText: {
    "&.MuiFormHelperText-root": {
      color: "#39464f",
      fontSize: "14px",
    },
  },
}));

const serverUri = process.env.REACT_APP_SERVER_URI;

// ------------------------------------------------------------------ Define Queries ---------------------------------------------------------------------
const useFetchDrawingsQuery = async (
  drawingFullPath: string,
  token: string | null
) => {
  return axios.get(`${serverUri}/api/v1/drawing/${drawingFullPath}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob",
  });
};

const getAvailableIhmItemForReplacementQuery = (ihmId: number | undefined) =>
  useQuery(GET_IHM_ITEMS_FOR_REPLACEMENT, {
    variables: {
      ihmId: ihmId,
    },
    skip: ihmId == undefined || ihmId === undefined || ihmId === 0,
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

interface ReplaceIhmInstallationVesselMapProps {
  selectedVesselDrawing: VesselDrawingDTO | undefined;
  ihmItem: IHMDTO;
  ihmItemPosition: IhmItemPositionDTO;
  modalClose: VoidFunction;
}

const ReplaceIhmInstallationVesselMap = ({
  selectedVesselDrawing,
  ihmItem,
  ihmItemPosition,
  modalClose,
}: ReplaceIhmInstallationVesselMapProps) => {
  interface ImageDimensions {
    width: number;
    height: number;
  }
  const classesNew = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [imageUrl, setImageUrl] = useState<string>();
  const { initialized, keycloak } = useKeycloak();
  const [userRole, setUserRole] = useState<undefined | string>(undefined);
  const [dimensionsOfImage, setDimensionsOfImage] = useState<ImageDimensions>();
  // form fields
  const [applicationOfPaint, setApplicationOfPaint] = useState<string>("");
  const [nameOfPaint, setNameOfPaint] = useState<string>("");
  const [nameOfEquipmentMachinery, setNameOfEquipmentMachinery] =
    useState<string>("");
  const [nameOfStructuralElement, setNameOfStructuralElement] =
    useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [partsUsed, setPartsUsed] = useState<string>("");
  const [quantity, setQuantity] = useState<number>(0);
  const [subPart, setSubPart] = useState<string>("SubPart 1");

  const [selectedIhmITemsForReplacement, setSelectedIhmITemsForReplacement] =
    useState<IhmITemsForReplacementDTO | undefined>(undefined);
  const [availableIhmItemForReplacement, setAvailableIhmItemForReplacement] =
    useState<IhmITemsForReplacementDTO[]>([]);
  const [quantityToReplace, setQuantityToReplace] = useState<number>(0);
  const [
    selectedIhmITemsForReplacementAvQ,
    setSelectedIhmITemsForReplacementAvQ,
  ] = useState<number>(0);

  // marker attributes
  const [selectedNewIhmPosition, setSelectedNewIhmPosition] =
    useState<IhmItemPositionDTO | null>(null);
  const [selectedExistingIhmPosition, setSelectedExistingNewIhmPosition] =
    useState<IhmItemPositionDTO | null>(null);

  const mapComponentRef = useRef<any>();

  const token = window.sessionStorage.getItem("jwt");

  // Use Queries
  const useGetAvailableIhmItemForReplacementQueryResponse =
    getAvailableIhmItemForReplacementQuery(ihmItem?.id);

  // ------------------------------------------------------------------ Define Mutations ---------------------------------------------------------------------
  // Replace
  const [ReplaceIhmPosition, ReplaceIhmPositionProps] = useMutation(
    REPLACE_IHM_POSITION,
    {
      fetchPolicy: "network-only",
    }
  );
  const useReplaceIhmPosition = (
    id: number | undefined,
    newPosition: IhmItemPositionDTO
  ) => {
    ReplaceIhmPosition({
      variables: {
        id: id,
        createIhmPositionInput: newPosition,
      },
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
      onCompleted(dt) {
        modalClose();
        if (dt.replaceInstallation === true) {
          if (userRole === "Maintainer") {
            enqueueSnackbar(`Installation has been Replaced Successfully.`, {
              variant: "success",
            });
          } else {
            enqueueSnackbar(`Installation has been filed for Replacement.`, {
              variant: "success",
            });
          }
        }
      },
      refetchQueries: [GET_IHM_INSTALLED_ITEMS],
    });
  };

  // ------------------------------------------------------------------ Hooks --------------------------------------------------------------
  useEffect(() => {
    if (selectedVesselDrawing != undefined) {
      const parsedPath = selectedVesselDrawing.path?.split("/").join("-");
      if (parsedPath) {
        useQueryExec(parsedPath, token);
      }
    }
  }, [selectedVesselDrawing]);

  useEffect(() => {
    if (ihmItemPosition) {
      ihmItemPosition.applicationOfPaint &&
        setApplicationOfPaint(ihmItemPosition.applicationOfPaint);
      ihmItemPosition.nameOfEquipmentAndMachinery &&
        setNameOfEquipmentMachinery(
          ihmItemPosition.nameOfEquipmentAndMachinery
        );

      ihmItemPosition.nameOfPaint &&
        setNameOfPaint(ihmItemPosition.nameOfPaint);

      ihmItemPosition.partsWhereUsed &&
        setPartsUsed(ihmItemPosition.partsWhereUsed);

      ihmItemPosition.nameOfStructuralElement &&
        setNameOfStructuralElement(ihmItemPosition.nameOfStructuralElement);

      ihmItemPosition.location && setLocation(ihmItemPosition.location);

      ihmItemPosition.quantity && setQuantity(ihmItemPosition.quantity);
      switch (ihmItemPosition.inventorySubPart) {
        case IhmInventorySubPart.SUB_CATEGORY_1:
          setSubPart("SubPart 1");
          break;
        case IhmInventorySubPart.SUB_CATEGORY_2:
          setSubPart("SubPart 2");
          break;
        case IhmInventorySubPart.SUB_CATEGORY_3:
          setSubPart("SubPart 3");
          break;

        default:
          break;
      }
    }
  }, [ihmItemPosition]);

  useEffect(() => {
    const availableItemsForReplacement: IhmITemsForReplacementDTO[] = [];
    if (useGetAvailableIhmItemForReplacementQueryResponse.data) {
      for (const item of useGetAvailableIhmItemForReplacementQueryResponse.data
        .getIhmITemsForReplacement) {
        availableItemsForReplacement.push({
          availableQuantity: item.availableQuantity,
          ihmId: item.ihmId,
          installedQuantity: item.installedQuantity,
          productCode: item.productCode,
          productName: item.productName,
          totalQuantity: item.totalQuantity,
          unit: item.unit,
          referenceNumber: item.referenceNumber,
        });
      }
      setAvailableIhmItemForReplacement(availableItemsForReplacement);
    }
  }, [useGetAvailableIhmItemForReplacementQueryResponse]);

  useEffect(() => {
    if (initialized) {
      if (keycloak.realmAccess) {
        if (keycloak.realmAccess.roles) {
          if (keycloak.realmAccess.roles.find((x) => x === "ShipOwner")) {
            setUserRole("ShipOwner");
          } else if (keycloak.realmAccess.roles.find((x) => x === "Supplier")) {
            setUserRole("Supplier");
          } else if (
            keycloak.realmAccess.roles.find((x) => x === "Maintainer")
          ) {
            setUserRole("Maintainer");
          }
        }
      } else if (window.sessionStorage.getItem("userRole")) {
        setUserRole(window.sessionStorage.getItem("userRole")?.toString());
      }
    }
  }, [keycloak, initialized]);

  useEffect(  ()=>{
    if(imageUrl){
      const fetchData = async () => {
        const dimensions =  await getImageDimensions(imageUrl);
        
        setDimensionsOfImage({width: dimensions.width, height: dimensions.height});
        return dimensions;
      }
      fetchData();
      
    }
  
  
  },[imageUrl]);

  // ------------------------------------------------------------------ Handlers ---------------------------------------------------------------------
  // Helper function
  async function useQueryExec(drawingFullPath: string, token: string | null) {
    const response = await useFetchDrawingsQuery(drawingFullPath, token);
    if (response && response.data) {
      const imgFromBlob = URL.createObjectURL(response.data);

      if (imgFromBlob) {
        setImageUrl(imgFromBlob);
      }
    }
  }

  function getImageDimensions(file: string): Promise<ImageDimensions> {
    return new Promise (function (resolved, rejected) {
      const i = new Image();
      i.onload = function(){
        resolved({width: i.width, height: i.height})
      };
      i.src = file
    })
  }

  const handleIhmItemReplaceInput = (event: SelectChangeEvent<number>) => {
    if (
      event.target.value != undefined &&
      availableIhmItemForReplacement != undefined
    ) {
      const data = availableIhmItemForReplacement.find(
        (x) => x.ihmId === (event.target.value as number)
      );
      if (data != undefined) {
        let helperText = `Replaced Quantity (total : ${data.availableQuantity})*`;
        if (
          data.unit != undefined &&
          data.unit !== null &&
          data.unit.length > 0
        ) {
          helperText = `Replaced Quantity (total : ${data.availableQuantity} / ${data.unit} )*`;
        }
        setSelectedIhmITemsForReplacementAvQ(data.availableQuantity);
        setQuantityToReplace(data.availableQuantity);
      }
      setSelectedIhmITemsForReplacement(data);
    }
  };

  const handleProductName = (ihmItem: IhmITemsForReplacementDTO): string => {
    let selectedText = "";
    if (
      ihmItem.productName != undefined &&
      ihmItem.productName !== null &&
      ihmItem.productName.length > 0
    ) {
      selectedText = ihmItem.productName;
    }
    if (
      ihmItem.productCode != undefined &&
      ihmItem.productCode !== null &&
      ihmItem.productCode.length > 0
    ) {
      selectedText += ` - ${ihmItem.productCode}`;
    }

    if (
      ihmItem.referenceNumber != undefined &&
      ihmItem.referenceNumber !== null &&
      ihmItem.referenceNumber.length > 0
    ) {
      selectedText += ` - ${ihmItem.referenceNumber}`;
    }
    if (selectedIhmITemsForReplacement) {
      if (selectedIhmITemsForReplacement.ihmId === ihmItem.ihmId) {
        return selectedText.substring(0, 55);
      }
    }
    return selectedText;
  };

  const handleQuantityReplaceInput = (e: any) => {
    const value = e.target.value.replace(/,/g, "");
    let _quantity = "0";
    if (value.includes(".")) {
      const decimals = value.split(".");
      if (decimals.length == 2) {
        if (decimals[1].length == 0) {
          _quantity = value;
        } else {
          _quantity = parseFloat(value).toFixed(decimals[1].length);
        }
      } else {
        _quantity = parseFloat(value).toFixed(0);
      }
    } else {
      _quantity = parseFloat(value).toFixed(0);
    }
    const finalQuantity = parseFloat(_quantity);
    if (selectedIhmITemsForReplacement) {
      if (
        !(finalQuantity > selectedIhmITemsForReplacement?.availableQuantity)
      ) {
        setQuantityToReplace(finalQuantity);
      }
    }
  };

  const useHandlePositionReplaceSubmit = () => {
    if (
      ihmItemPosition &&
      selectedIhmITemsForReplacement &&
      quantityToReplace > 0
    ) {
      const newPositionThatWillReplaceTheExisting: IhmItemPositionDTO = {
        applicationOfPaint: applicationOfPaint,
        nameOfPaint: nameOfPaint,
        vesselDrawingViewPath: ihmItemPosition.vesselDrawingViewPath,
        nameOfEquipmentAndMachinery: nameOfEquipmentMachinery,
        nameOfStructuralElement: nameOfStructuralElement,
        partsWhereUsed: partsUsed,
        xBounds: ihmItemPosition.xBounds,
        yBounds: ihmItemPosition.yBounds,
        x: ihmItemPosition.x,
        y: ihmItemPosition.y,
        ihmItemId: selectedIhmITemsForReplacement?.ihmId,
        inventoryPart: ihmItemPosition.inventoryPart,
        inventorySubPart: ihmItemPosition.inventorySubPart,
        quantity: quantityToReplace,
        location: location,
      };
      useReplaceIhmPosition(
        ihmItemPosition.id,
        newPositionThatWillReplaceTheExisting
      );
    }
    if (!quantityToReplace || quantityToReplace <= 0) {
      alert("Please provide a Quantity to Replace.");
    }
  };

  const mapRefForZooming = useRef<any>(null);

  const handleZoomIn = () => {
    const map = mapRefForZooming.current;
    if (map) {
      map.setZoom(map.getZoom() + 1);
    }
  }
  
  const handleZoomOut = () => {
    const map = mapRefForZooming.current;
    if (map) {
      map.setZoom(map.getZoom() - 1);
    }
  }

  return (
    <>
    <LoadingButton
    variant="contained"
    onClick={handleZoomIn}
  >
  +
  </LoadingButton>
  <LoadingButton
    sx={{ml:"5px"}}
    variant="contained"
    onClick={handleZoomOut}
  >
  -
  </LoadingButton>
    <Scrollbar>
      {selectedVesselDrawing != undefined ? (
        <MapContainer
          className="map"
          zoom={6}
          scrollWheelZoom={false}
          crs={L.CRS.Simple}
          bounds={[
            [0, 0],
            [3000, 3000],
          ]}
          minZoom={-6}
          maxZoom={20}
          ref={mapRefForZooming}
          attributionControl={false}
          zoomControl={false}
          style={{ height: "300px", width: "550px", backgroundColor: "white" }}
        >
        {imageUrl && dimensionsOfImage &&
        (

            <ImageOverlay
              url={imageUrl}
              bounds={[
                [0, 0],
                [dimensionsOfImage.height*10, dimensionsOfImage.width*10],
              ]}
            />
        ) }

          <RemoveIhmInstallationMapComponent
            placeMarkerToMap={false}
            positions={
              ihmItemPosition !== null && ihmItemPosition != undefined
                ? [ihmItemPosition]
                : []
            }
            enableEdit={true}
            ref={mapComponentRef}
          />
        </MapContainer>
      ) : (
        <Box
          sx={{
            height: "80px",
          }}
        >
          <Typography variant="h6">
            Placement preview is not available.
          </Typography>
        </Box>
      )}

      <IhmMapperForm
        applicationOfPaint={applicationOfPaint}
        setApplicationOfPaint={setApplicationOfPaint}
        nameOfPaint={nameOfPaint}
        setNameOfPaint={setNameOfPaint}
        nameOfEquipmentMachinery={nameOfEquipmentMachinery}
        setNameOfEquipmentMachinery={setNameOfEquipmentMachinery}
        nameOfStructuralElement={nameOfStructuralElement}
        setNameOfStructuralElement={setNameOfStructuralElement}
        location={location}
        setLocation={setLocation}
        partsUsed={partsUsed}
        setPartsUsed={setPartsUsed}
        quantity={quantity}
        setQuantity={setQuantity}
        subPart={subPart}
        setSubPart={setSubPart}
        selectedNewIhmPosition={selectedNewIhmPosition}
        setSelectedNewIhmPosition={setSelectedNewIhmPosition}
        selectedExistingIhmPosition={selectedExistingIhmPosition}
        setSelectedExistingNewIhmPosition={setSelectedExistingNewIhmPosition}
        mapperAction={MapperActionCodesEnum.IS_REPLACE}
        isFromReplace={true}
      />

      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{ display: "flex", flexDirection: "column", flex: "3", mr: 1 }}
        >
          <Select
            variant="standard"
            labelId="select-ship-owner-label"
            id="select-ship-owner-ihm-items-to-replace"
            label="IHM Item"
            className={classesNew.selectionField}
            value={
              selectedIhmITemsForReplacement
                ? selectedIhmITemsForReplacement?.ihmId
                : ""
            }
            placeholder="Please select a product name to replace the existing installation"
            onChange={handleIhmItemReplaceInput}
          >
            {availableIhmItemForReplacement &&
              availableIhmItemForReplacement.map(
                (ihmItem: IhmITemsForReplacementDTO, index: number) => (
                  <MenuItem
                    key={index}
                    value={ihmItem.ihmId}
                    selected={
                      selectedIhmITemsForReplacement &&
                      selectedIhmITemsForReplacement?.ihmId === ihmItem.ihmId
                        ? true
                        : false
                    }
                  >
                    {handleProductName(ihmItem)}
                  </MenuItem>
                )
              )}
          </Select>
          <FormHelperText className={classesNew.helperText}>
            Please select a product name to replace the existing installation
          </FormHelperText>
        </Box>
        <TextField
          sx={{ flex: "1" }}
          variant="standard"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          helperText={"Quantity to Install *"}
          value={quantityToReplace}
          onChange={handleQuantityReplaceInput}
          id="quantity-for-replace"
          className={classesNew.fieldWithHelperText}
        />
      </Box>

      <Stack
        sx={{ flexDirection: "row", justifyContent: "flex-end", mt: 3, mb: 3 }}
      >
        <LoadingButton
          variant="contained"
          id="removeSelectedItem"
          onClick={useHandlePositionReplaceSubmit}
          component="label"
          loading={ReplaceIhmPositionProps.loading}
        >
          Replace
        </LoadingButton>
      </Stack>
    </Scrollbar>
    </>
  );
};

export default ReplaceIhmInstallationVesselMap;
