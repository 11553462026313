import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// material
import { Box, Container, Tabs, Tab, Typography } from "@mui/material";

// hooks
import { capitalCase } from "change-case";
import StoredIhmsTable from "../../homepage/StoredIhmsTable";
import InstalledIhmsTable from "../../homepage/InstalledIhmsTable";
import PendingForApprovalIhmsTable from "../../homepage/PendingForApprovalIhmsTable";
import { useKeycloak } from "@react-keycloak/web";
import NotPlacedIhmsTable from "../../homepage/NotPlacedIhmsTable";
// ---------------------------------------------------------------------------------------------------------------------------------------------------------
export default function IHMsTableContainer() {
  const [currentTab, setCurrentTab] = useState("Stored");
  const [userRole, setUserRole] = useState<undefined | string>(undefined);
  const { initialized, keycloak } = useKeycloak();

  
  useEffect(() => {
    if (initialized) {
      if (keycloak.realmAccess) {
        if (keycloak.realmAccess.roles) {
          if (keycloak.realmAccess.roles.find((x) => x === "ShipOwner")) {
            setUserRole("ShipOwner");
          } else if (keycloak.realmAccess.roles.find((x) => x === "Supplier")) {
            setUserRole("Supplier");
          } else if (
            keycloak.realmAccess.roles.find((x) => x === "Maintainer")
          ) {
            setUserRole("Maintainer");
          }
        }
      } else if (window.sessionStorage.getItem("userRole")) {
        setUserRole(window.sessionStorage.getItem("userRole")?.toString());
      }
    }
  }, [keycloak, initialized]);
  // --------------------------------- tabs ------------------------------------------------------
  const TABS = [
    {
      value: "Stored",
      component: (
        <>
          <StoredIhmsTable />
        </>
      ),
    },
    {
      value: "Installed",
      component: (
        <>
          <InstalledIhmsTable />
        </>
      ),
    },
    {
      value: "Pending for Approval",
      component: (
        <>
          <PendingForApprovalIhmsTable />
        </>
      ),
    },
    {
      value: "Not placed",
      component: (
        <>
          <NotPlacedIhmsTable />
        </>
      ),
    },
  ];

  // ----------------------------------------------------------------------------------------------
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 4,
        }}
      >
        <Typography variant="h3" sx={{ mt: 2 }}>
          IHM Items
        </Typography>
      </Box>
      <Container maxWidth={"xl"}>
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(e, value) => setCurrentTab(value)}
        >
          {TABS.map((tab) => {

            let displayTabs = true;
            if((userRole == "ShipOwner" || userRole == "Supplier") && tab.value == "Not placed"){
              displayTabs = false;
            }
            return displayTabs && <Tab
                disableRipple
                key={tab.value}
                label={capitalCase(tab.value)}
                value={tab.value}
              />
          })}
        </Tabs>

        {TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return (
            isMatched && (
              <Box key={tab.value} sx={{ marginLeft: -3 }}>
                {tab.component}
              </Box>
            )
          );
        })}
      </Container>
    </div>
  );
}
