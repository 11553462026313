import { useMutation, useQuery } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import L,{  LatLngTuple } from "leaflet";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { ImageOverlay, MapContainer } from "react-leaflet";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ApprovalStatusEnum,
  IHMDTO,
  IhmInventoryPart,
  IhmInventorySubPart,
  IhmItemPositionDTO,
} from "../../@types/IHM";
import { IhmITemsForReplacementDTO } from "../../@types/IhmITemsForReplacementDTO";
import { VesselDrawingDTO } from "../../@types/Vessel";
import { VesselDrawingPositionDTO } from "../../@types/VesselDrawingPosition";
import {
  CREATE_IHM_POSITION,
  DELETE_IHM_POSITION_AND_IMAGE,
  PLACE_IHM_POSITION_ON_MAP,
  REMOVE_IHM_INSTALLATION,
  REPLACE_IHM_POSITION,
  UPDATE_IHM_POSITION,
  UPDATE_IHM_POSITION_MAP_IMAGE,
  UPDATE_POSITION_COORDNATES_MUTATION,
} from "../../graphQL/Mutations";
import {
  GET_APP_USER_IS_GUEST_QUERY,
  GET_IHM_BY_ID_QUERY,
  GET_IHM_INSTALLED_ITEMS,
  GET_IHM_ITEMS_FOR_REPLACEMENT,
  GET_IHM_ITEMS_WITH_MATERIALS,
  GET_IHM_ITEM_QUERY,
  GET_IHM_STORED_ITEMS,
  GET_IHM_ITEM_MAP_IMAGES,
  GET_VESSEL_DRAWINGS_BY_IHM,
} from "../../graphQL/Queries";
import WarningModal from "../modal/WarningModal";
import Scrollbar from "../Scrollbar";
import { MapComponent } from "./MapComponent";
import { MapperActionsEnum } from "./Mapper";
import VesselMapWarningModal from "./VesselMapWarningModal";
import html2canvas from 'html2canvas';
import { id } from "date-fns/locale";
const serverUri = process.env.REACT_APP_SERVER_URI;

// ------------------------------------------------------------------ Define Queries ---------------------------------------------------------------------
const useFetchDrawingsQuery = async (
  drawingFullPath: string,
  token: string | null
) => {
  return axios.get(`${serverUri}/api/v1/drawing/${drawingFullPath}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob",
  });
};

const getAvailableIhmItemForReplacementQuery = (ihmId: number | undefined) =>
  useQuery(GET_IHM_ITEMS_FOR_REPLACEMENT, {
    variables: {
      ihmId: ihmId,
    },
    skip: ihmId == undefined,
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

const getAppUserIsGuestQuery = () =>
  useQuery(GET_APP_USER_IS_GUEST_QUERY, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });
interface VesselMapProps {
  addPosition: (
    lat: number,
    lng: number,
    description: string,
    productCode: string,
    productName: string
  ) => void;
  removePosition: (lat: number, lng: number) => void;
  vesselDrawingPosition: VesselDrawingPositionDTO | undefined;
  selectedDrawing: VesselDrawingDTO;
  ihmItem: IHMDTO | undefined;
  modalClose?: VoidFunction;
  positionToBePlaced?: IhmItemPositionDTO;
  setSelectedPositionId?: Dispatch<SetStateAction<number | undefined>>;
  isPreviewMode?: boolean;
  allowedAction: MapperActionsEnum;
  isFromModal: boolean;
  clearPositionInformation?: boolean;
  setClearPositionInformation?: Dispatch<SetStateAction<boolean>>;
  //updateInstallationPosition?: (lat: number, lng: number, id: number)=>void;
}

enum ActionCodes {
  IS_DELETE,
  IS_REMOVE,
  IS_REPLACE,
  IS_SAVE,
  IS_APPROVE,
  IS_PLACE_ON_MAP,
  RECAPTURE,
}
interface UserAction {
  actionCode: ActionCodes;
  msg: string;
}

const VesselMap = ({
  removePosition,
  clearPositionInformation,
  setClearPositionInformation,
  vesselDrawingPosition,
  selectedDrawing,
  ihmItem,
  modalClose,
  positionToBePlaced,
  setSelectedPositionId,
  isPreviewMode,
  allowedAction,
  isFromModal,
  //updateInstallationPosition
}: VesselMapProps) => {
  const theme = useTheme();
  const { selectedPositionId } = useParams();

  const [ihmItemPositions, setIhmItemPositions] = useState<
    IhmItemPositionDTO[]
  >([]);
  const { enqueueSnackbar } = useSnackbar();
  const [imageUrl, setImageUrl] = useState<string>();
  const [enableEdit, setEnableEdit] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);
  const [userAction, setUserAction] = useState<UserAction>();
  const [userResponseTrue, setUserResponseTrue] = useState<boolean>(false);

  // form fields
  const [applicationOfPaint, setApplicationOfPaint] = useState<string>("");
  const [nameOfPaint, setNameOfPaint] = useState<string>("");
  const [nameOfEquipmentMachinery, setNameOfEquipmentMachinery] =
    useState<string>("");
  const [nameOfStructuralElement, setNameOfStructuralElement] =
    useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [ihmItemId, setIhmItemId] = useState<number>(0);
  const [partsUsed, setPartsUsed] = useState<string>("");
  const [quantity, setQuantity] = useState<number>(0);
  const [description, setDescription] = useState<string>("");

  const [saveButtonLabel, setSaveButtonLabel] = useState<string>("Save");
  const [quantityToReplaceHelperText, setQuantityToReplaceHelperText] =
    useState<string>("Replaced Quantity *");
  const [quantityToReplace, setQuantityToReplace] = useState<number>(0);
  const [selectedIhmITemsForReplacement, setSelectedIhmITemsForReplacement] =
    useState<IhmITemsForReplacementDTO | undefined>(undefined);
  const [availableIhmItemForReplacement, setAvailableIhmItemForReplacement] =
    useState<IhmITemsForReplacementDTO[]>([]);
  const [enableQuantityToReplace, setEnableQuantityToReplace] =
    useState<boolean>(false);
  const [changePositionInstallation, setChangePositionInstallation] = useState<boolean>(false);

  const [subPart, setSubPart] = useState<string>("SubPart 1");
  const [availableSubParts, setAvailableSubParts] = useState<string[]>([
    "SubPart 1",
    "SubPart 2",
    "SubPart 3",
  ]);

  const [userRole, setUserRole] = useState<undefined | string>(undefined);
  const { initialized, keycloak } = useKeycloak();
  const [selectedLocation, setSelectedLocation] = useState<
    IhmItemPositionDTO | undefined
  >(undefined);
  const [selectedLocationIsDeleted, setSelectedLocationIsDeleted] =
    useState(false);
  // marker attributes
  const [selectedLat, setSelectedLat] = useState(0);
  const [selectedLng, setSelectedLng] = useState(0);
  const [selectedNewIhmPosition, setSelectedNewIhmPosition] =
    useState<IhmItemPositionDTO | null>(null);
  const [selectedExistingIhmPosition, setSelectedExistingNewIhmPosition] =
    useState<IhmItemPositionDTO | null>(null);
  const [canAddPinToMap, setCanAddPinToMap] = useState<boolean>(false);
  const [preselectedPositionId, setPreselectedPositionId] = useState<number>();
  const [screenshotImage, setScreenshotImage] = useState<string | undefined>(undefined);
  const [files, setFiles] = useState<any>(null);

  const mapRef = useRef<HTMLDivElement>(null);
  const mapComponentRef = useRef<any>();

  const token = window.sessionStorage.getItem("jwt");

  // Use Queries
  const getAvailableIhmItemForReplacementQueryResp =
    getAvailableIhmItemForReplacementQuery(ihmItem?.id);

  const useGetAppUserIsGuestQuery = getAppUserIsGuestQuery();

  // ------------------------------------------------------------------ Define Mutations ---------------------------------------------------------------------
  // Update Map Image
  const [UpdateMapImage, UpdateMapImageProps] = useMutation(
    UPDATE_IHM_POSITION_MAP_IMAGE,
    {
      fetchPolicy: "network-only",
    }
  );
  const useRecaptureMapPicture = (id: number, archive: File) => {
    UpdateMapImage({
      variables: {
        id: id,
        archive: archive
      },
      refetchQueries: [GET_IHM_BY_ID_QUERY, GET_IHM_ITEM_MAP_IMAGES],
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
      onCompleted(dt) {
        toast.configure();
        toast.success(`Update was successfull`);
      }
    });
  }

  const [
    UpdatePositionCoordinatesMutation,
    UpdatePositionCoordinatesMutationProps,
  ] = useMutation(UPDATE_POSITION_COORDNATES_MUTATION, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err}`);
    },
    fetchPolicy: "network-only",
  });
  const useUpdatePositionCoordinatesMutation = (
    positionId: number,
    x: number,
    y: number,
    archive: File
  ) => {
    UpdatePositionCoordinatesMutation({
      variables: {
        id: positionId,
        x: x,
        y: y,
        archive: archive
      },
      onCompleted(dt) {
        toast.configure();
        toast.success(` Please wait `);
        useRecaptureMapPicture(positionId, archive);
      },
      onError(dt){
        toast.configure();
        toast.error(` Update failed `);
      }
  });
}



  const updateInstallationPosition = async (lat: number, lng: number, id: number)=>{
    if(id){
      mapComponentRef.current.makeMarkersBlue();
      const selectedFile = await captureScreenshot();
      const data = await readUploadedFile(selectedFile);
      useUpdatePositionCoordinatesMutation(id, lng, lat, data);
    }
  }


  // Create
  const [CreateIhmPosition, CreateIhmPositionProps] = useMutation(
    CREATE_IHM_POSITION,
    {
      fetchPolicy: "network-only",
    }
  );
  const useCreateIhmPosition = (archive: File, newPosition: IhmItemPositionDTO) => {
    CreateIhmPosition({
      variables: {
        createIhmPositionInput: newPosition,
        archive: archive
      },
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
      refetchQueries: [GET_IHM_BY_ID_QUERY, GET_IHM_ITEM_MAP_IMAGES],
      onCompleted(dt) {
        modalClose && modalClose();
        if (dt.createIhmPosition.id) {
          enqueueSnackbar(`Position created successfully`, {
            variant: "success",
          });
          clearSelectedLocation();
          setEnableEdit(false);
          mapComponentRef.current.resetAllMarkers();
        }
      },
    });
  };
  // Replace
  const [ReplaceIhmPosition, ReplaceIhmPositionProps] = useMutation(
    REPLACE_IHM_POSITION,
    {
      fetchPolicy: "network-only",
    }
  );
  const useReplaceIhmPosition = (
    id: number | undefined,
    newPosition: IhmItemPositionDTO
  ) => {
    ReplaceIhmPosition({
      variables: {
        id: id,
        createIhmPositionInput: newPosition,
      },
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
      onCompleted(dt) {
        if (dt.replaceInstallation) {
          enqueueSnackbar(`Position replaced successfully`, {
            variant: "success",
          });
          clearSelectedLocation();
          setEnableEdit(false);
          mapComponentRef.current.resetAllMarkers();
        }
      },
      refetchQueries: [GET_IHM_BY_ID_QUERY, GET_IHM_ITEM_MAP_IMAGES],
    });
  };
  // Update
  const [UpdateIhmPosition, UpdateIhmPositionProps] = useMutation(
    UPDATE_IHM_POSITION,
    {
      fetchPolicy: "network-only",
    }
  );
  const useUpdateIhmPosition = (updatePosition: IhmItemPositionDTO) => {
    UpdateIhmPosition({
      variables: {
        updateIhmPositionInput: updatePosition,
      },
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
      refetchQueries: [GET_IHM_ITEMS_WITH_MATERIALS, GET_IHM_BY_ID_QUERY, GET_IHM_ITEM_MAP_IMAGES],
      onCompleted(dt) {
        modalClose && modalClose();
        if (dt.updateIhmPosition.id) {
          enqueueSnackbar(`Position updated successfully`, {
            variant: "success",
          });
          mapComponentRef.current.resetAllMarkers();
        }
        clearSelectedLocation();
        setEnableEdit(false);
      },
    });
  };
  // Delete Installation
  const [DeleteIhmPositionByLatLng, DeleteIhmPositionByLatLngProps] =
    useMutation(DELETE_IHM_POSITION_AND_IMAGE, {
      fetchPolicy: "network-only",
    });
  const useDeleteIhmPositionByLatLng = () => {
    DeleteIhmPositionByLatLng({
      variables: {
        positionId: selectedLocation?.id,
      },
      refetchQueries: [
        GET_IHM_BY_ID_QUERY,
        GET_IHM_ITEM_QUERY,
        GET_VESSEL_DRAWINGS_BY_IHM,
        GET_IHM_STORED_ITEMS,
        GET_IHM_ITEM_MAP_IMAGES
      ],
      onCompleted() {
        enqueueSnackbar(`Position Deleted  successfully`, {
          variant: "success",
        });
        clearSelectedLocation();
        setEnableEdit(false);
        mapComponentRef.current.resetAllMarkers();
      },
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
    });
  };
  // Remove Installation
  const [RemoveInstallation, RemoveInstallationProps] = useMutation(
    REMOVE_IHM_INSTALLATION,
    {
      fetchPolicy: "network-only",
    }
  );
  const useRemoveInstallation = (id: number | undefined) => {
    RemoveInstallation({
      variables: {
        id: selectedLocation?.id,
      },
      refetchQueries: [
        GET_IHM_BY_ID_QUERY,
        GET_IHM_ITEM_QUERY,
        GET_VESSEL_DRAWINGS_BY_IHM,
        GET_IHM_STORED_ITEMS,
        GET_IHM_ITEM_MAP_IMAGES
      ],
      onCompleted() {
        enqueueSnackbar(`Position Removed successfully`, {
          variant: "success",
        });
        clearSelectedLocation();
        setEnableEdit(false);
        mapComponentRef.current.resetAllMarkers();
      },
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
    });
  };
  // Place Position On Map
  const [PlaceIhmPositionOnMap, PlaceIhmPositionOnMapProps] = useMutation(
    PLACE_IHM_POSITION_ON_MAP,
    {
      fetchPolicy: "network-only",
    }
  );
  const usePlaceIhmPositionOnMap = (updatePosition: IhmItemPositionDTO, archive: File) => {
    PlaceIhmPositionOnMap({
      variables: {
        updateIhmPositionInput: updatePosition,
        archive: archive
      },
      refetchQueries:
        isFromModal === true
          ? [GET_IHM_ITEMS_WITH_MATERIALS, GET_IHM_INSTALLED_ITEMS]
          : [GET_IHM_BY_ID_QUERY, GET_IHM_ITEM_MAP_IMAGES, GET_IHM_INSTALLED_ITEMS],
      onCompleted(dt) {
        modalClose && modalClose();
        if (dt.placeInstalledItemToMap.id) {
          if (allowedAction === MapperActionsEnum.IS_PLACE_ON_MAP) {
            setCanAddPinToMap(true);
            setSelectedNewIhmPosition(null);
          }
          enqueueSnackbar(`Position placed successfully`, {
            variant: "success",
          });
        }
      },
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
    });
  };

  // ------------------------------------------------------------------ Hooks --------------------------------------------------------------
  useEffect(() => {
    if (
      selectedPositionId &&
      (preselectedPositionId == undefined || preselectedPositionId === null)
    ) {
      setPreselectedPositionId(parseInt(selectedPositionId));
      selectMarker(undefined, undefined, parseInt(selectedPositionId));
    }
  }, [selectedPositionId]);

  useEffect(() => {
    if (clearPositionInformation === true && setClearPositionInformation) {
      mapComponentRef.current.resetAllMarkers();
      setClearPositionInformation(false);
      clearSelectedLocation();
    }
  }, [clearPositionInformation, setClearPositionInformation]);

  useEffect(() => {
    if (initialized) {
      if (keycloak.realmAccess) {
        if (keycloak.realmAccess.roles) {
          if (keycloak.realmAccess.roles.find((x) => x === "ShipOwner")) {
            setUserRole("ShipOwner");
          } else if (keycloak.realmAccess.roles.find((x) => x === "Supplier")) {
            setUserRole("Supplier");
          } else if (
            keycloak.realmAccess.roles.find((x) => x === "Maintainer")
          ) {
            setUserRole("Maintainer");
          }
        }
      } else if (window.sessionStorage.getItem("userRole")) {
        setUserRole(window.sessionStorage.getItem("userRole")?.toString());
      }
    }
  }, [keycloak, initialized]);

  useEffect(() => {
    if (setSelectedPositionId && selectedLocation) {
      setSelectedPositionId(selectedLocation.id);
    } else if (setSelectedPositionId) {
      setSelectedPositionId(undefined);
    }
    if (selectedLocation?.isDeleted != undefined) {
      setSelectedLocationIsDeleted(selectedLocation?.isDeleted);
    }
  }, [setSelectedPositionId, selectedLocation]);

  useEffect(() => {
    if (allowedAction === MapperActionsEnum.IS_PLACE_ON_MAP) {
      setEnableEdit(true);
      setCanAddPinToMap(true);
    } else if (allowedAction === MapperActionsEnum.IS_APPROVE) {
      setEnableEdit(true);
    } else if (allowedAction === MapperActionsEnum.IS_VIEW) {
      setEnableEdit(false);
      setCanAddPinToMap(false);
    } else if (allowedAction === MapperActionsEnum.IS_INSTALL_IHM_ITEM_TO_MAP) {
      setEnableEdit(true);
      setCanAddPinToMap(true);
    }
  }, [allowedAction]);

  useEffect(() => {
    if (selectedDrawing) {
      const parsedPath = selectedDrawing.path?.split("/").join("-");
      if (parsedPath) {
        queryExec(parsedPath, token);
      }
      if (ihmItem?.ihmPosition && ihmItem?.ihmPosition.length > 0) {
        const newPosArray = [];
        for (const position of ihmItem.ihmPosition) {
          if (position.vesselDrawingViewPath == selectedDrawing.path) {
            newPosArray.push(position);
          }
        }
        setIhmItemPositions(newPosArray);
      }
    }
  }, [selectedDrawing, ihmItem?.ihmPosition]);

  useEffect(() => {
    if (positionToBePlaced) {
      setApplicationOfPaint(
        positionToBePlaced.applicationOfPaint
          ? positionToBePlaced.applicationOfPaint
          : ""
      );
      setNameOfPaint(
        positionToBePlaced.nameOfPaint ? positionToBePlaced.nameOfPaint : ""
      );
      setNameOfEquipmentMachinery(
        positionToBePlaced.nameOfEquipmentAndMachinery
          ? positionToBePlaced.nameOfEquipmentAndMachinery
          : ""
      );
      setNameOfStructuralElement(
        positionToBePlaced.nameOfStructuralElement
          ? positionToBePlaced.nameOfStructuralElement
          : ""
      );
      setPartsUsed(
        positionToBePlaced.partsWhereUsed
          ? positionToBePlaced.partsWhereUsed
          : ""
      );
      setQuantity(
        positionToBePlaced.quantity ? positionToBePlaced.quantity : 0
      );
      setDescription(
        positionToBePlaced.description ? positionToBePlaced.description : ""
      );
      setLocation(
        positionToBePlaced.location ? positionToBePlaced.location : ""
      );
      setIhmItemId(
        positionToBePlaced.ihmItemId ? positionToBePlaced.ihmItemId : 0
      );

      if (
        positionToBePlaced.inventorySubPart !== null &&
        positionToBePlaced.inventorySubPart != undefined
      ) {
        switch (positionToBePlaced.inventorySubPart) {
          case IhmInventorySubPart.SUB_CATEGORY_1:
            setSubPart(availableSubParts[0]);
            break;
          case IhmInventorySubPart.SUB_CATEGORY_2:
            setSubPart(availableSubParts[1]);
            break;
          case IhmInventorySubPart.SUB_CATEGORY_3:
            setSubPart(availableSubParts[2]);
            break;
          default:
            break;
        }
      }
    }
  }, [positionToBePlaced]);

  useEffect(() => {
    if (userAction != undefined && userResponseTrue == true) {
      switch (userAction.actionCode) {
        case ActionCodes.IS_DELETE:
          deleteSelectedItem();
          break;
        case ActionCodes.IS_REMOVE:
          removeSelectedInstallation();
          break;
        case ActionCodes.IS_REPLACE:
          handlePositionReplaceSubmit();
          break;
        case ActionCodes.IS_SAVE:
          handlePositionSubmit();
          break;
        case ActionCodes.IS_PLACE_ON_MAP:
          handlePlacePositionToMap();
          break;
        case ActionCodes.RECAPTURE:
          handleRecapture();
          break;

        default:
          break;
      }
      setUserResponseTrue(false);
    }
  }, [userResponseTrue, userAction]);

  useEffect(() => {
    const availableItemsForReplacement: IhmITemsForReplacementDTO[] = [];
    if (getAvailableIhmItemForReplacementQueryResp.data) {
      for (const item of getAvailableIhmItemForReplacementQueryResp.data
        .getIhmITemsForReplacement) {
        availableItemsForReplacement.push({
          availableQuantity: item.availableQuantity,
          ihmId: item.ihmId,
          installedQuantity: item.installedQuantity,
          productCode: item.productCode,
          productName: item.productName,
          totalQuantity: item.totalQuantity,
          unit: item.unit,
          referenceNumber: item.referenceNumber,
        });
      }
      setAvailableIhmItemForReplacement(availableItemsForReplacement);
    }
  }, [getAvailableIhmItemForReplacementQueryResp]);

  useEffect(() => {
    if (enableEdit === false) {
      setSelectedIhmITemsForReplacement(undefined);
    }
  }, [enableEdit, selectedLocation, preselectedPositionId]);

  // ------------------------------------------------------------------ Handlers ---------------------------------------------------------------------
  // Helper function
  async function queryExec(drawingFullPath: string, token: string | null) {
    const response = await useFetchDrawingsQuery(drawingFullPath, token);
    if (response && response.data) {
      const imgFromBlob = URL.createObjectURL(response.data);

      if (imgFromBlob) {
        setImageUrl(imgFromBlob);
      }
    }
  }

  const handleIhmItemReplaceInput = (event: SelectChangeEvent<number>) => {
    if (
      event.target.value != undefined &&
      availableIhmItemForReplacement != undefined
    ) {
      const data = availableIhmItemForReplacement.find(
        (x) => x.ihmId === (event.target.value as number)
      );
      if (data != undefined) {
        let helperText = `Replaced Quantity (total : ${data.availableQuantity})*`;
        if (
          data.unit != undefined &&
          data.unit !== null &&
          data.unit.length > 0
        ) {
          helperText = `Replaced Quantity (total : ${data.availableQuantity} / ${data.unit} )*`;
        }
        setQuantityToReplaceHelperText(helperText);
        setQuantityToReplace(data.availableQuantity);
      }
      setSelectedIhmITemsForReplacement(data);
    }
  };

  const manageNewPosition = (
    lat: number,
    lng: number,
    applicationOfPaint: string,
    nameOfPaint: string,
    nameOfEquipmentAndMachinery: string,
    nameOfStructuralElement: string,
    partsWhereUsed: string
  ) => {
    if (ihmItem?.id && selectedDrawing) {
      clearSelectedLocation();
      const newMarker: IhmItemPositionDTO = {
        description: `${ihmItem.productName} - ${ihmItem.productCode}`,
        quantity: quantity,
        vesselDrawingViewPath: `${selectedDrawing.path}`,
        xBounds: 1000,
        yBounds: 2000,
        x: lng,
        y: lat,
        ihmItemId: ihmItem.id,
        applicationOfPaint: applicationOfPaint,
        nameOfPaint: nameOfPaint,
        nameOfEquipmentAndMachinery: nameOfEquipmentAndMachinery,
        nameOfStructuralElement: nameOfStructuralElement,
        partsWhereUsed: partsWhereUsed,
        inventoryPart: IhmInventoryPart.PART_1,
        location: location,
      };
      setSelectedNewIhmPosition(newMarker);
      setCanAddPinToMap(false);
    }
  };

  // If component is rendered from SinglePositionMapper then it already has a preselected position and
  // it doesnt need the form to clear
  const clearSelectedLocation = () => {
    if (positionToBePlaced == undefined) {
      setSelectedLocation(undefined);
      setSelectedExistingNewIhmPosition(null);
      setApplicationOfPaint("");
      setNameOfPaint("");
      setNameOfEquipmentMachinery("");
      setNameOfStructuralElement("");
      setLocation("");
      setPartsUsed("");
      setQuantity(0);
      setDescription("");
      setCanAddPinToMap(true);
      setPreselectedPositionId(undefined);
      setSelectedIhmITemsForReplacement(undefined);
      setQuantityToReplace(0);
      setQuantityToReplaceHelperText("Replaced Quantity *");
    }
  };

  const selectMarker = (lat?: number, lng?: number, _positionId?: number) => {
    let selectedIhmPosition: IhmItemPositionDTO[] | undefined;
    if (_positionId) {
      selectedIhmPosition = ihmItem?.ihmPosition?.filter(
        (x) => x.id === _positionId
      );
      if (
        selectedIhmPosition &&
        selectedIhmPosition[0] &&
        selectedIhmPosition[0].y &&
        selectedIhmPosition[0].x
      ) {
        setSelectedLat(selectedIhmPosition[0].y);
        setSelectedLng(selectedIhmPosition[0].x);
      }
    } else if (lat && lng) {
      setSelectedLat(lat);
      setSelectedLng(lng);
      selectedIhmPosition = ihmItem?.ihmPosition?.filter(
        (x) => x.x === lng && x.y === lat
      );
    }

    if (
      selectedIhmPosition != undefined &&
      selectedIhmPosition !== null &&
      selectedIhmPosition.length > 0
    ) {
      setCanAddPinToMap(false);
      if (
        selectedIhmPosition[0].approvalStatus !== ApprovalStatusEnum.APPROVED &&
        userRole === "Maintainer"
      ) {
        setSaveButtonLabel("Approve");
      } else if (userRole === "ShipOwner") {
        setSaveButtonLabel("Edit");
      }
      if (selectedIhmPosition.length == 1) {
        setSelectedLocation(selectedIhmPosition[0]);
        setSelectedExistingNewIhmPosition(selectedIhmPosition[0]);
        setSelectedNewIhmPosition(null);
        if (allowedAction !== MapperActionsEnum.IS_VIEW) {
          setCanAddPinToMap(true);
        }
        setApplicationOfPaint(
          selectedIhmPosition[0].applicationOfPaint
            ? selectedIhmPosition[0].applicationOfPaint
            : ""
        );
        setNameOfPaint(
          selectedIhmPosition[0].nameOfPaint
            ? selectedIhmPosition[0].nameOfPaint
            : ""
        );
        setNameOfEquipmentMachinery(
          selectedIhmPosition[0].nameOfEquipmentAndMachinery
            ? selectedIhmPosition[0].nameOfEquipmentAndMachinery
            : ""
        );
        setNameOfStructuralElement(
          selectedIhmPosition[0].nameOfStructuralElement
            ? selectedIhmPosition[0].nameOfStructuralElement
            : ""
        );
        setPartsUsed(
          selectedIhmPosition[0].partsWhereUsed
            ? selectedIhmPosition[0].partsWhereUsed
            : ""
        );
        setQuantity(
          selectedIhmPosition[0].quantity ? selectedIhmPosition[0].quantity : 0
        );
        setDescription(
          ihmItem ? `${ihmItem.productName} - ${ihmItem.productCode}` : ""
        );
        setLocation(
          selectedIhmPosition[0].location ? selectedIhmPosition[0].location : ""
        );
        setIhmItemId(
          selectedIhmPosition[0].ihmItemId
            ? selectedIhmPosition[0].ihmItemId
            : 0
        );

        if (
          selectedIhmPosition[0].inventorySubPart !== null &&
          selectedIhmPosition[0].inventorySubPart != undefined
        ) {
          switch (selectedIhmPosition[0].inventorySubPart) {
            case IhmInventorySubPart.SUB_CATEGORY_1:
              setSubPart(availableSubParts[0]);
              break;
            case IhmInventorySubPart.SUB_CATEGORY_2:
              setSubPart(availableSubParts[1]);
              break;
            case IhmInventorySubPart.SUB_CATEGORY_3:
              setSubPart(availableSubParts[2]);
              break;
            default:
              break;
          }
        }
      }
    }
  };

  const handleUserActions = (userActionObject: UserAction) => {
    setUserAction(userActionObject);
    setIsWarningModalOpen(true);
  };

  const handleCancel = () => {
    if (isFromModal === true && modalClose) {
      modalClose();
    }
    if (allowedAction === MapperActionsEnum.IS_PLACE_ON_MAP) {
      setEnableEdit(true);
      setCanAddPinToMap(true);
    }
    clearSelectedLocation();
    setSelectedNewIhmPosition(null);
    mapComponentRef.current.resetAllMarkers();
  };

  const handlePositionReplaceSubmit = () => {
    if (
      selectedLocation &&
      selectedIhmITemsForReplacement &&
      quantityToReplace > 0
    ) {
      const newPositionThatWillReplaceTheExisting: IhmItemPositionDTO = {
        applicationOfPaint: selectedLocation.applicationOfPaint,
        nameOfPaint: selectedLocation.nameOfPaint,
        vesselDrawingViewPath: selectedLocation.vesselDrawingViewPath,
        nameOfEquipmentAndMachinery:
          selectedLocation.nameOfEquipmentAndMachinery,
        nameOfStructuralElement: selectedLocation.nameOfStructuralElement,
        partsWhereUsed: selectedLocation.partsWhereUsed,
        xBounds: selectedLocation.xBounds,
        yBounds: selectedLocation.yBounds,
        x: selectedLocation.x,
        y: selectedLocation.y,
        ihmItemId: selectedIhmITemsForReplacement?.ihmId,
        inventoryPart: selectedLocation.inventoryPart,
        inventorySubPart: selectedLocation.inventorySubPart,
        quantity: quantityToReplace,
        location: selectedLocation.location,
      };
      useReplaceIhmPosition(
        selectedLocation.id,
        newPositionThatWillReplaceTheExisting
      );
    }
  };

  const readUploadedFile = async (inputFile: any): Promise<File> => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
      temporaryFileReader.readAsDataURL(inputFile);
      temporaryFileReader.onload = () => {
        resolve(inputFile);
      }
    });
  };

  const handlePositionSubmit = async () => {
    if (selectedNewIhmPosition !== null) {
      selectedNewIhmPosition.imageOfPositionOfTheItem = screenshotImage != undefined && screenshotImage != null ? JSON.stringify(screenshotImage) : undefined;
       mapComponentRef.current.makeMarkersBlue();
      const selectedFile = await captureScreenshot();
      switch (subPart) {
        case "SubPart 1":
          if (
            applicationOfPaint != "" &&
            nameOfPaint != "" &&
            location != "" &&
            quantity != 0
          ) {
            selectedNewIhmPosition.inventorySubPart =
              IhmInventorySubPart.SUB_CATEGORY_1;
            const data = await readUploadedFile(selectedFile)
            useCreateIhmPosition(
              data, selectedNewIhmPosition
            );

          } else {
            alert("please provide valid information for all fields");
          }
          break;
        case "SubPart 2":
          if (
            nameOfEquipmentMachinery != "" &&
            partsUsed != "" &&
            location != "" &&
            quantity != 0
          ) {
            selectedNewIhmPosition.inventorySubPart =
              IhmInventorySubPart.SUB_CATEGORY_2;

            const data = await readUploadedFile(selectedFile)
            useCreateIhmPosition(
              data, selectedNewIhmPosition
            );

          } else {
            alert("please provide valid information for all fields");
          }
          break;
        case "SubPart 3":
          if (
            nameOfStructuralElement != "" &&
            partsUsed != "" &&
            location != "" &&
            quantity != 0
          ) {
            selectedNewIhmPosition.inventorySubPart =
              IhmInventorySubPart.SUB_CATEGORY_3;
            const data = await readUploadedFile(selectedFile)
            useCreateIhmPosition(
              data, selectedNewIhmPosition
            );

          } else {
            alert("please provide valid information for all fields");
          }
          break;

        default:
          break;
      }
    } else if (selectedExistingIhmPosition !== null) {
      const updatedIhmPositionInput: IhmItemPositionDTO = {
        id: selectedExistingIhmPosition.id,
        applicationOfPaint: selectedExistingIhmPosition.applicationOfPaint,
        description: selectedExistingIhmPosition.description,
        location: selectedExistingIhmPosition.location,
        nameOfEquipmentAndMachinery:
          selectedExistingIhmPosition.nameOfEquipmentAndMachinery,
        nameOfPaint: selectedExistingIhmPosition.nameOfPaint,
        nameOfStructuralElement:
          selectedExistingIhmPosition.nameOfStructuralElement,
        partsWhereUsed: selectedExistingIhmPosition.partsWhereUsed,
        quantity: selectedExistingIhmPosition.quantity,
        x: selectedExistingIhmPosition.x,
        xBounds: selectedExistingIhmPosition.xBounds,
        y: selectedExistingIhmPosition.y,
        yBounds: selectedExistingIhmPosition.yBounds,
      };
      useUpdateIhmPosition(updatedIhmPositionInput);
    }
    setCanAddPinToMap(true);
  };

  const handlePlacePositionToMap = async () => {
    if (selectedNewIhmPosition && positionToBePlaced) {
      positionToBePlaced.x = selectedNewIhmPosition.x;
      positionToBePlaced.y = selectedNewIhmPosition.y;
      positionToBePlaced.vesselDrawingViewPath = selectedDrawing.path;
      mapComponentRef.current.makeMarkersBlue();
      const selectedFile = await captureScreenshot();
      const data = await readUploadedFile(selectedFile)
      usePlaceIhmPositionOnMap(positionToBePlaced, data);
    }
  };

  const handleRecapture = async () => {
    mapComponentRef.current.makeMarkersBlue();
    const selectedFile = await captureScreenshot();
    const data = await readUploadedFile(selectedFile);

    if (selectedPositionId) {
      useRecaptureMapPicture(parseInt(selectedPositionId), data);
    }

  }

  const removeSelectedInstallation = () => {
    if (selectedLocation) {
      useRemoveInstallation(selectedLocation?.id);
    }
  };



  const deleteSelectedItem = () => {
    if (selectedLat != 0 && selectedLng != 0) {
      if (enableEdit) {
        mapComponentRef.current.removeMarker(selectedLat, selectedLng);
        removePosition(selectedLat, selectedLng);

        const LatLng = {
          Lat: selectedLat,
          Lng: selectedLng,
        };

        useDeleteIhmPositionByLatLng();
      }
    } else {
      setIsModalOpen(true);
    }
  };

  const handleQuantityReplaceInput = (e: any) => {
    const data = e.target.value as string;
    let quantityInput = parseInt(data);
    if (
      selectedIhmITemsForReplacement != undefined &&
      quantityInput != undefined &&
      quantityInput > selectedIhmITemsForReplacement?.availableQuantity
    ) {
      setQuantityToReplace(selectedIhmITemsForReplacement?.availableQuantity);
    } else {
      if (
        selectedIhmITemsForReplacement != undefined &&
        quantityInput != undefined
      ) {
        setQuantityToReplace(quantityInput);
      } else {
        quantityInput = 0;
        setQuantityToReplace(quantityInput);
      }
    }
  };

  const handleQuantityInput = (e: any) => {
    const value = e.target.value.replace(/,/g, "");
    let _quantity = "0";
    if (value.includes(".")) {
      const decimals = value.split(".");
      if (decimals.length == 2) {
        if (decimals[1].length == 0) {
          _quantity = value;
        } else {
          _quantity = parseFloat(value).toFixed(decimals[1].length);
        }
      } else {
        _quantity = parseFloat(value).toFixed(0);
      }
    } else {
      _quantity = parseFloat(value).toFixed(0);
    }
    const finalQuantity = parseFloat(_quantity);
    setQuantity(finalQuantity);
    if (
      selectedNewIhmPosition != undefined &&
      selectedNewIhmPosition !== null
    ) {
      selectedNewIhmPosition.quantity = finalQuantity;
      setSelectedNewIhmPosition(selectedNewIhmPosition);
    }
    if (
      selectedExistingIhmPosition != undefined &&
      selectedExistingIhmPosition !== null
    ) {
      selectedExistingIhmPosition.quantity = finalQuantity;
      setSelectedExistingNewIhmPosition(selectedExistingIhmPosition);
    }
  };

  const handleProductName = (ihmItem: IhmITemsForReplacementDTO): string => {
    let selectedText = "";
    if (
      ihmItem.productName != undefined &&
      ihmItem.productName !== null &&
      ihmItem.productName.length > 0
    ) {
      selectedText = ihmItem.productName;
    }
    if (
      ihmItem.productCode != undefined &&
      ihmItem.productCode !== null &&
      ihmItem.productCode.length > 0
    ) {
      selectedText += ` - ${ihmItem.productCode}`;
    }
    if (
      ihmItem.referenceNumber != undefined &&
      ihmItem.referenceNumber !== null &&
      ihmItem.referenceNumber.length > 0
    ) {
      selectedText += ` - ${ihmItem.referenceNumber}`;
    }

    if (selectedIhmITemsForReplacement) {
      if (selectedIhmITemsForReplacement.ihmId === ihmItem.ihmId) {
        return selectedText.substring(0, 55);
      }
    }
    return selectedText;
  };

  const handleSubPartInput = (event: SelectChangeEvent) => {
    const selectedSP = event.target.value as string;
    const foundSP = availableSubParts.filter((sp) => sp == selectedSP)[0];
    setSubPart(foundSP);
  };


  const captureScreenshot = async (): Promise<Blob | null> => {
    let file = null;
    if (mapRef.current) {
      const canvas = await html2canvas(mapRef.current, { scrollY: -window.scrollY });
      if (canvas) {
        
        const image = canvas.toDataURL();
        const blobBin = atob(image.split(',')[1]);
        const array = [];
        for (let i = 0; i < blobBin.length; i++) {
          array.push(blobBin.charCodeAt(i));
        }
        file = new Blob([new Uint8Array(array)], { type: 'image/png' });
        setFiles(file);
      }
    }
    return file;
  };
  const [zoomValue, setZoomValue] = useState<number>(1);





  const mapRefForZooming = useRef<any>(null);

  const handleZoomIn = () => {
    const map = mapRefForZooming.current;
    if (map) {
      map.setZoom(map.getZoom() + 0.25);
    }
  }

  const handleZoomOut = () => {
    const map = mapRefForZooming.current;
    if (map) {
      map.setZoom(map.getZoom() - 0.25);
    }
  }



  interface ImageDimensions {
    width: number;
    height: number;
  }

  function getImageDimensions(file: string): Promise<ImageDimensions> {
    return new Promise(function (resolved, rejected) {
      const i = new Image();
      i.onload = function () {
        resolved({ width: i.width, height: i.height })
      };
      i.src = file
    })
  }
  const [dimensionsOfImage, setDimensionsOfImage] = useState<ImageDimensions>();
  useEffect(() => {
    if (imageUrl) {
      const fetchData = async () => {
        const dimensions = await getImageDimensions(imageUrl);

        setDimensionsOfImage({ width: dimensions.width, height: dimensions.height });
        return dimensions;
      }
      fetchData();

    }


  }, [imageUrl]);


  interface ImageDimensions {
    width: number;
    height: number;
    
  }


  const calculateBoundsOfMapContainer = (dimensionsOfImage: ImageDimensions) : LatLngTuple[] =>{
    const bounds : LatLngTuple[] = [];
    let maxWidth = 0;
    let maxHeight = 0;
    if(dimensionsOfImage.width >= dimensionsOfImage.height){
      
      maxWidth = dimensionsOfImage.width * ( (dimensionsOfImage.width)/ dimensionsOfImage.height);
      maxHeight = dimensionsOfImage.height * ( (dimensionsOfImage.width)/ dimensionsOfImage.height);
      
      bounds.push([0,0]);
      bounds.push([maxHeight,maxWidth]);
      

    }else{
      
      maxWidth = dimensionsOfImage.width * ( (dimensionsOfImage.width)/ dimensionsOfImage.height);
      maxHeight = dimensionsOfImage.height * ( (dimensionsOfImage.width)/ dimensionsOfImage.height);
      
      bounds.push([0,0]);
      bounds.push([maxHeight,maxWidth]);
      
    }
    return bounds;
  }


  const calculateImageResize = (dimensionsOfImage: ImageDimensions) : [number,number] => {
    let maxWidth = 0;
    let maxHeight = 0;
    if(dimensionsOfImage.width >= dimensionsOfImage.height){
      maxWidth = dimensionsOfImage.width * ( (dimensionsOfImage.width)/ dimensionsOfImage.height);
      maxHeight = dimensionsOfImage.height * ( (dimensionsOfImage.width)/ dimensionsOfImage.height);
            
    }else{
      
      maxWidth = dimensionsOfImage.width * ( (dimensionsOfImage.width)/ dimensionsOfImage.height);
      maxHeight = dimensionsOfImage.height * ( (dimensionsOfImage.width)/ dimensionsOfImage.height);
      
    }
    return [maxWidth,maxHeight];
  }

  const calculateBoundsOfImage = (dimensionsOfImage: ImageDimensions) : LatLngTuple[] =>{
    const imageSize = calculateImageResize(dimensionsOfImage);
    return [[0,0],[imageSize[0],imageSize[1]]];
  }

  return (
    <>
      <LoadingButton
        variant="contained"
        onClick={handleZoomIn}
      >
        +
      </LoadingButton>
      <LoadingButton
        sx={{ ml: "5px" }}
        variant="contained"
        onClick={handleZoomOut}
      >
        -
      </LoadingButton>
      Change Position of Installation <Checkbox
      checked={changePositionInstallation}
      disabled={!enableEdit}
      onClick={() => setChangePositionInstallation(!changePositionInstallation)}
      />
      <Scrollbar>
        <div ref={mapRef}>
        {imageUrl && dimensionsOfImage &&
              (
          <MapContainer
            ref={mapRefForZooming}
            className="map"
            zoom={zoomValue}
            scrollWheelZoom={true}
            crs={L.CRS.Simple}
            bounds={calculateBoundsOfMapContainer({ width: dimensionsOfImage.width, height: dimensionsOfImage.height })}
            zoomSnap={0.25}
            minZoom={-6}
            maxZoom={20}
            attributionControl={false}
            zoomControl={false}
            style={{ height: "300px", width: "550px", backgroundColor: "white" }}
          >
            {imageUrl && dimensionsOfImage &&
              (

                <ImageOverlay
                  url={imageUrl}
                  bounds={calculateBoundsOfImage({ width: dimensionsOfImage.width, height: dimensionsOfImage.height })}
                />
              )}

            <MapComponent
              placeMarkerToMap={canAddPinToMap}
              addMarker={manageNewPosition}
              selectedMarker={selectMarker}
              positions={ihmItemPositions}
              enableEdit={enableEdit}
              ref={mapComponentRef}
              description={description}
              applicationOfPaint={applicationOfPaint}
              nameOfPaint={nameOfPaint}
              nameOfEquipmentAndMachinery={nameOfEquipmentMachinery}
              nameOfStructuralElement={nameOfStructuralElement}
              partsWhereUsed={partsUsed}
              selectedPositionId={preselectedPositionId}
              setSelectedPositionId={setPreselectedPositionId}
              setMarkerNewPosition={(lat,lng,id)=>{
                if(updateInstallationPosition){
                  updateInstallationPosition(lat,lng,id);
                }
              }}
              captureScreenshot={captureScreenshot}
              readUploadedFile={readUploadedFile}
              changePositionOfInstallation={changePositionInstallation}
            />
          </MapContainer>)}
        </div>
        <Scrollbar>
          <div style={{ padding: 8, paddingTop: 16 }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ color: "text.primary" }}
              >
                Select SubPart
              </InputLabel>
              <Select
                variant="standard"
                labelId={`select-label-id-}`}
                id={`select-subpart-id}`}
                label="Select SubPart"
                value={subPart}
                onChange={handleSubPartInput}
                disabled={
                  !enableEdit ||
                  allowedAction === MapperActionsEnum.IS_PLACE_ON_MAP ||
                  allowedAction === MapperActionsEnum.IS_VIEW ||
                  selectedLocationIsDeleted === true
                }
              >
                {availableSubParts.map((subPart: string, _i: number) => (
                  <MenuItem key={`subpart_${_i}`} value={subPart}>
                    {subPart == "SubPart 1"
                      ? "I-1 Paints"
                      : subPart == "SubPart 2"
                        ? "I-2 Equipment and machinery"
                        : subPart == "SubPart 3" && "I-3 Structure"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {subPart == "SubPart 1" && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", flexDirection: "row", m: 1 }}>
                <TextField
                  variant="standard"
                  fullWidth
                  helperText="Application of Paint *"
                  disabled={
                    !enableEdit ||
                    allowedAction === MapperActionsEnum.IS_PLACE_ON_MAP ||
                    allowedAction === MapperActionsEnum.IS_VIEW ||
                    selectedLocationIsDeleted === true
                  }
                  value={applicationOfPaint}
                  onChange={(e: any) => {
                    const data = e.target.value as string;
                    setApplicationOfPaint(data);
                    if (
                      selectedNewIhmPosition != undefined &&
                      selectedNewIhmPosition !== null
                    ) {
                      selectedNewIhmPosition.applicationOfPaint = data;
                      setSelectedNewIhmPosition(selectedNewIhmPosition);
                    }
                    if (
                      selectedExistingIhmPosition != undefined &&
                      selectedExistingIhmPosition !== null
                    ) {
                      selectedExistingIhmPosition.applicationOfPaint = data;
                      setSelectedExistingNewIhmPosition(
                        selectedExistingIhmPosition
                      );
                    }
                  }}
                />

                <TextField
                  variant="standard"
                  fullWidth
                  helperText="Name of Paint *"
                  sx={{ ml: 1 }}
                  disabled={
                    !enableEdit ||
                    allowedAction === MapperActionsEnum.IS_PLACE_ON_MAP ||
                    allowedAction === MapperActionsEnum.IS_VIEW ||
                    selectedLocationIsDeleted === true
                  }
                  value={nameOfPaint}
                  onChange={(e: any) => {
                    const data = e.target.value as string;
                    setNameOfPaint(data);
                    if (
                      selectedNewIhmPosition != undefined &&
                      selectedNewIhmPosition !== null
                    ) {
                      selectedNewIhmPosition.nameOfPaint = data;
                      setSelectedNewIhmPosition(selectedNewIhmPosition);
                    }
                    if (
                      selectedExistingIhmPosition != undefined &&
                      selectedExistingIhmPosition !== null
                    ) {
                      selectedExistingIhmPosition.nameOfPaint = data;
                      setSelectedExistingNewIhmPosition(
                        selectedExistingIhmPosition
                      );
                    }
                  }}
                />

                <TextField
                  variant="standard"
                  helperText="Location *"
                  fullWidth
                  sx={{ ml: 1 }}
                  disabled={
                    !enableEdit ||
                    allowedAction === MapperActionsEnum.IS_PLACE_ON_MAP ||
                    allowedAction === MapperActionsEnum.IS_VIEW ||
                    selectedLocationIsDeleted === true
                  }
                  value={location}
                  onChange={(e: any) => {
                    const data = e.target.value as string;
                    setLocation(data);
                    if (
                      selectedNewIhmPosition != undefined &&
                      selectedNewIhmPosition !== null
                    ) {
                      selectedNewIhmPosition.location = data;
                      setSelectedNewIhmPosition(selectedNewIhmPosition);
                    }
                    if (
                      selectedExistingIhmPosition != undefined &&
                      selectedExistingIhmPosition !== null
                    ) {
                      selectedExistingIhmPosition.location = data;
                      setSelectedExistingNewIhmPosition(
                        selectedExistingIhmPosition
                      );
                    }
                  }}
                />
                <TextField
                  variant="standard"
                  fullWidth
                  helperText="Installed Quantity *"
                  sx={{ ml: 1 }}
                  disabled={
                    !enableEdit ||
                    allowedAction === MapperActionsEnum.IS_PLACE_ON_MAP ||
                    allowedAction === MapperActionsEnum.IS_VIEW ||
                    selectedLocationIsDeleted === true
                  }
                  value={quantity}
                  onChange={handleQuantityInput}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
          )}

          {subPart == "SubPart 2" && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", flexDirection: "row", m: 1 }}>
                <TextField
                  variant="standard"
                  fullWidth
                  helperText="Name of equipment and machinery *"
                  sx={{ mt: 1 }}
                  disabled={
                    !enableEdit ||
                    allowedAction === MapperActionsEnum.IS_PLACE_ON_MAP ||
                    allowedAction === MapperActionsEnum.IS_VIEW ||
                    selectedLocationIsDeleted === true
                  }
                  value={nameOfEquipmentMachinery}
                  onChange={(e: any) => {
                    const data = e.target.value as string;
                    setNameOfEquipmentMachinery(data);
                    if (
                      selectedNewIhmPosition != undefined &&
                      selectedNewIhmPosition !== null
                    ) {
                      selectedNewIhmPosition.nameOfEquipmentAndMachinery = data;
                      setSelectedNewIhmPosition(selectedNewIhmPosition);
                    }
                    if (
                      selectedExistingIhmPosition != undefined &&
                      selectedExistingIhmPosition !== null
                    ) {
                      selectedExistingIhmPosition.nameOfEquipmentAndMachinery =
                        data;
                      setSelectedExistingNewIhmPosition(
                        selectedExistingIhmPosition
                      );
                    }
                  }}
                />

                <TextField
                  variant="standard"
                  fullWidth
                  helperText="location *"
                  sx={{ mt: 1, ml: 1 }}
                  value={location}
                  disabled={
                    !enableEdit ||
                    allowedAction === MapperActionsEnum.IS_PLACE_ON_MAP ||
                    allowedAction === MapperActionsEnum.IS_VIEW ||
                    selectedLocationIsDeleted === true
                  }
                  onChange={(e: any) => {
                    const data = e.target.value as string;
                    setLocation(data);
                    if (
                      selectedNewIhmPosition != undefined &&
                      selectedNewIhmPosition !== null
                    ) {
                      selectedNewIhmPosition.location = data;
                      setSelectedNewIhmPosition(selectedNewIhmPosition);
                    }
                    if (
                      selectedExistingIhmPosition != undefined &&
                      selectedExistingIhmPosition !== null
                    ) {
                      selectedExistingIhmPosition.location = data;
                      setSelectedExistingNewIhmPosition(
                        selectedExistingIhmPosition
                      );
                    }
                  }}
                />

                <TextField
                  variant="standard"
                  fullWidth
                  helperText="Parts where used *"
                  sx={{ mt: 1, ml: 1 }}
                  disabled={
                    !enableEdit ||
                    allowedAction === MapperActionsEnum.IS_PLACE_ON_MAP ||
                    allowedAction === MapperActionsEnum.IS_VIEW ||
                    selectedLocationIsDeleted === true
                  }
                  value={partsUsed}
                  onChange={(e: any) => {
                    const data = e.target.value as string;
                    setPartsUsed(data);
                    if (
                      selectedNewIhmPosition != undefined &&
                      selectedNewIhmPosition !== null
                    ) {
                      selectedNewIhmPosition.partsWhereUsed = data;
                      setSelectedNewIhmPosition(selectedNewIhmPosition);
                    }
                    if (
                      selectedExistingIhmPosition != undefined &&
                      selectedExistingIhmPosition !== null
                    ) {
                      selectedExistingIhmPosition.partsWhereUsed = data;
                      setSelectedExistingNewIhmPosition(
                        selectedExistingIhmPosition
                      );
                    }
                  }}
                />
                <TextField
                  variant="standard"
                  fullWidth
                  helperText="Installed Quantity *"
                  sx={{ mt: 1, ml: 1 }}
                  disabled={
                    !enableEdit ||
                    allowedAction === MapperActionsEnum.IS_PLACE_ON_MAP ||
                    allowedAction === MapperActionsEnum.IS_VIEW ||
                    selectedLocationIsDeleted === true
                  }
                  value={quantity}
                  onChange={handleQuantityInput}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", m: 1 }}></Box>
            </Box>
          )}

          {subPart == "SubPart 3" && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", flexDirection: "row", m: 1 }}>
                <TextField
                  variant="standard"
                  fullWidth
                  helperText="Name of structural element *"
                  sx={{ mt: 1 }}
                  disabled={
                    !enableEdit ||
                    allowedAction === MapperActionsEnum.IS_PLACE_ON_MAP ||
                    allowedAction === MapperActionsEnum.IS_VIEW ||
                    selectedLocationIsDeleted === true
                  }
                  value={nameOfStructuralElement}
                  onChange={(e: any) => {
                    const data = e.target.value as string;
                    setNameOfStructuralElement(data);
                    if (
                      selectedNewIhmPosition != undefined &&
                      selectedNewIhmPosition !== null
                    ) {
                      selectedNewIhmPosition.nameOfStructuralElement = data;
                      setSelectedNewIhmPosition(selectedNewIhmPosition);
                    }
                    if (
                      selectedExistingIhmPosition != undefined &&
                      selectedExistingIhmPosition !== null
                    ) {
                      selectedExistingIhmPosition.nameOfStructuralElement = data;
                      setSelectedExistingNewIhmPosition(
                        selectedExistingIhmPosition
                      );
                    }
                  }}
                />

                <TextField
                  variant="standard"
                  fullWidth
                  helperText="Location *"
                  sx={{ mt: 1, ml: 1 }}
                  disabled={
                    !enableEdit ||
                    allowedAction === MapperActionsEnum.IS_PLACE_ON_MAP ||
                    allowedAction === MapperActionsEnum.IS_VIEW ||
                    selectedLocationIsDeleted === true
                  }
                  value={location}
                  onChange={(e: any) => {
                    const data = e.target.value as string;
                    setLocation(data);
                    if (
                      selectedNewIhmPosition != undefined &&
                      selectedNewIhmPosition !== null
                    ) {
                      selectedNewIhmPosition.location = data;
                      setSelectedNewIhmPosition(selectedNewIhmPosition);
                    }
                    if (
                      selectedExistingIhmPosition != undefined &&
                      selectedExistingIhmPosition !== null
                    ) {
                      selectedExistingIhmPosition.location = data;
                      setSelectedExistingNewIhmPosition(
                        selectedExistingIhmPosition
                      );
                    }
                  }}
                />

                <TextField
                  variant="standard"
                  fullWidth
                  helperText="Parts where used *"
                  sx={{ mt: 1, ml: 1 }}
                  disabled={
                    !enableEdit ||
                    allowedAction === MapperActionsEnum.IS_PLACE_ON_MAP ||
                    allowedAction === MapperActionsEnum.IS_VIEW ||
                    selectedLocationIsDeleted === true
                  }
                  value={partsUsed}
                  onChange={(e: any) => {
                    const data = e.target.value as string;
                    setPartsUsed(data);
                    if (
                      selectedNewIhmPosition != undefined &&
                      selectedNewIhmPosition !== null
                    ) {
                      selectedNewIhmPosition.partsWhereUsed = data;
                      setSelectedNewIhmPosition(selectedNewIhmPosition);
                    }
                    if (
                      selectedExistingIhmPosition != undefined &&
                      selectedExistingIhmPosition !== null
                    ) {
                      selectedExistingIhmPosition.partsWhereUsed = data;
                      setSelectedExistingNewIhmPosition(
                        selectedExistingIhmPosition
                      );
                    }
                  }}
                />
                <TextField
                  variant="standard"
                  fullWidth
                  helperText="Installed Quantity *"
                  sx={{ mt: 1, ml: 1 }}
                  disabled={
                    !enableEdit ||
                    allowedAction === MapperActionsEnum.IS_PLACE_ON_MAP ||
                    allowedAction === MapperActionsEnum.IS_VIEW ||
                    selectedLocationIsDeleted === true
                  }
                  value={quantity}
                  onChange={handleQuantityInput}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", m: 1 }}></Box>
            </Box>
          )}
          {(allowedAction == MapperActionsEnum.IS_REPLACE ||
            allowedAction == MapperActionsEnum.IS_ALL) &&
            getAvailableIhmItemForReplacementQueryResp?.data
              ?.getIhmITemsForReplacement?.length > 0 && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", flexDirection: "row", m: 1 }}>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", flex: "3" }}
                  >
                    <Select
                      variant="standard"
                      labelId="select-ship-owner-label"
                      id="select-ship-owner-ihm-items-to-replace"
                      label="IHM Item"
                      value={
                        selectedIhmITemsForReplacement
                          ? selectedIhmITemsForReplacement?.ihmId
                          : ""
                      }
                      placeholder="Please select a product name to replace the existing installation"
                      onChange={handleIhmItemReplaceInput}
                      disabled={
                        (userRole != undefined && userRole === "ShipOwner") ||
                        enableEdit === false ||
                        selectedLocationIsDeleted === true
                      }
                      sx={{ mr: 1 }}
                    >
                      {availableIhmItemForReplacement &&
                        availableIhmItemForReplacement.map(
                          (ihmItem: IhmITemsForReplacementDTO, index: number) => (
                            <MenuItem
                              key={index}
                              value={ihmItem.ihmId}
                              selected={
                                selectedIhmITemsForReplacement &&
                                  selectedIhmITemsForReplacement?.ihmId ===
                                  ihmItem.ihmId
                                  ? true
                                  : false
                              }
                            >
                              {handleProductName(ihmItem)}
                            </MenuItem>
                          )
                        )}
                    </Select>
                    <FormHelperText>
                      Please select a product name to replace the existing
                      installation
                    </FormHelperText>
                  </Box>
                  <TextField
                    sx={{ flex: "1" }}
                    variant="standard"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={quantityToReplaceHelperText}
                    disabled={
                      (!enableEdit && !enableQuantityToReplace) ||
                      selectedLocationIsDeleted === true
                    }
                    value={quantityToReplace}
                    onChange={handleQuantityReplaceInput}
                    id="quantity-for-replace"
                  />
                </Box>
              </Box>
            )}

          {!isPreviewMode && (
            <Stack
              sx={{ flexDirection: "row", justifyContent: "space-between", m: 1 }}
            >
              {isFromModal == false &&
                allowedAction !== MapperActionsEnum.IS_PLACE_ON_MAP &&
                userRole != undefined &&
                userRole != "ShipOwner" ? (
                <FormControlLabel
                  control={
                    <Switch
                      checked={enableEdit}
                      onChange={() => {
                        setEnableEdit(!enableEdit);
                        setDescription(
                          ihmItem
                            ? `${ihmItem.productName} - ${ihmItem.productCode}`
                            : ""
                        );
                        setCanAddPinToMap(!enableEdit);

                        if (
                          enableEdit === true &&
                          selectedLocation &&
                          preselectedPositionId == undefined
                        ) {
                          clearSelectedLocation();
                          setSelectedNewIhmPosition(null);
                          mapComponentRef.current.resetMarkers();
                        }
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Enable Edit"
                />
              ) : (
                <div></div>
              )}

              <Stack
                sx={{ flexDirection: "row", justifyContent: "space-between" }}
              >
                {
                  enableEdit &&
                  selectedLocation != undefined && useGetAppUserIsGuestQuery?.data?.getAppUserIsGuest ===
                  false && selectedPositionId != undefined &&
                  (
                    <LoadingButton
                      variant="contained"
                      id="deleteSelectedItem"
                      onClick={() =>
                        handleUserActions({
                          actionCode: ActionCodes.RECAPTURE,
                          msg: "Are you sure  you want to Recapture the map?",
                        })
                      }
                      component="label"
                      sx={{ mr: 1 }}
                      loading={DeleteIhmPositionByLatLngProps.loading}
                      disabled={
                        userRole == undefined ||
                        userRole !== "Maintainer" ||
                        !enableEdit ||
                        selectedLocation == undefined
                      }
                    >
                      Recapture
                    </LoadingButton>
                  )
                }


                {enableEdit &&
                  selectedLocation != undefined &&
                  (allowedAction == MapperActionsEnum.IS_ALL ||
                    allowedAction == MapperActionsEnum.IS_REPLACE) &&
                  useGetAppUserIsGuestQuery?.data?.getAppUserIsGuest ===
                  false && (
                    <LoadingButton
                      variant="contained"
                      id="replaceSelectedItem"
                      onClick={() =>
                        handleUserActions({
                          actionCode: ActionCodes.IS_REPLACE,
                          msg: "Are you sure  you want to Replace the selected Installation?",
                        })
                      }
                      component="label"
                      sx={{ mr: 1 }}
                      loading={ReplaceIhmPositionProps.loading}
                      disabled={
                        userRole == undefined ||
                        userRole !== "Maintainer" ||
                        !enableEdit ||
                        selectedLocation == undefined ||
                        selectedIhmITemsForReplacement == undefined ||
                        selectedLocationIsDeleted === true
                      }
                    >
                      Replace
                    </LoadingButton>
                  )}
                {enableEdit &&
                  (selectedLocation != undefined ||
                    selectedNewIhmPosition != undefined) &&
                  (allowedAction == MapperActionsEnum.IS_ALL ||
                    allowedAction == MapperActionsEnum.IS_SAVE ||
                    allowedAction == MapperActionsEnum.IS_APPROVE ||
                    allowedAction == MapperActionsEnum.IS_PLACE_ON_MAP ||
                    allowedAction ==
                    MapperActionsEnum.IS_INSTALL_IHM_ITEM_TO_MAP) &&
                  useGetAppUserIsGuestQuery?.data?.getAppUserIsGuest ===
                  false && (
                    <LoadingButton
                      variant="contained"
                      id="removeSelectedItem"
                      onClick={() =>
                        handleUserActions({
                          actionCode:
                            allowedAction == MapperActionsEnum.IS_PLACE_ON_MAP
                              ? ActionCodes.IS_PLACE_ON_MAP
                              : ActionCodes.IS_SAVE,
                          msg: "Are you sure  you want to Save this Installation?",
                        })
                      }
                      component="label"
                      loading={
                        selectedExistingIhmPosition == null
                          ? UpdateIhmPositionProps.loading
                          : CreateIhmPositionProps.loading
                      }
                      disabled={selectedLocationIsDeleted === true}
                    >
                      {saveButtonLabel}
                    </LoadingButton>
                  )}
                {enableEdit && (
                  <LoadingButton
                    variant="contained"
                    id="cancelMapUpdate"
                    onClick={handleCancel}
                    component="label"
                    loading={
                      selectedExistingIhmPosition == null
                        ? UpdateIhmPositionProps.loading
                        : CreateIhmPositionProps.loading
                    }
                  >
                    Clear fields
                  </LoadingButton>
                )}
              </Stack>
            </Stack>
          )}
        </Scrollbar>

        {userAction != undefined && (
          <WarningModal
            isOpenPreview={isWarningModalOpen}
            onClosePreview={() => setIsWarningModalOpen(false)}
            setUserResponseTrue={() => {
              setUserResponseTrue(true);
              setIsWarningModalOpen(false);
            }}
            warningTitle={userAction?.msg}
          />
        )}

        <VesselMapWarningModal
          isOpenPreview={isModalOpen}
          onClosePreview={() => setIsModalOpen(false)}
        />
      </Scrollbar>
    </>
  );
};

export default VesselMap;
