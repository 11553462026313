import { gql } from "@apollo/client";

//--------------------------------------- SHIPOWNERS ------------------------------------------------
export const GET_SHIPOWNERS_QUERY = gql`
  query fetchShipOwners($limit: Int!, $page: Int!, $searchQuery: String!) {
    fetchShipOwners(limit: $limit, page: $page, searchQuery: $searchQuery) {
      ShipOwners {
        id
        name
      }
    }
  }
`;
export const GET_SHIPOWNERS_LIST_QUERY = gql`
  query fetchShipOwners($limit: Int!, $page: Int!, $searchQuery: String!) {
    fetchShipOwners(limit: $limit, page: $page, searchQuery: $searchQuery) {
      ShipOwners {
        id
        name
        city
        avatar
        streetName
        maintainerResponsibleId
        maintainerApproverId
        shipOwnerBackgroundImg
        vessels {
          id
          name
          purchaseOrderItems {
            id
          }
        }
      }
      TotalItems
    }
  }
`;
export const GET_SHIPOWNER_BY_ID_QUERY = gql`
  query getShipOwner($id: Int!) {
    getShipOwner(id: $id) {
      id
      name
      vatNumber
      phoneNumber
      country
      city
      postalCode
      streetName
      webSite
      emailAddress
      nameOfOperator
      faxNumber
      contactPersonName
      contactPersonEmail
      avatar
      maintainerApproverId
      maintainerResponsibleId
      ExportIhm
      ExportMD_SDOC
      shipOwnerBackgroundImg
      danaosApiKey
      danaosUrl
    }
  }
`;

export const GET_SHIPOWNER_SUPPLIERS_QUERY = gql`
  query getShipOwnerSuppliers(
    $id: Int!
    $limit: Int!
    $page: Int!
    $searchQuery: String!
  ) {
    getShipOwnerSuppliers(
      id: $id
      limit: $limit
      page: $page
      searchQuery: $searchQuery
    ) {
      id
      name
      emailAddress
      phoneNumber
    }
  }
`;

export const COUNT_SHIPOWNER_SUPPLIERS_QUERY = gql`
  query getShipOwnerSuppliersNumber($id: Int!) {
    getShipOwnerSuppliersNumber(id: $id)
  }
`;

export const GET_SHIPOWNER_BY_ID_WITH_SUPPLIERS_QUERY = gql`
  query getShipOwner($id: Int!) {
    getShipOwner(id: $id) {
      id
      name
      vatNumber
      phoneNumber
      country
      city
      postalCode
      streetName
      webSite
      emailAddress
      contactPersonName
      contactPersonEmail
      avatar
      ExportIhm
      ExportMD_SDOC
      vessels {
        id
        vesselPurchaseOrderItemIhms {
          id
          purchaseOrderItem {
            id
            supplier {
              id
              name
              country
              city
              streetName
              streetNumber
              postalCode
              emailAddress
              phoneNumber
            }
          }
        }
      }
    }
  }
`;
export const GET_SHIPOWNER_USERS_BY_VESSEL_QUERY = gql`
  query getShipOwner($id: Int!) {
    getShipOwner(id: $id) {
      id
      name
      vessels {
        id
        userShipOwnerVessels {
          appUserId
        }
      }
    }
  }
`;
export const GET_SHIPOWNER_VESSELS_QUERY = gql`
  query getShipOwnerWithVessels($id: Int!) {
    getShipOwnerWithVessels(id: $id) {
      id
      name
      vessels {
        id
        name
      }
    }
  }
`;
export const GET_COUNT_SHIPOWNER_QUERY = gql`
  query countShipOwners {
    countShipOwners
  }
`;

//--------------------------------------- APP USERS ---------------------------------------------------
export const GET_FILTER_APP_USER = gql`
  query filterAppUsers(
    $name: String!
  ) {
    filterAppUsers(
      name: $name
    ) {
      id
      name
    }
  }
`;




export const GET_APP_USER_BY_SHIPOWNER_QUERY = gql`
  query getAppUsersByShipOwner(
    $shipOwnerId: Int!
    $limit: Int!
    $page: Int!
    $searchToken: String!
    $fetchDeleted: Boolean!
  ) {
    getAppUsersByShipOwner(
      shipOwnerId: $shipOwnerId
      limit: $limit
      page: $page
      searchToken: $searchToken
      fetchDeleted: $fetchDeleted
    ) {
      id
      name
      lastname
      email
      identityId
      isVerified
    }
  }
`;



export const GET_APP_USER_WITH_SHIPOWNER_QUERY = gql`
  query getAllAppUsersWithShipOwner(

    $limit: Int!
    $page: Int!
    $searchToken: String!

  ) {
    getAllAppUsersWithShipOwner(

      limit: $limit
      page: $page
      searchToken: $searchToken

    ) {
      rows {
      id
      name
      lastname
      email
      shipowners
      }
      totalRows
    }
  }
`;

export const GET_APP_USER_BY_IDENTITY_ID = gql`
  query getAppUserByIdentityId($identityId: String!) {
    getAppUserByIdentityId(identityId: $identityId) {
      id
      name
      lastname
      email
      identityId
      isVerified
    }
  }
`;

export const GET_APP_USER_BY_ID_QUERY = gql`
  query getAppUser($id: Int!) {
    getAppUser(id: $id) {
      id
      name
      lastname
      position
      city
      email
      phoneNumber
      about
      avatar
      userShipOwner {
        id
        includeAllVessels
        userShipOwnerVessels {
          id
          vesselId
        }
      }
    }
  }
`;

export const GET_APP_USER_IS_GUEST_QUERY = gql`
  query getAppUserIsGuest {
    getAppUserIsGuest
  }
`;

export const GET_APP_USER_BY_TOKEN_QUERY = gql`
  query getAppUserByToken {
    getAppUserByToken {
      id
      name
      lastname
      city
      email
      phoneNumber
      position
      about
      avatar
      isGuest
      isVerified
      isDeleted
      userShipOwner {
        id
        shipOwnerId
      }
      userSupplier {
        id
        supplier {
          id
          name
          country
          city
          streetName
          streetNumber
          postalCode
          emailAddress
          phoneNumber
          avatar
        }
      }
    }
  }
`;

export const GET_TOTAL_SUPPLIERS_FOR_SHIPOWNER_QUERY = gql`
  query getTotalSuppliersForShipOwner {
    getTotalSuppliersForShipOwner
  }
`;

export const GET_SUPPLIER_COMPLIANCE_FOR_SHIPOWNER_QUERY = gql`
  query getSupplierComplianceForShipOwner {
    getSupplierComplianceForShipOwner
  }
`;

export const GET_APP_USERS_BY_SUPPLIER_QUERY = gql`
  query getAppUsersBySupplier(
    $supplierId: Int!
    $limit: Int!
    $page: Int!
    $searchToken: String!
  ) {
    getAppUsersBySupplier(
      supplierId: $supplierId
      limit: $limit
      page: $page
      searchToken: $searchToken
    ) {
      id
      name
      lastname
      email
      identityId
      isVerified
      userSupplier {
        id
        supplierId
        isDefault
      }
    }
  }
`;

export const GET_SUPPLIERS_DEFAULT_USER_QUERY = gql`
  query getMySuppliersDefaultAppUser {
    getMySuppliersDefaultAppUser {
      id
      name
      lastname
      city
      phoneNumber
      about
      email
      position
      userSupplier {
        id
        isDefault
      }
    }
  }
`;

export const REPORT_SUPPLIER_REQUESTS_QUERY = gql`
  query reportSupplierRequests($wizardId: Int!) {
    reportSupplierRequests(wizardId: $wizardId) {
      name
      attributes {
        status
        inScope
        poNumber
        supplierEmailAddress
        inProgress
        total
        productCode
      }
      children {
        name
        attributes {
          status
          inScope
          poNumber
          supplierEmailAddress
          inProgress
          total
          productCode
        }
        children {
          name
          attributes {
            status
            inScope
            poNumber
            supplierEmailAddress
            inProgress
            total
            productCode
          }
          children {
            name
            attributes {
              status
              inScope
              poNumber
              supplierEmailAddress
              inProgress
              total
              productCode
            }
          }
        }
      }
    }
  }
`;

export const GET_VESSEL_MAINTAINERS_QUERY = gql`
  query getMaintainers {
    getMaintainers {
      identityId
      name
      lastname
      city
      email
      phoneNumber
    }
  }
`;
//--------------------------------------- VESSELS -------------------------------------------------------

export const GET_VESSELS = gql`
query getAllVessels(

    $limit: Int!
    $page: Int!
    $searchQuery: String!
) {
  getAllVessels(

      limit: $limit
      page: $page
      searchQuery: $searchQuery
  ){
      id
      name


  }
}`;

export const GET_VESSEL_AND_SHIPOWNER_BY_EXTERNAL_ID = gql`
  query getVesselAndShipOwnerByExternalId(
    $shipOwnerExternalId: String!
    $vesselExternalId: String!
  ){
  getVesselAndShipOwnerByExternalId(
    shipOwnerExternalId: $shipOwnerExternalId
    vesselExternalId: $vesselExternalId
  ){
    shipOwnerName
    vesselName
  }
  }
`;






export const GET_VESSEL_ATACHMENTS = gql`
  query getListOfVesselAttachments(
    $vesselId: Int!
    $take: Int!
    $skip: Int!
  ) {
    getListOfVesselAttachments(
      vesselId: $vesselId
      take: $take
      skip: $skip
    ){
      total
      attachments{
        id
        name
        path
        mimeType
        includeToExport
      }
    }
  }
`;

export const GET_VESSELS_BY_USER_ROLE_QUERY = gql`
  query findAllVesselsByRole {
    findAllVesselsByRole
  }
`;

export const GET_VESSELS_BY_SHIPOWNER_WITH_ACTIVE_PERSON_RESPONSIBLE_QUERY = gql`
  query getVesselsWithActivePersonResponsibleQuery(
    $shipOwnerId: Int!
    $limit: Int!
    $page: Int!
    $searchQuery: String!
  ) {
    getVesselsWithActivePersonResponsibleQuery(
      shipOwnerId: $shipOwnerId
      limit: $limit
      page: $page
      searchQuery: $searchQuery
    ) {
      vesselId
      vesselName
      vesselImo
      vesselFleet
      vesselPersonResponsibleFullName
    }
  }
`;

export const GET_VESSELS_BY_SHIPOWNER_QUERY = gql`
  query getVesselsByShipOwner(
    $shipOwnerId: Int!
    $limit: Int!
    $page: Int!
    $searchQuery: String!
  ) {
    getVesselsByShipOwner(
      selectedShipOwnerId: $shipOwnerId
      limit: $limit
      page: $page
      searchQuery: $searchQuery
    ) {
      id
      name
      imoNumber
      vesselType {
        id
        name
      }
      email
      InternationalCallSign
      FlagState
      PortofRegistry
      DateRegisterFlagState
      Shipyard
      HullNo
      Capacity
      OwningCompany
      OwningCompanyAddress
      DateOfDelivery
      VesselClass
      IHMClass
      Fleet
      VesselMaintainerResponsibleName
      vesselPersonResponsibles {
        id
        firstName
        lastName
        endTo
        emailAddress
      }
    }
  }
`;

export const GET_FILE_NAME_DATA = gql`
  query getFileNameData($vesselId: Int!){
    getFileNameData(vesselId: $vesselId){
      vesselName
      vesselImo
      version
    }
  }
`;

export const GET_VESSEL_BY_ID_QUERY = gql`
  query getVessel($id: Int!) {
    getVessel(id: $id) {
      id
      name
      imoNumber
      email
      vesselOperator
      OwningCompany
      OwningCompanyAddress
      vesselTypeId
      shipOwnerId
      InternationalCallSign
      FlagState
      PortofRegistry
      DateRegisterFlagState
      Shipyard
      HullNo
      Capacity
      DateOfDelivery
      VesselClass
      IHMClass
      Fleet
      initialIhmReportNumber
      initialIhmReportCompanyName
      VesselMaintainerResponsibleId
      VesselMaintainerApproverId
      ccIhmExport
      doNotShareIhm
      enableVersioning
      vesselImages {
        id
        name
      }
      vesselPersonResponsibles {
        id
        firstName
        lastName
        startFrom
        endTo
      }
    }
  }
`;

export const GET_VESSEL_PERSON_RESPONSIBLE_BY_VESSEL_ID = gql`
  query getVesselPersonResponsibleByVesselId($vesselId: Int!) {
    getVesselPersonResponsibleByVesselId(vesselId: $vesselId) {
      id
      firstName
      lastName
      position
      emailAddress
      phoneNumber
      mobilePhoneNumber
      vesselId
      startFrom
      endTo
    }
  }
`;

export const GET_VESSEL_PERSON_RESPONSIBLE_BY_ID = gql`
  query getVesselPersonResponsible($id: Int!) {
    getVesselPersonResponsible(id: $id) {
      id
      firstName
      lastName
      companyName
      position
      emailAddress
      phoneNumber
      mobilePhoneNumber
      vesselId
      startFrom
      endTo
    }
  }
`;

export const GET_VESSELS_BY_APP_USER = gql`
  query getVesselsByAppUser($appUserId: Int!) {
    getVesselsByAppUser(appUserId: $appUserId) {
      id
      name
    }
  }
`;

export const GET_VESSELS_BY_APP_USER_AND_SHIPOWNER = gql`
  query getVesselsByAppUserAndShipOwner($appUserId: Int!, $shipOwnerId: Int!){
    getVesselsByAppUserAndShipOwner(appUserId: $appUserId, shipOwnerId: $shipOwnerId){
      id
      name
    }
  }
`;

export const VESSEL_TYPES_QUERY = gql`
  query vesselTypes {
    vesselTypes {
      id
      name
    }
  }
`;

export const GET_REPORT_VESSEL_PO_STATUS = gql`
  query reportVesselPoStatus(
    $dateFromInput: String!
    $totalMonths: Int!
    $shipOwnerId: Int!
    $vesselId: Int!
  ) {
    reportVesselPoStatus(
      dateFromInput: $dateFromInput
      totalMonths: $totalMonths
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
    ) {
      totalPoInScope
      totalPoOutOfScope
      totalUnderReview
    }
  }
`;

export const GET_REPORT_VESSEL_PO_ITEM_STATUS = gql`
  query reportVesselPoItemStatus(
    $dateFromInput: String!
    $totalMonths: Int!
    $shipOwnerId: Int!
    $vesselId: Int!
    $orderStatus: [Int!]!
  ) {
    reportVesselPoItemStatus(
      dateFromInput: $dateFromInput
      totalMonths: $totalMonths
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
      orderStatus: $orderStatus
    ) {
      total
      percentage
      comparisonText
      comparisonPercentage
      positive
      dateRangeReport
      dateRangeReportComparison
    }
  }
`;

export const GET_REPORT_VESSEL_PO_IN_SCOPE = gql`
  query reportVesselPoInScope(
    $dateFromInput: String!
    $totalMonths: Int!
    $shipOwnerId: Int!
    $vesselId: Int!
  ) {
    reportVesselPoInScope(
      dateFromInput: $dateFromInput
      totalMonths: $totalMonths
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
    ) {
      total
      totalInScope
    }
  }
`;

export const GET_SUPPLIER_COMPLIANCE_FOR_VESSEL_QUERY = gql`
  query getSupplierComplianceForVessel(
    $dateFromInput: String!
    $totalMonths: Int!
    $reportPeriod: Int!
    $shipOwnerId: Int!
    $vesselId: Int!
  ) {
    getSupplierComplianceForVessel(
      dateFromInput: $dateFromInput
      totalMonths: $totalMonths
      reportPeriod: $reportPeriod
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
    )
  }
`;

export const GET_FLEET_COMPLIANCE_FOR_VESSEL_QUERY = gql`
  query getFleetCompliance(
    $dateFromInput: String!
    $totalMonths: Int!
    $shipOwnerId: Int!
    $vesselId: Int!
  ) {
    getFleetCompliance(
      dateFromInput: $dateFromInput
      totalMonths: $totalMonths
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
    )
  }
`;

export const GET_PO_IN_SCOPE_PERCENTAGE_BY_SO_AND_VSL_QUERY = gql`
  query getPOInScopePercentageByShipOwnerAndVessel(
    $shipOwnerId: Int!
    $vesselId: Int!
    $dateFromInput: String!
    $totalMonths: Int!
  ) {
    getPOInScopePercentageByShipOwnerAndVessel(
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
      dateFromInput: $dateFromInput
      totalMonths: $totalMonths
    )
  }
`;

export const GET_PO_IN_SCOPE_BY_SO_AND_VSL_QUERY = gql`
  query getPOInScopeByShipOwnerAndVessel(
    $shipOwnerId: Int!
    $vesselId: Int!
    $dateFromInput: String!
    $totalMonths: Int!
    $userRole: String!
  ) {
    getPOInScopeByShipOwnerAndVessel(
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
      dateFromInput: $dateFromInput
      totalMonths: $totalMonths
      userRole: $userRole
    )
  }
`;

export const GET_TOTAL_IHM_ENTITIES = gql`
  query getTotalIhmEntities(
    $dateFromInput: String!
    $totalMonths: Int!
    $reportPeriod: Int!
    $shipOwnerId: Int!
    $vesselId: Int!
  ) {
    getTotalIhmEntities(
      dateFromInput: $dateFromInput
      totalMonths: $totalMonths
      reportPeriod: $reportPeriod
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
    ) {
      total
      totalPchm
      totalChm
    }
  }
`;

// ----------------------------------------------- VESSEL IMAGES -----------------------------------------------
export const GET_VESSEL_IMAGES_BY_VESSEL_QUERY = gql`
  query getImagesByVessel($vesselId: Int!) {
    getImagesByVessel(vesselId: $vesselId) {
      id
      name
    }
  }
`;

// ----------------------------------------------- VESSEL DRAWINGS -----------------------------------------------
export const GET_VESSEL_HAS_DRAWING_VIEW_PATH_BY_IHM_ID = gql`
  query getVesselHasDrawingViewByIhmId($ihmItemId: Int!) {
    getVesselHasDrawingViewByIhmId(ihmItemId: $ihmItemId)
  }
`;

export const GET_VESSEL_DRAWINGS_BY_VESSEL_ID = gql`
  query vesselDrawingViewsByVesselId(
    $vesselId: Int!
    $limit: Int!
    $page: Int!
  ) {
    vesselDrawingViewsByVesselId(
      vesselId: $vesselId
      limit: $limit
      page: $page
    ) {
      id
      name
      path
      vesselId
    }
  }
`;

export const GET_VESSEL_DRAWINGS_BY_PATH = gql`
  query getManyVesselDrawingViewsByPathsArray(
    $vesselDrawingPathsArray: [String!]!
  ) {
    getManyVesselDrawingViewsByPathsArray(
      vesselDrawingPathsArray: $vesselDrawingPathsArray
    ) {
      id
      name
      path
      comments
      mimeType
      vesselId
    }
  }
`;
export const GET_VESSEL_DRAWINGS_BY_IHM = gql`
  query findVesselDrawingByIhm($ihmId: Int!) {
    findVesselDrawingByIhm(ihmId: $ihmId) {
      id
      name
      path
      comments
      mimeType
      vesselId
    }
  }
`;

// ----------------------------------------------- SUPPLIERS -----------------------------------------------
export const GET_SUPPLIERS_ALL_QUERY = gql`
  query suppliers {
    suppliers {
      id
      name
      city
      streetName
      streetNumber
    }
  }
`;
export const GET_SUPPLIERS_LIST_QUERY = gql`
  query getSuppliers($limit: Int!, $page: Int!, $searchToken: String!) {
    getSuppliers(limit: $limit, page: $page, searchToken: $searchToken) {
      Suppliers {
        id
        name
        city
        streetName
        streetNumber
        avatar
        supplierBackgroundImg
        supplierImages {
          id
          name
        }
      }
      TotalItems
    }
  }
`;
export const GET_SUPPLIER_QUERY = gql`
  query getSuppliersForTable(
    $limit: Int! 
    $page: Int! 
    $searchToken: String!
    $filtersArray: [InScopeValidationFilter!]!
    $sortingOrder: InScopeValidationOrder!
  ){
    getSuppliersForTable(
      limit: $limit 
      page: $page 
      searchToken: $searchToken
      filtersArray: $filtersArray
      sortingOrder: $sortingOrder
    ){
      Suppliers {
        id
        name
        emailAddress
      }
      TotalItems
    }
  }
`;

export const GET_USERS_SUPPLIERS_OF_SUPPLIER = gql`
  query getUsersSuppliersOfSupplier(
    $supplierId: Int!
    $limit: Int!
    $page: Int!
  ){
    getUsersSuppliersOfSupplier(
      supplierId: $supplierId
      limit: $limit
      page: $page
    ){
      suppliersUsers {
        id
        name
        lastname
        email
      }
      totalItems
    }
  }
`;

export const GET_SUPPLIER_BY_ID_QUERY = gql`
  query getSupplier($id: Int!) {
    getSupplier(id: $id) {
      id
      name
      country
      city
      streetName
      streetNumber
      postalCode
      phoneNumber
      emailAddress
      faxNumber
      divisionName
      avatar
      alias
      sendNotifications
      imageOfSignature
      enableSupplierPortal
      sparePartsManagementPortal
    }
  }
`;

export const GET_SUPPLIER_DETAILS_BY_ID_QUERY = gql`
  query getSupplierForSupplierPortal($id: Int!){
    getSupplierForSupplierPortal(id: $id){
      id
      name
      address
      username
      postalCode
      phoneNumber
      emailAddress
      faxNumber
      divisionName
      avatar
      alias
      sendNotifications
      imageOfSignature
      function
      nameOfEmployee
    }
  }
`;


export const GET_SUPPLIER_BY_IDENTITY_ID_QUERY = gql`
  query getSupplierByIdentityId {
    getSupplierByIdentityId {
      id
      name
      country
      city
      streetName
      streetNumber
      postalCode
      phoneNumber
      emailAddress
      faxNumber
      divisionName
      supplierBackgroundImg
    }
  }
`;
export const GET_SUPPLIERS_BY_SHIPOWNER_QUERY = gql`
  query getManyByShipOwnerId(
    $shipOwnerId: Int!
    $limit: Int!
    $page: Int!
    $searchToken: String!
  ) {
    getManyByShipOwnerId(
      shipOwnerId: $shipOwnerId
      limit: $limit
      page: $page
      searchToken: $searchToken
    ) {
      id
      name
      emailAddress
      country
      city
      streetName
      streetNumber
      postalCode
    }
  }
`;

export const GET_SUPPLIER_INVENTORY_QUERY = gql`
  query getSupplierInventory(
    $limit: Int!
    $page: Int!
    $filtersArray: [InScopeValidationFilter!]!
    $sortingOrder: InScopeValidationOrder!
    $searchToken: String!
  ) {
    getSupplierInventory(
      limit: $limit
      page: $page
      filtersArray: $filtersArray
      sortingOrder: $sortingOrder
      searchToken: $searchToken
    ) {
      rows {
          id
        productName
        productCode
        containsHazMat
        itemUnit
        productInformation
        supplierInventoryMaterial {
          id
          name
          thresholdValue
          presentAboveThresholdValue
          mass
          unit
          category
          comment
        }
      }
      totalRows

    }
  }
`;

export const GET_SUPPLIER_INVENTORY_MATERIALS_BY_SUPPLIER_INVENTORY_QUERY = gql`
  query getSupplierInventoryMaterialsBySupplierInventory(
    $supplierInventoryId: Int!
    $limit: Int!
    $page: Int!
    $filtersArray: [InScopeValidationFilter!]!
    $sortingOrder: InScopeValidationOrder!
  ) {
    getSupplierInventoryMaterialsBySupplierInventory(
      supplierInventoryId: $supplierInventoryId
      limit: $limit
      page: $page
      filtersArray: $filtersArray
      sortingOrder: $sortingOrder
    ) {
      id
      name
      category
      thresholdValue
      presentAboveThresholdValue
      mass
      unit
      comment
    }
  }
`;

// ------------------------------------------ PURCHASE ORDER ITEMS--------------------------------------------
export const FIND_POITEMS_BY_SO_AND_VESSEL_QUERY = gql`
  query purchaseOrderItemByShipOwnerAndVessel(
    $states: [Int!]!
    $supplierRequestId: String!
    $limit: Int!
    $page: Int!
    $selectedVesselId: Int!
    $filtersArray: [InScopeValidationFilter!]!
    $sortingOrder: InScopeValidationOrder!
    $inScope: Int!
    $containsHazMat: Int!
    $documentType: [Int!]!
    $orderStatuses: [Int!]!
    $poRequestStatus: Int!
    $searchToken: String!
    $shipOwnerId: Int!
  ){
    purchaseOrderItemByShipOwnerAndVessel(
      states: $states
      supplierRequestId: $supplierRequestId
      limit: $limit
      page: $page
      selectedVesselId: $selectedVesselId
      filtersArray: $filtersArray
      sortingOrder: $sortingOrder
      inScope: $inScope
      containsHazMat: $containsHazMat
      documentType: $documentType
      orderStatuses: $orderStatuses
      poRequestStatus: $poRequestStatus
      searchToken: $searchToken
      shipOwnerId: $shipOwnerId
    ){
     rows {
      id
      productName
      productCode
      equipmentDescription
      containsCHM
      productDescription
      equipmentManufacturer
      orderStatus
      documentType
      supplierName
      poDate
      purchaseOrderNumber
      shipOwnerName
      vesselName
     } 
     totalRows
    }
  }
`;



export const GET_PURCHASE_ORDER_ITEMS_BY_SHIP_OWNER = gql`
  query purchaseOrderItemsByShipOwner(
    $shipOwnerId: Int!
    $limit: Int!
    $page: Int!
    $searchToken: String!
  ) {
    purchaseOrderItemsByShipOwner(
      shipOwnerId: $shipOwnerId
      limit: $limit
      page: $page
      searchToken: $searchToken
    ) {
      id
      productCode
      purchaseOrder {
        id
        number
      }
      equipmentManufacturer
      equipmentDescription
      quantity
      vessel {
        id
        name
      }
      EquipmentId
      ProductDescription
      General
      OrderStatus
      MdSDoCStatus
      FormType
      ExternalCategory
      containsPCHM
      containsCHM
      inScope
      SupplierName
      SupplierReferenceNumber
    }
  }
`;

export const COUNT_SHIP_OWNER_ORDERS = gql`
  query countPurchaseOrderItemsByShipOwner($shipOwnerId: Int!) {
    countPurchaseOrderItemsByShipOwner(shipOwnerId: $shipOwnerId)
  }
`;

export const CHECK_IF_PO_CAN_GENERATE_GREEN_DOCUMENTS = gql`
  query checkIfPosCanGenerateGreenDocuments($purchaseOrderIdsArray: [Int!]!) {
    checkIfPosCanGenerateGreenDocuments(
      purchaseOrderIdsArray: $purchaseOrderIdsArray
    )
  }
`;

export const GET_PURCHASE_ORDER_ITEM = gql`
  query getPurchaseOrderItem($id: Int!) {
    getPurchaseOrderItem(id: $id) {
      id
      productCode
      productName
      equipmentManufacturer
      equipmentDescription
      PoDate
      quantity
      vesselId
      EquipmentId
      ProductDescription
      General
      OrderStatus
      MdSDoCStatus
      FormType
      ExternalCategory
      containsPCHM
      containsCHM
      inScope
      unit
      vessel {
        id
        name
      }
      supplier {
        id
        name
        emailAddress
        country
        city
        streetName
        streetNumber
        postalCode
        phoneNumber
      }
      purchaseOrder {
        id
        number
        batchId
      }
    }
  }
`;

export const GET_PURCHASE_ORDER_ITEM_REPORT = gql`
  query reportPoItem(
    $dateFromInput: String!
    $totalMonths: Int!
    $statuses: [Int!]!
    $shipOwnerId: Int!
    $vesselId: Int!
    $inScope: Boolean!
  ) {
    reportPoItem(
      dateFromInput: $dateFromInput
      totalMonths: $totalMonths
      statuses: $statuses
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
      inScope: $inScope
    ) {
      total
    }
  }
`;

export const GET_PURCHASE_ORDER_ITEMS_BY_VESSEL = gql`
  query purchaseOrderItemsByVessel(
    $vesselId: Int!
    $limit: Int!
    $page: Int!
    $searchToken: String!
  ) {
    purchaseOrderItemsByVessel(
      vesselId: $vesselId
      limit: $limit
      page: $page
      searchToken: $searchToken
    ) {
      id
      productCode
      purchaseOrder {
        id
        number
      }
      equipmentManufacturer
      equipmentDescription
      quantity
      vesselId
      PoDate
      EquipmentId
      ProductDescription
      General
      OrderStatus
      MdSDoCStatus
      FormType
      ExternalCategory
      containsPCHM
      containsCHM
      inScope
    }
  }
`;

export const GET_FORM_QUERY = gql`
  query getForm($poItemId: Int!) {
    getForm(poItemId: $poItemId) {
      id
      purchaseOrderItemSupplierDocument {
        purchaseOrderItemMd {
          purchaseOrderItemSupplierDocumentId
          idNo
          remark1
          remark2
          remark3
          companyName
          divisionName
          address
          unit
          amount
          contactPerson
          telephoneNumber
          faxNumber
          emailAddress
        }
        purchaseOrderSupplierDocumentSdoc {
          purchaseOrderItemSupplierDocumentId
          sdocNumber
          issuerName
          issuerAddress
        }
      }
    }
  }
`;

export const GET_CONTACT_PERSON_BY_SUPPLIER_REQUEST_QUERY = gql`
  query getContactPersonBySupplierRequest($supplierRequestId: String!) {
    getContactPersonBySupplierRequest(supplierRequestId: $supplierRequestId) {
      id
      name
      lastname
    }
  }
`;

export const GET_IS_PO_ITEM_CONFIGURED_QUERY = gql`
  query isPoItemConfigured($id: Int!) {
    isPoItemConfigured(id: $id)
  }
`;

// ------------------------------------------ PURCHASE ORDERS--------------------------------------------
export const GET_PURCHASE_ORDERS = gql`
  query purchaseOrders {
    purchaseOrders {
      id
      number
      batchId
    }
  }
`;

export const GET_PURCHASE_ORDERS_EXTERNAL_IDS_SEARCH_TOKEN = gql`
  query getPurchaseOrdersExternalIdsSearchToken(
    $shipOwnerExternalId: String!,
    $vesselExternalId: String!,
    $searchToken: String!
    ) {
    getPurchaseOrdersExternalIdsSearchToken(
      shipOwnerExternalId: $shipOwnerExternalId,
      vesselExternalId: $vesselExternalId,
      searchToken: $searchToken
     ){
      Number
      ExternalId
      Items {
        externalId
        productName
      }
    }
  }
`;

export const GET_PURCHASE_ORDER_ACTION_STATUSES = gql`
  query getPoActionStatuses($poId: Int!) {
    getPoActionStatuses(poId: $poId) {
      id
      rows {
        poActionName
        poActionStatus
      }
      approverFullName
      supplierFullName
    }
  }
`;

export const GET_PURCHASE_ORDER_REPORT_QUERY = gql`
  query reportPo(
    $dateFromInput: String!
    $totalMonths: Int!
    $statuses: [Int!]!
    $shipOwnerId: Int!
    $vesselId: Int!
    $inScope: Boolean!
  ) {
    reportPo(
      dateFromInput: $dateFromInput
      totalMonths: $totalMonths
      statuses: $statuses
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
      inScope: $inScope
    ) {
      total
    }
  }
`;

export const GET_TOTAL_NUMBER_OF_POS_QUERY = gql`
  query getTotalPoNumber(
    $shipOwnerId: Int!
    $vesselId: Int!
    $dateFromInput: String!
    $totalMonths: Int!
  ) {
    getTotalPoNumber(
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
      dateFromInput: $dateFromInput
      totalMonths: $totalMonths
    )
  }
`;

export const GET_PO_REQUEST_SUPPLIER_REPORT_QUERY = gql`
  query reportPoRequests(
    $dateFromInput: String!
    $totalMonths: Int!
    $statuses: [Int!]!
    $shipOwnerId: Int!
    $vesselId: Int!
    $inScope: Boolean!
  ) {
    reportPoRequests(
      dateFromInput: $dateFromInput
      totalMonths: $totalMonths
      statuses: $statuses
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
      inScope: $inScope
    ) {
      total
    }
  }
`;

export const GET_SUPPLIER_COMPLIANCE_REPORT_QUERY = gql`
  query supplierCompliance {
    supplierCompliance
  }
`;

export const GET_FILTER_SUPPLIERS = gql`
  query filterSuppliers(
    $name: String!
  ) {
    filterSuppliers(
      name: $name
    ) {
      id
      name
      country
      city
      streetName
      streetNumber
      postalCode
      emailAddress
      phoneNumber
      faxNumber
      alias
      divisionName
    }
  }
`;

export const GET_SUPPLIER_COMPLIANCE_QUERY = gql`
  query getSupplierCompliance(
    $shipOwnerId: Int!
    $supplierId: Int!
    $limit: Int!
    $page: Int!
    $filtersArray: [InScopeValidationFilter!]!
    $sortingOrder: InScopeValidationOrder!
  ) {
    getSupplierCompliance(
      shipOwnerId: $shipOwnerId
      supplierId: $supplierId
      limit: $limit
      page: $page
      filtersArray: $filtersArray
      sortingOrder: $sortingOrder
    ) {
      id
      supplierId
      shipOwnerId
      name
      country
      city
      phoneNumber
      compliance
      responseRate
      numberOfPOs
      inScopePOs
      isValid
    }
  }
`;

export const FIND_POS_BY_SO_AND_VESSEL_QUERY = gql`
  query purchaseOrdersByShipOwnerAndVessel(
    $shipOwnerId: Int!
    $vesselId: Int!
  ) {
    purchaseOrdersByShipOwnerAndVessel(
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
    ) {
      id
      number
      createdAt
    }
  }
`;

export const CHECK_SUPPLIER_USER_INFORMATION_QUERY = gql`
  query checkSupplierAndUserInformationAreComplete {
    checkSupplierAndUserInformationAreComplete
  }
`;

// ------------------------------------------ PURCHASE ORDERS WIZARD--------------------------------------------
export const GET_WIZARD_QUERY = gql`
  query getWizard($id: Int!) {
    getWizard(id: $id) {
      appUserId
      filename
      stepNumber
      shipOwnerId
      poMappedExcelColumnInput
      automaticDetectionIsExecuted
      totalRecords
      totalVessels
      totalSuppliersFound
      totalNewSuppliersFound
      totalInScopes
      totalOutOfScopes
    }
  }
`;

export const GET_WIZARD_LIST = gql`
  query getWizards($limit: Int!, $page: Int!, $steps: [Int!]!) {
    getWizards(limit: $limit, page: $page, steps: $steps) {
      Id
      filename
      useremail
      userId
      wizardStepName
      wizardStep
      shipOwnerName
      shipOwnerId
      totalRecords
      totalVessels
      totalSuppliersFound
      totalNewSuppliersFound
      totalInScopes
      totalOutOfScopes
    }
  }
`;

// ------------------------------------------ IHM --------------------------------------------
export const GET_IHMS_BY_USER_ROLE_QUERY = gql`
  query findAllIhmsByRole {
    findAllIhmsByRole
  }
`;

export const GET_IHM_REMAINING_QUANTITY_QUERY = gql`
  query getIhmRemainingQuantity($ihmId: Int!) {
    getIhmRemainingQuantity(ihmId: $ihmId)
  }
`;

// ------------------------------------------ IHM ITEMS --------------------------------------------
export const GET_IHM_ITEM_QUERY = gql`
  query getIhmItem($id: Int!) {
    getIhmItem(id: $id) {
      id
      quantity
      unit
      equipmentManufacturer
      equipmentDescription
      productName
      ihmPosition {
        id
        approvalStatus
        description
        vesselDrawingViewPath
        applicationOfPaint
        nameOfPaint
        nameOfEquipmentAndMachinery
        nameOfStructuralElement
        partsWhereUsed
        xBounds
        yBounds
        x
        y
        inventoryPart
        inventorySubPart
        quantity
        location
        ihmPositionImage {
          id
          name
          storagePath
          ihmPositionId
        }
      }
    }
  }
`;

export const CHECK_IMG_EXISTS = gql`
  query checkImageExists($ihmPositionId: Int!) {
    checkImageExists(ihmPositionId: $ihmPositionId)
  }
`;

export const GET_IHM_ITEMS_BY_SHIP_OWNER = gql`
  query getShipOwnerIhmItems($ihmQueryObject: IhmQueryObject!) {
    getShipOwnerIhmItems(ihmQueryObject: $ihmQueryObject) {
      id
      equipmentManufacturer
      equipmentDescription
      productName
      productCode
      quantity
      unit
      referenceNumber
      containsPCHM
      inventoryPart
      inventorySubPart
      dateAdded
      dateApproved
      approvedBy
      deleted
      vesselPurchaseOrderItemIhm {
        id
      }
    }
  }
`;

export const GET_IHM_ITEMS_WITH_MATERIALS = gql`
  query getIhmItemsWithMaterials($ihmQueryObject: IhmQueryObject!) {
    getIhmItemsWithMaterials(ihmQueryObject: $ihmQueryObject) {
      total
      items {
        id
        vesselId
        ihmId
        ihmUnit
        materialName
        materialCategory
        materialThresholdValue
        materialPresentAboveThresholdValue
        materialMass
        materialUnit
        materialComments
        equipmentManufacturer
        equipmentDescription
        productCode
        productName
        quantity
        containsPCHM
        inventoryPart
        inventorySubPart
        dateAdded
        dateApproved
        dateModified
        approvedBy
        deleted
        status
        replacedByIhmItemId
        applicationOfPaint
        nameOfPaint
        nameOfEquipmentAndMachinery
        nameOfStructuralElement
        partsWhereUsed
        location
        referenceNumber
        createdByAppUser
        createdByAppUserId
        installationNumber
        x
        y
        positionId
        totalQuantity
        totalQuantityUnit
      }
    }
  }
`;

export const GET_IHM_ITEMS_FOR_REPLACEMENT = gql`
  query getIhmITemsForReplacement($ihmId: Int!) {
    getIhmITemsForReplacement(ihmId: $ihmId) {
      ihmId
      productCode
      productName
      totalQuantity
      installedQuantity
      availableQuantity
      referenceNumber
    }
  }
`;

export const GET_IHM_BY_ID_QUERY = gql`
  query getIhmItem($id: Int!) {
    getIhmItem(id: $id) {
      id
      equipmentManufacturer
      equipmentDescription
      productName
      productCode
      quantity
      unit
      referenceNumber
      containsPCHM
      inventoryPart
      inventorySubPart
      dateAdded
      dateApproved
      approvedBy
      deleted
      status
      hasImg
      location
      replacedByIhmItem {
        id
        productCode
        productName
      }
      vesselPurchaseOrderItemIhm {
        id
        vesselId
        purchaseOrderItemId
        vessel {
          id
          name
          imoNumber
          email
          vesselType {
            id
            name
          }
          shipOwner {
            id
            name
          }
        }
      }
      ihmItemMaterials {
        id
        name
        category
        mass
        thresholdValue
        presentAboveThresholdValue
        unit
        comments
      }
      vesselIhmAttachments {
        id
        name
        path
        mimeType
        includeToExport
      }
      ihmPosition {
        id
        approvalStatus
        description
        vesselDrawingViewPath
        applicationOfPaint
        nameOfPaint
        nameOfEquipmentAndMachinery
        nameOfStructuralElement
        partsWhereUsed
        xBounds
        yBounds
        x
        y
        location
        isDeleted
        quantity
        inventoryPart
        inventorySubPart
        replacedByIhmPosition {
          id
          ihmItem {
            id
            productCode
            productName
          }
        }
      }
    }
  }
`;



export const FIND_POSITIONS_BASED_ON_DRAWING = gql`
  query findPositionBasedOnDrawing($fullDrawingPath: String!) {
    findPositionBasedOnDrawing(fullDrawingPath: $fullDrawingPath) {
      id
      description
      location
      applicationOfPaint
      nameOfPaint
      nameOfEquipmentAndMachinery
      nameOfStructuralElement
      partsWhereUsed
      vesselDrawingViewPath
      inventoryPart
      inventorySubPart
      xBounds
      yBounds
      x
      y
      quantity
      approvalStatus
      installationNumber
      isDeleted
      deletionDate
      ihmItemId
    }
  }
`;

export const GET_IHM_POSITION_BY_ID = gql`
  query IhmPosition($id: Int!) {
    IhmPosition(id: $id) {
      id
      description
      location
      applicationOfPaint
      nameOfPaint
      nameOfEquipmentAndMachinery
      nameOfStructuralElement
      partsWhereUsed
      vesselDrawingViewPath
      inventoryPart
      inventorySubPart
      xBounds
      yBounds
      x
      y
      quantity
      approvalStatus
      installationNumber
      isDeleted
      deletionDate
      ihmItemId
      replacedByIhmPositionId
      ihmPositionImage {
        id
        name
        storagePath
      }
    }
  }
`;

export const GET_IHM_ITEM_MAP_IMAGES = gql`
  query getIhmItemMapImages($id: Int!){
    getIhmItemMapImages(id: $id){
      Id
      MapImage
    }
  }
`

export const GET_SCREENSHOT_IMAGE = gql`
  query getIhmPositionScreenshot($ihmPositionId: Int!){
    getIhmPositionScreenshot(ihmPositionId: $ihmPositionId){
        id
        description
        location
        applicationOfPaint
        nameOfPaint
        nameOfEquipmentAndMachinery
        nameOfStructuralElement
        partsWhereUsed
        vesselDrawingViewPath
        inventoryPart
        inventorySubPart
        xBounds
        yBounds
        x
        y
        quantity
        approvalStatus
        installationNumber
        isDeleted
        deletionDate
        ihmItemId
        replacedByIhmPositionId
        ihmPositionImage {
          id
          name
          storagePath
        }
      }
  }
`;

export const GET_IHM_POSITION_WITH_DRAWING_QUERY = gql`
  query getIhmPositionWithDrawing($ihmPositionId: Int!) {
    getIhmPositionWithDrawing(ihmPositionId: $ihmPositionId) {
      ihmPosition {
        id
        description
        location
        applicationOfPaint
        nameOfPaint
        nameOfEquipmentAndMachinery
        nameOfStructuralElement
        partsWhereUsed
        vesselDrawingViewPath
        inventoryPart
        inventorySubPart
        xBounds
        yBounds
        x
        y
        quantity
        approvalStatus
        installationNumber
        isDeleted
        deletionDate
        ihmItemId
        replacedByIhmPositionId
      }
      ihmItem {
        id
        equipmentManufacturer
        equipmentDescription
        productName
        inventoryPart
        inventorySubPart
        quantity
        unit
      }
      vesselDrawing {
        id
        name
        path
        vesselId
      }
      ihmItemToReplace {
        id
        productName
        productCode
      }
      ihmPositionToReplace {
        id
        quantity
      }
    }
  }
`;

export const GET_IHM_POSITION_IMAGE_QUERY = gql`
  query ihmPositionImage($ihmPositionId: Int!) {
    ihmPositionImage(ihmPositionId: $ihmPositionId) {
      id
      name
      storagePath
      ihmPositionId
    }
  }
`;

export const GET_REPORT_STATUS_IHM_ITEMS = gql`
  query getReportStatusIhmItems(
    $dateFromInput: String!
    $totalMonths: Int!
    $reportPeriod: Int!
    $shipOwnerId: Int!
    $vesselId: Int!
  ) {
    getReportStatusIhmItems(
      dateFromInput: $dateFromInput
      totalMonths: $totalMonths
      reportPeriod: $reportPeriod
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
    ) {
      storedStatusTotal
      installedStatusTotal
      removedStatusTotal
    }
  }
`;

export const GET_REPORT_STATUS_IHM_ITEMS_SINCE_LAST_LOGIN = gql`
  query getReportStatusIhmItemsSinceLastLogin(
    $shipOwnerId: Int!
    $vesselId: Int!
  ) {
    getReportStatusIhmItemsSinceLastLogin(
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
    ) {
      storedStatusTotal
      installedStatusTotal
      removedStatusTotal
    }
  }
`;

export const GET_IHM_MATERIAL_BY_ID = gql`
  query findOne($id: Int!) {
    findOne(id: $id) {
      name
      category
      thresholdValue
      presentAboveThresholdValue
      mass
      unit
      comments
      ihmItemId
    }
  }
`;

export const GET_REPORT_IHM_ITEMS = gql`
  query getReportIhmItems(
    $dateFromInput: String!
    $totalMonths: Int!
    $reportPeriod: Int!
    $inventoryPart: Int!
    $subInventoryPart: Int!
    $status: [Int!]!
    $shipOwnerId: Int!
    $vesselId: Int!
    $includeAnnex1Materials: Boolean!
    $includeAnnex2Materials: Boolean!
  ) {
    getReportIhmItems(
      dateFromInput: $dateFromInput
      totalMonths: $totalMonths
      reportPeriod: $reportPeriod
      inventoryPart: $inventoryPart
      subInventoryPart: $subInventoryPart
      status: $status
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
      includeAnnex1Materials: $includeAnnex1Materials
      includeAnnex2Materials: $includeAnnex2Materials
    ) {
      total
      percentage
      comparisonText
      comparisonPercentage
      positive
    }
  }
`;

export const GET_REPORT_STORED_AND_INSTALLED_ITEMS = gql`
  query getReportStoredAndInstalled(
    $dateFromInput: String!
    $totalMonths: Int!
    $reportPeriod: Int!
    $shipOwnerId: Int!
    $vesselId: Int!
  ) {
    getReportStoredAndInstalled(
      dateFromInput: $dateFromInput
      totalMonths: $totalMonths
      reportPeriod: $reportPeriod
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
    ) {
      total
      totalStored
      totalInstalled
    }
  }
`;

// ------------------------------------------ VESSEL IHM ------------------------------------------------
export const GET_IHM_STORED_ITEMS = gql`
  query findIhmStoredItems(
    $searchToken: String!
    $shipOwnerId: Int!
    $vesselId: Int!
    $limit: Int!
    $page: Int!
  ) {
    findIhmStoredItems(
      searchToken: $searchToken
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
      limit: $limit
      page: $page
    ) {
      ihmId
      vesselName
      vesselImo
      inventorySubPart
      equipmentDescription
      productName
      storedQuantity
      location
      referenceNumber
      dateAdded
    }
  }
`;

export const GET_IHM_STORED_ITEMS_WITH_TOTAL_NUMBER_OF_ROWS = gql`
  query findIhmItemsAndTotalNumberOfRows(
    $searchToken: String!
    $shipOwnerId: Int!
    $vesselId: Int!
    $limit: Int!
    $page: Int!
    $filtersArray: [InScopeValidationFilter!]!
    $sortingOrder: InScopeValidationOrder!
  ) {
    findIhmItemsAndTotalNumberOfRows(
      searchToken: $searchToken
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
      limit: $limit
      page: $page
      filtersArray: $filtersArray
      sortingOrder: $sortingOrder
    ) {
      totalRows
      vesselIhmItemResponseRows{
        ihmId
        vesselName
        vesselImo
        inventorySubPart
        equipmentDescription
        productName
        storedQuantity
        location
        referenceNumber
        dateAdded
        equipmentManufacturer
        pendingItemStatus
      }
    }
  }
`;

export const GET_IHM_INSTALLED_ITEMS = gql`
  query findIhmInstalledItems(
    $searchToken: String!
    $shipOwnerId: Int!
    $vesselId: Int!
    $limit: Int!
    $page: Int!
    $showOnlyNotPlaced: Boolean!
  ) {
    findIhmInstalledItems(
      searchToken: $searchToken
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
      limit: $limit
      page: $page
      showOnlyNotPlaced: $showOnlyNotPlaced
    ) {
      ihmId
      ihmPositionId
      vesselName
      vesselImo
      inventorySubPart
      equipmentDescription
      productName
      installedQuantity
      location
      referenceNumber
      dateAdded
    }
  }
`;

export const GET_IHM_PENDING_FOR_APPROVAL_ITEMS = gql`
  query findIhmPendingForApprovalItems(
    $searchToken: String!
    $shipOwnerId: Int!
    $vesselId: Int!
    $limit: Int!
    $page: Int!
  ) {
    findIhmPendingForApprovalItems(
      searchToken: $searchToken
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
      limit: $limit
      page: $page
    ) {
      ihmId
      ihmPositionId
      vesselName
      vesselImo
      inventorySubPart
      equipmentDescription
      productName
      installedQuantity
      location
      referenceNumber
      dateAdded
      approvalStatus
      requestDate
      requestBy
    }
  }
`;
// ------------------------------------------ VESSEL IHM VERSIONING ---------------------------------------------

export const GET_VESSEL_IHM_VERSIONING = gql`
  query getVesselIhmVersioning($vesselId: Int!){
    getVesselIhmVersioning(vesselId: $vesselId){
      id
      updatedAt
      comment
      numberOfVersioning
      vesselId
      vesselInfoLastModified
      tableInfoLastModified
      MdSdocInfoLastModified
    }
  }
`;

// ------------------------------------------ VESSEL IHM HISTORY ------------------------------------------------
export const GET_VESSEL_IHM_HISTORY_BY_IHM = gql`
  query vesselIhmHistoriesByIhm($ihmId: Int!) {
    vesselIhmHistoriesByIhm(ihmId: $ihmId) {
      id
      text
      createdAt
    }
  }
`;

// ------------------------------------------ PURCHASE ORDERS ---------------------------------------------------
export const GET_PURCHASE_ORDERS_BY_USER_ROLE_QUERY = gql`
  query findAllPOsByRole {
    findAllPOsByRole
  }
`;

// ------------------------------------------ PURCHASE ORDER REQUESTS--------------------------------------------
export const GET_PO_ORDERS_EXTERNAL = gql`
  query getPurchaseOrdersWithPurchaseOrderItems(
    $shipOwnerExternalId: String!
    $vesselExternalId: String!

  ){
    getPurchaseOrdersWithPurchaseOrderItems(
      vesselExternalId: $vesselExternalId
      shipOwnerExternalId: $shipOwnerExternalId

    ){
      Number
      ExternalId
      Items {
        externalId
        productName
      }
    }
  }
`;

export const GET_PO_ORDERS_WITH_ITEMS_EXTERNAL = gql`
  query getPoItemsSupplierDocumentsExternal(
    $vesselExternalId: String!
    $purchaseOrderExternalId: String!
    $limit: Int!
    $page: Int!
    $filtersArray: [InScopeValidationFilter!]!
    $purchaseOrderItemExternalId: String!
    $searchToken: String!
  ){
    getPoItemsSupplierDocumentsExternal(
      vesselExternalId: $vesselExternalId
      purchaseOrderExternalId: $purchaseOrderExternalId
      limit: $limit
      page: $page
      filtersArray: $filtersArray
      purchaseOrderItemExternalId: $purchaseOrderItemExternalId
      searchToken: $searchToken
    ){
      rows {
        externalIdpoi
        productName
        poisdExternalId
        poiproductCode
        poiequipmentDescription
        poicontainsCHM
      }
      totalRows
    }
  }
`;

export const GET_IMPORTED_REQUESTS = gql`
  query getImportedPos(


    $limit: Int!
    $page: Int!
    $selectedVesselId: Int!
    $filtersArray: [InScopeValidationFilter!]!
    $sortingOrder: InScopeValidationOrder!
    $shipOwnerId: Int
  ) {
    getImportedPos(


      limit: $limit
      page: $page
      selectedVesselId: $selectedVesselId
      filtersArray: $filtersArray
      sortingOrder: $sortingOrder
      shipOwnerId: $shipOwnerId
    ) {
      id
      poRequestSupplierId
      purchaseOrderId
      supplierRequestId
      purchaseOrderNumber
      productName
      productCode
      equipmentManufacturer
      shipOwnerName
      shipOwnerId
      vesselId
      vesselName
      vesselIMO
      supplierName
      supplierReferenceNumber
      newMessagesInChat
      orderStatus
      supplierRequestEmailAddress
      poDate
      containsCHM
      documentType
      inScope
      totalRows
      supplierId
    }
  }
`;


export const GET_PURCHASE_ORDER_REQUESTS = gql`
  query getPurchaseOrderRequests(
    $states: [Int!]!
    $supplierRequestId: String!
    $limit: Int!
    $page: Int!
    $selectedVesselId: Int!
    $filtersArray: [InScopeValidationFilter!]!
    $sortingOrder: InScopeValidationOrder!
    $inScope: Int!
    $containsHazMat: Int!
    $documentType: [Int!]!
    $orderStatuses: [Int!]!
    $poRequestStatus: Int!
    $shipOwnerId: Int
  ) {
    getPurchaseOrderRequests(
      states: $states
      supplierRequestId: $supplierRequestId
      limit: $limit
      page: $page
      selectedVesselId: $selectedVesselId
      filtersArray: $filtersArray
      sortingOrder: $sortingOrder
      inScope: $inScope
      containsHazMat: $containsHazMat
      documentType: $documentType
      orderStatuses: $orderStatuses
      poRequestStatus: $poRequestStatus
      shipOwnerId: $shipOwnerId
    ) {
      id
      poRequestSupplierId
      purchaseOrderId
      supplierRequestId
      purchaseOrderNumber
      productName
      productCode
      equipmentManufacturer
      shipOwnerName
      shipOwnerId
      vesselId
      vesselName
      vesselIMO
      supplierName
      supplierReferenceNumber
      newMessagesInChat
      orderStatus
      supplierRequestEmailAddress
      poDate
      containsCHM
      documentType
      lastUpdate
      appUserName
      inScope
      totalRows
      supplierId
    }
  }
`;

export const GET_PURCHASE_ORDER_REQUESTS_DANAOS = gql`
  query getDanaosRowsbyShipOwnerId(
    $limit: Int!
    $page: Int!
    $isProcessed: Boolean!
    $shipOwnerId: Int!
  ){
    getDanaosRowsbyShipOwnerId(
      limit: $limit
      page: $page
      isProcessed: $isProcessed
      shipOwnerId: $shipOwnerId
    ){
      rows {
        id
        vesselCode
        department
        documentCode
        documentNo
        itemSerialNo
        parcial
        exportSequence
        recordStatus
        syncDate
        entryType
        imoNumber
        vesselName
        poNumber
        poApprovedDate
        vendorName
        vendorEmail
        vendorPhone
        materialCategory
        equipmentName
        modelName
        makerName
        equipmentSerialNo
        subEquipmentName
        partNo
        itemShortDescription
        itemLongDescription
        qty
        uom
        vendorHeaderRemarks
        mdRequired
        sdReceived
        expectedDeliveryDate
        vendorCountry
        deliveryPort
        apiUniqueId
        ledgerCard
        orderReference
        dateExported
        poDate
        processedDate
        shipOwnerId
      }
      totalRows
    }
  }
`;

export const DOWNLOAD_EXCEL_FILE_DANAOS = gql`
  query downloadExcelFileDanaos(
    $total: Int!
  ){
    downloadExcelFileDanaos(
      totalVendors: $total
    )
  }
`;



export const GET_PURCHASE_ORDER_REQUESTS_DANAOS_BY_SHIPOWNER_ID_PROCESSED = gql`
  query getDanaosRowsbyShipOwnerIdProcessed(
    $limit: Int!
    $page: Int!
    $isProcessed: Boolean!
    $shipOwnerId: Int!
  ){
    getDanaosRowsbyShipOwnerIdProcessed(
      limit: $limit
      page: $page
      isProcessed: $isProcessed
      shipOwnerId: $shipOwnerId
    ){
      rows {
        batchDownloadId
        shipOwnerName
        batchDownloadDate
      }
      totalRows
    }
  }
`;

export const GET_PO_REQUEST_SUPPLIERS_BY_PO_NUMBER_AND_SUP_REQ_ID = gql`
  query getPurchaseOrderRequestsByPoNumberAndSuppReqId(
    $poNumber: String!
    $supplierRequestId: String!
    $vesselImo: String!
    $stateOfPoRequest: [Int!]!
    $selectedPurchaseOrderId: Int!
    $supplierId: Int!
    $dateOfPo: String!
    $supplierReferenceNumber: String!
  ) {
    getPurchaseOrderRequestsByPoNumberAndSuppReqId(
      poNumber: $poNumber
      supplierRequestId: $supplierRequestId
      vesselImo: $vesselImo
      stateOfPoRequest: $stateOfPoRequest
      selectedPurchaseOrderId: $selectedPurchaseOrderId
      supplierId: $supplierId
      dateOfPo: $dateOfPo
      supplierReferenceNumber: $supplierReferenceNumber
    ) {
      id
      poNumber
      productName
      productCode
      supplierReferenceNumber
      poDate
      inScope
      unit
      quantity
      vesselName
      supplierName
      shipOwnerName
      equipmentDescription
    }
  }
`;

export const GET_PURCHASE_ORDER_ITEMS_BY_PO_NUMBER_AND_SUP_REQ_ID = gql`
  query findPurchaseOrderItemsByPoNumberAndSuppReqId(
    $vesselId: Int!
    $supplierId: Int!
    $purchaseOrderId: Int!
  ) {
    findPurchaseOrderItemsByPoNumberAndSuppReqId(
      vesselId: $vesselId
      supplierId: $supplierId
      purchaseOrderId: $purchaseOrderId
    ) {
      id
      poNumber
      productName
      productCode

      poDate
      inScope
      unit
      quantity
      vesselName
      supplierName
      shipOwnerName
      equipmentDescription
    }
  }
`;

export const GET_TOTAL_PCHM_SUPPLIER_REQUEST = gql`
  query getTotalPchmSupplierRequest {
    getTotalPchmSupplierRequest {
      Total
      PoItems
    }
  }
`;

export const GET_SUPPLIER_ISSUERS = gql`
  query getIssuers {
    getIssuers {
      id
      name
      lastname
    }
  }
`;

export const GET_SUPPLIER_REQUEST_GREEN_FORM_QUERY_APP_USER = gql`
  query getSupplierRequestGreenFormUsingAppUser(
    $supplierRequestId: String!
    $vesselImo: String!
  ){
    getSupplierRequestGreenFormUsingAppUser(
      supplierRequestId: $supplierRequestId
      vesselImo: $vesselImo
    ){
      remark1
      remark2
      remark3
      divisionName
      unit
      contactPerson
      contactPersonId
      telephoneNumber
      address
      amount
      companyName
      emailAddress
      availableSupplierContactPersons {
        id
        name
        lastname
      }
    }
  }
`;

export const GET_SUPPLIER_REQUEST_GREEN_FORM_QUERY = gql`
  query getSupplierRequestGreenForm(
    $supplierRequestId: String!
    $vesselImo: String!
  ) {
    getSupplierRequestGreenForm(
      supplierRequestId: $supplierRequestId
      vesselImo: $vesselImo
    ) {
      remark1
      remark2
      remark3
      divisionName
      unit
      contactPerson
      contactPersonId
      telephoneNumber
      address
      amount
      companyName
      emailAddress
      availableSupplierContactPersons {
        id
        name
        lastname
      }
    }
  }
`;

export const FIND_ALL_POS_BY_ROLE_QUERY = gql`
  query findAllPOsByRole {
    findAllPOsByRole
  }
`;

export const FIND_ALL_REQUESTS_BY_ROLE = gql`
  query findAllRequestsByRole($states: [Int!]!) {
    findAllRequestsByRole(states: $states)
  }
`;
export const FIND_ALL_POI_REQUESTS_BY_ROLE = gql`
  query findAllPoItemsByRole($states: [Int!]!) {
    findAllPoItemsByRole(states: $states)
  }
`;

export const FIND_ALL_PO_SHIP_OWNER = gql`
  query findAllPoForShipOwner($states: [Int!]!) {
    findAllPoForShipOwner(states: $states)
  }
`;

export const FIND_ALL_VESSELS_BY_ROLE = gql`
  query findAllVesselsByRole {
    findAllVesselsByRole
  }
`;

export const FIND_ALL_IHMS_BY_ROLE = gql`
  query findAllIhmsByRole {
    findAllIhmsByRole
  }
`;

export const FIND_SHIPOWNER_COMPLIANCE_QUERY = gql`
  query shipOwnerCompliance {
    shipOwnerCompliance {
      vesselCompliance
      shipOwnerCompliance
    }
  }
`;

export const GET_REPORT_HAZARDOUS_MATERIALS_QUERY = gql`
  query getReportHazardousMaterials(
    $dateFromInput: String!
    $totalMonths: Int!
    $reportPeriod: Int!
    $shipOwnerId: Int!
    $vesselId: Int!
  ) {
    getReportHazardousMaterials(
      dateFromInput: $dateFromInput
      totalMonths: $totalMonths
      reportPeriod: $reportPeriod
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
    ) {
      totalStored
      totalInstalled
    }
  }
`;

export const FIND_SUPPLIER_COMPLIANCE_QUERY = gql`
  query supplierCompliance(
    $shipOwnerId: Int!
    $vesselId: Int!
  ) {
    supplierCompliance(
      shipOwnerId: $shipOwnerId
      vesselId: $vesselId
    )
  }
`;

export const GET_HAZ_DASH_SMART_AGENT_PO = gql`
  query hazDashSmartAgentPo {
    hazDashSmartAgentPo
  }
`;

export const GET_PURCHASE_ORDER_ITEM_GREEN_FORM_QUERY = gql`
  query getPurchaseOrderItemGreenForm($poItemId: Int!) {
    getPurchaseOrderItemGreenForm(poItemId: $poItemId) {
      remark1
      remark2
      remark3
      divisionName
      unit
      contactPerson
      contactPersonId
      telephoneNumber
      address
      amount
      companyName
      emailAddress
      availableSupplierContactPersons {
        id
        name
        lastname
      }
    }
  }
`;

export const GET_OUT_OF_SCOPE_PORS_QUERY = gql`
  query getOutOfScopePoRequestSuppliers(
    $supplierRequestId: String!
    $purchaseOrderNumber: String!
    $limit: Int!
    $page: Int!
    $filtersArray: [InScopeValidationFilter!]!
    $sortingOrder: InScopeValidationOrder!
    $vesselImo: String!
  ) {
    getOutOfScopePoRequestSuppliers(
      supplierRequestId: $supplierRequestId
      purchaseOrderNumber: $purchaseOrderNumber
      limit: $limit
      page: $page
      filtersArray: $filtersArray
      sortingOrder: $sortingOrder
      vesselImo: $vesselImo
    ) {
      id
      productName
      productCode
      supplierReferenceNumber
    }
  }
`;

export const GET_PO_REQUEST_BY_PURCHASE_ORDER_ITEM_ID = gql`
  query GetPoRequestSupplierByPurchaseOrderItemId($purchaseOrderItemId: Int!){
    GetPoRequestSupplierByPurchaseOrderItemId(
      purchaseOrderItemId: $purchaseOrderItemId
    ){
      supplierRequestId
      purchaseOrderItemId
      poNumber
      supplierReferenceNumber
      supplierId
      vesselName
      vesselImo
      equipmentManufacturer
      equipmentDescription
      productCode
      productName
      quantity
      unit
      containsPCHM
      containsCHM
      inScope
      poDate
      equipmentId
      productDescription
      general
      mdSDoCStatus
      formType
      externalCategory
      supplierRequestEmailAddress
      supplierRequestName
      poRequestStatus
      state
      matchingAnalysis
      doNotExecuteWorkflow
      wizardId
      submitToSupplierInvitationDate
      supplierDocumentSubmissionDate
      maintainerActionDate
      totalSupplierReminders
      supplierName
      shipOwnerName
      externalId
    }
  }
`;

export const GET_ALL_SYSTEMS = gql`
  query getAllSystems{
    getAllSystems{
      isEx
      capacity
      id
    }
  }
`;

export const GET_SP_SERIAL_NUMBERS = gql`
  query getSerialNumbers(
    $limit: Int!
    $page: Int!
    $filtersArray: [InScopeValidationFilter!]!
    $sortingOrder: InScopeValidationOrder!
  ){
    getSerialNumbers(
      limit: $limit
      page: $page
      filtersArray: $filtersArray
      sortingOrder: $sortingOrder
    ){
      rows {
        id
        isEx
        troManufacturer
        filterManufacturer
        serialNumber
        spSystemId
        sPSystem {
          id
          isEx
          capacity
        }
      }
      totalRows
    }
  }
`;

export const GET_PURCHASE_ORDER_ITEMS_FOR_EVERY_PO_REQUEST = gql`
  query getPurchaseOrderItemsForEveryPoRequest(
    $purchaseOrdersToBeSearched: [PurchaseOrdersToSearch!]!
    $limit: Int!
    $page: Int!
    $filtersArray: [InScopeValidationFilter!]!
    $sortingOrder: InScopeValidationOrder!
    $dateFrom: String
    $dateTo: String
  ){
    getPurchaseOrderItemsForEveryPoRequest(
      purchaseOrdersToBeSearched: $purchaseOrdersToBeSearched
      limit: $limit
      page: $page
      filtersArray: $filtersArray
      sortingOrder: $sortingOrder
      dateFrom: $dateFrom
      dateTo: $dateTo
    ){
      rows  {
        purchaseOrderId
        purchaseOrderItemId
        totalUploadedDocuments
        totalGeneratedDocuments
        createdAt
        updatedAt
        equipmentManufacturer
        equipmentDescription
        productName
        productCode
        quantity
        containsPCHM
        containsCHM
        inScope
        vesselId
        supplierId
        supplierName
        EquipmentId
        ProductDescription
        General
        OrderStatus
        MdSDoCStatus
        FormType
        ExternalCategory
        poRequestSupplierId
        unit
        poItemSupplierReferenceNumber
        documentType
        poNumber
        poDate
        PoRequestSupplierState
        }
      totalRows
    }
  }
`

export const GET_PURCHASE_ORDER_ITEM_BY_PO_REQUEST = gql`
  query getPurchaseOrderItemByPoRequest(
    $supplierRequestId: String!
    $purchaseOrderId: Int!
    $purchaseOrderNumber: String!
    $limit: Int!
    $page: Int!
    $filtersArray: [InScopeValidationFilter!]!
    $sortingOrder: InScopeValidationOrder!
    $dateFrom: String
    $dateTo: String
  ) {
    getPurchaseOrderItemByPoRequest(
      supplierRequestId: $supplierRequestId
      purchaseOrderId: $purchaseOrderId
      purchaseOrderNumber: $purchaseOrderNumber
      limit: $limit
      page: $page
      filtersArray: $filtersArray
      sortingOrder: $sortingOrder
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
    rows  {
        purchaseOrderId
        purchaseOrderItemId
        totalUploadedDocuments
        totalGeneratedDocuments
        createdAt
        updatedAt
        equipmentManufacturer
        equipmentDescription
        productName
        productCode
        quantity
        containsPCHM
        containsCHM
        inScope
        vesselId
        supplierId
        supplierName
        EquipmentId
        ProductDescription
        General
        OrderStatus
        MdSDoCStatus
        FormType
        ExternalCategory
        poRequestSupplierId
        unit
        poItemSupplierReferenceNumber
        documentType
        poNumber
        poDate
        PoRequestSupplierState
        }
        totalRows
    }
  }
`;

export const GET_SUPPLIER_NAME_AND_SUPPLIER_REFERNCE_NUMBER = gql`
  query getSupplierNameAndSupplierReferenceNumber(
    $supplierRequestId: String!
    $purchaseOrderId: Int!
    $purchaseOrderNumber: String!
  ) {
    getSupplierNameAndSupplierReferenceNumber(
      supplierRequestId: $supplierRequestId
      purchaseOrderId: $purchaseOrderId
      purchaseOrderNumber: $purchaseOrderNumber
    ) {
      name
      supplierRenfernceNumer
      supplierId
    }
  }
`;




export const GET_NEXT_AVAILABLE_STATES = gql`
  query GetNextAvailableStates(
    $supplierRequestId: String!
    $purchaseOrderNumber: String!
    $vesselImo: String!
    $poDate: String!
    $supplierReferenceNumber: String!
    $supplierId: Int!
  ) {
    GetNextAvailableStates(
      supplierRequestId: $supplierRequestId
      purchaseOrderNumber: $purchaseOrderNumber
      vesselImo: $vesselImo
      poDate: $poDate
      supplierReferenceNumber: $supplierReferenceNumber
      supplierId: $supplierId
    )
  }
`;

export const GET_STATE_OF_DOCUMENTS = gql`
  query GetStateOfDocuments(
    $purchaseOrderId: Int!
    $supplierId: Int!
  ){
    GetStateOfDocuments(
      purchaseOrderId: $purchaseOrderId
      supplierId: $supplierId
    ){
      countOfPurchaseOrderItems
      countOfGeneratedDocumentsOfPurchaseOrderItems
    }
  }
`;

export const CHECK_PO_CONTAINS_HAZ_MAT = gql`
  query checkPoContainsHazMat($poId: Int!, $supplierRequestId: String!) {
    checkPoContainsHazMat(poId: $poId, supplierRequestId: $supplierRequestId)
  }
`;

export const GET_PO_REQUESTS_BY_USER_ROLE_QUERY = gql`
  query findAllRequestsByRole($states: [Int!]) {
    findAllRequestsByRole(states: $states)
  }
`;

// ------------------------------------------ PURCHASE ORDER REQUEST SUPPLIER--------------------------------------------
export const CHECK_PO_REQ_IS_CONFIGURED_QUERY = gql`
  query checkPoRequestIsConfigured($poId: Int!, $supplierRequestId: String!) {
    checkPoRequestIsConfigured(
      poId: $poId
      supplierRequestId: $supplierRequestId
    )
  }
`;

// ------------------------------------------ PURCHASE ORDER CHAT--------------------------------------------
export const GET_CHAT_PURCHASE_ORDER_ITEM = gql`
  query getChatPurchaseOrderItem($poItemId: Int!) {
    getChatPurchaseOrderItem(poItemId: $poItemId) {
      purchaseOrderItemId
      purchaseOrderItemChatMessages {
        chatMessage
        from
        to
      }
    }
  }
`;

export const GET_CHAT_PURCHASE_ORDER = gql`
  query getChatPurchaseOrder($poId: Int!, $poRequestId: String!) {
    getChatPurchaseOrder(poId: $poId, poRequestId: $poRequestId) {
      purchaseOrderId
      purchaseOrderRequestId
      purchaseOrderItemChatMessages {
        chatMessage
        leftText
        rightText
      }
    }
  }
`;

// ------------------------------------------ PURCHASE ORDER ITEM MD--------------------------------------------
export const GET_FORMS_QUERY = gql`
  query getForms($poItemId: Int!) {
    getForms(poItemId: $poItemId) {
      id
      purchaseOrderId
      vesselId
      supplierId
      equipmentManufacturer
      equipmentDescription
      productName
      productCode
      quantity
      unit
      containsPCHM
      containsCHM
      inScope
      EquipmentId
      ProductDescription
      General
      OrderStatus
      MdSDoCStatus
      FormType
      ExternalCategory
      SupplierName
      PoDate
      containsDeliveryUnit
      containsDeliveryQuantity
      documentType
      purchaseOrder {
        id
      }

      vessel {
        id
      }

      supplier {
        id
      }

      purchaseOrderItemSupplierDocument {
        id
        purchaseOrderItemId
        isApproved
        purchaseOrderItemMd {
          remark1
          remark2
          remark3
          amount
          unit
          deliveryAmount
          deliveryUnit
        }

        purchaseOrderSupplierDocumentSdoc {
          id
          purchaseOrderItemSupplierDocumentId
          sdocNumber
          issuerName
          issuerId
          issuerAddress
          purchaseOrderSupplierDocumentSdocRegulation {
            id
            title
            purchaseOrderSupplierDocumentSdocId
            documentNumber
            title
            edition
            dateOfIssue
          }
        }
      }
    }
  }
`;

export const GET_POITEM_MD_BASED_ON_POITEM = gql`
  query getPurchaseOrderItemMdBasedOnPoItem($poItemId: Int!) {
    getPurchaseOrderItemMdBasedOnPoItem(poItemId: $poItemId) {
      id
      purchaseOrderItemSupplierDocumentId
      idNo
      remark1
      remark2
      remark3
      companyName
      divisionName
      address
      contactPerson
      telephoneNumber
      faxNumber
      emailAddress
      unit
      amount
      containsDeliveryUnit
      containsDeliveryQuantity
      purchaseOrderItemMdMaterials {
        id
        purchaseOrderItemMdId
        thresholdValue
        presentAboveThresholdValue
        mass
        unit
        comments
        name
        category
      }
    }
  }
`;

export const GET_POITEM_MD_MATERIAL_NAMES = gql`
  query getMaterialNames($isEuSsr: Boolean!) {
    getMaterialNames(isEuSsr: $isEuSsr)
  }
`;

export const GET_POITEM_MD_MATERIAL_NAMES_REMAINING = gql`
  query getPurchaseOrderItemMaterialNames($isEuSsr: Boolean!, $poId: Int!) {
    getPurchaseOrderItemMaterialNames(isEuSsr: $isEuSsr, poId: $poId)
  }
`;

export const GET_POITEM_MATERIAL_DETAILS = gql`
  query getMaterialDetails($materialName: String!) {
    getMaterialDetails(materialName: $materialName) {
      categories
      thresholdValue
    }
  }
`;

// ------------------------------------------ PURCHASE ORDER UPLOADED DOCUMENTS --------------------------------------------
export const GET_DOCUMENT_INFO_QUERY = gql`
  query getDocumentInfo($uuid: String!) {
    getDocumentInfo(uuid: $uuid) {
      isValid
      documentType
      documentName
      issuer
      issueDate
      signature
      isFound
    }
  }
`;

export const GET_UPLOADED_DOCUMENTS_BY_PO_ITEM_ID = gql`
  query poItemUploadedDocumentsByPoItemId($poItemId: Int!) {
    poItemUploadedDocumentsByPoItemId(poItemId: $poItemId) {
      TotalDocumentsFound
      DocumentIds
    }
  }
`;

export const GET_UPLOADED_DOCUMENTS_IDS_BY_PO_ID = gql`
  query poItemUploadedDocumentsByPurchaseOrderId($poId: Int!){
    poItemUploadedDocumentsByPurchaseOrderId(poId: $poId){
      TotalDocumentsFound
      DocumentIds {
        poItemId
        documentId
        productName
        containsCHM
        isSdoc
      }
    }
  }
`;

export const GET_UPLOADED_DOCUMENTS_BY_PO_ID_QUERY = gql`
  query getUploadedDocumentsByPoId($poId: Int!, $supplierId: Int!, $vesselImo: String!) {
    getUploadedDocumentsByPoId(poId: $poId, supplierId: $supplierId, vesselImo: $vesselImo) {
      poItemUploadedDocId
      poItemUploadedDocName
      poItemId
      poRequestSupplierState
      isGenerated
    }
  }
`;

export const GET_UPLOADED_DOCUMENTS_BY_ID_QUERY = gql`
  query getPoItemUploadedDocument($id: Int!) {
    getPoItemUploadedDocument(id: $id) {
      id
      containsSdoc
      containsMd
      mimeType
      name
    }
  }
`;

export const GET_COUNT_DOCUMENTS_QUERY = gql`
  query countDocuments {
    countDocuments {
      totalGeneratedGreenDocuments
      totalGeneratedHazMatDocuments
      totalUploadedGreenDocuments
      totalUploadedHazMatDocuments
      totalApiGreenDocuments
      totalApiHazMatDocuments
    }
  }
`;

// ------------------------------------------ PURCHASE ORDER ITEM HISTORY--------------------------------------------
export const GET_PO_REQUEST_HISTORY = gql`
  query getPoRequestHistory($supplierRequestId: String!) {
    getPoRequestHistory(supplierRequestId: $supplierRequestId) {
      id
      fromState
      toState
      stateMachineLog
      userLog
      supplierRequestId
    }
  }
`;

// ------------------------------------------ MY NOTIFICATIONS --------------------------------------------
export const GET_USER_NOTIFICATIONS_QUERY = gql`
  query myNotifications(
    $page: Int!
    $limit: Int!
    $markAsRead: Boolean!
    $readOnlyNotSaw: Boolean!
  ) {
    myNotifications(
      page: $page
      limit: $limit
      markAsRead: $markAsRead
      readOnlyNotSaw: $readOnlyNotSaw
    ) {
      id
      what
      text
      userEmailAddress
      iSawIt
    }
  }
`;

export const COUNT_MY_NOTIFICATIONS_QUERY = gql`
  query countMyNotifications {
    countMyNotifications {
      Id
      Total
    }
  }
`;

// ------------------------------------------ MY FOLLOWINGS --------------------------------------------
export const GET_IS_USER_FOLLOWING_ENTITY_QUERY = gql`
  query isFollowing($followType: Int!, $entityReferenceId: Int!) {
    isFollowing(followType: $followType, entityReferenceId: $entityReferenceId)
  }
`;

export const GET_FOLLOWED_SYSTEM_ENTITIES_QUERY = gql`
  query iamFollowing($limit: Int!, $page: Int!) {
    iamFollowing(limit: $limit, page: $page) {
      id
      type
      referenceId
      submitEmail
      userEmailAddress
      name
    }
  }
`;

// ------------------------------------------ DANAOS API DATA--------------------------------------------
export const GET_DANAOS_ROWS = gql`
  query getDanaosRows($limit: Int!, $page: Int!, $isProcessed: Boolean!) {
    iamFollowing(limit: $limit, page: $page, isProcessed: $isProcessed) {
      id
      type
      referenceId
      submitEmail
      userEmailAddress
      name
    }
  }
`;

export const GET_ALL_DANAOS_ROWS = gql`
  query downloadDanaosRows(
    $shipOwnerId: Int!
  ){
    downloadDanaosRows(
      shipOwnerId: $shipOwnerId
    )
  }
`;

// ----------------------------------------SPARE PARTS------------------------------------------------------

export const GET_SPARE_PARTS = gql`
  query getSpareParts($limit: Int!, $page: Int!, $filtersArray: [InScopeValidationFilter!]!, $sortingOrder: InScopeValidationOrder!, $searchToken: String!){
    getSpareParts(limit: $limit, page: $page, filtersArray: $filtersArray, sortingOrder: $sortingOrder, searchToken: $searchToken){
      rows {
        id
        alwaysInclude
        recommendedQuantity
        itemNumber
        productName
        description
        spCategory {
          id
          spCategoryId
          spSparePartId
        }
      }
      totalRows
    }
  }
`;

// ----------------------------------------COMPONENTS--------------------------------------------------------

export const GET_SP_COMPONENTS = gql`
  query getComponents($limit: Int!, $page: Int!, $filtersArray: [InScopeValidationFilter!]!, $sortingOrder: InScopeValidationOrder!, $searchToken: String!){
    getComponents(limit: $limit, page: $page, filtersArray: $filtersArray, sortingOrder: $sortingOrder, searchToken: $searchToken){
      rows {
          id
          name
          definition
          thumbnailImage
          drawingImage
          filterManufacturer
          troManufacturer
          hasMap
      }
      totalRows
    }
  }
`;

// ---------------------------------------COMPONENTS AND SPAREPARTS---------------------------------------------

export const GET_SPARE_PARTS_FOR_COMPONENT = gql`
  query getSparePartsForComponent($spComponentId: Int!,  $limit: Int!, $page: Int!, $sortingOrder: InScopeValidationOrder!, $filtersArray: [InScopeValidationFilter!]!){
    getSparePartsForComponent(spComponentId: $spComponentId, limit: $limit, page: $page, sortingOrder: $sortingOrder, filtersArray: $filtersArray){
      rows {
        id
        itemNumber
        productName
        installationId
      }
      totalRows
    }
  }
`;

export const GET_POSITIONS_FOR_SPARE_PART_OF_COMPONENT = gql`
  query getPositionsForSparePartOfComponent($spSparePartsComponentId: Int!){
    getPositionsForSparePartOfComponent(spSparePartsComponentId: $spSparePartsComponentId){
      id
      lat
      lon
      spSparePartsComponentId
    }
  }
`;

export const GET_SP_COMPONENTS_FOR_SP_SYSTEM = gql`
  query getSpComponenentsForSpSystem($capacity: String!, $isEx: String!, $limit: Int!, $page: Int!, $filtersArray: [InScopeValidationFilter!]!, $sortingOrder: InScopeValidationOrder!){
    getSpComponenentsForSpSystem(capacity: $capacity, isEx: $isEx, limit: $limit, page: $page, filtersArray: $filtersArray, sortingOrder: $sortingOrder){
      rows {
        id
        name
        thumbnailImage
        drawingImage
        filterManufacturer
        troManufacturer
        definition
      }
      totalRows
    }
  }
`;

export const GET_SP_ORDERS_WITH_SPAREPARTS = gql`
  query getSpOrdersWithSpareparts($limit: Int!, $page: Int!, $filtersArray: [InScopeValidationFilter!]!, $sortingOrder: InScopeValidationOrder!){
    getSpOrdersWithSpareparts(limit: $limit, page: $page, filtersArray: $filtersArray, sortingOrder: $sortingOrder){
      rows {
        id
        createdAt
        firstName
        lastName
        emailAddress
        phoneNumber
        vesselImo
        comments
        selectedSpareParts
        quotationIsProcessed
      }
      totalRows
    }
  }
`;

export const GET_SHIPOWNERS_EMAIL_REPORTS = gql`
  query getShipOwnerEmailReports($limit: Int!, $page: Int!, $filtersArray: [InScopeValidationFilter!]!, $sortingOrder: InScopeValidationOrder!){
    getShipOwnerEmailReports(limit: $limit, page: $page, filtersArray: $filtersArray, sortingOrder: $sortingOrder){
      rows {
        batchId
        shipOwnerName
        submitedAt
        percentOfEmailsForVessels
      }
      totalRows
    }
  }
`;

export const GET_SHIPOWNERS_COMPLETE_IHM_EMAIL_REPORTS = gql`
  query getShipOwnerEmailCompleteReports($batchId: String!, $limit: Int!, $page: Int!, $filtersArray: [InScopeValidationFilter!]!, $sortingOrder: InScopeValidationOrder!){
    getShipOwnerEmailCompleteReports(batchId: $batchId, limit: $limit, page: $page, filtersArray: $filtersArray, sortingOrder: $sortingOrder){
      rows {
        id
        batchId
        shipOwnerName
        emailAddresses
        isSent
        submitedAt
        vesselName
        vesselId
      }
      totalRows
    }
  }
`;