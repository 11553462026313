import * as React from "react";
import { useEffect, useState } from "react";
// utils
import {
  Container,
  Typography,
  Stepper,
  Box,
  Step,
  StepLabel,
  Button,
} from "@mui/material";
import LoadingScreen from "../../../../components/LoadingScreen";
import {
  ImportSiWizardSteps,
  SupplierInventoryDTO,
} from "../../../../@types/Supplier";
import ImportSiWizardSelectFile from "./ImportSiWizardSelectFile";
import ImportSiWizardMapHeaders from "./ImportSiWizardMapHeaders";
import ImportSiWizardResults from "./ImportSiWizardResults";

type ImportSiWizardProps = {
  onClosePreview: VoidFunction;
};

// ------------------------------------------------------------------------------------------------------------
export default function ImportSiWizard({
  onClosePreview,
}: ImportSiWizardProps) {
  const [activeStep, setActiveStep] = useState<ImportSiWizardSteps>(
    ImportSiWizardSteps.SELECT_EXCEL_FILE
  );
  const [canProceed, setCanProceed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Step 1 Props
  const [file, setFile] = useState<File>();
  // Step 2 Props
  const [supplierInventoriesArray, setSupplierInventoriesArray] =
    useState<SupplierInventoryDTO[]>();

  // ------------------------------------------------ Define Mutations ------------------------------------------------

  // --------------------------------------- Component Lifecycle ---------------------------------------------
  // Map excel headers to list (step1)
  useEffect(() => {
    if (file) {
      setCanProceed(true);
    }
  }, [file]);
  // ------------------------------------------- Handlers -----------------------------------------------------
  // Handle Next step
  const handleNext = () => {
    switch (activeStep) {
      case ImportSiWizardSteps.SELECT_EXCEL_FILE: {
        setActiveStep(ImportSiWizardSteps.SI_VALIDATION);
        break;
      }
      case ImportSiWizardSteps.SI_VALIDATION: {
        setActiveStep(ImportSiWizardSteps.FINISHED);
        break;
      }
      case ImportSiWizardSteps.FINISHED: {
        onClosePreview();
        break;
      }
      default: {
        break;
      }
    }
  };

  // Other Handlers
  //
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setCanProceed(true);
  };
  //
  const handleReset = () => {
    setActiveStep(0);
  };

  // --------------------------------------- Component Routing ----------------------------------------------
  const steps = [
    {
      title: "Select Excel File",
      content: (
        <ImportSiWizardSelectFile
          setCanProceed={setCanProceed}
          file={file}
          setFile={setFile}
        />
      ),
    },
    {
      title: "Preview Items To Import",
      content: (
        <ImportSiWizardMapHeaders
          file={file}
          supplierInventoriesArray={supplierInventoriesArray}
          setSupplierInventoriesArray={setSupplierInventoriesArray}
        />
      ),
    },
    {
      title: "Import Results",
      content: supplierInventoriesArray && (
        <ImportSiWizardResults
          supplierInventoriesArray={supplierInventoriesArray}
        />
      ),
    },
  ];

  // ------------------------------------------- Return -----------------------------------------------------
  return isLoading ? (
    <LoadingScreen />
  ) : (
    <Container maxWidth={"xl"}>
      <Box sx={{ width: "100%", marginTop: 3 }}>
        <Stepper activeStep={activeStep}>
          {steps.map((stp) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={stp.title} {...stepProps}>
                <StepLabel {...labelProps}>{stp.title}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {steps[activeStep].content}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                marginBottom: 1,
              }}
            >
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext} disabled={canProceed === false}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </Container>
  );
}
