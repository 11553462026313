import VesselMap from "./VesselMap";
import { useEffect, useState } from "react";

import L from "leaflet";
import { IHMDTO, IhmItemPositionDTO } from "../../@types/IHM";
import {
  GET_IHM_BY_ID_QUERY,
  GET_IHM_POSITION_BY_ID,
  GET_VESSEL_DRAWINGS_BY_IHM,
} from "../../graphQL/Queries";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { VesselDrawingDTO } from "../../@types/Vessel";
import { VesselDrawingPositionDTO } from "../../@types/VesselDrawingPosition";
import { useKeycloak } from "@react-keycloak/web";
import { IhmItemMaterialRowDTO } from "../../@types/IHMMaterialRow";
import { MapperActionsEnum } from "./Mapper";

// Define Queries
export const useGetVesselDrawingEntitiesQuery = (ihmId: number | undefined) =>
  useQuery(GET_VESSEL_DRAWINGS_BY_IHM, {
    variables: {
      ihmId: ihmId,
    },
    skip: !ihmId,
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

export const useGetIhmQuery = (ihmId: number | undefined) =>
  useQuery(GET_IHM_BY_ID_QUERY, {
    variables: {
      id: ihmId,
    },
    skip: !ihmId,
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

export const useGetPositionQuery = (positionId: number | undefined) =>
  useQuery(GET_IHM_POSITION_BY_ID, {
    variables: {
      id: positionId,
    },
    skip: !positionId,
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

type SinglePositionMapperProps = {
  modalClose?: VoidFunction;
  isFromModal: boolean;
  ihmItemMaterialRow: IhmItemMaterialRowDTO;
  isPreviewMode?: boolean;
  allowedActions: MapperActionsEnum;
};

const SinglePositionMapper = ({
  ihmItemMaterialRow,
  modalClose,
  isPreviewMode,
  allowedActions,
  isFromModal,
}: SinglePositionMapperProps) => {
  const [ihmItemDrawingsAvailable, setIhmItemDrawingsAvailable] = useState<
    VesselDrawingDTO[]
  >([]);
  const [ihmItemDrawingSelected, setIhmItemDrawingSelected] =
    useState<VesselDrawingDTO>();

  const [selectedVesselDrawingPositions, setSelectedVesselDrawingPositions] =
    useState<VesselDrawingPositionDTO>();
  const [ihmItem, setIhmItem] = useState<IHMDTO>();
  const [positionToBePlaced, setPositionToBePlaced] =
    useState<IhmItemPositionDTO>();
  const { initialized, keycloak } = useKeycloak();
  const [userRole, setUserRole] = useState<undefined | string>(undefined);

  L.Icon.Default.imagePath = "leaflet/";

  // Use Queries
  const getVesselDrawingEntitiesQueryResult = useGetVesselDrawingEntitiesQuery(
    ihmItemMaterialRow?.ihmId
  );

  const getIhmQueryResult = useGetIhmQuery(ihmItemMaterialRow.ihmId);

  const getPositionQueryResult = useGetPositionQuery(
    ihmItemMaterialRow.positionId
  );

  // ------------------------------------------------------------------ Hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (initialized) {
      if (keycloak.realmAccess) {
        if (keycloak.realmAccess.roles) {
          if (keycloak.realmAccess.roles.find((x) => x === "ShipOwner")) {
            setUserRole("ShipOwner");
          } else if (keycloak.realmAccess.roles.find((x) => x === "Supplier")) {
            setUserRole("Supplier");
          } else if (
            keycloak.realmAccess.roles.find((x) => x === "Maintainer")
          ) {
            setUserRole("Maintainer");
          }
        }
      } else if (window.sessionStorage.getItem("userRole")) {
        setUserRole(window.sessionStorage.getItem("userRole")?.toString());
      }
    }
  }, [keycloak, initialized]);

  useEffect(() => {
    if (getPositionQueryResult?.data?.IhmPosition) {
      setPositionToBePlaced(getPositionQueryResult?.data?.IhmPosition);
    }
  }, [getPositionQueryResult.data]);

  useEffect(() => {
    setIhmItemDrawingsAvailable(
      getVesselDrawingEntitiesQueryResult.data?.findVesselDrawingByIhm
    );
  }, [getVesselDrawingEntitiesQueryResult.data]);

  useEffect(() => {
    setIhmItem(getIhmQueryResult?.data?.getIhmItem);
  }, [getIhmQueryResult.data]);

  useEffect(() => {
    if (
      ihmItemDrawingsAvailable &&
      ihmItemDrawingsAvailable.length > 0 &&
      ihmItemMaterialRow &&
      ihmItemMaterialRow.positionId &&
      ihmItem &&
      ihmItem.ihmPosition &&
      ihmItem.ihmPosition.length > 0
    ) {
      const foundPosition = ihmItem.ihmPosition.find(
        (pos: IhmItemPositionDTO) => pos.id === ihmItemMaterialRow.positionId
      );
      if (foundPosition) {
        const foundPositionDrawing = ihmItemDrawingsAvailable.find(
          (drawing: VesselDrawingDTO) =>
            drawing.path === foundPosition.vesselDrawingViewPath
        );
        if (foundPositionDrawing) {
          setIhmItemDrawingSelected(foundPositionDrawing);
        } else {
          setIhmItemDrawingSelected(ihmItemDrawingsAvailable[0]);
        }
      } else {
        setIhmItemDrawingSelected(ihmItemDrawingsAvailable[0]);
      }
    } 
  }, [ihmItem, ihmItemMaterialRow, ihmItemDrawingsAvailable, isPreviewMode]);

  // ------------------------------------------------------------------ Handlers ---------------------------------------------------------------------
  const manageNewPosition = (
    lat: number,
    lng: number,
    description: string,
    productCode: string,
    productName: string
  ) => {
    if (selectedVesselDrawingPositions?.vesselDrawing.path) {
      const createPosition: IhmItemPositionDTO = {
        description: description,
        vesselDrawingViewPath:
          selectedVesselDrawingPositions?.vesselDrawing.path,
        xBounds: 1000,
        yBounds: 2000,
        x: lng,
        y: lat,
        ihmItemId: ihmItemMaterialRow.ihmId,
        productCode: productCode,
        productName: productName,
      };
      const updatedVDP = selectedVesselDrawingPositions;
      updatedVDP?.positionsOnDrawing?.push(createPosition);
      setSelectedVesselDrawingPositions(updatedVDP);
    }
  };

  const manageRemovePosition = (lat: number, lng: number) => {
    const positions: IhmItemPositionDTO[] = [];

    selectedVesselDrawingPositions?.positionsOnDrawing?.forEach(
      (item, index) => {
        if (!(item.y == lat && item.x == lng)) {
          positions.push(item);
        }
      }
    );

    if (selectedVesselDrawingPositions && positions) {
      const updatedVesselDrawingPositions: VesselDrawingPositionDTO = {
        vesselDrawing: selectedVesselDrawingPositions?.vesselDrawing,
        positionsOnDrawing: positions,
      };

      setSelectedVesselDrawingPositions(updatedVesselDrawingPositions);
    }
  };

  const handleDrawingInput = (event: SelectChangeEvent) => {
    setIhmItemDrawingSelected(
      ihmItemDrawingsAvailable.filter(
        (ihmItemDrawingAvailable: VesselDrawingDTO) =>
          ihmItemDrawingAvailable.id === parseInt(event.target.value as string)
      )[0]
    );
  };

  return (
    <div className="Mapper">
      <Box sx={{ mt: 1.2, ml: 2, display: "flex", flexDirection: "row" }}>
        <Typography>Total Quantity : {ihmItemMaterialRow.quantity}</Typography>
        <Typography sx={{ ml: 2 }}>
          Unit : {ihmItemMaterialRow.ihmUnit}
        </Typography>
      </Box>

      <hr></hr>
      {isFromModal === false && (
        <Box sx={{ mt: 2, mb: 1 }}>
          <Typography sx={{ ml: 2, fontStyle: "italic", fontWeight: "bold" }}>
            The selected Item has at least one installation with not location
            specified, please set the Pin and press Save.
          </Typography>
          <hr></hr>
        </Box>
      )}

      <div style={{ padding: 8, paddingTop: 16 }}>
        <Typography sx={{ mt: 0.5 }}>1. Select Vessel Drawing</Typography>
        <FormControl variant="standard" fullWidth>
          <Select
            disabled={isPreviewMode || userRole === "ShipOwner"}
            variant="standard"
            labelId={`select-label-id-}`}
            id={`select-id-}`}
            label="Header Mapping"
            value={ihmItemDrawingSelected ? `${ihmItemDrawingSelected.id}` : ""}
            onChange={handleDrawingInput}
          >
            {ihmItemDrawingsAvailable?.map(
              (ihmItemDrawingAvailable: VesselDrawingDTO, index: number) => {
                return (
                  <MenuItem key={index} value={ihmItemDrawingAvailable.id}>
                    {ihmItemDrawingAvailable.name}
                  </MenuItem>
                );
              }
            )}
          </Select>
        </FormControl>
        <Box sx={{ display: "flex", flexDirection: "row", width: 600 }}>
          <Typography sx={{ mt: 0.6, display: "inline-block" }}>
            2. Place a marker to the Drawing{" "}
          </Typography>
          <Typography
            sx={{
              display: "inline-block",
              mt: 1.2,
              ml: 0.4,
              fontSize: 12,
              fontStyle: "italic",
            }}
          >
            {" "}
            &#40; Yellow installed, red not approved &#41;
          </Typography>
        </Box>
      </div>
      {ihmItemDrawingSelected && (
        <VesselMap
          vesselDrawingPosition={{
            vesselDrawing: ihmItemDrawingSelected,
            positionsOnDrawing:
              isPreviewMode && positionToBePlaced ? [positionToBePlaced] : [],
          }}
          selectedDrawing={ihmItemDrawingSelected}
          positionToBePlaced={positionToBePlaced}
          addPosition={manageNewPosition}
          removePosition={manageRemovePosition}
          ihmItem={ihmItem}
          modalClose={modalClose}
          isPreviewMode={isPreviewMode}
          allowedAction={
            userRole !== "ShipOwner"
              ? allowedActions
              : MapperActionsEnum.IS_VIEW
          }
          isFromModal={isFromModal}
        />
      )}
    </div>
  );
};

export default SinglePositionMapper;
