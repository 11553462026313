export type ExcelHeaderMappings = {
    ExcelHeaders: ExcelHeaders,
    DatabaseColumns: PoDatabaseColumn[]
}

export type ExcelHeaders = string[];

export type PoDatabaseColumn = {
    DbColumnName: string;
    DbColumnLabel: string;
};

export type HeaderMapping = {
    [header: string]: string;
};

export type HeaderMappingDTO = {
    aa: string
    vessel_name: string
    po_number: string
    po_date: string
    supplier_name: string
    supplier_email: string
    equipment_description: string
    equipment_id: string
    product_description: string
    product_id: string
    manufacturer: string
    general_category: string
    Order_Status: string
    MD_SDoC_Status: string
    Form_Type: string
    hazardous_materials_items_included: string
    external_category: string
};

export type PurchaseOrderDTO = {
    id?: string
    number?: string
    batchId?: string
    createdAt?: string
}

export type RowObjectType = {
    [header: string]: string | boolean;
}

export type PurchaseOrderSupplierDTO = {
    inScope: boolean;
    vesselName: string;
    equipmentManufacturer: string;
    equipmentDescription: string;
    productCode: string;
    containsPCHM: boolean;
    containsCHM: boolean;
    quantity: number;
    poDate: string;
    equipmentId: string;
    productDescription: string;
    general: string;
    orderStatus: string;
    mdSDoCStatus: string;
    formType: string;
    externalCategory: string;
    supplierName: string;
    supplierRequestEmailAddress: string;
}

export enum OrderStatus {
    CREATED,
    PENDING_FOR_SUPPLIER_INFORMATION,
    APPROVED,
    REJECTED,
    INSTALLED,
    UNKNOWN,
    DOCUMENT_SAVED,
    DOCUMENT_PROCESS_IN_PROGRESS,
    DOCUMENT_PROCESS_FAILED,
    DOCUMENT_PROCESS_OK,
}

export type InScopedExcelColumnsDTO = {
    MappedExcelColumns: PoMappedExcelColumnDTO[];
    Rows: CreatePoRequestSupplierExcelInputDTO[];
}

export type PoMappedExcelColumnDTO = {
    ExcelColumnName: string;
    DatabaseColumnName: string;
    SearchForInScope: boolean;
}

export type CreatePoRequestSupplierExcelInputDTO = {
    poNumber: string;
    vesselName: string;
    equipmentManufacturer: string;
    equipmentDescription: string;
    productCode: string;
    productName: string;
    quantity: number;
    containsPCHM: boolean;
    containsCHM: boolean;
    inScope: boolean;
    poDate?: string;
    equipmentId?: string;
    productDescription?: string;
    general?: string;
    orderStatus?: string;
    mdSDoCStatus?: string;
    formType?: string;
    externalCategory?: string;
    supplierRequestEmailAddress: string;
}

export type PoValidationImportRowsDTO = {
    Rows: PoValidationImportRowDTO[];
    IsValid: boolean;
}

export type PoValidationImportRowDTO = {
    ExcelRowId?: number;
    ExcelColumnName?: string;
    ExcelColumnValue?: string;
    Comment?: string;
    IsValid?: boolean;
    Suggestions?: any[];
}

export type PoRowToImport = {
    containsCHM: boolean;
    containsPCHM: boolean;
    equipmentId: string;
    externalCategory: string;
    formType: string;
    general: string;
    inScope: boolean;
    mdSDoCStatus: string;
    orderStatus: number;
    poDate: string;
    poNumber: string;
    productDescription: string;
    productName: string;
    quantity: number;
    supplierRequestEmailAddress: string;
    supplierRequestName: string;
    vesselImo: string;
    vesselName: string;
}

export type mappingType = {
    [header: string]: headerType;
  };
export type headerType = {
value: string;
options: string[];
checkInScope: boolean;
};
