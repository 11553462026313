import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import axios from "axios";
import L from "leaflet";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { ImageOverlay, MapContainer } from "react-leaflet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  IHMDTO,
  IhmInventoryPart,
  IhmInventorySubPart,
  IhmItemPositionDTO,
} from "../../../@types/IHM";
import { VesselDrawingDTO } from "../../../@types/Vessel";
import { CREATE_IHM_POSITION } from "../../../graphQL/Mutations";
import { GET_IHM_STORED_ITEMS } from "../../../graphQL/Queries";
import Scrollbar from "../../Scrollbar";
import IhmMapperForm, { MapperActionCodesEnum } from "../IhmMapperForm";
import { InstallIhmMapComponent } from "./InstallIhmMapComponent";
import html2canvas from "html2canvas";

const serverUri = process.env.REACT_APP_SERVER_URI;

// ------------------------------------------------------------------ Define Queries ---------------------------------------------------------------------
const useFetchDrawingsQuery = async (
  drawingFullPath: string,
  token: string | null
) => {
  return axios.get(`${serverUri}/api/v1/drawing/${drawingFullPath}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob",
  });
};

interface InstallIhmVesselMapProps {
  selectedVesselDrawing: VesselDrawingDTO | undefined;
  ihmItem: IHMDTO | undefined;
  remainingQuantity: number;
  modalClose: VoidFunction;
}

const InstallIhmVesselMap = ({
  selectedVesselDrawing,
  ihmItem,
  remainingQuantity,
  modalClose,
}: InstallIhmVesselMapProps) => {
  interface ImageDimensions {
    width: number;
    height: number;
  }
  const { enqueueSnackbar } = useSnackbar();
  const [imageUrl, setImageUrl] = useState<string>();

  // form fields
  const [applicationOfPaint, setApplicationOfPaint] = useState<string>("");
  const [nameOfPaint, setNameOfPaint] = useState<string>("");
  const [nameOfEquipmentMachinery, setNameOfEquipmentMachinery] =
    useState<string>("");
  const [nameOfStructuralElement, setNameOfStructuralElement] =
    useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [partsUsed, setPartsUsed] = useState<string>("");
  const [quantity, setQuantity] = useState<number>(0);
  const [description, setDescription] = useState<string>("");
  const [subPart, setSubPart] = useState<string>("SubPart 1");

  // marker attributes
  const [selectedNewIhmPosition, setSelectedNewIhmPosition] =
    useState<IhmItemPositionDTO | null>(null);
  const [selectedExistingIhmPosition, setSelectedExistingNewIhmPosition] =
    useState<IhmItemPositionDTO | null>(null);
  const [screenshotImage, setScreenshotImage] = useState<string | undefined>(undefined);
  const [files, setFiles]=useState<any>(null);
    
  const mapRef = useRef<HTMLDivElement>(null);
  const mapComponentRef = useRef<any>();
  const [dimensionsOfImage, setDimensionsOfImage] = useState<ImageDimensions>();
  const token = window.sessionStorage.getItem("jwt");

  // ------------------------------------------------------------------ Define Mutations ---------------------------------------------------------------------
  // Create
  const [CreateIhmPosition, CreateIhmPositionProps] = useMutation(
    CREATE_IHM_POSITION,
    {
      fetchPolicy: "network-only",
    }
  );
  const useCreateIhmPosition = (archive: File, newPosition: IhmItemPositionDTO) => {
    CreateIhmPosition({
      variables: {
        createIhmPositionInput: newPosition,
        archive: archive
      },
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
      refetchQueries: [GET_IHM_STORED_ITEMS],
      onCompleted(dt) {
        if (dt.createIhmPosition.id) {
          enqueueSnackbar(`Position created successfully`, {
            variant: "success",
          });
        }
        modalClose();
      },
    });
  };

  // ------------------------------------------------------------------ Hooks --------------------------------------------------------------
  useEffect(() => {
    if (selectedVesselDrawing != undefined) {
      const parsedPath = selectedVesselDrawing.path?.split("/").join("-");
      if (parsedPath) {
        useQueryExec(parsedPath, token);
      }
    }
  }, [selectedVesselDrawing]);

  useEffect(() => {
    if (ihmItem) {
      // prepopulate AOP
      let prepopulatedApplicationOfPaint = "";
      if (ihmItem.equipmentManufacturer) {
        prepopulatedApplicationOfPaint = ihmItem.equipmentManufacturer;
        if (ihmItem.equipmentDescription) {
          prepopulatedApplicationOfPaint += ` / ${ihmItem.equipmentDescription}`;
        }
      }
      if (ihmItem.equipmentDescription) {
        prepopulatedApplicationOfPaint = ihmItem.equipmentDescription;
      }
      setApplicationOfPaint(prepopulatedApplicationOfPaint);

      // prepopulate Name of Equipment and Machinery
      let prepopulatedNameOfEquipment = "";
      if (ihmItem.equipmentManufacturer) {
        prepopulatedNameOfEquipment = ihmItem.equipmentManufacturer;
        if (ihmItem.equipmentDescription) {
          prepopulatedNameOfEquipment += ` / ${ihmItem.equipmentDescription}`;
        }
      }
      if (ihmItem.equipmentDescription) {
        prepopulatedNameOfEquipment = ihmItem.equipmentDescription;
      }
      setNameOfEquipmentMachinery(prepopulatedNameOfEquipment);

      // prepopulate Name of Paint
      if (ihmItem.productName) {
        setNameOfPaint(ihmItem.productName);
      }

      // prepopulate Parts where Used
      if (ihmItem.equipmentDescription) {
        setNameOfStructuralElement(ihmItem.equipmentDescription);
      }

      // prepopulate Parts where Used
      if (ihmItem.productName) {
        setPartsUsed(ihmItem.productName);
      }
    }
  }, [ihmItem]);

  useEffect(  ()=>{
    if(imageUrl){
      const fetchData = async () => {
        const dimensions =  await getImageDimensions(imageUrl);
        
        setDimensionsOfImage({width: dimensions.width, height: dimensions.height});
        return dimensions;
      }
      fetchData();
      
    }
  
  
  },[imageUrl]);

  useEffect(() => {
    if (remainingQuantity) {
      setQuantity(remainingQuantity);
    }
  }, [remainingQuantity]);

  // ------------------------------------------------------------------ Handlers ---------------------------------------------------------------------
  // Helper function
  async function useQueryExec(drawingFullPath: string, token: string | null) {
    const response = await useFetchDrawingsQuery(drawingFullPath, token);
    if (response && response.data) {
      const imgFromBlob = URL.createObjectURL(response.data);
      if (imgFromBlob) {
        setImageUrl(imgFromBlob);
      }
    }
  }

  function getImageDimensions(file: string): Promise<ImageDimensions> {
    return new Promise (function (resolved, rejected) {
      const i = new Image();
      i.onload = function(){
        resolved({width: i.width, height: i.height})
      };
      i.src = file
    })
  }


   const captureScreenshot = async () => {
    if (mapRef.current) {
      mapComponentRef.current.makeMarkersBlue();
      const canvas = await html2canvas(mapRef.current, {scrollY: -window.scrollY});
        // The canvas variable now holds the screenshot image
        const image = canvas.toDataURL();

        const blobBin = atob(image.split(',')[1]);
        const array = [];
        for(let i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
        }
         const file=new Blob([new Uint8Array(array)], {type: 'image/png'});
        setFiles(file);
        
        
        setScreenshotImage(image);

      
    }
  };


  useEffect(()=>{
  if(files != null && files != undefined ){
    useHandlePositionSubmit();
  }
  },[files]);


  const useHandlePositionSubmit =  async () => {
    if (selectedNewIhmPosition !== null) {
      selectedNewIhmPosition.imageOfPositionOfTheItem = screenshotImage != undefined && screenshotImage != null ? JSON.stringify(screenshotImage) : undefined;
      

      switch (subPart) {
        case "SubPart 1":
          if (
            applicationOfPaint != "" &&
            nameOfPaint != "" &&
            location != "" &&
            quantity != 0
          ) {
            selectedNewIhmPosition.inventorySubPart =
              IhmInventorySubPart.SUB_CATEGORY_1;
              if(files != null && files != undefined){
                const reader = new FileReader();
                reader.readAsDataURL(files);
                reader.onload = function () {
                  useCreateIhmPosition(
                    files, selectedNewIhmPosition
      
                  )}
              }else{
                const emptyBlob = new Blob([], { type: 'text/plain' });
                const file = new File([emptyBlob], 'empty.txt', {
                  type: 'text/plain',
                  lastModified: Date.now(),
                });
                //useCreateIhmPosition(
                //  file, selectedNewIhmPosition
    //
        //        )
              }
            //useCreateIhmPosition(selectedNewIhmPosition);
          } else {
            alert("please provide valid information for all fields");
          }
          break;
        case "SubPart 2":
          if (
            nameOfEquipmentMachinery != "" &&
            partsUsed != "" &&
            location != "" &&
            quantity != 0
          ) {
            selectedNewIhmPosition.inventorySubPart =
              IhmInventorySubPart.SUB_CATEGORY_2;
            //useCreateIhmPosition(selectedNewIhmPosition);
            if(files != null && files != undefined){
              const reader = new FileReader();
              reader.readAsDataURL(files);
              reader.onload = function () {
                useCreateIhmPosition(
                  files, selectedNewIhmPosition
    
                )}
            }else{
              const emptyBlob = new Blob([], { type: 'text/plain' });
              const file = new File([emptyBlob], 'empty.txt', {
                type: 'text/plain',
                lastModified: Date.now(),
              });
              useCreateIhmPosition(
                file, selectedNewIhmPosition
  
              )
            }
          } else {
            alert("please provide valid information for all fields");
          }
          break;
        case "SubPart 3":
          if (
            nameOfStructuralElement != "" &&
            partsUsed != "" &&
            location != "" &&
            quantity != 0
          ) {
            selectedNewIhmPosition.inventorySubPart =
              IhmInventorySubPart.SUB_CATEGORY_3;
            //useCreateIhmPosition(selectedNewIhmPosition);
            if(files != null && files != undefined){
              const reader = new FileReader();
              reader.readAsDataURL(files);
              reader.onload = function () {
                useCreateIhmPosition(
                  files, selectedNewIhmPosition
    
                )}
            }else{
              const emptyBlob = new Blob([], { type: 'text/plain' });
              const file = new File([emptyBlob], 'empty.txt', {
                type: 'text/plain',
                lastModified: Date.now(),
              });
              useCreateIhmPosition(
                file, selectedNewIhmPosition
  
              )
            }
          } else {
            alert("please provide valid information for all fields");
          }
          break;

        default:
          break;
      }
    }
  };

  const manageNewPosition = (
    lat: number,
    lng: number,
    applicationOfPaint: string,
    nameOfPaint: string,
    nameOfEquipmentAndMachinery: string,
    nameOfStructuralElement: string,
    partsWhereUsed: string
  ) => {
    if (ihmItem?.id && selectedVesselDrawing) {
      const newMarker: IhmItemPositionDTO = {
        description: `${ihmItem.productName} - ${ihmItem.productCode}`,
        quantity: quantity,
        vesselDrawingViewPath: `${selectedVesselDrawing?.path}`,
        xBounds: 1000,
        yBounds: 2000,
        x: lng,
        y: lat,
        ihmItemId: ihmItem.id,
        applicationOfPaint: applicationOfPaint,
        nameOfPaint: nameOfPaint,
        nameOfEquipmentAndMachinery: nameOfEquipmentAndMachinery,
        nameOfStructuralElement: nameOfStructuralElement,
        partsWhereUsed: partsWhereUsed,
        inventoryPart: IhmInventoryPart.PART_3,
        inventorySubPart: IhmInventorySubPart.SUB_CATEGORY_1,
        location: location,
      };
      setSelectedNewIhmPosition(newMarker);
    }
  };

  const clearSelectedLocation = () => {
    setSelectedExistingNewIhmPosition(null);
    setApplicationOfPaint("");
    setNameOfPaint("");
    setNameOfEquipmentMachinery("");
    setNameOfStructuralElement("");
    setLocation("");
    setPartsUsed("");
    setQuantity(0);
    setDescription("");
  };

  const handleCancel = () => {
    clearSelectedLocation();
    setSelectedNewIhmPosition(null);
    mapComponentRef.current.resetMarkers();
  };

  const mapRefForZooming = useRef<any>(null);

  const handleZoomIn = () => {
    const map = mapRefForZooming.current;
    if (map) {
      map.setZoom(map.getZoom() + 1);
    }
  }
  
  const handleZoomOut = () => {
    const map = mapRefForZooming.current;
    if (map) {
      map.setZoom(map.getZoom() - 1);
    }
  }

  return (
    <>
    <LoadingButton
    variant="contained"
    onClick={handleZoomIn}
  >
  +
  </LoadingButton>
  <LoadingButton
    sx={{ml:"5px"}}
    variant="contained"
    onClick={handleZoomOut}
  >
  -
  </LoadingButton>
    <Scrollbar>
      <div ref={mapRef}>
      <MapContainer
        className="map"
        zoom={6}
        ref={mapRefForZooming}
        scrollWheelZoom={false}
        crs={L.CRS.Simple}
        bounds={[
          [0, 0],
          [3000, 3000],
        ]}
        minZoom={-6}
        maxZoom={20}
        attributionControl={false}
        zoomControl={false}
        style={{ height: "300px", width: "550px", backgroundColor: "white" }}
      >
        {imageUrl && dimensionsOfImage &&
        (

            <ImageOverlay
              url={imageUrl}
              bounds={[
                [0, 0],
                [dimensionsOfImage.height*10, dimensionsOfImage.width*10],
              ]}
            />
        ) }

        <InstallIhmMapComponent
          placeMarkerToMap={true}
          addMarker={manageNewPosition}
          positions={
            selectedNewIhmPosition !== null ? [selectedNewIhmPosition] : []
          }
          enableEdit={true}
          ref={mapComponentRef}
          description={description}
          applicationOfPaint={applicationOfPaint}
          nameOfPaint={nameOfPaint}
          nameOfEquipmentAndMachinery={nameOfEquipmentMachinery}
          nameOfStructuralElement={nameOfStructuralElement}
          partsWhereUsed={partsUsed}
        />
      </MapContainer>
      </div>
      <IhmMapperForm
        applicationOfPaint={applicationOfPaint}
        setApplicationOfPaint={setApplicationOfPaint}
        nameOfPaint={nameOfPaint}
        setNameOfPaint={setNameOfPaint}
        nameOfEquipmentMachinery={nameOfEquipmentMachinery}
        setNameOfEquipmentMachinery={setNameOfEquipmentMachinery}
        nameOfStructuralElement={nameOfStructuralElement}
        setNameOfStructuralElement={setNameOfStructuralElement}
        location={location}
        setLocation={setLocation}
        partsUsed={partsUsed}
        setPartsUsed={setPartsUsed}
        quantity={quantity}
        setQuantity={setQuantity}
        subPart={subPart}
        setSubPart={setSubPart}
        selectedNewIhmPosition={selectedNewIhmPosition}
        setSelectedNewIhmPosition={setSelectedNewIhmPosition}
        selectedExistingIhmPosition={selectedExistingIhmPosition}
        setSelectedExistingNewIhmPosition={setSelectedExistingNewIhmPosition}
        mapperAction={MapperActionCodesEnum.IS_INSTALL}
        isFromInstall={true}
      />

      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 2,
          mb: 3,
        }}
      >
        <LoadingButton
          variant="contained"
          id="cancelMapUpdate"
          onClick={handleCancel}
          component="label"
          loading={CreateIhmPositionProps.loading}
        >
          Clear Data
        </LoadingButton>
        <LoadingButton
          variant="contained"
          id="removeSelectedItem"
          onClick={captureScreenshot}
          component="label"
          loading={CreateIhmPositionProps.loading}
        >
          Install
        </LoadingButton>
      </Stack>
    </Scrollbar>
    </>
  );
};

export default InstallIhmVesselMap;
