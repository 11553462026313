// material
import {
  Box,
  Button,
  Container,
  Typography,
  DialogActions,
} from "@mui/material";
// custom
import { DialogAnimate } from "../animate";
import Scrollbar from "../Scrollbar";

// ----------------------------------------------------------------------

type WarningModalProps = {
  isOpenPreview: boolean;
  onClosePreview: VoidFunction;
  warningTitle?: string;
  warningMsg?: string;
  setUserResponseTrue?: any;
};

export default function WarningModal({
  isOpenPreview,
  onClosePreview,
  warningTitle,
  warningMsg,
  setUserResponseTrue,
}: WarningModalProps) {
  const handleSubmit = () => {
    onClosePreview();
  };

  return (
    <DialogAnimate open={isOpenPreview} onClose={onClosePreview}>
      <Scrollbar>
        <Container>
          <Box sx={{ mt: 3, mb: 5 }}>
            {warningTitle ? (
              <Typography variant="h4" sx={{ mb: 1, alignContent: "centre" }}>
                {warningTitle}
              </Typography>
              ) :
              (
                <Typography variant="h4" sx={{ mb: 1, alignContent: "centre" }}>
                  Warning!
                </Typography>
              )
            }
            {warningMsg && (
              <Typography variant="h6" sx={{ mb: 5 }}>
                {warningMsg}
              </Typography>
            )}
          </Box>
        </Container>
      </Scrollbar>

      <DialogActions sx={{ py: 2, px: 3 }}>
        {setUserResponseTrue ? (
          <>
            <Button variant="contained" onClick={onClosePreview}>
              Cancel
            </Button>

            <Button variant="contained" onClick={setUserResponseTrue}>
              Ok
            </Button>
          </>
        ) : (
          <Button variant="contained" onClick={onClosePreview}>
            Ok
          </Button>
        )}
      </DialogActions>
    </DialogAnimate>
  );
}
