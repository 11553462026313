// material
import { useQuery } from "@apollo/client";
import { Icon } from "@iconify/react";
import { Box, Container, IconButton } from "@mui/material";
// custom
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IHMDTO, IhmItemPositionDTO } from "../../../@types/IHM";
import { VesselDrawingDTO } from "../../../@types/Vessel";
import { GET_IHM_POSITION_WITH_DRAWING_QUERY } from "../../../graphQL/Queries";
import { DialogAnimate } from "../../animate";
import Scrollbar from "../../Scrollbar";
import RemoveIhmInstallationMapper from "./RemoveIhmInstallationMapper";

// ----------------------------------------------------------------------
// Define Queries
const useGetIhmPositionIhmWithDrawingQuery = (ihmPositionId: number) =>
  useQuery(GET_IHM_POSITION_WITH_DRAWING_QUERY, {
    variables: {
      ihmPositionId: ihmPositionId,
    },
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

type RemoveIhmInstallationMapperModalProps = {
  isOpenPreview: boolean;
  onClosePreview: VoidFunction;
  ihmPositionId: number;
};

export default function RemoveIhmInstallationMapperModal({
  isOpenPreview,
  onClosePreview,
  ihmPositionId,
}: RemoveIhmInstallationMapperModalProps) {
  const [ihmItemPosition, setIhmItemPosition] = useState<IhmItemPositionDTO>();
  const [ihmItem, setIhmItem] = useState<IHMDTO>();
  const [vesselDrawing, setVesselDrawing] = useState<VesselDrawingDTO>();

  const useGetIhmPositionIhmWithDrawingQueryResponse =
    useGetIhmPositionIhmWithDrawingQuery(ihmPositionId);

  useEffect(() => {
    if (useGetIhmPositionIhmWithDrawingQueryResponse?.data) {
      setIhmItemPosition(
        useGetIhmPositionIhmWithDrawingQueryResponse?.data
          ?.getIhmPositionWithDrawing?.ihmPosition
      );
      setIhmItem(
        useGetIhmPositionIhmWithDrawingQueryResponse?.data
          ?.getIhmPositionWithDrawing?.ihmItem
      );
      setVesselDrawing(
        useGetIhmPositionIhmWithDrawingQueryResponse?.data
          ?.getIhmPositionWithDrawing?.vesselDrawing
      );
    }
  }, [useGetIhmPositionIhmWithDrawingQueryResponse.data]);

  return (
    <DialogAnimate
      open={isOpenPreview}
      onClose={onClosePreview}
      cardMinWidth={800}
      cardMinHeight={650}
    >
      <Box>
        <Scrollbar>
          <Container sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mb: 3,
                mr: -1,
                mt: 0.5,
              }}
            >
              <IconButton aria-label="delete" onClick={onClosePreview}>
                <Icon icon="akar-icons:circle-x" width={30} color={"#26567B"} />
              </IconButton>
            </Box>
            {ihmItemPosition && ihmItem && (
              <RemoveIhmInstallationMapper
                ihmItemPosition={ihmItemPosition}
                ihmItem={ihmItem}
                modalClose={onClosePreview}
                ihmItemDrawingsAvailable={
                  vesselDrawing ? [vesselDrawing] : undefined
                }
              />
            )}
          </Container>
        </Scrollbar>
      </Box>
    </DialogAnimate>
  );
}
