import { Box } from "@mui/material";
import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

type SingleDataGridBarProps = {
  value: number;
  barColor: string;
};

export default function SingleDataGridBar({
  value,
  barColor,
}: SingleDataGridBarProps) {
  const [chartSeries, setChartSeries] = useState<
    ApexAxisChartSeries | ApexNonAxisChartSeries
  >();

  const options: ApexOptions = {
    chart: {
      type: "bar",
      height: 40,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter(val: number, opts) {
        return `${val}%`;
      },
    },
    xaxis: {
      categories: [""],
      max: 100,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    colors: [barColor],
  };

  // ----------------------------------------- Component Lifecycle -----------------------------------------
  useEffect(() => {
    setChartSeries([
      {
        data: [value],
      },
    ]);
  }, [value]);

  // ---------------------------------------------------------------------------------------------------------------------------
  return (
    <Box id="chart">
      {chartSeries != undefined && (
        <ReactApexChart
          options={options}
          series={chartSeries}
          type="bar"
          height={120}
        />
      )}
    </Box>
  );
}
