import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import L from "leaflet";
import { toast } from "react-toastify";
import { IHMDTO, IhmItemPositionDTO } from "../../../@types/IHM";
import { VesselDrawingDTO } from "../../../@types/Vessel";
import {
  GET_IHM_POSITION_BY_ID,
  GET_IHM_REMAINING_QUANTITY_QUERY,
} from "../../../graphQL/Queries";
import ApproveIhmInstallationVesselMap from "./ApproveIhmInstallationVesselMap";

export const useGetPositionQuery = (_positionId: number | undefined) =>
  useQuery(GET_IHM_POSITION_BY_ID, {
    variables: {
      id: _positionId,
    },
    skip: !_positionId,
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

export const useGetIhmRemainingQuantityQuery = (ihmId: number | undefined) =>
  useQuery(GET_IHM_REMAINING_QUANTITY_QUERY, {
    variables: {
      ihmId: ihmId,
    },
    skip: !ihmId,
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

type ApproveIhmInstallationMapperProps = {
  ihmItemPosition: IhmItemPositionDTO;
  ihmItemToReplaceExisting: IHMDTO | undefined;
  ihmPositionToReplaceExisting: IhmItemPositionDTO | undefined;
  ihmItem: IHMDTO;
  ihmItemDrawingsAvailable: VesselDrawingDTO[] | undefined;
  modalClose: VoidFunction;
};

const ApproveIhmInstallationMapper = ({
  ihmItemPosition,
  ihmItem,
  modalClose,
  ihmItemDrawingsAvailable,
  ihmItemToReplaceExisting,
  ihmPositionToReplaceExisting,
}: ApproveIhmInstallationMapperProps) => {
  const [ihmItemDrawingSelected, setIhmItemDrawingSelected] =
    useState<VesselDrawingDTO>();

  L.Icon.Default.imagePath = "leaflet/";

  // Use Queries
  const useGetIhmRemainingQuantityQueryResponse =
    useGetIhmRemainingQuantityQuery(ihmItem?.id);

  // ------------------------------------------------------------------ Hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (
      ihmItemDrawingsAvailable != undefined &&
      ihmItemDrawingsAvailable?.length > 0 &&
      ihmItemDrawingsAvailable[0]
    ) {
      setIhmItemDrawingSelected(ihmItemDrawingsAvailable[0]);
    }
  }, [ihmItemDrawingsAvailable]);

  // -----------------------------------------------------------------------------------------------------------------------------------
  return (
    <div className="Mapper">
      <Box
        sx={{
          ml: 2,
          mr: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography>Total Quantity : {ihmItem.quantity}</Typography>
          <Typography sx={{ ml: 2 }}>Unit : {ihmItem.unit}</Typography>
        </Box>

        <Typography sx={{ ml: 2, fontWeight: "bold" }}>
          Remaining Quantity to be Installed :{" "}
          {
            useGetIhmRemainingQuantityQueryResponse?.data
              ?.getIhmRemainingQuantity
          }
        </Typography>
      </Box>

      <hr></hr>
      {ihmItemDrawingSelected ? (
        <div style={{ padding: 8, paddingTop: 16, paddingBottom: 10 }}>
          <Typography sx={{ mt: 0.5 }}>1. Select Vessel Drawing</Typography>
          <FormControl variant="standard" fullWidth>
            <Select
              variant="standard"
              labelId={`select-label-id-}`}
              id={`select-id-}`}
              label="Header Mapping"
              value={
                ihmItemDrawingSelected?.id ? `${ihmItemDrawingSelected.id}` : ""
              }
              disabled
            >
              {ihmItemDrawingsAvailable && ihmItemDrawingsAvailable?.length > 0
                ? ihmItemDrawingsAvailable.map(
                    (ihmItemDrawing: VesselDrawingDTO, index: number) => {
                      return (
                        <MenuItem key={index} value={ihmItemDrawing.id}>
                          {`${ihmItemDrawing.path?.slice(
                            0,
                            ihmItemDrawing.path?.indexOf("/")
                          )} - ${ihmItemDrawing.name}`}
                        </MenuItem>
                      );
                    }
                  )
                : null}
            </Select>
          </FormControl>
          <Box
            sx={{ display: "flex", flexDirection: "row", width: 600, mt: 4 }}
          >
            <Typography sx={{ mt: 0.6, display: "inline-block" }}>
              2. Place a marker to the Drawing{" "}
            </Typography>
          </Box>
        </div>
      ) : (
        <div style={{ padding: 8, paddingTop: 32, paddingBottom: 10 }}></div>
      )}

      {ihmItem && ihmItemPosition && (
        <ApproveIhmInstallationVesselMap
          ihmItem={ihmItem}
          ihmItemPosition={ihmItemPosition}
          ihmItemToReplaceExisting={ihmItemToReplaceExisting}
          ihmPositionToReplaceExisting={ihmPositionToReplaceExisting}
          selectedVesselDrawing={ihmItemDrawingSelected}
          remainingQuantity={
            useGetIhmRemainingQuantityQueryResponse?.data
              ?.getIhmRemainingQuantity
          }
          modalClose={modalClose}
        />
      )}
    </div>
  );
};

export default ApproveIhmInstallationMapper;
