import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// material
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { VesselDTO } from "../../@types/Vessel";
// utils
import { FieldInputProps, FormikErrors, FormikTouched } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useQuery } from "@apollo/client";
import { GET_SHIPOWNER_VESSELS_QUERY } from "graphQL/Queries";
// types
import { ShipOwnerUserFormValues } from "../../@types/ShipOwnerUser";
import { Multiselect } from "multiselect-react-dropdown";
import { ShipOwnerDTO } from "../../@types/ShipOwner";
// styles
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  multiSelectInput: {
    "& .searchWrapper": {
      minHeight: "56px",
      borderRadius: "8px",
      "& .chip": {
        background: "#39464f",
      },
    },
    "& .optionListContainer": {
      "& .optionContainer": {
        "& .highlightOption": {
          background: "#39464f",
        },
        "& .option": {
          background: "#fff",
          color: "#39464f",
        },
      },
    },
    "& .searchBox": {
      fontSize: "16px",
      marginTop: "12px",
      paddingLeft: "8px",
    },
  },
}));

// --------------------------------component prop types----------------------------------------------
type ShipOwnerUserFormProps = {
  getFieldProps: (
    nameOrOptions:
      | "Name"
      | "LastName"
      | "Position"
      | "City"
      | "PhoneNumber"
      | "Email"
      | "About"
      | "AvatarUrl"
  ) => FieldInputProps<ShipOwnerUserFormValues>;
  touched: FormikTouched<ShipOwnerUserFormValues>;
  errors: FormikErrors<ShipOwnerUserFormValues>;
  values: ShipOwnerUserFormValues;
  setSelectedVessels: React.Dispatch<
    React.SetStateAction<VesselDTO[] | undefined>
  >;
  isSubmitting: boolean;
  buttonMsg: string;
  selectedVessels: VesselDTO[] | undefined;
  isAllVesselsSelected: boolean;
  setIsAllVesselsSelected: React.Dispatch<React.SetStateAction<boolean>>;
  submitButtonIsloading: boolean;
  isEdit: boolean;
};

// -------------------------------------queries------------------------------------------------------
const getVesselsQuery = (sOwnerId: string | undefined) =>
  useQuery(GET_SHIPOWNER_VESSELS_QUERY, {
    variables: {
      id: sOwnerId ? parseInt(sOwnerId) : 0,
    },
    skip: !sOwnerId,
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

// -----------------------------------------------------------------------------------------------------
export default function ShipOwnerUserForm({
  getFieldProps,
  touched,
  errors,
  values,
  setSelectedVessels,
  isSubmitting,
  buttonMsg,
  selectedVessels,
  isAllVesselsSelected,
  setIsAllVesselsSelected,
  submitButtonIsloading,
  isEdit,
}: ShipOwnerUserFormProps) {
  // Native props
  const classesNew = useStyles();
  const { shipOwnerId } = useParams();
  const [shipOwnerVessels, setShipOwnerVessels] = useState<VesselDTO[]>([]);
  const [getVesselsQueryLoading, setGetVesselsQueryLoading] =
    useState<boolean>(true);

  // query
  const getVesselsQueryResponse = getVesselsQuery(shipOwnerId);
  // Hooks
  useEffect(() => {
    if (getVesselsQueryResponse && getVesselsQueryResponse.data) {
      setShipOwnerVessels(getVesselsQueryResponse.data.getShipOwnerWithVessels.vessels);
      setGetVesselsQueryLoading(false);
    }
  }, [getVesselsQueryResponse]);

  // Handlers
  const onSelect = (list: VesselDTO[]) => {
    setSelectedVessels(list);
  };
  const onRemove = (list: ShipOwnerDTO[]) => {
    setSelectedVessels(list);
  };

  return (
    <>
      {isEdit && getVesselsQueryLoading ? (
        <LinearProgress
          color="primary"
          sx={{
            minHeight: "5px",
            mt: 0.1,
          }}
        />
      ) : (
        <Stack spacing={3}>
          <TextField
            variant="standard"
            fullWidth
            label="Name *"
            {...getFieldProps("Name")}
            error={Boolean(touched.Name && errors.Name)}
            helperText={touched.Name && errors.Name}
            value={values?.Name}
          />
          <TextField
            variant="standard"
            fullWidth
            label="LastName *"
            {...getFieldProps("LastName")}
            error={Boolean(touched.LastName && errors.LastName)}
            helperText={touched.LastName && errors.LastName}
            value={values?.LastName}
          />

          <TextField
            variant="standard"
            fullWidth
            label="Position *"
            {...getFieldProps("Position")}
            error={Boolean(touched.Position && errors.Position)}
            helperText={touched.Position && errors.Position}
            value={values?.Position}
          />

          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 3, sm: 2 }}
          >
            <TextField
              variant="standard"
              fullWidth
              label="City"
              {...getFieldProps("City")}
              error={Boolean(touched.City && errors.City)}
              helperText={touched.City && errors.City}
              value={values?.City}
            />
            <TextField
              variant="standard"
              fullWidth
              label="PhoneNumber"
              {...getFieldProps("PhoneNumber")}
              error={Boolean(touched.PhoneNumber && errors.PhoneNumber)}
              helperText={touched.PhoneNumber && errors.PhoneNumber}
              value={values?.PhoneNumber}
            />
          </Stack>

          <FormControl
            variant="standard"
            fullWidth
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              columnGap: "16px",
            }}
          >
            <div style={{ flex: "1" }}>
              {
                // shipOwnerVessels &&
                <Multiselect
                  options={shipOwnerVessels}
                  displayValue="name"
                  onSelect={onSelect}
                  selectedValues={selectedVessels}
                  onRemove={onRemove}
                  className={classesNew.multiSelectInput}
                  disable={isAllVesselsSelected}
                  disablePreSelectedValues={false}
                  showCheckbox={true}
                />
              }
            </div>

            <FormGroup sx={{ flexDirection: "row" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAllVesselsSelected}
                    onChange={() =>
                      setIsAllVesselsSelected(!isAllVesselsSelected)
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Select All"
              />
            </FormGroup>
          </FormControl>

          <TextField
            variant="standard"
            fullWidth
            label="Email *"
            {...getFieldProps("Email")}
            error={Boolean(touched.Email && errors.Email)}
            helperText={touched.Email && errors.Email}
            value={values?.Email}
            disabled={isEdit}
          />

          <TextField
            variant="standard"
            fullWidth
            label="About"
            {...getFieldProps("About")}
            error={Boolean(touched.About && errors.About)}
            helperText={touched.About && errors.About}
            value={values?.About}
          />

          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={submitButtonIsloading}
              disabled={
                (selectedVessels == undefined ||
                  selectedVessels?.length == 0) &&
                isAllVesselsSelected == false
              }
            >
              {buttonMsg}
            </LoadingButton>
          </Box>
        </Stack>
      )}
    </>
  );
}
