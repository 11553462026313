import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
// material
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Container,
  LinearProgress,
} from "@mui/material";
// utils
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// @types
import { OrderStatus, PurchaseOrderDTO } from "../../../@types/PurchaseOrder";
//
import { useQuery, useMutation, ApolloError } from "@apollo/client";
import {
  GET_PURCHASE_ORDERS,
  GET_PURCHASE_ORDER_ITEM,
  GET_SUPPLIERS_ALL_QUERY,
} from "../../../graphQL/Queries";
import { PO_ITEM_CREATE, PO_ITEM_UPDATE } from "../../../graphQL/Mutations";
import { useSnackbar } from "notistack";
import { PurchaseOrderItemDTO } from "../../../@types/PurchaseOrderItem";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import Page from "../../../components/Page";
import { SupplierDTO } from "../../../@types/Supplier";

// --------------------------------------------- Define Queries ---------------------------------------
const useGetPurchaseOrdersQuery = () =>
  useQuery(GET_PURCHASE_ORDERS, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

const useGetSuppliersQuery = () =>
  useQuery(GET_SUPPLIERS_ALL_QUERY, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

const useGetPurchaseOrderItemQuery = (poItemId: string, isEdit: boolean) =>
  useQuery(GET_PURCHASE_ORDER_ITEM, {
    variables: {
      id: parseInt(poItemId),
    },
    skip: !isEdit,
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

// -----------------------------------------------------------------------------------------
type PurchaseOrderItemFormProps = {
  setNavigationHelper: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function PurchaseOrderItemForm({
  setNavigationHelper,
}: PurchaseOrderItemFormProps) {
  // native props
  const { vesselId, purchaseOrderItemId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const isEdit = pathname.includes("edit");

  const [purchaseOrderSelected, setPurchaseOrderSelected] =
    useState<PurchaseOrderDTO>();
  const [purchaseOrdersAvailable, setPurchaseOrdersAvailable] = useState<
    PurchaseOrderDTO[]
  >([]);
  const [isPoLoading, setIsPoLoading] = useState(true);

  const [supplierSelected, setSupplierSelected] = useState<SupplierDTO>();
  const [suppliersAvailable, setSuppliersAvailable] = useState<SupplierDTO[]>(
    []
  );
  const [isSupplierLoading, setIsSupplierLoading] = useState(true);

  const [purchaseOrderItem, setPurchaseOrderItem] =
    useState<PurchaseOrderItemDTO>();
  const [pODateValue, setPODateValue] = useState<string>();

  // ------------------------------------- Use Queries -----------------------------------------------
  const getPurchaseOrdersQueryResponse = useGetPurchaseOrdersQuery();
  const getSuppliersQueryResponse = useGetSuppliersQuery();

  const getPurchaseOrderItemQueryResponse = purchaseOrderItemId
    ? useGetPurchaseOrderItemQuery(purchaseOrderItemId, isEdit)
    : null;

  // ------------------------------------- Define Mutations -----------------------------------------------
  const [
    CreatePurchaseOrderItemMutation,
    CreatePurchaseOrderItemMutationProps,
  ] = useMutation(PO_ITEM_CREATE, {
    fetchPolicy: "network-only",
  });
  const [
    UpdatePurchaseOrderItemMutation,
    UpdatePurchaseOrderItemMutationProps,
  ] = useMutation(PO_ITEM_UPDATE, {
    fetchPolicy: "network-only",
  });

  const setSupplier = (supplierId: string) => {
    setSupplierSelected(
      suppliersAvailable.filter(
        (supplier: SupplierDTO) => supplier.id === supplierId
      )[0]
    );
  };
  // ------------------------------------- Hooks -----------------------------------------------
  useEffect(() => {
    if (purchaseOrderItem?.supplier) {
      setSupplier(purchaseOrderItem?.supplier.id);
    }
  }, [purchaseOrderItem]);

  useEffect(() => {
    if (getPurchaseOrderItemQueryResponse?.data) {
      setPurchaseOrderSelected(
        getPurchaseOrderItemQueryResponse.data.getPurchaseOrderItem
          .purchaseOrder
      );
      setPurchaseOrderItem(
        getPurchaseOrderItemQueryResponse.data.getPurchaseOrderItem
      );
    }
  }, [getPurchaseOrderItemQueryResponse]);

  useEffect(() => {
    setIsSupplierLoading(getSuppliersQueryResponse.loading);
    if (getSuppliersQueryResponse.data) {
      setSuppliersAvailable(getSuppliersQueryResponse.data.suppliers);
    }
  }, [getSuppliersQueryResponse]);

  useEffect(() => {
    setIsPoLoading(getPurchaseOrdersQueryResponse.loading);
    if (getPurchaseOrdersQueryResponse.data) {
      setPurchaseOrdersAvailable(
        getPurchaseOrdersQueryResponse.data.purchaseOrders
      );
    }
  }, [getPurchaseOrdersQueryResponse]);

  useEffect(() => {
    if (isEdit) {
      setNavigationHelper(true);
    } else {
      setNavigationHelper(false);
    }
  }, []);

  // ------------------------------------- Define Obkect Schema -----------------------------------------------
  const NewPurchaseOrderItemSchema = Yup.object().shape({
    productCode: Yup.string().required("Product Code is required"),
    productName: Yup.string().required("Product Name is required"),
    equipmentManufacturer: Yup.string().required(
      "Equipment Manufacturer is required"
    ),
    equipmentDescription: Yup.string().required(
      "Equipment Description is required"
    ),
    quantity: Yup.number().required("Quantity is required"),
    containsPCHM: Yup.boolean(),
    containsCHM: Yup.boolean(),
    inScope: Yup.boolean(),
    PoDate: Yup.date(),
    EquipmentId: Yup.string(),
    ProductDescription: Yup.string(),
    General: Yup.string(),
    OrderStatus: Yup.string(),
    MdSDoCStatus: Yup.string(),
    FormType: Yup.string(),
    ExternalCategory: Yup.string(),
  });

  // ------------------------------------- Initialize Form -----------------------------------------------
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      productCode: purchaseOrderItem ? purchaseOrderItem.productCode : "",
      productName: purchaseOrderItem ? purchaseOrderItem.productName : "",
      equipmentManufacturer: purchaseOrderItem
        ? purchaseOrderItem.equipmentManufacturer
        : "",
      equipmentDescription: purchaseOrderItem
        ? purchaseOrderItem.equipmentDescription
        : "",
      quantity: purchaseOrderItem ? `${purchaseOrderItem.quantity}` : "",
      containsPCHM: purchaseOrderItem ? purchaseOrderItem.containsPCHM : false,
      containsCHM: purchaseOrderItem ? purchaseOrderItem.containsCHM : false,
      inScope: purchaseOrderItem ? purchaseOrderItem.inScope : false,
      PoDate: purchaseOrderItem ? purchaseOrderItem.PoDate : "",
      EquipmentId: purchaseOrderItem ? purchaseOrderItem.EquipmentId : "",
      ProductDescription: purchaseOrderItem
        ? purchaseOrderItem.ProductDescription
        : "",
      General: purchaseOrderItem ? purchaseOrderItem.General : "",
      OrderStatus: purchaseOrderItem ? purchaseOrderItem.OrderStatus : "",
      MdSDoCStatus: purchaseOrderItem ? purchaseOrderItem.MdSDoCStatus : "",
      FormType: purchaseOrderItem ? purchaseOrderItem.FormType : "",
      ExternalCategory: purchaseOrderItem
        ? purchaseOrderItem.ExternalCategory
        : "",
    },
    validationSchema: NewPurchaseOrderItemSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        if (isEdit && purchaseOrderItemId != undefined) {
          // CASE UPDATE
          const updatedPurchaseOrderItemObject = {
            id: parseInt(purchaseOrderItemId),
            purchaseOrderId:
              purchaseOrderSelected?.id != undefined
                ? parseInt(purchaseOrderSelected.id)
                : 0,
            vesselId: purchaseOrderItem?.vesselId,
            productCode: values.productCode,
            productName: values.productName,
            equipmentManufacturer: values.equipmentManufacturer,
            equipmentDescription: values.equipmentDescription,
            quantity: parseInt(values.quantity),
            containsPCHM: values.containsPCHM,
            containsCHM: values.containsCHM,
            inScope: values.inScope,
            PoDate: values.PoDate,
            EquipmentId: values.EquipmentId,
            ProductDescription: values.ProductDescription,
            General: values.General,
            MdSDoCStatus: values.MdSDoCStatus,
            FormType: values.FormType,
            ExternalCategory: values.ExternalCategory,
            supplierId: supplierSelected?.id,
          };

          const useUpdatePurchaseOrderItemMutation = () => {
            UpdatePurchaseOrderItemMutation({
              variables: {
                updatePurchaseOrderItemInput: updatedPurchaseOrderItemObject,
              },
              onError(err: ApolloError) {
                toast.configure();
                toast.error(`Error: ${err.message}`);
                console.error(err);
              },
            });
          };
          useUpdatePurchaseOrderItemMutation();
        } else {
          // CASE CREATE
          if (vesselId) {
            const newPurchaseOrderItemObject = {
              purchaseOrderId:
                purchaseOrderSelected?.id != undefined
                  ? parseInt(purchaseOrderSelected.id)
                  : 0,
              vesselId: parseInt(vesselId),
              productCode: values.productCode,
              productName: values.productName,
              equipmentManufacturer: values.equipmentManufacturer,
              equipmentDescription: values.equipmentDescription,
              quantity: parseInt(values.quantity),
              containsPCHM: values.containsPCHM,
              containsCHM: values.containsCHM,
              inScope: values.inScope,
              PoDate: values.PoDate,
              EquipmentId: values.EquipmentId,
              ProductDescription: values.ProductDescription,
              General: values.General,
              OrderStatus: OrderStatus.CREATED,
              MdSDoCStatus: values.MdSDoCStatus,
              FormType: values.FormType,
              ExternalCategory: values.ExternalCategory,
              supplierId: supplierSelected?.id,
            };

            const useCreatePurchaseOrderItemMutation = () => {
              CreatePurchaseOrderItemMutation({
                variables: {
                  createPurchaseOrderItemInput: newPurchaseOrderItemObject,
                },
                onError(err) {
                  toast.configure();
                  toast.error(`Error: ${err.message}`);
                  console.error(err);
                },
              });
            };
            useCreatePurchaseOrderItemMutation();
          }
        }

        resetForm();
        setSubmitting(false);
        enqueueSnackbar(
          !isEdit
            ? `Purchase Order Item created successfully!`
            : `Purchase Order Item updated successfully!`,

          { variant: "success" }
        );
        // navigate("/dashboard/purchasedOrders/list");
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        toast.configure();
        toast.error(`Error: ${error}`);
      }
    },
  });

  // ------------------------------------- Handlers -----------------------------------------------
  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;

  useEffect(() => {
    if (values?.PoDate) {
      const theDate = new Date(values?.PoDate);
      const formattedDate = moment(theDate).format("YYYY-MM-DD");
      setPODateValue(formattedDate);
    }
  }, [values?.PoDate]);

  const handlePurchaseOrderInput = (event: SelectChangeEvent) => {
    setPurchaseOrderSelected(
      purchaseOrdersAvailable.filter(
        (poObject: PurchaseOrderDTO) =>
          poObject.id === (event.target.value as string)
      )[0]
    );
  };
  const handleSupplierInput = (event: SelectChangeEvent) => {
    setSupplierSelected(
      suppliersAvailable.filter(
        (supplier: SupplierDTO) =>
          supplier.id === (event.target.value as string)
      )[0]
    );
  };

  const handleContainsPCHMInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue("containsPCHM", event.target.checked);
  };
  const handleContainsCHMInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue("containsCHM", event.target.checked);
  };
  const handleInScopeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue("inScope", event.target.checked);
  };

  // ----------------------------------------------------------------------------------------------
  return (
    <Page title="Purchased Order">
      <Container maxWidth={"xl"}>
        <HeaderBreadcrumbs
          heading="Purchased Orders"
          links={[
            { name: "Dashboard" },
            { name: "Purchased Orders" },
            { name: "Purchased Order" },
          ]}
        />

        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Card sx={{ p: 3, minWidth: "150%" }}>
                  <Stack spacing={3}>
                    {/* PO SELECT */}
                    <FormControl
                      variant="standard"
                      fullWidth
                      sx={{ marginBottom: 2 }}
                    >
                      <InputLabel
                        id="purchase-order-select-label"
                        sx={{ color: "text.primary" }}
                      >
                        Select Purchase Order
                      </InputLabel>
                      {
                        <Select
                          variant="standard"
                          disabled={purchaseOrderItem ? true : false}
                          labelId="select-purchase-order-label"
                          id="select-purchase-order"
                          label="Purchase Order"
                          value={
                            purchaseOrderSelected != undefined
                              ? purchaseOrderSelected.id
                              : ""
                          }
                          onChange={handlePurchaseOrderInput}
                        >
                          {isPoLoading ? (
                            <LinearProgress color="primary" sx={{ pt: -1 }} />
                          ) : (
                            purchaseOrdersAvailable?.map(
                              (
                                purchaseOrderAvailableObject: PurchaseOrderDTO,
                                index: number
                              ) => (
                                <MenuItem
                                  key={index}
                                  value={purchaseOrderAvailableObject.id}
                                >
                                  {purchaseOrderAvailableObject.number}
                                </MenuItem>
                              )
                            )
                          )}
                        </Select>
                      }
                    </FormControl>

                    <FormControl
                      variant="standard"
                      fullWidth
                      sx={{ marginBottom: 2 }}
                    >
                      <InputLabel
                        id="purchase-order-select-label"
                        sx={{ color: "text.primary" }}
                      >
                        Select Supplier
                      </InputLabel>
                      {
                        <Select
                          variant="standard"
                          labelId="select-supplier-label"
                          id="select-supplier"
                          label="Supplier"
                          value={
                            supplierSelected != undefined
                              ? supplierSelected.id
                              : ""
                          }
                          onChange={handleSupplierInput}
                        >
                          {isSupplierLoading ? (
                            <LinearProgress color="primary" sx={{ pt: -1 }} />
                          ) : (
                            suppliersAvailable?.map(
                              (supplier: SupplierDTO, index: number) => (
                                <MenuItem key={index} value={supplier.id}>
                                  {supplier.name}
                                </MenuItem>
                              )
                            )
                          )}
                        </Select>
                      }
                    </FormControl>

                    {/* FORM FIELDS */}
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 3, sm: 2 }}
                    >
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Product Code *"
                        {...getFieldProps("productCode")}
                        error={Boolean(
                          touched.productCode && errors.productCode
                        )}
                        helperText={touched.productCode && errors.productCode}
                        value={values?.productCode}
                      />
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Product Name *"
                        {...getFieldProps("productName")}
                        error={Boolean(
                          touched.productName && errors.productName
                        )}
                        helperText={touched.productName && errors.productName}
                        value={values?.productName}
                      />
                    </Stack>

                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 3, sm: 2 }}
                    >
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Equipment Id"
                        {...getFieldProps("EquipmentId")}
                        error={Boolean(
                          touched.EquipmentId && errors.EquipmentId
                        )}
                        helperText={touched.EquipmentId && errors.EquipmentId}
                        value={values?.EquipmentId}
                      />
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Quantity *"
                        {...getFieldProps("quantity")}
                        error={Boolean(touched.quantity && errors.quantity)}
                        helperText={touched.quantity && errors.quantity}
                        value={values?.quantity}
                      />
                    </Stack>

                    <TextField
                      variant="standard"
                      fullWidth
                      label="Equipment Manufacturer *"
                      {...getFieldProps("equipmentManufacturer")}
                      error={Boolean(
                        touched.equipmentManufacturer &&
                          errors.equipmentManufacturer
                      )}
                      helperText={
                        touched.equipmentManufacturer &&
                        errors.equipmentManufacturer
                      }
                      value={values?.equipmentManufacturer}
                    />
                    <TextField
                      variant="standard"
                      fullWidth
                      label="Equipment Description *"
                      {...getFieldProps("equipmentDescription")}
                      error={Boolean(
                        touched.equipmentDescription &&
                          errors.equipmentDescription
                      )}
                      helperText={
                        touched.equipmentDescription &&
                        errors.equipmentDescription
                      }
                      value={values?.equipmentDescription}
                    />

                    <TextField
                      variant="standard"
                      fullWidth
                      label="Product Description"
                      {...getFieldProps("ProductDescription")}
                      error={Boolean(
                        touched.ProductDescription && errors.ProductDescription
                      )}
                      helperText={
                        touched.ProductDescription && errors.ProductDescription
                      }
                      value={values?.ProductDescription}
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Purchase Order Date"
                        value={pODateValue}
                        onChange={(dateVal: any) => {
                          if (dateVal) {
                            setFieldValue("PoDate", dateVal);
                          }
                        }}
                        renderInput={(params: any) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </LocalizationProvider>

                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 3, sm: 2 }}
                    >
                      <TextField
                        variant="standard"
                        fullWidth
                        label="MdSDoC Status"
                        {...getFieldProps("MdSDoCStatus")}
                        error={Boolean(
                          touched.MdSDoCStatus && errors.MdSDoCStatus
                        )}
                        helperText={touched.MdSDoCStatus && errors.MdSDoCStatus}
                        value={values?.MdSDoCStatus}
                      />
                    </Stack>

                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 3, sm: 2 }}
                    >
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Form Type"
                        {...getFieldProps("FormType")}
                        error={Boolean(touched.FormType && errors.FormType)}
                        helperText={touched.FormType && errors.FormType}
                        value={values?.FormType}
                      />
                      <TextField
                        variant="standard"
                        fullWidth
                        label="External Category"
                        {...getFieldProps("ExternalCategory")}
                        error={Boolean(
                          touched.ExternalCategory && errors.ExternalCategory
                        )}
                        helperText={
                          touched.ExternalCategory && errors.ExternalCategory
                        }
                        value={values?.ExternalCategory}
                      />
                    </Stack>

                    <TextField
                      variant="standard"
                      fullWidth
                      label="General"
                      {...getFieldProps("General")}
                      error={Boolean(touched.General && errors.General)}
                      helperText={touched.General && errors.General}
                      value={values?.General}
                    />
                  </Stack>

                  <FormGroup row>
                    <FormControlLabel
                      label="ContainsPCHM"
                      control={
                        <Checkbox
                          checked={values?.containsPCHM}
                          onChange={handleContainsPCHMInput}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                    />
                    <FormControlLabel
                      label="ContainsCHM"
                      control={
                        <Checkbox
                          checked={values?.containsCHM}
                          onChange={handleContainsCHMInput}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                    />
                    <FormControlLabel
                      label="InScope"
                      control={
                        <Checkbox
                          checked={values?.inScope}
                          onChange={handleInScopeInput}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                    />
                  </FormGroup>

                  <Box
                    sx={{
                      mt: 3,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        navigate(`/dashboard/purchasedOrders/list`);
                      }}
                    >
                      Cancel
                    </LoadingButton>

                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={
                        !isEdit
                          ? CreatePurchaseOrderItemMutationProps.loading
                          : UpdatePurchaseOrderItemMutationProps.loading
                      }
                      disabled={
                        !isEdit
                          ? CreatePurchaseOrderItemMutationProps.loading
                          : UpdatePurchaseOrderItemMutationProps.loading
                      }
                    >
                      {!isEdit ? "Create Item" : "Save Changes"}
                    </LoadingButton>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
