// material
import { Box, Button, Container, Typography } from "@mui/material";
// custom
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IhmItemMaterialRowDTO } from "../../@types/IHMMaterialRow";
import { GET_VESSEL_HAS_DRAWING_VIEW_PATH_BY_IHM_ID } from "../../graphQL/Queries";
import { DialogAnimate } from "../animate";
import { MapperActionsEnum } from "../map/Mapper";
import SinglePositionMapper from "../map/SinglePositionMapper";
import Scrollbar from "../Scrollbar";
import { Link } from "react-router-dom";

// Define Queries
const useGetVesselHasDrawingQuery = (
  ihmItemId: number | undefined,
  isOpenPreview: boolean
) =>
  useQuery(GET_VESSEL_HAS_DRAWING_VIEW_PATH_BY_IHM_ID, {
    variables: {
      ihmItemId: ihmItemId,
    },
    skip: !ihmItemId || isOpenPreview === false,
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

// ----------------------------------------------------------------------
type PlaceSingleIhmPositionModalProps = {
  isOpenPreview: boolean;
  onClosePreview: VoidFunction;
  selectedMaterialRow: IhmItemMaterialRowDTO;
  isPreviewMode?: boolean;
  allowedActions: MapperActionsEnum;
};

export default function PlaceSingleIhmPositionModal({
  isOpenPreview,
  onClosePreview,
  selectedMaterialRow,
  isPreviewMode,
  allowedActions,
}: PlaceSingleIhmPositionModalProps) {
  // use queries
  const getVesselHasDrawingQueryResponse = useGetVesselHasDrawingQuery(
    selectedMaterialRow?.ihmId,
    isOpenPreview
  );

  return (
    <DialogAnimate
      open={isOpenPreview}
      onClose={onClosePreview}
      cardMinWidth={800}
      cardMinHeight={720}
    >
      {getVesselHasDrawingQueryResponse?.data
        ?.getVesselHasDrawingViewByIhmId ? (
        <Scrollbar>
          <Container>
            <SinglePositionMapper
              ihmItemMaterialRow={selectedMaterialRow}
              modalClose={onClosePreview}
              isPreviewMode={isPreviewMode}
              allowedActions={allowedActions}
              isFromModal={true}
            />
          </Container>
        </Scrollbar>
      ) : (
        <Box sx={{ m: 4 }}>
          <Typography variant="h4">
            The Specified vessel does not have any vessel Drawings. Follow the
            Link above to the{" "}
            <Link
              to={`/dashboard/vessels/${selectedMaterialRow?.vesselId}/edit`}
            >
              Vessel Dashboard
            </Link>{" "}
            where you can add any Drawings of you choice.
          </Typography>
        </Box>
      )}
    </DialogAnimate>
  );
}
