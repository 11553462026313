import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import L from "leaflet";
import { toast } from "react-toastify";
import { IHMDTO, IhmItemPositionDTO } from "../../../@types/IHM";
import { VesselDrawingDTO } from "../../../@types/Vessel";
import {
  GET_IHM_POSITION_BY_ID,
  GET_IHM_REMAINING_QUANTITY_QUERY,
} from "../../../graphQL/Queries";
import ReplaceIhmInstallationVesselMap from "./ReplaceIhmInstallationVesselMap";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  Mapper: {
    padding: "0 45px 20px",
  },
  modalHeader: {
    marginBottom: "24px",
  },
  headerTitle: {
    "&.MuiTypography-root": {
      textAlign: "center",
      fontWeight: "600",
      marginBottom: "24px",
    },
  },
  headerDescription: {
    "&.MuiTypography-root": {
      fontSize: "14px",
    },
  },
  step1: {
    marginBottom: "24px",
  },
  step2: {
    marginBottom: "24px",
  },
  selectionField: {
    "&>.MuiSelect-select": {
      fontSize: "14px",
    },
  },
}));

export const useGetPositionQuery = (_positionId: number | undefined) =>
  useQuery(GET_IHM_POSITION_BY_ID, {
    variables: {
      id: _positionId,
    },
    skip: !_positionId,
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

export const useGetIhmRemainingQuantityQuery = (ihmId: number | undefined) =>
  useQuery(GET_IHM_REMAINING_QUANTITY_QUERY, {
    variables: {
      ihmId: ihmId,
    },
    skip: !ihmId,
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

type ReplaceIhmInstallationMapperProps = {
  ihmItemPosition: IhmItemPositionDTO;
  ihmItem: IHMDTO;
  ihmItemDrawingsAvailable: VesselDrawingDTO[] | undefined;
  modalClose: VoidFunction;
};

const ReplaceIhmInstallationMapper = ({
  ihmItemPosition,
  ihmItem,
  modalClose,
  ihmItemDrawingsAvailable,
}: ReplaceIhmInstallationMapperProps) => {
  const classesNew = useStyles();
  const [ihmItemDrawingSelected, setIhmItemDrawingSelected] =
    useState<VesselDrawingDTO>();

  L.Icon.Default.imagePath = "leaflet/";

  // Use Queries
  const useGetIhmRemainingQuantityQueryResponse =
    useGetIhmRemainingQuantityQuery(ihmItem?.id);

  // ------------------------------------------------------------------ Hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (
      ihmItemDrawingsAvailable != undefined &&
      ihmItemDrawingsAvailable?.length > 0 &&
      ihmItemDrawingsAvailable[0]
    ) {
      setIhmItemDrawingSelected(ihmItemDrawingsAvailable[0]);
    }
  }, [ihmItemDrawingsAvailable]);

  // -----------------------------------------------------------------------------------------------------------------------------------
  return (
    <div className={classesNew.Mapper}>
      <Stack className={classesNew.modalHeader}>
        <Typography className={classesNew.headerTitle}>
          Replace Request
        </Typography>
        <Typography className={classesNew.headerDescription}>
          You are about to proceed in the replacement of an item containing
          Hazardous Materials (HMs) which is installed on the vessel, with
          another item containing HMs found in stored items. The installed item
          wiil be removed entirely, and will still be visible in the ‘Removed’
          part of the IHM, but will no longer be considered as installed on the
          vessel. The installed item will retain the location and equipment
          description of the removed item. You request will be submitted for
          approval by HazDash IHM Maintenance Experts.
        </Typography>
      </Stack>
      <Stack className={classesNew.step1}>
        <Typography sx={{ fontSize: "14px", mb: 1 }}>
          1. Select Vessel Drawing*.
        </Typography>
        <FormControl variant="standard" fullWidth>
          <Select
            variant="standard"
            labelId={`select-label-id-}`}
            id={`select-id-}`}
            label="Header Mapping"
            value={
              ihmItemDrawingSelected?.id ? `${ihmItemDrawingSelected.id}` : ""
            }
            disabled
          >
            {ihmItemDrawingsAvailable != undefined &&
            ihmItemDrawingsAvailable?.length > 0
              ? ihmItemDrawingsAvailable.map(
                  (ihmItemDrawing: VesselDrawingDTO, index: number) => {
                    return (
                      <MenuItem key={index} value={ihmItemDrawing.id}>
                        {`${ihmItemDrawing.path?.slice(
                          0,
                          ihmItemDrawing.path?.indexOf("/")
                        )} - ${ihmItemDrawing.name}`}
                      </MenuItem>
                    );
                  }
                )
              : null}
          </Select>
        </FormControl>
      </Stack>
      <Stack className={classesNew.step2}>
        <Typography sx={{ fontSize: "14px" }}>
          2. Click to place a marker on the installation position of item on
          Drawing*.
        </Typography>
      </Stack>

      {ihmItem && ihmItemPosition && (
        <ReplaceIhmInstallationVesselMap
          ihmItem={ihmItem}
          ihmItemPosition={ihmItemPosition}
          selectedVesselDrawing={ihmItemDrawingSelected}
          modalClose={modalClose}
        />
      )}
    </div>
  );
};

export default ReplaceIhmInstallationMapper;
