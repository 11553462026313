// material
import { Box, Card, Grid, Typography } from "@mui/material";
//components
// hooks
import { ApolloError, useQuery } from "@apollo/client";
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import epelogobig from "../../assets/epe-logo.png";
import Page from "../../components/Page";
import { GET_DOCUMENT_INFO_QUERY } from "../../graphQL/Queries";

const InfoRow = (key: string, value: string) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        mb: 4,
      }}
    >
      <Typography variant="body1">{key}</Typography>
      <Typography variant="body1" sx={{ fontWeight: "bold", textAlign: "right" }}>
        {value}
      </Typography>
    </Box>
  );
};
// ----------------------------- Define Queries ---------------------------------------------------------
const useGetDocumentInfoQuery = (uuid: string | undefined) =>
  useQuery(GET_DOCUMENT_INFO_QUERY, {
    variables: {
      uuid: uuid,
    },
    skip: !uuid,
    onError(err: ApolloError) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });
// ---------------------------------------------------------------------------------------------------------------------------------------------------------
export default function DocumentPreviewPage() {
  const { uuid } = useParams();

  // ----------------------------- Use Queries ----------------------------------------------------
  const useGetDocumentInfoQueryResponse = useGetDocumentInfoQuery(uuid);

  const sendEmail = (e: any) => {
    e.preventDefault();
    if (useGetDocumentInfoQueryResponse?.data?.getDocumentInfo?.documentName) {
      location.href = `mailto:support@hazdash.com?subject=Request for Document ${useGetDocumentInfoQueryResponse?.data?.getDocumentInfo?.documentName}`;
    }
  };

  // ----------------------------------------------------------------------------------------------
  return (
    <Page title="HazDash Document Online Verification">
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#006298",
        }}
      >
        <nav
          style={{
            backgroundColor: "whitesmoke",
            width: "100%",
            height: "95px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <img src={epelogobig} alt="epe logo" />
        </nav>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "770px",
            padding: "0px 15px 20px",
            margin: "20px 15px",
            borderRadius: 3,
          }}
        >
          <Typography variant="h4" sx={{ m: 1, textAlign:"center" }}>
            HAZDASH Documents Online Verification
          </Typography>
          <Typography variant="body1" sx={{ m: 0, mb: 4 }}>
            Thank you for submitting your document for verification. <br />
            Please see below verification result and detail of corresponding
            document.
          </Typography>
          {useGetDocumentInfoQueryResponse?.data?.getDocumentInfo?.isValid ===
          true ? (
            <Icon
              icon="clarity:success-standard-line"
              width={50}
              height={50}
              color="rgb(96,168,122)"
            />
          ) : (
            <Icon
              icon="akar-icons:circle-x"
              width={50}
              height={50}
              color="rgb(231,63,67)"
            />
          )}
          <Box
            sx={{
              m: 0,
              mb: 4,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              padding: "0px 0px"
            }}
          >
            {InfoRow(
              "Document Status*:",
              `${
                useGetDocumentInfoQueryResponse?.data?.getDocumentInfo?.isValid
                  ? "Valid"
                  : !useGetDocumentInfoQueryResponse?.data?.getDocumentInfo?.isFound ? "Not Found" : "Pending Approval"
              }`
            )}
            {InfoRow(
              "Document Type:",
              useGetDocumentInfoQueryResponse?.data?.getDocumentInfo
                ?.documentType
            )}
            {InfoRow(
              "Document No:",
              useGetDocumentInfoQueryResponse?.data?.getDocumentInfo
                ?.documentName
            )}
            {InfoRow(
              "Issuer:",
              useGetDocumentInfoQueryResponse?.data?.getDocumentInfo?.issuer
            )}
            {InfoRow(
              "Issue Date:",
              useGetDocumentInfoQueryResponse?.data?.getDocumentInfo?.issueDate?.split('T')[0].replaceAll('-','/')
            )}
          </Box>
          <LoadingButton variant="contained" onClick={sendEmail} disabled={!useGetDocumentInfoQueryResponse?.data?.getDocumentInfo?.isFound}>
            Request a copy **
          </LoadingButton>
          <Typography variant="body1" sx={{ mt: 1, ml: 0, mr: 0, textAlign:"justify-content" }}>
            All generated documents via HAZDASH are under the scope of the
            relevant <a href="#/app/terms" target="_blank">Terms of Use</a>. If you have any questions or comments,
            please contact us at <a href = "mailto: support@hazdash.com ">support@hazdash.com </a>.
          </Typography>
          <Typography
            variant="body1"
            sx={{ mt: 0, ml: 0, mr: 0, fontStyle: "italic", textAlign:"justify-content" }}
          >
            *Documents pending approval have been submitted for review by
            EPE&apos;s team of experienced HAZMAT experts. Their contents appear
            as declared by the Issuer, but EPE may not guarantee the validity of
            those contents according to the stipulated review process.
          </Typography>
          <Typography
            variant="body1"
            sx={{ mt: 0, ml: 0, mr: 0, mb: 0, fontStyle: "italic" , textAlign:"justify-content" }}
          >
            **If you wish a copy of this document, you may submit a request at <a href = "mailto: support@hazdash.com ">support@hazdash.com </a>, stating your role, details and reason for
            requesting.
          </Typography>
        </Card>
      </Grid>
    </Page>
  );
}
