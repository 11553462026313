import { useState, useEffect, useRef, useCallback } from "react";
// material
import {
  Card,
  Box,
  Tooltip,
} from "@mui/material";
// utils
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useQuery } from "@apollo/client";
import {
  GET_SUPPLIER_COMPLIANCE_QUERY,
} from "../../../graphQL/Queries";

import {
  DataGrid,
  GridToolbar,
  GridFilterPanel,
  GridSelectionModel,
  GridFilterModel,
  GridSortModel,
  GridColumns,
  GridRowModel,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { useKeycloak } from "@react-keycloak/web";
import { FilterTypeDTO, OperationValue } from "../../../@types/DataGrid";
import SingleDataGridBar from "../../charts/SingleDataGridBar";

const useStyles = makeStyles(() => ({
  customStyle: {
    "& .MuiDataGrid-toolbarContainer": {
      background: "#ffffff",
    },
  },
}));


const useGetSupplierComplianceQuery = (
    shipOwnerId: string | undefined,
    limit: number,
    page: number,
    filtersArray: FilterTypeDTO[],
    sortField: string | undefined,
    sortOrder: string | undefined,
) =>
  useQuery(GET_SUPPLIER_COMPLIANCE_QUERY, {
    variables: {
        shipOwnerId: shipOwnerId ? parseInt(shipOwnerId) : 0,
        supplierId: 0,
        limit: limit,
        page: page,
        filtersArray: filtersArray,
        sortingOrder: {
            field: sortField,
            sort: sortOrder,
        },
    },
    skip: !shipOwnerId,
    onError(err) {
      toast.configure();
      toast.error(err.message);
    },
    fetchPolicy: "network-only",
  });

type SupplierComplianceTableProps = {
    selectedShipOwnerId?: string | undefined;
};

export default function SupplierComplianceTable ({ selectedShipOwnerId }: SupplierComplianceTableProps) {
    // Native props
    const { initialized, keycloak } = useKeycloak();
    const classesNew = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const [dataTableRows, setDataTableRows] = useState<GridRowModel[]>([]);
    // datagrid filtering
    const [filtersArray, setFiltersArray] = useState<FilterTypeDTO[]>([]);
    // dataGrid sorting
    const [sortField, setSortField] = useState<string>("purchaseOrderNumber");
    const [sortOrder, setSortOrder] = useState<string>("asc");
    // dataGrid selection
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const prevSelectionModel = useRef<GridSelectionModel>(selectionModel);

    // Get User Role from keycloack
    const [userRole, setUserRole] = useState<undefined|string>(undefined);

    // Use Queries
    const useGetSupplierComplianceQueryResponse = useGetSupplierComplianceQuery(
        selectedShipOwnerId,
        rowsPerPage,
        page,
        filtersArray,
        sortField,
        sortOrder,
    )

    // --------------------------------- Mutations -------------------------------------------------

    // --------------------------------- Component Lifecycle ------------------------------------------------------
    useEffect(()=>{
        if (useGetSupplierComplianceQueryResponse?.data?.getSupplierCompliance) {
            const dataTableRowsArray: GridRowModel[] = [];
            for(const supplierCompliance of useGetSupplierComplianceQueryResponse.data.getSupplierCompliance){
                const dataTableRow = {
                    id: supplierCompliance.id,
                    supplierId: supplierCompliance.supplierId,
                    shipOwnerId: supplierCompliance.shipOwnerId,
                    name: supplierCompliance.name,
                    country: supplierCompliance.country,
                    city: supplierCompliance.city,
                    phoneNumber: supplierCompliance.phoneNumber,
                    compliance: supplierCompliance.compliance,
                    responseRate: supplierCompliance.responseRate,
                    numberOfPOs: supplierCompliance.numberOfPOs,
                    inScopePOs: supplierCompliance.inScopePOs,
                    isValid: supplierCompliance.isValid,
                };
                dataTableRowsArray.push(dataTableRow);
            }
            setDataTableRows(dataTableRowsArray);
        }
    },[useGetSupplierComplianceQueryResponse.data])

    useEffect(()=>{
        if (initialized) {
        if (keycloak.realmAccess) {
            if (keycloak.realmAccess.roles) {
            if (keycloak.realmAccess.roles.find((x) => x === "ShipOwner")) {
                setUserRole("ShipOwner");
            } else if (keycloak.realmAccess.roles.find((x) => x === "Supplier")) {
                setUserRole("Supplier");
            } else if (keycloak.realmAccess.roles.find((x) => x === "Maintainer")) {
                setUserRole("Maintainer");
            }
            }
        }else if(window.sessionStorage.getItem('userRole')){
            setUserRole(window.sessionStorage.getItem('userRole')?.toString());
        }
        }
    },[keycloak,initialized])

    // --------------------------------- handlers ---------------------------------------------------
    // sorting handler
    const handleSortModelChange = (newModel: GridSortModel) => {
        if (
            newModel !== null &&
            newModel != undefined &&
            newModel.length == 1 &&
            newModel[0].sort &&
            newModel[0].field
        ) {
            setSortField(newModel[0].field);
            setSortOrder(newModel[0].sort?.toString());
        }
    };
    // filtering handler
    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        const properFilterArray: FilterTypeDTO[] = [];

        filterModel.items.forEach((filterObject) => {
            let opVal = OperationValue.UNKNOWN;
        if (
            filterObject.columnField != undefined &&
            filterObject.operatorValue != undefined &&
            filterObject.value != undefined
        ) {
            let opVal = OperationValue.UNKNOWN;
            switch (filterObject.operatorValue) {
            case "contains":
                opVal = OperationValue.CONTAINS;
                break;
            case "equals":
                opVal = OperationValue.EQUALS;
                break;
            case "startsWith":
                opVal = OperationValue.STARTS_WITH;
                break;
            case "endsWith":
                opVal = OperationValue.ENDS_WITH;
                break;
            default:
                break;
            }
            const properFilter: FilterTypeDTO = {
                columnField: filterObject.columnField,
                operatorValue: opVal,
                value:  filterObject.value,
            };
            properFilterArray.push(properFilter);
        }else if (filterObject.operatorValue=="isEmpty" || filterObject.operatorValue=="isNotEmpty"){
            switch (filterObject.operatorValue) {
            case "isEmpty":
                opVal = OperationValue.IS_EMPTY;
                break;
            case "isNotEmpty":
                opVal = OperationValue.IS_NOT_EMPTY;
                break;
            default:
                break;
            }
            const properFilter: FilterTypeDTO = {
                columnField: filterObject.columnField,
                operatorValue: opVal,
                value: opVal==4 || opVal==5 ? '' : filterObject.value,
            };
            properFilterArray.push(properFilter);
        }
        });
        if (properFilterArray.length > 0) {
        setFiltersArray(properFilterArray);
        }
    }, []);

    // ------------------------------------------------ Table Header ---------------------------------------------------------------------------------
    const TABLE_COLUMNS: GridColumns = [
        {
            field: "name",
            headerName: "Name",
            width: 180,
            filterable: true,
            sortable: true,
        },
        {
            field: "country",
            headerName: "Country",
            width: 180,
            filterable: true,
            sortable: true,
        },
        {
            field: "city",
            headerName: "City",
            width: 180,
            filterable: true,
            sortable: true,
        },
        {
            field: "phoneNumber",
            headerName: "Phone Number",
            width: 180,
            filterable: true,
            sortable: true,
        },
        {
            field: "compliance",
            headerName: "Compliance %",
            width: 180,
            filterable: false,
            sortable: true,
            renderCell(params) {
                return(
                    <Tooltip title={`${params.value} %`}>
                        <Box sx={{ mt: 3, ml: -3 }}>
                            <SingleDataGridBar
                                value={params.value}
                                barColor={"#0079C1"}
                            />
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            field: "responseRate",
            headerName: "Response Rate %",
            width: 180,
            filterable: false,
            sortable: true,
            renderCell(params) {
                return(
                    <Tooltip title={`${params.value} %`}>
                        <Box sx={{ mt: 3, ml: -3 }}>
                            <SingleDataGridBar
                                value={params.value}
                                barColor={"#4cd964"}
                            />
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            field: "inScopePOs",
            headerName: "In Scope POs",
            width: 180,
            filterable: false,
            sortable: true,
        },
    ];

    return (
        <div>
        <Card sx={{ minHeight: "530px" }}>

            <Box
            sx={{
                height: 550,
                width: 1,
                "& .super-app-theme--2": {
                bgcolor: () => "rgba(91, 229, 132, 0.15)",
                },
                "& .super-app-theme--1": {
                bgcolor: () => "rgba(255, 244, 165, 0.5)",
                },
            }}
            >
                <DataGrid
                    columns={TABLE_COLUMNS}
                    rows={dataTableRows}
                    disableSelectionOnClick
                    components={{
                        Toolbar: GridToolbar,
                        FilterPanel: GridFilterPanel,
                    }}
                    className={classesNew.customStyle}
                    // // selection props
                    onSelectionModelChange={(newSelectionModel) =>
                    setSelectionModel(newSelectionModel)
                    }
                    selectionModel={selectionModel}
                    // filtering props
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                    // sorting props
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    // pagination props
                    pagination
                    paginationMode="server"
                    rowCount={dataTableRows.length}
                    rowsPerPageOptions={[20, 50, 100]}
                    pageSize={rowsPerPage}
                    onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
                    onPageChange={(newPage) => {
                        prevSelectionModel.current = selectionModel;
                        setPage(newPage);
                    }}
                    loading={useGetSupplierComplianceQueryResponse?.loading}
                />
            </Box>

        </Card>


        </div>
    );
}