import { useEffect, useState } from "react";
// material
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { SupplierInventoryMaterialDTO } from "../../../../@types/Supplier";
import { CREATE_SUPPLIER_INVENTORY_MATERIAL_MUTATION } from "../../../../graphQL/Mutations";
import {
  GET_POITEM_MATERIAL_DETAILS,
  GET_POITEM_MD_MATERIAL_NAMES,
} from "../../../../graphQL/Queries";

// --------------------------------------------- Define Queries ---------------------------------------
const useGetPOItemMDMaterialNamesQuery = (isEuSsr: boolean) =>
  useQuery(GET_POITEM_MD_MATERIAL_NAMES, {
    variables: {
      isEuSsr: isEuSsr,
    },
    onError(err: ApolloError) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

const useGetPOItemMaterialDetailsQuery = (materialName: string | undefined) =>
  useQuery(GET_POITEM_MATERIAL_DETAILS, {
    variables: {
      materialName: materialName,
    },
    skip: !materialName,
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

type AddNewSupplierInventoryMaterialFormProps = {
  supplierInventoryId: number;
  onClosePreview: VoidFunction;
};
// ---------------------------------------------------------------------------------------------------------------------------------------------------------
export default function AddNewSupplierInventoryMaterialForm({
  supplierInventoryId,
  onClosePreview,
}: AddNewSupplierInventoryMaterialFormProps) {
  // native props
  const { enqueueSnackbar } = useSnackbar();
  // Select Name
  const [availableNames, setAvailableNames] = useState<string[]>([]);
  // Select Category
  const [availableCategories, setAvailableCategories] = useState<string[]>([]);
  const [availableUnits, setAvailableUnits] = useState<string[]>([
    "kg",
    "gr",
    "mg",
  ]);

  // Define Form Schema
  const NewSupplierInventoryMaterialTable = Yup.object().shape({
    name: Yup.string().required("Please provide a value for material name"),
    category: Yup.string().required(
      "Please provide a value for material category"
    ),
    thresholdValue: Yup.string().required(
      "Please provide a value for material threshold value"
    ),
    presentAboveThresholdValue: Yup.boolean(),
    mass: Yup.number().required("Please provide a value for material mass"),
    unit: Yup.string().required("Please provide a value for material unit"),
  });

  // Initialize Form Schema values
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      category: "",
      thresholdValue: "",
      presentAboveThresholdValue: true,
      mass: 0,
      unit: "",
      comment: "",
    },
    validationSchema: NewSupplierInventoryMaterialTable,
    onSubmit: (values, { setSubmitting, resetForm }) => {},
  });
  const {
    errors,
    values,
    touched,
    handleSubmit,
    setFieldValue,
    getFieldProps,
  } = formik;

  // ------------------------------------- Use Queries -----------------------------------------------
  const getPOItemMDMaterialNamesQueryResponse =
    useGetPOItemMDMaterialNamesQuery(true);
  const getPOItemMaterialDetailsQueryResponse =
    useGetPOItemMaterialDetailsQuery(values.name);

  // ---------------------------------------- Hooks --------------------------------------------------
  // Get available names
  useEffect(() => {
    if (
      getPOItemMDMaterialNamesQueryResponse &&
      getPOItemMDMaterialNamesQueryResponse.data
    ) {
      setAvailableNames(
        getPOItemMDMaterialNamesQueryResponse.data.getMaterialNames
      );
    }
  }, [getPOItemMDMaterialNamesQueryResponse.data]);

  // Get available categories
  useEffect(() => {
    if (
      getPOItemMaterialDetailsQueryResponse &&
      getPOItemMaterialDetailsQueryResponse.data
    ) {
      setAvailableCategories(
        getPOItemMaterialDetailsQueryResponse.data.getMaterialDetails.categories
      );
    }
  }, [getPOItemMaterialDetailsQueryResponse.data]);

  useEffect(() => {
    if (getPOItemMaterialDetailsQueryResponse.data) {
      setFieldValue(
        "thresholdValue",
        getPOItemMaterialDetailsQueryResponse.data.getMaterialDetails
          ?.thresholdValue
      );
    }
  }, [values.name, getPOItemMaterialDetailsQueryResponse.data]);

  // --------------------------------- Mutations ---------------------------------------------------
  // CREATE
  const [
    CreateSupplierInventoryMaterialMutation,
    CreateSupplierInventoryMaterialMutationProps,
  ] = useMutation(CREATE_SUPPLIER_INVENTORY_MATERIAL_MUTATION, {
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err}`);
    },
    fetchPolicy: "network-only",
  });
  const useCreateSupplierInventoryMaterialMutation = (
    createSupplierInventoryMaterialInput: SupplierInventoryMaterialDTO
  ) => {
    CreateSupplierInventoryMaterialMutation({
      refetchQueries: "active",
      variables: {
        createSupplierInventoryMaterialInput:
          createSupplierInventoryMaterialInput,
      },
      onCompleted(dt) {
        if (dt.createSupplierInventoryMaterial) {
          formik.resetForm();
          formik.setSubmitting(false);
          enqueueSnackbar(
            `Supplier Inventory Material was added Successfully!`,
            { variant: "success" }
          );
          onClosePreview();
        }
      },
    });
  };

  // --------------------------------- handlers ---------------------------------------------------
  const handleNameInput = (event: SelectChangeEvent) => {
    setFieldValue(
      "name",
      availableNames.filter(
        (nameObject: string) => nameObject === (event.target.value as string)
      )[0]
    );
  };
  const handleCategoryInput = (event: SelectChangeEvent) => {
    const _category = availableCategories.filter(
      (categoryObject: string) =>
        categoryObject == (event.target.value as string)
    )[0];
    setFieldValue("category", _category);
  };
  const handleUnitInput = (event: SelectChangeEvent) => {
    const _selectedUnit = availableUnits.filter(
      (unit: string) => unit === (event.target.value as string)
    )[0];

    setFieldValue("unit", _selectedUnit);
  };
  const handleAddNewMaterial = () => {
    try {
      // CASE CREATE
      const newSupplierInventoryMaterialObject: SupplierInventoryMaterialDTO = {
        name: values.name,
        category:
          values.name == "Ozone Depleting Substances (ODS)"
            ? values.category
            : "",
        thresholdValue: values.thresholdValue,
        presentAboveThresholdValue: values.presentAboveThresholdValue,
        mass: parseFloat(`${values.mass}`) ? parseFloat(`${values.mass}`) : 0,
        unit: values.unit,
        supplierInventoryId: supplierInventoryId,
        comment: values.comment ? values.comment : "-"
      };
      // Use Mutation
      useCreateSupplierInventoryMaterialMutation(
        newSupplierInventoryMaterialObject
      );
    } catch (error) {
      console.error(error);
    }
  };

  // ----------------------------------------------------------------------------------------------
  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <FormControl
            variant="standard"
            sx={{ flex: "1 0 0", width: "350px" }}
          >
            <InputLabel
              id="supplier-inventory-material-label"
              sx={{ color: "text.primary" }}
            >
              Name *
            </InputLabel>
            <Select
              variant="standard"
              labelId="select-name-label"
              id="select-name"
              label="Name *"
              value={values.name ? values.name : ""}
              onChange={handleNameInput}
            >
              {availableNames?.map((nameObject: string, index: number) => (
                <MenuItem key={index} value={nameObject}>
                  {nameObject}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="standard" sx={{ flex: "1 0 0" }}>
            <InputLabel
              id="demo-simple-select-label"
              sx={{ color: "text.primary" }}
            >
              Category *
            </InputLabel>
            <Select
              variant="standard"
              labelId="select-category-label"
              id="select-category"
              label="Category *"
              value={values.category ? values.category : ""}
              onChange={handleCategoryInput}
              disabled={values.name !== "Ozone Depleting Substances (ODS)"}
            >
              {availableCategories?.map(
                (categoryObject: string, index: number) => (
                  <MenuItem key={index} value={categoryObject}>
                    {categoryObject}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>

          <TextField
            variant="standard"
            fullWidth
            label="Threshold Value *"
            {...getFieldProps("thresholdValue")}
            error={Boolean(touched.thresholdValue && errors.thresholdValue)}
            helperText={touched.thresholdValue && errors.thresholdValue}
            value={values?.thresholdValue ? values?.thresholdValue : ""}
            disabled
          />

          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 3, sm: 2 }}
          >
            <TextField
              variant="standard"
              fullWidth
              label="Mass *"
              {...getFieldProps("mass")}
              error={Boolean(touched.mass && errors.mass)}
              helperText={touched.mass && errors.mass}
              value={values?.mass}
            />
            <FormControl variant="standard" fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ color: "text.primary" }}
              >
                Unit *
              </InputLabel>
              <Select
                variant="standard"
                labelId="select-unit-label"
                id="select-unit"
                label="Unit *"
                value={values.unit ? values.unit : ""}
                onChange={handleUnitInput}
              >
                {availableUnits?.map((unit: string, index: number) => (
                  <MenuItem key={index} value={unit}>
                    {unit}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>

          <FormGroup sx={{ pb: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={true}
                  disabled
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Present Above Threshold Value"
            />
          </FormGroup>

          <TextField
              variant="standard"
              fullWidth
              label="Comment"
              {...getFieldProps("comment")}
              value={values?.comment}
            />

        </Stack>

        <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
          <LoadingButton variant="contained" onClick={onClosePreview}>
            cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={handleAddNewMaterial}
            loading={CreateSupplierInventoryMaterialMutationProps.loading}
            disabled={CreateSupplierInventoryMaterialMutationProps.loading}
          >
            Add
          </LoadingButton>
        </Box>
      </Form>
    </FormikProvider>
  );
}
