import React, { useState } from "react";
import { Icon } from "@iconify/react";
import arrowCircleRightOutline from "@iconify/icons-eva/arrow-circle-right-outline";
// material
import { Card, Typography, LinearProgress, Grid, Box } from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
import { useNavigate } from "react-router-dom";
//styles
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  cardSelected: {
    "&.MuiCard-root": {
      border: "1px solid #39464f",
      backgroundColor: "rgba(57, 70, 79, 0.08)",
    },
  },
  navigationIcon: {
    cursor: "pointer",
    color: "#0079C1",
    transition: "0.5s ease",
    "&:hover": {
      color: "#39464f",
      transition: "0.5s ease",
    },
  },
}));

// ----------------------------------------------------------------------

type NumberCardProps = {
  Title: string;
  Value: string | undefined;
  isloading?: boolean;
  isFilter?: boolean;
  filterCallback?: (isSelected: boolean) => void;
  navigationUrl?: string;
  sx?: any;
  gridSx?: any;
};

export default function NumberCard({
  Title,
  Value,
  isloading,
  isFilter,
  filterCallback,
  navigationUrl,
  sx,
  gridSx,
}: NumberCardProps) {
  const classesNew = useStyles();
  const [isSelected, setIsSelected] = useState<boolean | undefined>(false);
  const navigate = useNavigate();

  if (
    navigationUrl != undefined &&
    navigationUrl !== null &&
    navigationUrl.length > 0
  ) {
    isFilter = false;
  }

  const selectedHandler = () => {
    if (isFilter) {
      setIsSelected(!isSelected);
      if (filterCallback) {
        filterCallback(!isSelected);
      }
    }
  };

  const navigationHandler = (navigationUrl: string) => {
    navigate(navigationUrl);
  };

  return (
    <Grid sx={gridSx ? gridSx : {}}>
      <Card
        sx={
          sx
            ? sx
            : {
                display: "flex",
                alignItems: "flex-start",
                p: 2,
                minHeight: "100px",
                width: "220px",
                cursor: isFilter ? "pointer" : "default",
              }
        }
        onClick={() => selectedHandler()}
        className={isSelected ? classesNew.cardSelected : ""}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            columnGap: 1,
          }}
        >
          {isloading && <LinearProgress color="primary" sx={{ pt: -1 }} />}
          <Typography variant="subtitle2" sx={{ fontSize: "18px", flex: 1 }}>
            {Title}
          </Typography>
          <Typography variant="h2" sx={{}}>
            {Value != undefined ? fNumber(Value) : 0}
          </Typography>
          {navigationUrl != undefined &&
            navigationUrl !== null &&
            navigationUrl.length > 0 && (
              <Icon
                width={45}
                height={45}
                icon={arrowCircleRightOutline}
                className={classesNew.navigationIcon}
                onClick={() => navigationHandler(navigationUrl)}
              />
            )}
        </Box>
      </Card>
    </Grid>
  );
}
