// material
import { useQuery } from "@apollo/client";
import { Icon } from "@iconify/react";
import { Box, Button, Container, IconButton, Typography } from "@mui/material";
// custom
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IHMDTO } from "../../../@types/IHM";
import {
  GET_IHM_ITEM_QUERY,
  GET_VESSEL_DRAWINGS_BY_IHM,
} from "../../../graphQL/Queries";
import { DialogAnimate } from "../../animate";
import InstallIhmMapper from "../../map/installIhm/InstallIhmMapper";
import Scrollbar from "../../Scrollbar";

// ----------------------------------------------------------------------
// Define Queries
const useGetIhmItemQuery = (ihmItemId: number) =>
  useQuery(GET_IHM_ITEM_QUERY, {
    variables: {
      id: ihmItemId,
    },
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

export const useGetVesselDrawingEntitiesQuery = (ihmId: number | undefined) =>
  useQuery(GET_VESSEL_DRAWINGS_BY_IHM, {
    variables: {
      ihmId: ihmId,
    },
    skip: !ihmId,
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

type InstallIhmMapperModalProps = {
  isOpenPreview: boolean;
  onClosePreview: VoidFunction;
  ihmId: number;
};

export default function InstallIhmMapperModal({
  isOpenPreview,
  onClosePreview,
  ihmId,
}: InstallIhmMapperModalProps) {
  const [ihmItem, setIhmItem] = useState<IHMDTO>();

  const useGetIhmItemQueryResponse = useGetIhmItemQuery(ihmId);

  const useGetVesselDrawingEntitiesQueryResponse =
    useGetVesselDrawingEntitiesQuery(ihmItem?.id);

  useEffect(() => {
    if (useGetIhmItemQueryResponse?.data) {
      setIhmItem(useGetIhmItemQueryResponse.data.getIhmItem);
    }
  }, [useGetIhmItemQueryResponse.data]);

  return (
    <DialogAnimate
      open={isOpenPreview}
      onClose={onClosePreview}
      cardMinWidth={
        useGetVesselDrawingEntitiesQueryResponse?.data?.findVesselDrawingByIhm
          .length > 0 &&
        useGetVesselDrawingEntitiesQueryResponse?.loading == false
          ? 800
          : 500
      }
      cardMinHeight={
        useGetVesselDrawingEntitiesQueryResponse?.data?.findVesselDrawingByIhm
          .length > 0 &&
        useGetVesselDrawingEntitiesQueryResponse?.loading == false
          ? 650
          : 150
      }
    >
      {useGetVesselDrawingEntitiesQueryResponse?.data?.findVesselDrawingByIhm
        .length > 0 &&
      useGetVesselDrawingEntitiesQueryResponse?.loading == false ? (
        <Box>
          <Scrollbar>
            <Container sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mb: 3,
                  mr: -1,
                  mt: 0.5,
                }}
              >
                <IconButton aria-label="delete" onClick={onClosePreview}>
                  <Icon
                    icon="akar-icons:circle-x"
                    width={30}
                    color={"#26567B"}
                  />
                </IconButton>
              </Box>
              {ihmItem && (
                <InstallIhmMapper
                  ihmItem={ihmItem}
                  modalClose={onClosePreview}
                  ihmItemDrawingsAvailable={
                    useGetVesselDrawingEntitiesQueryResponse?.data
                      ?.findVesselDrawingByIhm
                  }
                />
              )}
            </Container>
          </Scrollbar>
        </Box>
      ) : (
        <div>
          <Typography variant="h4" sx={{ m: 3 }}>
            The specified vessel does not contain any Drawings.
          </Typography>
          <Button
            variant="contained"
            sx={{ mb: 3, ml: "220px" }}
            onClick={onClosePreview}
          >
            Ok
          </Button>
        </div>
      )}
    </DialogAnimate>
  );
}
